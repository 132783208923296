export const FULL_OPACITY = 100;
export const PARTIAL_OPACITY = 30;
// I'm not sure that should be hard-coded
export const DATASET_WITH_FULL_OPACITY = '450k (v4) Public';
export const getDashboardDatasets = (datasets, defaultDataset = DATASET_WITH_FULL_OPACITY) => {
    const mappedDatasets = datasets.map((dataset) => ({
        id: dataset.version_id,
        name: dataset.version_name,
        provider: dataset.provider,
        description: dataset.metadata,
        opacity: dataset.version_name === defaultDataset ? FULL_OPACITY : PARTIAL_OPACITY,
    }));
    if (mappedDatasets.length === 1) {
        mappedDatasets[0].opacity = FULL_OPACITY;
    }
    return mappedDatasets;
};

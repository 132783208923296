import { ELevelResult } from 'Shared/types';
import { EProvider, PROVIDER_NAME } from 'Common/consts';
/**
 * Class service with static methods for work with Datasets
 */
export class DatasetsService {
    /**
     * Creates an empty array of Datasets
     *
     * @static
     * @returns An empty array
     */
    static get empty() {
        return [];
    }
    /**
     * Gets the name of dataset's provider
     *
     * @static
     * @param providerId - unique id passed a provider
     * @throws Error
     * @returns Provider name
     */
    static getProviderName(providerId) {
        const providerName = PROVIDER_NAME[providerId];
        if (!providerName) {
            throw new Error(`Cannot get name of unsupported provider: ${providerId}`);
        }
        return providerName;
    }
    /**
     * Gets the default level result type for dataset's provider
     *
     * @static
     * @param providerId - unique id passed a provider
     * @throws Error
     * @returns Default level result
     */
    static getProviderDefaultLevelResult(providerId) {
        switch (providerId) {
            case EProvider.UKB:
                return ELevelResult.Gene;
            case EProvider.FinnGen:
                return ELevelResult.Variant;
            default:
                throw new Error(`Cannot get default level result for unsupported provider: ${providerId}`);
        }
    }
    /**
     * Gets an object of providers and dataset for them as key and values
     *
     * If there are multiple datasets for the same provider,
     * the last one will be returned
     * @static
     * @param datasets - array of dataset
     * @returns Object of providers and dataset for them as key and values
     */
    static getLastByProvider(datasets) {
        return datasets.reduce((acc, dataset) => (Object.assign(Object.assign({}, acc), { [dataset.providerId]: dataset })), {});
    }
    /**
    * Gets an array of datasets with unique providers
    *
    * If there are default values for particular provider the default one will be returned
    * otherwise the last one will be returned
    * @static
    * @param datasets - array of dataset
    * @param defaultDatasetVersion - Object of providers and default dataset
    * for them as key and values
    * @returns Array of datasets with unique providers
    */
    static getDefaultForProvider(datasets, defaultDatasetVersion) {
        const defaultDatasets = Object.entries(defaultDatasetVersion)
            .reduce((acc, [provider, name]) => {
            const dataset = datasets.find((item) => provider === item.providerId && item.name === name);
            if (dataset) {
                acc[dataset.providerId] = dataset;
            }
            return acc;
        }, {});
        const lastDatasets = DatasetsService.getLastByProvider(datasets);
        return Object.values(Object.assign(Object.assign({}, lastDatasets), defaultDatasets));
    }
}

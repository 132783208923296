import { DEFAULT_API_SERVICE_URI } from 'API/consts';
import { RESTResource } from '../RESTResource';
/* eslint-disable @typescript-eslint/indent */
export class CollapsingModelsResource extends RESTResource {
    constructor() {
        super((urlParams) => {
            var _a;
            const { datasetVersionId } = urlParams;
            const urlRoot = (_a = process.env.DATASETS_SERVICE_URI) !== null && _a !== void 0 ? _a : DEFAULT_API_SERVICE_URI;
            return `${urlRoot}/v1/${datasetVersionId}/collapsing_models`;
        });
    }
}
export default new CollapsingModelsResource();

/** @todo Remove this file after moving all types to their actual scope */
export var EPhewasType;
(function (EPhewasType) {
    EPhewasType["Gene"] = "gene";
    EPhewasType["Phenotype"] = "phenotype";
})(EPhewasType || (EPhewasType = {}));
export var EPageName;
(function (EPageName) {
    EPageName["About"] = "About";
    EPageName["Gene"] = "Gene";
    EPageName["GeneDashboard"] = "GeneDashboard";
    EPageName["Phenotype"] = "Phenotype";
    EPageName["PhenotypeCatalogue"] = "PhenotypeCatalogue";
    EPageName["QQ"] = "QQ";
    EPageName["QV"] = "QV";
    EPageName["FES"] = "FES";
    EPageName["Search"] = "Search";
    EPageName["Variant"] = "Variant";
    EPageName["VariantDashboard"] = "VariantDashboard";
    EPageName["ModelDefinitions"] = "ModelDefinitions";
})(EPageName || (EPageName = {}));

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, memo, useMemo, } from 'react';
import { Button } from 'Shared/components';
import { hasDatasetProviderLabel } from 'Search/utils';
import styles from './RecentSuggestion.css';
const RecentSuggestion = (props) => {
    const { index, suggestion, onClick, onMouseOver, onRemove, isSelected = false, } = props;
    const { name, datasetProviderName, type } = suggestion;
    const className = useMemo(() => (isSelected ? `${styles.item} ${styles.itemSelected}` : styles.item), [isSelected]);
    const handleSelect = useCallback(() => onMouseOver(index), [index, onMouseOver]);
    const handleClick = useCallback(() => onClick(suggestion), [suggestion, onClick]);
    const handleRemove = useCallback((event) => {
        event.stopPropagation();
        onRemove(index);
    }, [index, onRemove]);
    const hasProviderLabel = hasDatasetProviderLabel(type, datasetProviderName);
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    return (_jsxs("li", Object.assign({ className: className, title: name, onClick: handleClick, onMouseEnter: handleSelect, onFocus: handleSelect, "data-testid": "RecentSuggestion" }, { children: [_jsxs("div", Object.assign({ className: styles.labels }, { children: [_jsx("p", Object.assign({ className: styles.label }, { children: name }), void 0), hasProviderLabel && (_jsxs("p", Object.assign({ className: styles.providerLabel }, { children: ["(", datasetProviderName, ")"] }), void 0))] }), void 0), _jsx(Button, Object.assign({ className: styles.removeButton, onClick: handleRemove }, { children: "remove" }), void 0)] }), void 0));
};
export default memo(RecentSuggestion);

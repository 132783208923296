import { ETraitsTypes } from 'Shared/types';
import { logger } from 'Common/utils/logger/logger';
/**
 * Class service with static methods for work with TraitsType
 */
export class TraitsTypeService {
    /**
     * Gets a Traits type value
     * @static
     * @param traitsType - traits type
     * @param defaultTraitsType - default traits type
     * @returns Traits type value
     */
    static create(traitsType, defaultTraitsType) {
        if (traitsType === ETraitsTypes.Binary) {
            return ETraitsTypes.Binary;
        }
        if (traitsType === ETraitsTypes.Continuous) {
            return ETraitsTypes.Continuous;
        }
        if (defaultTraitsType) {
            logger.warn(`Received invalid traits type.${defaultTraitsType} traits will be set by default`);
            return defaultTraitsType;
        }
        throw new Error(`Invalid traits type provided: ${traitsType}`);
    }
    /**
     * Gets an opposite traits type value
     * @static
     * @param traitsType - traits type
     * @returns Traits type value
     */
    static getOppositeTraits(traitsType) {
        return traitsType === ETraitsTypes.Binary ? ETraitsTypes.Continuous : ETraitsTypes.Binary;
    }
}

import { ELoadingStatus } from 'API/types';
import { VariantMetadataService } from 'Common/services';
export const getInitialState = () => ({
    dataExists: false,
    loadingStatus: ELoadingStatus.Init,
    loadingMessage: '',
    errorType: null,
    variantId: '',
    details: {
        variantMetadata: VariantMetadataService.empty,
    },
    categories: [],
    associations: {
        collection: {},
        order: [],
    },
    datasets: [],
    significantAssociations: [],
    significantIndicators: {},
    countedPhenotypes: {
        significantBinaryPhenotypesCount: 0,
        significantContinuousPhenotypesCount: 0,
        suggestiveBinaryPhenotypesCount: 0,
        suggestiveContinuousPhenotypesCount: 0,
    },
});

import { createMockedURL, getMock } from 'API/utils';
import { phenotypeIdMock, phenotypeNameMock } from 'Common/mocks';
import { encodeStringToBase64 } from 'Common/utils';
import phenotypeIdResource from './resource';
export const phenotypeIdResponseMock = JSON.stringify(phenotypeIdMock);
export const emptyPhenotypeIdResponseMock = JSON.stringify('');
export const encodedPhenotypeNameMock = encodeStringToBase64(phenotypeNameMock);
export const requestParams = {
    phenotypeNameBase64: encodedPhenotypeNameMock,
};
const mockedURL = {
    [createMockedURL(phenotypeIdResource, requestParams)]: {
        full: phenotypeIdResponseMock,
        empty: emptyPhenotypeIdResponseMock,
    },
};
export const fixtures = getMock(mockedURL);

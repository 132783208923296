import { DEFAULT_API_SERVICE_URI } from 'API/consts';
import { RESTResource } from '../RESTResource';
var VariantSourceUrl;
(function (VariantSourceUrl) {
    VariantSourceUrl["OMIM"] = "omim";
    VariantSourceUrl["HGMD"] = "hgmd";
    VariantSourceUrl["CLIN_VAR"] = "clin_var";
    VariantSourceUrl["EBI_GWAS"] = "ebi-gwas";
    VariantSourceUrl["GBMI"] = "gbmi";
})(VariantSourceUrl || (VariantSourceUrl = {}));
export const externalResourceData = new RESTResource(({ externalVariantSource, geneId, }) => {
    var _a;
    const urlRoot = (_a = process.env.GENES_SERVICE_URI) !== null && _a !== void 0 ? _a : DEFAULT_API_SERVICE_URI;
    return `${urlRoot}/v1/genes/${geneId}/${VariantSourceUrl[externalVariantSource]}/`;
});
export const externalVariantSources = new RESTResource(({ geneId }) => {
    var _a;
    const urlRoot = (_a = process.env.GENES_SERVICE_URI) !== null && _a !== void 0 ? _a : DEFAULT_API_SERVICE_URI;
    return `${urlRoot}/v1/genes/${geneId}/stats/`;
});
export default {
    externalVariantSources,
    externalResourceData,
};

import { ChromosomesService } from 'Common/services';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setChromosomes } from 'Store/cacheData/cacheData.slice';
import { getChromosomesLoaded } from 'Store/cacheData/selectors/selectors';
function useCacheDataFlag(delay = 3600000) {
    const dispatch = useDispatch();
    const isLoaded = useSelector(getChromosomesLoaded);
    const timer = useCallback(() => {
        dispatch(setChromosomes(ChromosomesService.empty));
    }, [dispatch]);
    useEffect(() => {
        if (isLoaded) {
            setTimeout(timer, delay);
        }
    }, [isLoaded, timer, delay]);
}
export default useCacheDataFlag;

import { datasetVersionIdMock, phenotypeIdMock } from 'Common/mocks';
import { getMock } from 'API/utils';
import { EConsequenceType } from 'Shared/plots/types';
import { UKBDatasetVersionId, FinnGenDatasetVersionId } from '../datasets';
import { phenotypeStatsResourceGLR, phenotypeStatsResourcePLR, phenotypeStatsResourceVLR } from './resource';
export const phenotypeStatsResponseMock = {
    cases: 2932,
    controls: 9991,
    largest_effect_size: null,
    lowest_pvalue: 0.044996091480126364,
    most_extreme_odds_ratio: 472945586,
    participants: 2035,
    consequence_types: [
        EConsequenceType.StopGained,
        EConsequenceType.BidirectionalGeneFusion,
        EConsequenceType.ProteinAlteringVariant,
    ],
};
export const emptyResponse = {};
export const requestParamsUKB = {
    datasetVersionId: UKBDatasetVersionId,
    phenotypeId: phenotypeIdMock,
};
export const requestParamsCommon = {
    datasetVersionId: datasetVersionIdMock,
    phenotypeId: phenotypeIdMock,
};
export const requestParamsFinnGen = {
    datasetVersionId: FinnGenDatasetVersionId,
    phenotypeId: phenotypeIdMock,
};
const mockedURL = {
    // Gene level result
    [phenotypeStatsResourceGLR.URLFactory(requestParamsUKB)]: {
        full: phenotypeStatsResponseMock,
        empty: emptyResponse,
    },
    [phenotypeStatsResourceGLR.URLFactory(requestParamsCommon)]: {
        full: phenotypeStatsResponseMock,
        empty: emptyResponse,
    },
    [phenotypeStatsResourceGLR.URLFactory(requestParamsFinnGen)]: {
        full: phenotypeStatsResponseMock,
        empty: emptyResponse,
    },
    // Variant level result
    [phenotypeStatsResourceVLR.URLFactory(requestParamsUKB)]: {
        full: phenotypeStatsResponseMock,
        empty: emptyResponse,
    },
    [phenotypeStatsResourceVLR.URLFactory(requestParamsCommon)]: {
        full: phenotypeStatsResponseMock,
        empty: emptyResponse,
    },
    [phenotypeStatsResourceVLR.URLFactory(requestParamsFinnGen)]: {
        full: phenotypeStatsResponseMock,
        empty: emptyResponse,
    },
    // Phenotype level result
    [phenotypeStatsResourcePLR.URLFactory(requestParamsUKB)]: {
        full: phenotypeStatsResponseMock,
        empty: emptyResponse,
    },
    [phenotypeStatsResourcePLR.URLFactory(requestParamsCommon)]: {
        full: phenotypeStatsResponseMock,
        empty: emptyResponse,
    },
    [phenotypeStatsResourcePLR.URLFactory(requestParamsFinnGen)]: {
        full: phenotypeStatsResponseMock,
        empty: emptyResponse,
    },
};
export const fixtures = getMock(mockedURL);

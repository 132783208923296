import { geneNameMock, phenotypeIdMock, variantIdMock, datasetVersionIdMock, } from 'Common/mocks';
import { EDatasetsNames, EProvider } from 'Common/consts';
import { getMock } from 'API/utils';
import { genesResource, phenotypesResource, variantsResource, } from './resource';
export const UKBDatasetVersionId = 'a4936c3e-9c57-431e-8409-b33e28f968dc';
export const FinnGenDatasetVersionId = '1b240b82-4641-4d5d-8a09-08ec7cbbd222';
export const UBKWesDatasetVersionId = '88475cd0-0dac-4f58-8734-3db7541b95c2';
export const UBKWgsDatasetVersionId = '0c2fea04-ec12-487c-8290-0e2be22a2dc2';
export const UKB500kDatasetVersionId = '11111c3e-9c57-431e-8409-b33e28f55555';
export const GenesResponseDatasetVersionIdMock = datasetVersionIdMock;
export const UKB300kDatasetResponseMock = {
    version_id: GenesResponseDatasetVersionIdMock,
    version_name: EDatasetsNames.PUBLIC_300K,
    provider: EProvider.UKB,
    metadata: [
        'Phewas CGR_PHESANT v1.0.2',
        'UKB Phenotypic Release: v23',
        'Other extra statistics: 10000',
    ],
};
export const response = [
    {
        version_id: UKBDatasetVersionId,
        version_name: EDatasetsNames.PUBLIC_450K,
        provider: EProvider.UKB,
        metadata: [
            'Phewas CGR_PHESANT v1.0.2',
            'UKB Phenotypic Release: v27390',
            'UKB Exome Count: 268,451',
        ],
    },
    {
        version_id: FinnGenDatasetVersionId,
        version_name: EDatasetsNames.FINNGEN_R6_GWAS,
        provider: EProvider.FinnGen,
        metadata: [
            'Phewas CGR_PHESANT v1.0.5',
            'UKB Phenotypic Release: v1000',
            'Other statistics: 100',
        ],
    },
];
export const responseEvaDataset = [
    {
        version_id: '0b4a5314-7de1-41kd1-8f15-98173d78fb36',
        version_name: 'OMIM',
        provider: 'eva',
        metadata: [
            'version: 27 Feb 2022',
            'gene_mim_total: 4759',
        ],
    },
    {
        version_id: 'c0a5f33b-7e1d-4d85-a73c-0b4e07f21e9a',
        version_name: 'HGMD',
        provider: 'eva',
        metadata: [
            'version: Q2 2023',
        ],
    },
    {
        version_id: '1bb4ae37-328c-414d-9aa3-0414477266e4',
        version_name: 'ClinVar',
        provider: 'eva',
        metadata: [
            'version: 3 Jul 2023',
        ],
    },
];
export const genesResponse = [
    UKB300kDatasetResponseMock,
    ...response,
];
export const genesResponseWithEvaDataset = [
    UKB300kDatasetResponseMock,
    ...response,
    ...responseEvaDataset,
];
export const phenotypesResponseMock = [
    ...response,
    UKB300kDatasetResponseMock,
];
export const variantsResponse = [
    ...response,
    {
        version_id: '082b5371-de6f-4691-8c18-88bb6b9edf01',
        version_name: EDatasetsNames.PUBLIC_300K,
        provider: EProvider.UKB,
        metadata: [
            'Phewas CGR_PHESANT v1.0.2',
            'UKB Phenotypic Release: v445',
            'Other extra statistics: 10340',
        ],
    },
];
export const emptyResponse = [];
const mockedURL = {
    [genesResource.URLFactory({ resourceId: geneNameMock })]: {
        full: genesResponse,
        empty: emptyResponse,
    },
    [phenotypesResource.URLFactory({ resourceId: phenotypeIdMock })]: {
        full: phenotypesResponseMock,
        empty: emptyResponse,
    },
    [variantsResource.URLFactory({ resourceId: variantIdMock })]: {
        full: variantsResponse,
        empty: emptyResponse,
    },
};
export const fixtures = getMock(mockedURL);

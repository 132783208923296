import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, Fragment, memo, } from 'react';
import { NoWrapLabel } from 'Common/components/NoWrapLabel';
import { SEARCH_SUGGESTIONS_LIMIT } from 'Search/consts';
import { ESearchSuggestionsType, ECombinedSuggestionsType, } from 'Search/types';
import SearchSuggestion from '../SearchSuggestion/SearchSuggestion';
import styles from './SearchSuggestions.css';
export const SUGGESTIONS_TYPE_LABELS = {
    [ESearchSuggestionsType.Gene]: 'Gene',
    [ESearchSuggestionsType.Phenotype]: 'Phenotype',
    [ESearchSuggestionsType.Variant]: 'Variant',
    [ESearchSuggestionsType.FullMatch]: '',
    [ECombinedSuggestionsType.Recent]: 'Recent Search',
};
const SearchSuggestions = (props) => {
    const { suggestions, suggestionsGroups, suggestionsIndex, onSuggestionClick, onSuggestionMouseOver, onSuggestionMouseOut, renderItem = (itemProps) => (_jsx(SearchSuggestion, Object.assign({}, itemProps), void 0)), className, hasNoticeText = true, } = props;
    const suggestionsContent = useMemo(() => (suggestions.length === 0
        ? (_jsx("li", Object.assign({ className: styles.noSuggestions }, { children: "No suggestions have been found" }), void 0))
        : (suggestions.map((suggestionsItem, index) => {
            const groupLabel = (suggestionsGroups[index]) && (_jsx("li", Object.assign({ className: styles.groupLabel, "aria-label": "suggestionGroup" }, { children: SUGGESTIONS_TYPE_LABELS[suggestionsGroups[index]] }), void 0));
            const key = `${suggestionsItem.type}-${index}`;
            const isSelected = index === suggestionsIndex;
            return (_jsxs(Fragment, { children: [groupLabel, renderItem({
                        index,
                        suggestion: suggestionsItem,
                        onClick: onSuggestionClick,
                        onMouseOver: onSuggestionMouseOver,
                        isSelected,
                        className,
                    })] }, key));
        }))), [
        suggestions,
        suggestionsGroups,
        suggestionsIndex,
        renderItem,
        onSuggestionClick,
        onSuggestionMouseOver,
        className,
    ]);
    const showNoticeText = hasNoticeText && suggestions.length === SEARCH_SUGGESTIONS_LIMIT;
    return (_jsxs("div", Object.assign({ className: styles.layout }, { children: [_jsx("ul", Object.assign({ className: styles.list, onMouseLeave: onSuggestionMouseOut, onBlur: onSuggestionMouseOut, "aria-label": "searchSuggestion" }, { children: suggestionsContent }), void 0), showNoticeText && (_jsxs("p", Object.assign({ className: styles.noticeText }, { children: [_jsxs(NoWrapLabel, { children: ["Number of results is capped at", ' ', SEARCH_SUGGESTIONS_LIMIT, ".\u00A0"] }, void 0), _jsx(NoWrapLabel, { children: "Enter more characters to focus your search" }, void 0)] }), void 0))] }), void 0));
};
export default memo(SearchSuggestions);

export class Route {
    constructor(pathPattern, defaultParams) {
        this.pathPattern = pathPattern;
        this.optionalParams = [];
        const pathParts = this.pathPattern.split('/');
        this.paramsCollection = {};
        this.pathParts = pathParts.map((part, index) => {
            if (!part.startsWith(':')) {
                return part;
            }
            let paramName = part.substr(1);
            const isOptional = paramName.endsWith('?');
            if (isOptional) {
                paramName = paramName.substr(0, paramName.length - 1);
                this.optionalParams.push(`:${paramName}`);
            }
            if (!paramName) {
                return '';
            }
            this.paramsCollection[paramName] = index;
            if (isOptional) {
                return null;
            }
            return part;
        });
        if (defaultParams) {
            Object.entries(defaultParams).forEach(([paramName, paramValue]) => {
                const paramIndex = this.paramsCollection[paramName];
                if (paramIndex === undefined) {
                    return;
                }
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                this.pathParts[paramIndex] = paramValue;
            });
        }
    }
    getPathWithParams(routeParams) {
        if (!routeParams) {
            return this.pathPattern;
        }
        return Object.entries(routeParams).reduce((acc, [paramName, paramValue]) => {
            if (typeof paramValue !== 'string') {
                return acc;
            }
            const paramIndex = this.paramsCollection[paramName];
            if (paramIndex === undefined) {
                return acc;
            }
            acc[paramIndex] = paramValue;
            return acc;
        }, this.pathParts.slice())
            .filter((param) => param !== null)
            .join('/');
    }
    createArrayPathsWithOptionalParams() {
        const basePath = this.pathParts
            .filter((param) => param !== null).join('/');
        const optionalParams = [...this.optionalParams];
        return optionalParams.reduce((acc, _, index) => {
            const optionalPath = optionalParams.slice(0, index + 1).join('/');
            acc.push(`${basePath}/${optionalPath}/`);
            return acc;
        }, [basePath]);
    }
    getPath(routeParams) {
        return this.getPathWithParams(routeParams);
    }
    getPathsWithOptionalParams() {
        return this.createArrayPathsWithOptionalParams();
    }
    createSubroute(routeParams, appendPath = '') {
        return new Route(`${this.getPathWithParams(routeParams)}${appendPath}`);
    }
}

import { CollapsingModelService } from 'Common/services/CollapsingModel';
/**
 * Class service with static methods for represents CollapsingModels collection
 *
 */
export class CollapsingModelsService {
    /**
     * Returns empty CollapsingModels object
     *
     * @static
     * @returns Empty CollapsingModels object
     */
    static get empty() {
        return CollapsingModelsService.createCollection([]);
    }
    /**
     * Creates collection of CollapsingModels
     *
     * @static
     * @param collapsingModels - array with collapsingModels data from API
     * @returns collection of CollapsingModels
     */
    static createCollection(collapsingModels) {
        return collapsingModels.reduce((acc, { id, name, description, kind, }) => {
            acc[id] = CollapsingModelService.create(id, name, description, kind);
            return acc;
        }, {});
    }
    /**
     * Add new CollapsingModel to the CollapsingModels object collection
     *
     * @static
     * @param collection - CollapsingModels object collection
     * @param item - CollapsingModel object
     * @returns CollapsingModels object
     */
    static add(collection, item) {
        return Object.assign(Object.assign({}, collection), { [item.id]: item });
    }
    /**
     * Get CollapsingModel object from the CollapsingModels object collection
     *
     * @static
     * @param collection - CollapsingModels object collection
     * @param id - Id of CollapsingModel
     * @returns CollapsingModel object
     */
    static getCollapsingModelById(collection, id) {
        var _a;
        return (_a = collection[id]) !== null && _a !== void 0 ? _a : null;
    }
    /**
     * Get an array of CollapsingModel ids by defined level result
     *
     * @static
     * @param collapsingModels - CollapsingModels array
     * @param levelResult - level result kind
     * @returns CollapsingModel ids array
     */
    static getCollapsingModelIds(collapsingModels, levelResult) {
        if (!collapsingModels) {
            return [];
        }
        return collapsingModels.reduce((acc, curr) => {
            const currLevelResult = CollapsingModelService.getLevelResult(curr.kind, levelResult);
            if (currLevelResult === levelResult) {
                acc.push(curr.id);
            }
            return acc;
        }, []);
    }
    /**
     * Sort the CollapsingModel array by name property
     *
     * @static
     * @param collapsingModels - Array of the unsorted CollapsingModels object
     * @returns Array of the sorted CollapsingMode objects
     */
    static getSortedByName(collapsingModels) {
        return collapsingModels.sort((a, b) => a.name.localeCompare(b.name));
    }
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ParagraphWithMargin__paragraphWithMargin__LhQg0{margin:.5rem 0}", "",{"version":3,"sources":["webpack://./src/views/about/containers/ParagraphWithMargin/ParagraphWithMargin.css"],"names":[],"mappings":"AAAA,iDACE,cACF","sourcesContent":[".paragraphWithMargin {\n  margin: 0.5rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paragraphWithMargin": "ParagraphWithMargin__paragraphWithMargin__LhQg0"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ParagraphBolded__paragraphBolded__2pbWY{font-weight:700}", "",{"version":3,"sources":["webpack://./src/views/about/containers/ParagraphBolded/ParagraphBolded.css"],"names":[],"mappings":"AAAA,yCACE,eACF","sourcesContent":[".paragraphBolded {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paragraphBolded": "ParagraphBolded__paragraphBolded__2pbWY"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchDisclaimer__container__2VeX_{color:var(--font-color);line-height:1.25rem;width:45rem}.SearchDisclaimer__title__3XY3w{font-weight:700;margin-bottom:.3rem}", "",{"version":3,"sources":["webpack://./src/views/search/components/SearchDisclaimer/SearchDisclaimer.css"],"names":[],"mappings":"AAAA,oCAEE,uBAAwB,CADxB,mBAAoB,CAEpB,WACF,CAEA,gCACE,eAAgB,CAChB,mBACF","sourcesContent":[".container {\n  line-height: 1.25rem;\n  color: var(--font-color);\n  width: 45rem;\n}\n\n.title {\n  font-weight: 700;\n  margin-bottom: 0.3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SearchDisclaimer__container__2VeX_",
	"title": "SearchDisclaimer__title__3XY3w"
};
export default ___CSS_LOADER_EXPORT___;

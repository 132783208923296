import { getMock } from 'API/utils';
import resource from './resource';
export const response = {
    name: 'Ig_2',
    description: 'Immunoglobulin domain',
    pfamAccession: 'PF13895',
};
export const emptyResponse = {};
export const requestParams = {
    pfamDomainId: 'PF13895',
};
const mockedURL = {
    [resource.URLFactory(requestParams)]: {
        full: response,
        empty: emptyResponse,
    },
};
export const fixtures = getMock(mockedURL);

import { jsx as _jsx } from "react/jsx-runtime";
import { v4 as uuidv4 } from 'uuid';
import { ExternalLink } from 'Shared/components';
import { CGR_INFORMATICS_SUPPORT_EMAIL, DATA_LICENSE_URL } from 'Common/consts';
export const DATA_LICENSE_SECTION_TITLE = 'Data licence';
const KEY_PREFIX_PUBLIC = 'about-view-public_data-license';
export const DATA_LICENSE_PUBLIC = [
    {
        key: `${KEY_PREFIX_PUBLIC}_paragraph-1_${uuidv4()}`,
        description: [
            'You can download results using the functionality of this site as well as obtain a bulk download from us. You agree to the terms of the ',
            _jsx(ExternalLink, Object.assign({ to: DATA_LICENSE_URL, title: DATA_LICENSE_URL, isTextFieldLink: true }, { children: "LICENSE" }), `${KEY_PREFIX_PUBLIC}_data-license-link_${uuidv4()}`),
            ' for any downloaded data.',
        ],
    },
    {
        key: `${KEY_PREFIX_PUBLIC}_paragraph-2_${uuidv4()}`,
        description: [
            'To request access to a download of gene-level and variant-level results compiled across all available phenotypes, please contact ',
            _jsx(ExternalLink, Object.assign({ to: `mailto:${CGR_INFORMATICS_SUPPORT_EMAIL}`, isTextFieldLink: true }, { children: CGR_INFORMATICS_SUPPORT_EMAIL }), `${KEY_PREFIX_PUBLIC}_cgr-informatics-support-email_${uuidv4()}`),
            '.',
        ],
    },
];

import { logger } from 'Common/utils/logger/logger';
import { DEFAULT_API_SERVICE_URI } from 'API/consts';
import { RESTResource } from '../RESTResource';
const geneToleranceResourceSearchParams = {
    geneName: 'gene_name',
};
/* eslint-disable @typescript-eslint/indent */
export default new RESTResource(() => {
    var _a;
    const urlRoot = (_a = process.env.GENES_SERVICE_URI) !== null && _a !== void 0 ? _a : DEFAULT_API_SERVICE_URI;
    return `${urlRoot}/v1/genes/tolerance`;
}, 'get', geneToleranceResourceSearchParams, (error) => {
    logger.warn(`Error fetching Gene Tolerance data from service: ${error.message}. Empty data will be used instead`);
    return {
        ensembl_gene_id: '',
    };
});

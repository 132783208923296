/**
 * Types of search suggestions
 */
export var ESearchSuggestionsType;
(function (ESearchSuggestionsType) {
    ESearchSuggestionsType["Gene"] = "GENE";
    ESearchSuggestionsType["Phenotype"] = "BINARY_PHENOTYPE";
    ESearchSuggestionsType["Variant"] = "VARIANT";
    ESearchSuggestionsType["FullMatch"] = "FULL_MATCH_SUGGESTIONS";
})(ESearchSuggestionsType || (ESearchSuggestionsType = {}));
/**
 * Types of selected suggestions collections
 */
export var ESelectedSuggestionsType;
(function (ESelectedSuggestionsType) {
    ESelectedSuggestionsType[ESelectedSuggestionsType["Recent"] = 0] = "Recent";
    ESelectedSuggestionsType[ESelectedSuggestionsType["Search"] = 1] = "Search";
    ESelectedSuggestionsType[ESelectedSuggestionsType["Null"] = 2] = "Null";
})(ESelectedSuggestionsType || (ESelectedSuggestionsType = {}));
/**
 * Types which aggregate (consist of) multiple suggestion types
 */
export var ECombinedSuggestionsType;
(function (ECombinedSuggestionsType) {
    ECombinedSuggestionsType["Recent"] = "RECENT";
})(ECombinedSuggestionsType || (ECombinedSuggestionsType = {}));

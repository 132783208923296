import { jsx as _jsx } from "react/jsx-runtime";
import ManhattanPlot from 'Shared/plots/components/ManhattanPlot/ManhattanPlot';
import { Legend } from 'Shared/plots/components/ManhattanPlot/components/Legend';
import { transformSVGToString } from 'Shared/utils/transformSVGToString';
import { EXPORTED_MANHATTAN_PLOT_LOWER_THRESHOLD_CONFIG, EXPORTED_MANHATTAN_PLOT_UPPER_THRESHOLD_CONFIG, MANHATTAN_PLOT_AXIS_CONFIG, } from 'Common/consts/plot';
/**
 * Transform GPAManhattanPlot plot to string format
 *
 * @param config - Plot data, thresholds, axis and getDataPointShape
 * @returns - Plot in string format
 */
export const transformManhattanPlotToString = ({ plotData, getDataPointShape, labelsConfig, }) => Promise.resolve(transformSVGToString(_jsx(ManhattanPlot, { data: plotData, axis: MANHATTAN_PLOT_AXIS_CONFIG, upperThreshold: EXPORTED_MANHATTAN_PLOT_UPPER_THRESHOLD_CONFIG, lowerThreshold: EXPORTED_MANHATTAN_PLOT_LOWER_THRESHOLD_CONFIG, useInlineStyles: true, getDataPointShape: getDataPointShape, legend: (_jsx(Legend, { labelsConfig: labelsConfig, useInlineStyles: true }, void 0)) }, void 0)));

import { ELevelResult, ETraitsTypes } from 'Shared/types';
import { EVariantSource } from 'GeneDashboardView/components/ExternalVariantSources/types';
const TEALIUM_EVENT_PREFIX = 'user';
var EViews;
(function (EViews) {
    EViews["Gene"] = "gene_view";
    EViews["GeneDashboard"] = "gene_dashboard_view";
    EViews["Phenotype"] = "phenotype_view";
    EViews["Variant"] = "variant_view";
    EViews["VariantDashboard"] = "variant_dashboard_view";
    EViews["QV"] = "qv_view";
})(EViews || (EViews = {}));
export const TEALIUM_EVENT = {
    ABOUT_VIEW: `${TEALIUM_EVENT_PREFIX}_about`,
    MODEL_DEFINITIONS: `${TEALIUM_EVENT_PREFIX}_model_definitions`,
    SEARCH: `${TEALIUM_EVENT_PREFIX}_search`,
    FES_PLOT: `${TEALIUM_EVENT_PREFIX}_fes_plot`,
    QQ_PLOT: `${TEALIUM_EVENT_PREFIX}_qq_plot`,
    GENE_VIEW_GLR_BINARY: `${TEALIUM_EVENT_PREFIX}_${EViews.Gene}_${ELevelResult.Gene}_${ETraitsTypes.Binary}`,
    GENE_VIEW_GLR_CONTINUOUS: `${TEALIUM_EVENT_PREFIX}_${EViews.Gene}_${ELevelResult.Gene}_${ETraitsTypes.Continuous}`,
    GENE_VIEW_VLR_BINARY: `${TEALIUM_EVENT_PREFIX}_${EViews.Gene}_${ELevelResult.Variant}_${ETraitsTypes.Binary}`,
    GENE_VIEW_VLR_CONTINUOUS: `${TEALIUM_EVENT_PREFIX}_${EViews.Gene}_${ELevelResult.Variant}_${ETraitsTypes.Continuous}`,
    GENE_VIEW_PLR_BINARY: `${TEALIUM_EVENT_PREFIX}_${EViews.Gene}_${ELevelResult.Phenotype}_${ETraitsTypes.Binary}`,
    GENE_VIEW_PLR_CONTINUOUS: `${TEALIUM_EVENT_PREFIX}_${EViews.Gene}_${ELevelResult.Phenotype}_${ETraitsTypes.Continuous}`,
    GENE_VIEW_EXPORT_DATA: `${TEALIUM_EVENT_PREFIX}_${EViews.Gene}_export_data`,
    GENE_VIEW_MANHATTAN_PLOT_QV_LINK: `${TEALIUM_EVENT_PREFIX}_${EViews.Gene}_manhattan_plot_qv`,
    GENE_VIEW_MANHATTAN_PLOT_VARIANT_LINK: `${TEALIUM_EVENT_PREFIX}_${EViews.Gene}_manhattan_plot_variant`,
    GENE_VIEW_MANHATTAN_PLOT_PHENOTYPE_LINK: `${TEALIUM_EVENT_PREFIX}_${EViews.Gene}_manhattan_plot_phenotype`,
    GENE_DASHBOARD: `${TEALIUM_EVENT_PREFIX}_${EViews.GeneDashboard}`,
    GENE_DASHBOARD_MANHATTAN_PLOT_PHENOTYPE_LINK: `${TEALIUM_EVENT_PREFIX}_${EViews.GeneDashboard}_manhattan_plot_phenotype`,
    GENE_DASHBOARD_MANHATTAN_PLOT_VARIANT_LINK: `${TEALIUM_EVENT_PREFIX}_${EViews.GeneDashboard}_manhattan_plot_variant`,
    GENE_DASHBOARD_MANHATTAN_PLOT_QV_LINK: `${TEALIUM_EVENT_PREFIX}_${EViews.GeneDashboard}_manhattan_plot_qv`,
    GENE_DASHBOARD_OMIM: `${TEALIUM_EVENT_PREFIX}_${EViews.GeneDashboard}_${EVariantSource.OMIM}`,
    GENE_DASHBOARD_HGMD: `${TEALIUM_EVENT_PREFIX}_${EViews.GeneDashboard}_${EVariantSource.HGMD}`,
    GENE_DASHBOARD_GBMI: `${TEALIUM_EVENT_PREFIX}_${EViews.GeneDashboard}_${EVariantSource.GBMI}`,
    GENE_DASHBOARD_CLIN_VAR: `${TEALIUM_EVENT_PREFIX}_${EViews.GeneDashboard}_${EVariantSource.CLIN_VAR}`,
    GENE_DASHBOARD_EBI_GWAS: `${TEALIUM_EVENT_PREFIX}_${EViews.GeneDashboard}_${EVariantSource.EBI_GWAS}`,
    PHENOTYPE_VIEW_GLR: `${TEALIUM_EVENT_PREFIX}_${EViews.Phenotype}_${ELevelResult.Gene}`,
    PHENOTYPE_VIEW_VLR: `${TEALIUM_EVENT_PREFIX}_${EViews.Phenotype}_${ELevelResult.Variant}`,
    PHENOTYPE_VIEW_PLR: `${TEALIUM_EVENT_PREFIX}_${EViews.Phenotype}_${ELevelResult.Phenotype}`,
    PHENOTYPE_VIEW_EXPORT_DATA: `${TEALIUM_EVENT_PREFIX}_${EViews.Phenotype}_export_data`,
    PHENOTYPE_VIEW_FES_PLOT: `${TEALIUM_EVENT_PREFIX}_${EViews.Phenotype}_fes`,
    PHENOTYPE_VIEW_QQ_PLOT: `${TEALIUM_EVENT_PREFIX}_${EViews.Phenotype}_qq`,
    PHENOTYPE_VIEW_MANHATTAN_PLOT_GENE_LINK: `${TEALIUM_EVENT_PREFIX}_${EViews.Phenotype}_manhattan_plot_gene`,
    PHENOTYPE_VIEW_MANHATTAN_PLOT_QV_LINK: `${TEALIUM_EVENT_PREFIX}_${EViews.Phenotype}_manhattan_plot_qv`,
    PHENOTYPE_VIEW_MANHATTAN_PLOT_VARIANT_LINK: `${TEALIUM_EVENT_PREFIX}_${EViews.Phenotype}_manhattan_plot_variant`,
    QV_VIEW: `${TEALIUM_EVENT_PREFIX}_${EViews.QV}`,
    QV_VIEW_EXPORT_DATA: `${TEALIUM_EVENT_PREFIX}_${EViews.QV}_export_data`,
    QV_VIEW_LOLLIPOP_PLOT_GENE_LINK: `${TEALIUM_EVENT_PREFIX}_${EViews.QV}_lollipop_plot_gene`,
    QV_VIEW_LOLLIPOP_PLOT_PHENOTYPE_LINK: `${TEALIUM_EVENT_PREFIX}_${EViews.QV}_lollipop_plot_phenotype`,
    QV_VIEW_LOLLIPOP_PLOT_VARIANT_LINK: `${TEALIUM_EVENT_PREFIX}_${EViews.QV}_lollipop_plot_variant`,
    QV_VIEW_PROTEIN_VISUALIZATION_LINK: `${TEALIUM_EVENT_PREFIX}_${EViews.QV}_lollipop_plot_protein_visualization`,
    VARIANT_VIEW_VLR_BINARY: `${TEALIUM_EVENT_PREFIX}_${EViews.Variant}_${ELevelResult.Variant}_${ETraitsTypes.Binary}`,
    VARIANT_VIEW_VLR_CONTINUOUS: `${TEALIUM_EVENT_PREFIX}_${EViews.Variant}_${ELevelResult.Variant}_${ETraitsTypes.Continuous}`,
    VARIANT_VIEW_EXPORT_DATA: `${TEALIUM_EVENT_PREFIX}_${EViews.Variant}_export_data`,
    VARIANT_VIEW_MANHATTAN_PLOT_GENE_LINK: `${TEALIUM_EVENT_PREFIX}_${EViews.Variant}_manhattan_plot_gene`,
    VARIANT_VIEW_MANHATTAN_PLOT_PHENOTYPE_LINK: `${TEALIUM_EVENT_PREFIX}_${EViews.Variant}_manhattan_plot_phenotype`,
    VARIANT_DASHBOARD: `${TEALIUM_EVENT_PREFIX}_${EViews.VariantDashboard}`,
    VARIANT_DASHBOARD_MANHATTAN_PLOT_GENE_LINK: `${TEALIUM_EVENT_PREFIX}_${EViews.VariantDashboard}_manhattan_plot_gene`,
    VARIANT_DASHBOARD_MANHATTAN_PLOT_PHENOTYPE_LINK: `${TEALIUM_EVENT_PREFIX}_${EViews.VariantDashboard}_manhattan_plot_phenotype`,
};

export const DASHBOARD_PVALUE_THRESHOLD = 1e-3;
export const DASHBOARD_PVALUE_THRESHOLD_TEXT = DASHBOARD_PVALUE_THRESHOLD.toExponential();
export const SIGNIFICANT_ASSOCIATIONS_COUNT = 5;
export const SUGGESTIVE_ASSOCIATIONS_THRESHOLD = 1e-6;
export const SUGGESTIVE_ASSOCIATIONS_THRESHOLD_TEXT = SUGGESTIVE_ASSOCIATIONS_THRESHOLD
    .toExponential();
export const SIGNIFICANT_ASSOCIATIONS_THRESHOLD = 1e-8;
export const SIGNIFICANT_ASSOCIATIONS_THRESHOLD_TEXT = SIGNIFICANT_ASSOCIATIONS_THRESHOLD
    .toExponential();
export const VARIANT_DASHBOARD_NAME = 'Variant';
export const GENE_DASHBOARD_NAME = 'Gene';
export const INDICATORS_TOOLTIP_TEXT = `Number of distinct phenotypes significantly associated (p ≤ ${SIGNIFICANT_ASSOCIATIONS_THRESHOLD})`;

import { ETraitsTypes } from 'Shared/types';
import { getTableColumns as getBinaryTableColumns } from './BinaryTable/consts';
import { getTableColumns as getContinuousTableColumns } from './ContinuousTable/consts';
export const getTableColumns = {
    [ETraitsTypes.Binary]: getBinaryTableColumns,
    [ETraitsTypes.Continuous]: getContinuousTableColumns,
};
export const SORT_BY_VARIANT = [
    {
        id: 'variant_id',
        desc: false,
    },
];

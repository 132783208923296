import { ELevelResult, ETraitsTypes } from 'Shared/types';
import { EDatasetsNames } from 'Common/consts';
const traitsTypes = [
    ETraitsTypes.Binary,
    ETraitsTypes.Continuous,
];
export const linksConfig = {
    [EDatasetsNames.FINNGEN_R8]: {
        [ELevelResult.Variant]: [
            ETraitsTypes.Binary,
        ],
    },
    [EDatasetsNames.FINNGEN_R10]: {
        [ELevelResult.Variant]: [
            ETraitsTypes.Binary,
        ],
    },
    [EDatasetsNames.FINNGEN_R12]: {
        [ELevelResult.Variant]: [
            ETraitsTypes.Binary,
            ETraitsTypes.Continuous,
        ],
    },
    Variant: {
        [ELevelResult.Variant]: traitsTypes,
    },
    Gene: {
        [ELevelResult.Gene]: traitsTypes,
        [ELevelResult.Variant]: traitsTypes,
    },
};
/**
 * Check for Finngen dataset and dashboard type
 * @param datasetName name of incoming dataset
 * @param dashboardType name of Dashboard
 * @returns an array of level results with traits types
 */
export const getAvailableLinks = (datasetName, dashboardType) => {
    if (datasetName === EDatasetsNames.FINNGEN_R8
        || datasetName === EDatasetsNames.FINNGEN_R10
        || datasetName === EDatasetsNames.FINNGEN_R12) {
        return linksConfig[datasetName];
    }
    return linksConfig[dashboardType];
};

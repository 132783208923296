import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/no-array-index-key */
import { useCallback, useMemo, Fragment, forwardRef, memo, } from 'react';
import classnames from 'classnames';
import { hasDatasetProviderLabel } from 'Search/utils';
import styles from './SearchSuggestion.css';
const SearchSuggestion = forwardRef((props, ref) => {
    const { index, suggestion, onClick, onMouseOver, isSelected = false, renderValue, className, } = props;
    const classname = useMemo(() => (isSelected
        ? `${styles.item} ${styles.itemSelected}`
        : styles.item), [isSelected]);
    const handleSelect = useCallback(() => onMouseOver(index), [index, onMouseOver]);
    const handleClick = useCallback(() => onClick(suggestion), [suggestion, onClick]);
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    const { name, datasetProviderName, type } = suggestion;
    const hasProviderLabel = hasDatasetProviderLabel(type, datasetProviderName);
    const getRenderedValue = useCallback(() => {
        const suggestionValue = renderValue && renderValue(suggestion);
        if (Array.isArray(suggestionValue)) {
            return suggestionValue.map((el, elIndex) => _jsx(Fragment, { children: el }, `${elIndex}`));
        }
        return suggestionValue;
    }, [renderValue, suggestion]);
    const suggestionName = renderValue
        ? getRenderedValue()
        : name;
    return (_jsxs("li", Object.assign({ className: classname, title: name, onClick: handleClick, onMouseEnter: handleSelect, onFocus: handleSelect }, { children: [_jsx("p", Object.assign({ className: classnames(styles.label, className), ref: ref }, { children: suggestionName }), void 0), hasProviderLabel && (_jsxs("p", Object.assign({ className: styles.providerLabel }, { children: ["(", datasetProviderName, ")"] }), void 0))] }), void 0));
});
SearchSuggestion.displayName = 'SearchSuggestion';
export default memo(SearchSuggestion);

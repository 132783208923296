import { APIError } from './APIError';
export class InvalidJSONResponseError extends APIError {
    constructor(error, response) {
        let message = 'Invalid JSON response';
        if (error) {
            message = `${message}: ${error.message}`;
        }
        if (response) {
            message = `${message}. Request URL: ${response.url}`;
        }
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
    }
}

import { NO_DATA_CHAR } from 'Common/consts';
import { NO_DATA_LABEL, PREC_VALUE_THRESHOLD } from './consts';
import { EGeneToleranceMetric } from './types';
export class GeneToleranceService {
    static fromJSON(data) {
        const geneToleranceMetrics = Object.keys(data);
        return geneToleranceMetrics.reduce((acc, curr) => {
            if (!data[curr] || data[curr] === NO_DATA_LABEL) {
                acc[curr] = NO_DATA_CHAR;
            }
            else {
                acc[curr] = data[curr];
            }
            return acc;
        }, {});
    }
    static isEmptyData(data) {
        return Object.values(data).every((item) => item === NO_DATA_CHAR);
    }
    static getProteinCSCValue(value) {
        if (!value || value === NO_DATA_LABEL) {
            return NO_DATA_LABEL;
        }
        const parsedValue = parseFloat(value);
        return Number.isNaN(parsedValue)
            ? NO_DATA_LABEL
            : `${parsedValue * 100}`;
    }
    static getPRECValue(PRECValue, PLIValue) {
        if (!PRECValue || !PLIValue || PRECValue === NO_DATA_LABEL || PLIValue === NO_DATA_LABEL) {
            return NO_DATA_LABEL;
        }
        const roundedValue = parseFloat(PLIValue).toFixed(2);
        return parseFloat(roundedValue) < PREC_VALUE_THRESHOLD
            ? PRECValue
            : NO_DATA_LABEL;
    }
    static get empty() {
        return {};
    }
    static create({ chromosome, protein_coding_bases, coverage_exacv2, rvis_exacv2, mtr_genic_tile_exac, pli_exac, lof_fdr_exac, prec_exac, pcgerp_tile, }) {
        const result = GeneToleranceService.fromJSON({
            [EGeneToleranceMetric.Chromosome]: chromosome,
            [EGeneToleranceMetric.ProteinCodingBases]: protein_coding_bases,
            [EGeneToleranceMetric.ProteinCodingSeqCovered]: GeneToleranceService.getProteinCSCValue(coverage_exacv2),
            [EGeneToleranceMetric.RVIS]: rvis_exacv2,
            [EGeneToleranceMetric.MTR]: mtr_genic_tile_exac,
            [EGeneToleranceMetric.PLI]: pli_exac,
            [EGeneToleranceMetric.LoFDepletionFDR]: lof_fdr_exac,
            [EGeneToleranceMetric.PREC]: GeneToleranceService.getPRECValue(prec_exac, pli_exac),
            [EGeneToleranceMetric.PCGERP]: pcgerp_tile,
        });
        if (GeneToleranceService.isEmptyData(result)) {
            return GeneToleranceService.empty;
        }
        return result;
    }
}

import { geneIdMock } from 'Common/mocks';
import { getMock } from 'API/utils';
import resource from './resource';
export const transcriptsResponseMock = {
    pfamDomains: [
        {
            pfamDomainId: 'PF13895',
            pfamDomainStart: 217,
            pfamDomainEnd: 300,
        },
        {
            pfamDomainId: 'PF13895',
            pfamDomainStart: 314,
            pfamDomainEnd: 420,
        },
    ],
    geneId: 'ENST00000155892',
    transcriptId: 'ENST00000232892',
    proteinLength: 840,
};
export const emptyTranscriptsResponseMock = {
    pfamDomains: [],
    transcriptId: '',
    geneId: '',
    proteinLength: 0,
};
export const requestParams = {
    geneId: geneIdMock,
};
const mockedURL = {
    [resource.URLFactory(requestParams)]: {
        full: transcriptsResponseMock,
        empty: emptyTranscriptsResponseMock,
    },
};
export const fixtures = getMock(mockedURL);

import { QuantileService } from '../Quantile/Quantile.service';
/**
 * Class service with static methods for work with BinaryQuantile
 */
export class BinaryQuantileService {
    /**
     * Gets an BinaryQuantile object
     * @static
     * @param quantileData - binary quantile data
     * @returns BinaryQuantile object
     */
    static create(quantileData) {
        const binaryStats = quantileData.stats;
        if (!binaryStats) {
            return QuantileService.create(quantileData);
        }
        return Object.assign(Object.assign({}, QuantileService.create(quantileData)), { oddsRatio: binaryStats.odds_ratio, oddsRatioLCI: binaryStats.odds_ratio_lci, oddsRatioUCI: binaryStats.odds_ratio_uci, QVCases: binaryStats.qv_cases, QVControls: binaryStats.qv_controls });
    }
}

import { QuantileService } from '../Quantile/Quantile.service';
/**
 * Class service with static methods for work with ContinuousQuantile
 */
export class ContinuousQuantileService {
    /**
     * Gets an ContinuousQuantile object
     * @static
     * @param quantileData - continuous quantile data
     * @returns ContinuousQuantile object
     */
    static create(quantileData) {
        const continuousStats = quantileData.stats;
        if (!continuousStats) {
            return QuantileService.create(quantileData);
        }
        return Object.assign(Object.assign({}, QuantileService.create(quantileData)), { beta: continuousStats.beta, yesQV: continuousStats.yes_qv, LCI: continuousStats.odds_ratio_lci, UCI: continuousStats.odds_ratio_uci });
    }
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HTTPNotFoundError } from 'API/errors';
import { Transcripts } from 'API/resources';
import { logger } from 'Common/utils/logger/logger';
import { fetchDomains } from '../fetchDomains/fetchDomains';
export const fetchTranscripts = (geneId, signal, setLoadingMessage) => __awaiter(void 0, void 0, void 0, function* () {
    setLoadingMessage('Loading variants and transcripts...');
    let transcriptsData = {
        proteinLength: 0,
        transcriptId: '',
        geneId: '',
        pfamDomains: [],
    };
    try {
        transcriptsData = yield Transcripts.resource.getData({ geneId }, undefined, undefined, signal);
    }
    catch (err) {
        if (err instanceof HTTPNotFoundError) {
            logger.warn(`No transcript data was fetched for gene ${geneId}. No plots will be displayed`);
        }
        else {
            throw err;
        }
    }
    let pfamDomainsData = [];
    if (transcriptsData.pfamDomains.length > 0) {
        pfamDomainsData = yield fetchDomains(transcriptsData, signal, setLoadingMessage);
    }
    return {
        transcriptsData,
        pfamDomainsData,
    };
});

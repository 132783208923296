import { createMockedURL, getMock } from 'API/utils';
import { response as datasets } from '../datasets';
import resource from './resource';
export const searchResponseMock = {
    query: 'query',
    results: {
        genes: [
            {
                id: 'AADAC',
                name: 'AADAC',
                datasets,
            },
        ],
        phenotypes: [
            {
                id: 'bbf4b251-0c79-45cc-9fb9-c363c3aff18c',
                name: '41270#I66#I66 Occlusion and stenosis of cerebral arteries| not resulting in cerebral infarction',
                datasets,
            },
        ],
        variants: [
            {
                id: 'c3aff18c-0c79-45cc-9fb9-c363bbf4b251',
                name: 'Mock variant',
                datasets,
            },
        ],
    },
};
export const emptyResponse = {
    query: '',
    results: {
        genes: [],
        phenotypes: [],
        variants: [],
    },
};
const requestParams = {};
const mockedURL = {
    [createMockedURL(resource, requestParams)]: {
        full: searchResponseMock,
        empty: emptyResponse,
    },
};
export const fixtures = getMock(mockedURL);

import { useState, useCallback, useMemo } from 'react';
export const EMPTY_INDEX = -1;
export var EIterationDirection;
(function (EIterationDirection) {
    EIterationDirection[EIterationDirection["Previous"] = -1] = "Previous";
    EIterationDirection[EIterationDirection["Next"] = 1] = "Next";
})(EIterationDirection || (EIterationDirection = {}));
/**
 * Hooks implements cycled iterator over a given collection
 *
 * @param collection - Collection to iterate over
 * @returns Public API for CollectionIterator hook
 *  - Current element's index
 *  - Method which resets index of the current element
 *  - Method which returns next element from collection
 *  - Method which returns previous element from collection
 *  - Method which sets index of the current element
 *  - Method which gets current element
 */
export function useIterator(collection) {
    const [index, setIndex] = useState(EMPTY_INDEX);
    /**
     * Provides method for selecting element based on direction
     *
     * @private
     *
     * @param direction - Delta index of the next element
     * @returns Next element getter
     */
    const selectSuggestion = useCallback((direction) => () => {
        const size = collection.length;
        const nextUnboundedIndex = index + direction;
        const nextIndex = nextUnboundedIndex < 0
            ? (size + nextUnboundedIndex)
            : (nextUnboundedIndex % size);
        setIndex(nextIndex);
        return collection[nextIndex];
    }, [collection, index]);
    /**
     * Resets index of the current element
     */
    const reset = useCallback(() => setIndex(EMPTY_INDEX), []);
    /**
     * Sets and gets next after the current element from collection
     *
     * @returns Next element after the current
     */
    const next = useMemo(() => selectSuggestion(EIterationDirection.Next), [selectSuggestion]);
    /**
     * Sets and gets previous before the current element from collection
     *
     * @returns Previous element before the current
     */
    const previous = useMemo(() => selectSuggestion(EIterationDirection.Previous), [selectSuggestion]);
    /**
     * Gets current element from collection
     *
     * @returns Current element
     */
    const getCurrent = useCallback(() => (index === EMPTY_INDEX ? null : collection[index]), [collection, index]);
    /**
     * Gets first element from collection
     *
     * @returns Current element
     */
    const getFirst = useCallback(() => { var _a; return (_a = collection[0]) !== null && _a !== void 0 ? _a : null; }, [collection]);
    return {
        index,
        reset,
        next,
        previous,
        setIndex,
        getCurrent,
        getFirst,
    };
}

export var ELoadingStatus;
(function (ELoadingStatus) {
    ELoadingStatus[ELoadingStatus["Init"] = 0] = "Init";
    ELoadingStatus[ELoadingStatus["Loading"] = 1] = "Loading";
    ELoadingStatus[ELoadingStatus["Finished"] = 2] = "Finished";
})(ELoadingStatus || (ELoadingStatus = {}));
export var EMockTypes;
(function (EMockTypes) {
    EMockTypes[EMockTypes["empty"] = 0] = "empty";
    EMockTypes[EMockTypes["full"] = 1] = "full";
    EMockTypes[EMockTypes["clientError"] = 2] = "clientError";
    EMockTypes[EMockTypes["serverError"] = 3] = "serverError";
})(EMockTypes || (EMockTypes = {}));

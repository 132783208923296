import { createSelector } from '@reduxjs/toolkit';
import { getState } from './rootState';
export const getLoadingStatus = createSelector(getState, ({ loadingStatus }) => loadingStatus);
export const getCommonLoadingStatus = createSelector(getState, ({ commonLoadingStatus }) => commonLoadingStatus);
export const getLoadingMessage = createSelector(getState, ({ loadingMessage }) => loadingMessage);
export const getErrorType = createSelector(getState, ({ errorType }) => errorType);
export const getCommonErrorType = createSelector(getState, ({ commonErrorType }) => commonErrorType);
export const getDataExists = createSelector(getState, ({ dataExists }) => dataExists);
export const getTraitsType = createSelector(getState, ({ traitsType }) => traitsType);
export const getPhenotype = createSelector(getState, ({ phenotype }) => phenotype);
export const getStats = createSelector(getState, ({ stats }) => stats);
export const getCategory = createSelector(getState, ({ category }) => category);
export const getCollapsingModels = createSelector(getState, ({ collapsingModels }) => collapsingModels);
export const getIsEmpty = createSelector(getState, ({ collapsingModels }) => collapsingModels.length === 0);
export const getVerifiedPhenotypeId = createSelector(getState, ({ verifiedPhenotypeId }) => verifiedPhenotypeId);

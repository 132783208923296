import { ManhattanPlotDataPoint } from 'Shared/plots/models/ManhattanPlotDataPoint/ManhattanPlotDataPoint';
/**
 * DataPointAdapter adapter for TPhenotypeAssociationVLR and PhenotypeAssociationGLR instances
 *
 * @extends ManhattanPlotDataPoint<T>
 */
export class DataPointAdapterGPA extends ManhattanPlotDataPoint {
    constructor(association, positionInChromosome) {
        super(association, [positionInChromosome, association.pvalueLog || 0], association.id);
    }
}

import { ManhattanPlotDataPoint } from 'Shared/plots/models';
/**
 * DataPointAdapter adapter for store data item
 *
 * @extends ManhattanPlotDataPoint<TPhenotypeAssociationPLR>
 */
export class DataPointAdapter extends ManhattanPlotDataPoint {
    constructor(association) {
        super(association, [undefined, association.pvalueLog], association.id);
    }
}

/**
 * Class with static methods for represents phenotype stats
 *
 */
export class PhenotypeStatsService {
    /**
     * Creates phenotype stats object from API response
     *
     * @static
     * @param rawData - Response data from API
     * @returns Phenotype stats object
     */
    static fromResponse(rawData) {
        return PhenotypeStatsService.create(rawData);
    }
    /**
     * Create empty phenotype stats object
     *
     * @static
     * @return Empty phenotype stats object
     */
    static get empty() {
        return PhenotypeStatsService.create({});
    }
    /**
     * Create phenotype stats object
     *
     * @static
     * @param stats - Phenotype stats
     * @return Phenotype stats object
     */
    // TODO remove partial after type clarification
    static create(stats) {
        var _a, _b, _c, _d, _e, _f, _g;
        return {
            pvalue: (_a = stats.lowest_pvalue) !== null && _a !== void 0 ? _a : null,
            controls: (_b = stats.controls) !== null && _b !== void 0 ? _b : null,
            cases: (_c = stats.cases) !== null && _c !== void 0 ? _c : null,
            oddsRatio: (_d = stats.most_extreme_odds_ratio) !== null && _d !== void 0 ? _d : null,
            participants: (_e = stats.participants) !== null && _e !== void 0 ? _e : null,
            size: (_f = stats.largest_effect_size) !== null && _f !== void 0 ? _f : null,
            consequenceTypes: (_g = stats.consequence_types) !== null && _g !== void 0 ? _g : null,
        };
    }
}

import { ETraitsTypes, ELevelResult } from 'Shared/types';
import { capitalizeFirstLetter } from 'Common/utils';
import { isWgsOrWes } from 'Common/utils/isWesWgs/isWesWgs';
export const getErrorMessage = (levelResult, traitsType, datasetVersionId) => {
    const currentLevelResult = levelResult === ELevelResult.Gene ? 'Gene' : 'Variant';
    const anotherLevelResult = levelResult === ELevelResult.Gene ? 'Variant' : 'Gene';
    if (isWgsOrWes(datasetVersionId) && levelResult === ELevelResult.Variant) {
        return `${currentLevelResult}-level results are not available for this dataset. Please see Gene-level results or switch to another dataset`;
    }
    const currentTraitsType = capitalizeFirstLetter(traitsType);
    const anotherTraitsType = capitalizeFirstLetter(traitsType === ETraitsTypes.Binary
        ? ETraitsTypes.Continuous
        : ETraitsTypes.Binary);
    return `${currentTraitsType} traits are not available for ${currentLevelResult}-level results. Please see ${anotherTraitsType} traits or switch to ${anotherLevelResult}-level results or to another dataset.`;
};

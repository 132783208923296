import { NA_CHAR } from 'Common/consts';
import { getDatasetFullLabel } from 'Common/utils/getDatasetFullLabel/getDatasetFullLabel';
import { logger } from 'Common/utils/logger/logger';
/**
 * Return formatted name of given dataset
 *
 * @param dataset - an array of dataset objects
 * @param datasetId - actual id of given dataset
 * @returns a full name dataset
 */
export const getDatasetRowName = (dataset, datasetId) => {
    const datasetItem = dataset.find((set) => set.id === datasetId);
    if (!datasetItem) {
        logger.warn('Error getting formatted name of given dataset: No dataset provided');
        return NA_CHAR;
    }
    return getDatasetFullLabel(datasetItem.provider, datasetItem.name);
};

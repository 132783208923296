import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import Link from 'Common/components/Link/Link';
import classnames from 'classnames';
import InfoIcon from 'Shared/assets/icons/info.svg';
import { ABOUT_VIEW_PATH } from 'Router/index';
import styles from './AboutPageLink.css';
const AboutPageLink = ({ customIconClassName, customLinkClassName, referrerState, }) => {
    const route = useMemo(() => ({
        pathname: ABOUT_VIEW_PATH,
        state: referrerState,
    }), [referrerState]);
    return (_jsxs(Link, Object.assign({ to: route, className: classnames(styles.link, customLinkClassName) }, { children: [_jsx(InfoIcon, { className: classnames(styles.infoIcon, customIconClassName) }, void 0), _jsx("span", Object.assign({ className: styles.contact }, { children: "About" }), void 0)] }), void 0));
};
export default memo(AboutPageLink);

import { jsx as _jsx } from "react/jsx-runtime";
import { v4 as uuidv4 } from 'uuid';
import { ExternalLink } from 'Shared/components';
import { COLUMBUS_CGR_URL, SMART_PHENOTYPES_URL } from 'Common/consts';
export const OTHER_RESOURCES_SECTION_TITLE = 'Other resources';
const KEY_PREFIX_INTERNAL = 'about-view-internal_other-resources';
export const OTHER_RESOURCES_INTERNAL = [
    {
        key: `${KEY_PREFIX_INTERNAL}_paragraph-1_${uuidv4()}`,
        description: [
            'For more information on smart phenotypes, please see CGR’s smart phenotype registry ',
            _jsx(ExternalLink, Object.assign({ to: SMART_PHENOTYPES_URL, title: SMART_PHENOTYPES_URL, isTextFieldLink: true }, { children: "here." }), `${KEY_PREFIX_INTERNAL}_smart-phenotypes-link_${uuidv4()}`),
        ],
    },
    {
        key: `${KEY_PREFIX_INTERNAL}_paragraph-2_${uuidv4()}`,
        description: [
            'To define your own cohorts for analysis, please see Columbus ',
            _jsx(ExternalLink, Object.assign({ to: COLUMBUS_CGR_URL, title: COLUMBUS_CGR_URL, isTextFieldLink: true }, { children: "here." }), `${KEY_PREFIX_INTERNAL}_columbus-cgr-link_${uuidv4()}`),
        ],
    },
];

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Title__title__3dwJr{font-size:1.1rem;margin-bottom:.5rem;margin-top:2rem}", "",{"version":3,"sources":["webpack://./src/views/about/components/Title/Title.css"],"names":[],"mappings":"AAAA,qBACE,gBAAiB,CAEjB,mBAAqB,CADrB,eAEF","sourcesContent":[".title {\n  font-size: 1.1rem;\n  margin-top: 2rem;\n  margin-bottom: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "Title__title__3dwJr"
};
export default ___CSS_LOADER_EXPORT___;

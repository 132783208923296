import { configureStore } from '@reduxjs/toolkit';
import datasets from 'Datasets/store/datasetSelector.slice';
import geneView from 'GeneView/store/slice';
import geneDashboardView from 'GeneDashboardView/store/slice';
import phenotypeView from 'PhenotypeView/store/slice';
import phenotypeCatalogueView from 'PhenotypeCatalogueView/store/slice';
import qvView from 'QVView/store/qvView.slice';
import qqView from 'QQView/store/slice';
import fesView from 'FESView/store/slice';
import cacheData from 'Store/cacheData/cacheData.slice';
import { reducer as variantView, } from 'VariantView/store';
import variantDashboardView from '../views/variantDashboard/store/slice';
// temporary disable for restHooks until non-serializable values will be removed
const serializableConfig = {
    ignoredActions: [
        'phenotypeView/initWorker',
        'qvView/initWorker',
        'variantView/initWorker',
        'geneView/initWorker',
    ],
    warnAfter: 1000,
};
const immutableConfig = {
    warnAfter: 1000,
};
const store = configureStore({
    reducer: {
        datasets,
        geneView,
        geneDashboardView,
        cacheData,
        phenotypeView,
        phenotypeCatalogueView,
        qvView,
        qqView,
        fesView,
        variantView,
        variantDashboardView,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: serializableConfig,
        immutableCheck: immutableConfig,
    }),
});
export default store;

import { ELevelResult } from 'Shared/types';
import { PhenotypeAssociationVLRService } from 'Common/services/PhenotypeAssociations';
/**
 * Class service with static methods for work with BinaryAssociationVLR
 */
export class BinaryAssociationVLRService {
    /**
     * Creates binary phenotype association vlr object
     * @static
     * @param association - Original phenotype association data received from API
     * @param phenotypeData - Original phenotype data received from API
     * @param collapsingModel - Collapsing model object
     */
    static create(association, phenotypeData, collapsingModel) {
        const binaryAssociation = PhenotypeAssociationVLRService.createBinaryAssociation(association, phenotypeData, collapsingModel);
        const { oddsRatio, controlAaf, controlMaf } = binaryAssociation;
        return Object.assign(Object.assign({}, binaryAssociation), { levelResult: ELevelResult.Variant, coordinates: {
                x: Math.log10(controlMaf || controlAaf || 1),
                y: Math.log10(oddsRatio || 1),
            } });
    }
}

import { createSlice, current } from '@reduxjs/toolkit';
import { ESortType } from 'Shared/components/Table';
import { ELevelResult, ETraitsTypes } from 'Shared/types';
import { CollapsingModelsService, PhenotypeAssociationFactory, PhenotypeService, PhenotypeStatsService, PhenotypicCategoryService, TraitsTypeService, } from 'Common/services';
import { getHasAncestryData, sortAssociations, sortByPhenotypicCategory, } from 'Common/utils';
import { PhenotypeAssociationPLRService } from 'Common/services/PhenotypeAssociations/PhenotypeAssociationPLR/PhenotypeAssociationPLR.service';
import { ELoadingStatus } from 'API/types';
import { ECleanup, } from 'PhenotypeView/store/types';
import { handleFilteringGLR, handleFilteringPLR, handleFilteringVLR } from 'PhenotypeView/store/utils';
import { AssociationsService } from 'PhenotypeView/services';
import { fetchPLRData, fetchGPAData, fetchAPIData, setDataExists, setLoadingMessage, } from './actions';
import { getInitialState } from './initialState';
let worker;
const phenotypeView = createSlice({
    name: 'phenotypeView',
    initialState: getInitialState(),
    reducers: {
        cleanup(state, action) {
            const { collapsingModels, commonLoadingStatus, commonErrorType, errorType, loadingStatus, loadingMessage, traitsType, stats, category, phenotype, verifiedPhenotypeId, [ELevelResult.Gene]: geneLevelState, [ELevelResult.Variant]: variantLevelState, [ELevelResult.Phenotype]: phenotypeLevelState, } = getInitialState();
            state.errorType = errorType;
            state.loadingStatus = loadingStatus;
            state.loadingMessage = loadingMessage;
            state.traitsType = traitsType;
            state.stats = stats;
            state.category = category;
            state.phenotype = phenotype;
            state.verifiedPhenotypeId = verifiedPhenotypeId;
            state[ELevelResult.Gene] = geneLevelState;
            state[ELevelResult.Variant] = variantLevelState;
            state[ELevelResult.Phenotype] = phenotypeLevelState;
            if (action.payload === ECleanup.All) {
                state.commonErrorType = commonErrorType;
                state.commonLoadingStatus = commonLoadingStatus;
                state.collapsingModels = collapsingModels;
            }
        },
        initWorker(_, action) {
            worker = action.payload;
        },
        terminateWorker() {
            worker = null;
        },
        setTableSortBy: (state, action) => {
            const { columnId, sortType, levelResult } = action.payload;
            const sortBy = []; // TODO to migrate that logic to table related adapter
            if (sortType !== ESortType.None) {
                sortBy.push({
                    id: columnId,
                    desc: sortType === ESortType.Desc,
                });
            }
            state[levelResult].tableSettings.sortBy = sortBy;
        },
        setTablePageSize: (state, action) => {
            const { pageSize, levelResult } = action.payload;
            state[levelResult].tableSettings.pageSize = pageSize;
        },
        setFiltersGLR(state, action) {
            const { ancestries, collapsingModels, maxPValue, gene, isMostSignificantModel, isDirectionOfEffectFilterActive, } = action.payload;
            const { traitsType } = state;
            const viewState = state[ELevelResult.Gene];
            viewState.isFiltering = true;
            if (ancestries !== undefined) {
                viewState.filters.ancestries = ancestries;
            }
            if (collapsingModels !== undefined) {
                viewState.filters.collapsingModels = collapsingModels;
            }
            if (maxPValue !== undefined) {
                viewState.filters.maxPValue = maxPValue;
            }
            if (gene !== undefined) {
                viewState.filters.gene = gene;
            }
            if (isMostSignificantModel !== undefined) {
                viewState.filters.isMostSignificantModel = isMostSignificantModel;
            }
            if (isDirectionOfEffectFilterActive !== undefined) {
                viewState.filters.isDirectionOfEffectFilterActive = isDirectionOfEffectFilterActive;
            }
            handleFilteringGLR(viewState, worker, traitsType);
        },
        setFiltersVLR(state, action) {
            const { ancestries, collapsingModels, maxPValue, gene, variant, isMostSignificantModel, isDirectionOfEffectFilterActive, consequenceTypes, } = action.payload;
            const viewState = state[ELevelResult.Variant];
            viewState.isFiltering = true;
            if (ancestries !== undefined) {
                viewState.filters.ancestries = ancestries;
            }
            if (collapsingModels !== undefined) {
                viewState.filters.collapsingModels = collapsingModels;
            }
            if (maxPValue !== undefined) {
                viewState.filters.maxPValue = maxPValue;
            }
            if (gene !== undefined) {
                viewState.filters.gene = gene;
            }
            if (variant !== undefined) {
                viewState.filters.variant = variant;
            }
            if (isMostSignificantModel !== undefined) {
                viewState.filters.isMostSignificantModel = isMostSignificantModel;
            }
            if (isDirectionOfEffectFilterActive !== undefined) {
                viewState.filters.isDirectionOfEffectFilterActive = isDirectionOfEffectFilterActive;
            }
            if (consequenceTypes !== undefined) {
                viewState.filters.consequenceTypes = consequenceTypes;
            }
            handleFilteringVLR(viewState, worker);
        },
        setFiltersPLR(state, action) {
            const { ancestries, categories, phenotype, maxPValue, } = action.payload;
            const viewState = state[ELevelResult.Phenotype];
            viewState.isFiltering = true;
            if (ancestries !== undefined) {
                viewState.filters.ancestries = ancestries;
            }
            if (categories !== undefined) {
                viewState.filters.categories = categories;
            }
            if (phenotype !== undefined) {
                viewState.filters.phenotype = phenotype;
            }
            if (maxPValue !== undefined) {
                viewState.filters.maxPValue = maxPValue;
            }
            handleFilteringPLR(viewState, worker);
        },
        updateFilteredAssociations(state, action) {
            const { filteredAssociationsIds, levelResult } = action.payload;
            state[levelResult].isFiltering = false;
            state[levelResult].filteredAssociationsIds = filteredAssociationsIds;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(setLoadingMessage, (state, action) => {
            state.loadingMessage = action.payload;
        })
            .addCase(setDataExists, (state, action) => {
            state.dataExists = action.payload;
        })
            .addCase(fetchAPIData.pending, (state) => {
            state.errorType = null;
            state.commonLoadingStatus = ELoadingStatus.Loading;
        })
            .addCase(fetchAPIData.fulfilled, (state, action) => {
            const { verifiedPhenotypeId, collapsingModels, } = action.payload;
            state.verifiedPhenotypeId = verifiedPhenotypeId;
            state.collapsingModels = collapsingModels;
            state.commonLoadingStatus = ELoadingStatus.Finished;
            state.loadingMessage = '';
        })
            .addCase(fetchAPIData.rejected, (state, action) => {
            if (action.meta.aborted) {
                return;
            }
            const { type: errorType } = action.payload;
            state.commonErrorType = errorType;
            state.commonLoadingStatus = ELoadingStatus.Finished;
            state.loadingMessage = '';
        })
            .addCase(fetchPLRData.pending, (state) => {
            state.errorType = null;
            state.loadingStatus = ELoadingStatus.Loading;
        })
            .addCase(fetchPLRData.fulfilled, (state, action) => {
            state.loadingMessage = '';
            state.loadingStatus = ELoadingStatus.Finished;
            const { phenotypeResponse, stats, } = action.payload;
            const viewState = state[ELevelResult.Phenotype];
            state.phenotype = PhenotypeService.create(phenotypeResponse.phenotype_name, phenotypeResponse.phenotype_id);
            state.category = PhenotypicCategoryService.create(phenotypeResponse.category_name, phenotypeResponse.category_short_name, phenotypeResponse.category_id);
            const traitsType = TraitsTypeService.create(phenotypeResponse.traits_type, ETraitsTypes.Binary);
            state.traitsType = traitsType;
            state.stats = PhenotypeStatsService.fromResponse(stats);
            viewState.associations.collection = {};
            viewState.associations.order = [];
            const categoriesCollection = {};
            const phenotypeNames = [];
            Object.keys(phenotypeResponse.associations).forEach((id) => {
                const associations = phenotypeResponse.associations[id];
                if (!associations.length) {
                    return;
                }
                associations.forEach((association) => {
                    const { phenotype_name: phenotypeName, category_id: phenotypicCategoryId, category_name: phenotypicCategoryName, category_short_name: phenotypicCategoryShortName, } = association;
                    if (!categoriesCollection[phenotypicCategoryName]) {
                        categoriesCollection[phenotypicCategoryName] = (PhenotypicCategoryService.create(phenotypicCategoryName, phenotypicCategoryShortName, phenotypicCategoryId));
                    }
                    if (phenotypeName) {
                        phenotypeNames.push(phenotypeName);
                    }
                    const phenotypeAssociation = PhenotypeAssociationPLRService.create(association);
                    viewState.associations.collection[phenotypeAssociation.id] = phenotypeAssociation;
                    viewState.associations.order.push(phenotypeAssociation.id);
                });
            });
            sortAssociations(viewState.associations);
            viewState.phenotypes = Array.from(new Set(phenotypeNames));
            const categories = Object
                .values(categoriesCollection)
                .sort(sortByPhenotypicCategory);
            viewState.categories = categories;
            viewState.filters.categories = categories;
            viewState.filteredAssociationsIds = AssociationsService.getFilteredData({
                associations: viewState.associations,
                filters: viewState.filters,
                levelResult: ELevelResult.Phenotype,
                traitsType,
            });
        })
            .addCase(fetchPLRData.rejected, (state, action) => {
            if (action.meta.aborted) {
                return;
            }
            const { type: errorType } = action.payload;
            state.errorType = errorType;
            state.loadingStatus = ELoadingStatus.Finished;
            state.loadingMessage = '';
        })
            .addCase(fetchGPAData.pending, (state) => {
            state.errorType = null;
            state.loadingStatus = ELoadingStatus.Loading;
        })
            .addCase(fetchGPAData.fulfilled, (state, action) => {
            var _a;
            state.loadingMessage = '';
            state.loadingStatus = ELoadingStatus.Finished;
            const { phenotypeResponse, stats, levelResult, } = action.payload;
            const viewState = state[levelResult];
            state.phenotype = PhenotypeService.create(phenotypeResponse.phenotype_name, phenotypeResponse.phenotype_id);
            state.category = PhenotypicCategoryService.create(phenotypeResponse.category_name, phenotypeResponse.category_short_name, phenotypeResponse.category_id);
            const traitsType = TraitsTypeService.create(phenotypeResponse.traits_type, ETraitsTypes.Binary);
            state.traitsType = traitsType;
            state.stats = PhenotypeStatsService.fromResponse(stats);
            const collapsingModelsData = current(state).collapsingModels;
            const collapsingModelsCollection = (CollapsingModelsService.createCollection(collapsingModelsData));
            const genes = {};
            const genesMostSignificantModels = {};
            const variants = {};
            const variantsMostSignificantModels = {};
            const collapsingModels = [];
            const ancestries = [];
            Object.keys(phenotypeResponse.associations).forEach((id) => {
                const associations = phenotypeResponse.associations[id];
                if (!associations.length) {
                    return;
                }
                const collapsingModel = collapsingModelsCollection[id];
                collapsingModels.push(collapsingModel);
                associations.forEach((association) => {
                    const phenotypeAssociation = PhenotypeAssociationFactory.create(levelResult, association, phenotypeResponse, collapsingModel);
                    const { pvalue } = phenotypeAssociation;
                    const { ancestry, gene_name: geneName, } = association;
                    if (geneName) {
                        const currentModel = genes[geneName];
                        if (!!ancestry && !ancestries.includes(ancestry)) {
                            ancestries.push(ancestry);
                        }
                        if (levelResult === ELevelResult.Gene) {
                            if (!currentModel) {
                                genesMostSignificantModels[geneName] = [];
                            }
                            genesMostSignificantModels[geneName].push({
                                ancestry,
                                collapsingModelId: collapsingModel.id,
                                pvalue,
                                beta: phenotypeAssociation.beta,
                                oddsRatio: phenotypeAssociation.oddsRatio,
                            });
                            genesMostSignificantModels[geneName].sort((a, b) => a.pvalue - b.pvalue);
                        }
                        if (!currentModel
                            || (currentModel.pvalue === pvalue
                                && currentModel.collapsingModel.name.localeCompare(collapsingModel.name) === 1)
                            || currentModel.pvalue > pvalue) {
                            genes[geneName] = { collapsingModel, pvalue };
                        }
                    }
                    if (levelResult === ELevelResult.Variant) {
                        const { variant_name: variantName, } = association;
                        if (!!ancestry && !ancestries.includes(ancestry)) {
                            ancestries.push(ancestry);
                        }
                        if (variantName) {
                            const currentModel = variants[variantName];
                            if (!currentModel) {
                                variantsMostSignificantModels[variantName] = [];
                            }
                            variantsMostSignificantModels[variantName].push({
                                ancestry,
                                collapsingModelId: collapsingModel.id,
                                consequenceType: phenotypeAssociation.consequenceType,
                                pvalue,
                                effectSize: phenotypeAssociation.effectSize,
                                oddsRatio: phenotypeAssociation.oddsRatio,
                            });
                            variantsMostSignificantModels[variantName].sort((a, b) => a.pvalue - b.pvalue);
                            if (!currentModel
                                || (currentModel.pvalue === pvalue
                                    && currentModel.collapsingModel.name.localeCompare(collapsingModel.name) === 1)
                                || currentModel.pvalue > pvalue) {
                                variants[variantName] = { collapsingModel, pvalue };
                            }
                        }
                    }
                    viewState.associations.collection[phenotypeAssociation.id] = phenotypeAssociation;
                    viewState.associations.order.push(phenotypeAssociation.id);
                });
            });
            sortAssociations(viewState.associations);
            Object.keys(genes).forEach((geneName) => {
                viewState.genes[geneName] = genes[geneName].collapsingModel.id;
            });
            if (levelResult === ELevelResult.Gene) {
                viewState
                    .genesMostSignificantModels = genesMostSignificantModels;
            }
            if (levelResult === ELevelResult.Variant) {
                Object.keys(variants).forEach((variantName) => {
                    viewState.variants[variantName] = (variants[variantName].collapsingModel.id);
                });
                viewState
                    .variantsMostSignificantModels = variantsMostSignificantModels;
            }
            viewState.collapsingModels = CollapsingModelsService.getSortedByName(collapsingModels);
            viewState.filters.collapsingModels = viewState.collapsingModels;
            viewState.ancestries = ancestries.sort((a, b) => a.localeCompare(b));
            viewState.filters.ancestries = viewState.ancestries;
            viewState.filters.hasAncestryData = getHasAncestryData(ancestries);
            if ('consequenceTypes' in viewState.filters) {
                viewState.filters.consequenceTypes = (_a = stats.consequence_types) !== null && _a !== void 0 ? _a : [];
            }
            viewState.filteredAssociationsIds = AssociationsService.getFilteredData({
                associations: viewState.associations,
                filters: viewState.filters,
                genesMostSignificantModels: viewState.genesMostSignificantModels,
                variantsMostSignificantModels: viewState.variantsMostSignificantModels,
                levelResult,
                traitsType,
            });
        })
            .addCase(fetchGPAData.rejected, (state, action) => {
            if (action.meta.aborted) {
                return;
            }
            const { type: errorType } = action.payload;
            state.errorType = errorType;
            state.loadingStatus = ELoadingStatus.Finished;
            state.loadingMessage = '';
        })
            .addDefaultCase((state) => state);
    },
});
export const { cleanup, initWorker, terminateWorker, setTableSortBy, setTablePageSize, setFiltersGLR, setFiltersVLR, setFiltersPLR, updateFilteredAssociations, } = phenotypeView.actions;
export default phenotypeView.reducer;

import { jsx as _jsx } from "react/jsx-runtime";
import { BackButton, ESectionType, Section } from 'Shared/components';
import { useReferrer } from 'Common/hooks';
import styles from './BackToReferrerButton.css';
const BackToReferrerButton = ({ withSection }) => {
    const referrer = useReferrer();
    if (!referrer) {
        return null;
    }
    if (withSection) {
        return (_jsx(Section, Object.assign({ type: ESectionType.Minimized, "data-testid": "back-to-referrer-button", className: styles.withSection }, { children: _jsx(BackButton, { onClick: referrer.goBack, label: referrer.name }, void 0) }), void 0));
    }
    return (_jsx("div", Object.assign({ "data-testid": "back-to-referrer-button", className: styles.referrerButton }, { children: _jsx(BackButton, { onClick: referrer.goBack, label: referrer.name }, void 0) }), void 0));
};
export default BackToReferrerButton;

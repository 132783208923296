import { DEFAULT_API_SERVICE_URI } from 'API/consts';
import { RESTResource } from '../RESTResource';
/* eslint-disable @typescript-eslint/indent */
export default new RESTResource((urlParams) => {
    var _a;
    const urlRoot = (_a = process.env.QV_SERVICE_URI) !== null && _a !== void 0 ? _a : DEFAULT_API_SERVICE_URI;
    const { datasetVersionId } = urlParams;
    return `${urlRoot}/v1/${datasetVersionId}/qv`;
}, 'get', {
    ancestry: 'ancestry',
    collapsingModelId: 'collapsing_model_id',
    geneId: 'gene_id',
    phenotypeId: 'phenotype_id',
    phenotypicCategoryId: 'category_id',
});

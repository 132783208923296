export var BinaryVariantsDataType;
(function (BinaryVariantsDataType) {
    BinaryVariantsDataType["Case"] = "case";
    BinaryVariantsDataType["Control"] = "control";
})(BinaryVariantsDataType || (BinaryVariantsDataType = {}));
export var ContinuousVariantsDataType;
(function (ContinuousVariantsDataType) {
    ContinuousVariantsDataType["Variants"] = "variants";
})(ContinuousVariantsDataType || (ContinuousVariantsDataType = {}));
export var TLollipopPlotLinkType;
(function (TLollipopPlotLinkType) {
    TLollipopPlotLinkType["GENE"] = "GENE";
    TLollipopPlotLinkType["PHENOTYPE"] = "PHENOTYPE";
})(TLollipopPlotLinkType || (TLollipopPlotLinkType = {}));

import { ELevelResult, ETraitsTypes } from 'Shared/types';
import { phenotypeMock, phenotypicCategoryNameMock } from './api';
export const maxPValueMock = 0.00003;
export const maxPValuePlaceholderMock = 'Max p-value';
export const genesMock = ['gene_1', 'gene_2'];
export const collapsingModelsDataMock = [
    {
        id: 'flexdmg',
        name: 'flexdmg',
        description: 'flexdmg description',
        levelResult: ELevelResult.Gene,
    },
    {
        id: 'flexnonsyn',
        name: 'flexnonsyn',
        description: 'flexnonsyn description',
        levelResult: ELevelResult.Gene,
    },
    {
        id: 'flexnonsynmtr',
        name: 'flexnonsynmtr',
        description: 'flexnonsynmtr description',
        levelResult: ELevelResult.Gene,
    },
    {
        id: 'ptv',
        name: 'ptv',
        description: 'ptv description',
        levelResult: ELevelResult.Gene,
    },
    {
        id: 'ptv5pcnt',
        name: 'ptv5pcnt',
        description: 'ptv5pcnt description',
        levelResult: ELevelResult.Gene,
    },
    {
        id: 'ptv_all',
        name: 'ptv_all',
        description: 'ptv_all description',
        levelResult: ELevelResult.Gene,
    },
    {
        id: 'ptvraredmg',
        name: 'ptvraredmg',
        description: 'ptvraredmg description',
        levelResult: ELevelResult.Gene,
    },
    {
        id: 'raredmg',
        name: 'raredmg',
        description: 'raredmg description',
        levelResult: ELevelResult.Gene,
    },
    {
        id: 'raredmgmtr',
        name: 'raredmgmtr',
        description: 'raredmgmtr description',
        levelResult: ELevelResult.Gene,
    },
    {
        id: 'rec',
        name: 'rec',
        description: 'rec description',
        levelResult: ELevelResult.Variant,
    },
    {
        id: 'syn',
        name: 'syn',
        description: 'syn description',
        levelResult: ELevelResult.Variant,
    },
    {
        id: 'UR',
        name: 'UR',
        description: 'UR description',
        levelResult: ELevelResult.Variant,
    },
    {
        id: 'URmtr',
        name: 'URmtr',
        description: 'URmtr description',
        levelResult: ELevelResult.Variant,
    },
    {
        id: 'raredmg_2',
        name: 'raredmg_2',
        description: 'raredmg_2 description',
        levelResult: ELevelResult.Variant,
    },
];
export const associationsDataMock = {
    phenotype: phenotypeMock,
    category: phenotypicCategoryNameMock,
    collapsingModel: 'test',
    pvalue: 0.1,
    traitsType: ETraitsTypes.Binary,
};
export const ancestryMock = 'European';
export const ancestriesMock = [
    ancestryMock,
    'South Asian',
];

/**
 * Class with static methods for represents gene stats
 *
 */
export class GeneStatsService {
    /**
     * Creates gene stats object from API response
     *
     * @static
     * @param rawData - Response data from API
     * @returns Gene stats object
     */
    static fromResponse(rawData) {
        return GeneStatsService.create(rawData);
    }
    /**
     * Create empty gene stats object
     *
     * @static
     * @return Empty gene stats object
     */
    static get empty() {
        return GeneStatsService.create({});
    }
    /**
     * Create gene stats object
     *
     * @static
     * @param stats - Gene stats
     * @return Gene stats object
     */
    static create(stats) {
        var _a;
        return {
            consequenceTypes: (_a = stats.consequence_types) !== null && _a !== void 0 ? _a : [],
        };
    }
}

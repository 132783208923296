import { ManhattanPlotDataPoint } from 'Shared/plots/models/ManhattanPlotDataPoint/ManhattanPlotDataPoint';
/**
 * ManhattanPlotDataPoint adapter for store data item
 *
 * @extends ManhattanPlotDataPoint<TStorePlotDataItem>
 */
export class DataPointAdapter extends ManhattanPlotDataPoint {
    constructor(variantAssociation) {
        super(variantAssociation, [undefined, variantAssociation.pvalueLog], variantAssociation.id);
    }
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../SearchBox/SearchBox.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchSuggestion__item__2wa22{cursor:pointer;display:flex;flex-shrink:0;justify-content:flex-start;line-height:1.25rem;padding:.375rem 1.5rem}.SearchSuggestion__item__2wa22>p{height:auto}.SearchSuggestion__itemSelected__2jRPr{background-color:#0000000d}.SearchSuggestion__label__1gGXd{margin:0;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.SearchSuggestion__providerLabel__xa1Va{flex-shrink:0;margin:0 0 0 .25rem}", "",{"version":3,"sources":["webpack://./src/views/search/components/SearchSuggestion/SearchSuggestion.css"],"names":[],"mappings":"AAAA,+BAKE,cAAe,CAHf,YAAa,CACb,aAAc,CACd,0BAA2B,CAG3B,mBAAoB,CADpB,sBAEF,CAEA,iCACE,WACF,CAEA,uCACE,0BACF,CAEA,gCAKE,QAAS,CADT,eAAgB,CADhB,sBAAuB,CADvB,kBAIF,CAEA,wCAEE,aAAc,CACd,mBACF","sourcesContent":[".item {\n  composes: suggestionsItem from '../SearchBox/SearchBox.css';\n  display: flex;\n  flex-shrink: 0;\n  justify-content: flex-start;\n  cursor: pointer;\n  padding: 0.375rem 1.5rem;\n  line-height: 1.25rem;\n}\n\n.item > p {\n  height: auto;\n}\n\n.itemSelected {\n  background-color: rgb(0 0 0 / 5%);\n}\n\n.label {\n  composes: suggestionsLabel from '../SearchBox/SearchBox.css';\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  margin: 0;\n}\n\n.providerLabel {\n  composes: suggestionsProviderLabel from '../SearchBox/SearchBox.css';\n  flex-shrink: 0;\n  margin: 0 0 0 0.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "SearchSuggestion__item__2wa22 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["suggestionsItem"] + "",
	"itemSelected": "SearchSuggestion__itemSelected__2jRPr",
	"label": "SearchSuggestion__label__1gGXd " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["suggestionsLabel"] + "",
	"providerLabel": "SearchSuggestion__providerLabel__xa1Va " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["suggestionsProviderLabel"] + ""
};
export default ___CSS_LOADER_EXPORT___;

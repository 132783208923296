import { ELevelResult, ETraitsTypes } from 'Shared/types';
import { PhenotypeAssociationGLRService } from './PhenotypeAssociationGLR/PhenotypeAssociationGLR.service';
import { PhenotypeAssociationVLRService } from './PhenotypeAssociationVLR/PhenotypeAssociationVLR.service';
/**
 * Class service with static methods for create PhenotypeAssociation objects
 */
export class PhenotypeAssociationFactory {
    /**
     * Create a PhenotypeAssociation object for GLR and VLR
     *
     * @static
     * @param levelResult - short view identifier (used ELevelResult)
     * @param traitsType - type traits (used ETraitsTypes)
     * @param association - response data from API
     * @param phenotypeData - phenotypeData response from API
     * @param collapsingModel - collapsing model for PhenotypeAssociation
     * @param id - custom id for PhenotypeAssociation (uuid v4 is used by default)
     * @returns PhenotypeAssociation object or throws an Error
     */
    static create(levelResult, association, phenotypeData, collapsingModel, id) {
        const { traits_type: traitsType } = phenotypeData;
        if (levelResult === ELevelResult.Gene && traitsType === ETraitsTypes.Binary) {
            return PhenotypeAssociationGLRService.createBinaryAssociation(association, phenotypeData, collapsingModel, id);
        }
        if (levelResult === ELevelResult.Gene && traitsType === ETraitsTypes.Continuous) {
            return PhenotypeAssociationGLRService.createContinuousAssociation(association, phenotypeData, collapsingModel, id);
        }
        if (levelResult === ELevelResult.Variant && traitsType === ETraitsTypes.Binary) {
            return PhenotypeAssociationVLRService.createBinaryAssociation(association, phenotypeData, collapsingModel, id);
        }
        if (levelResult === ELevelResult.Variant && traitsType === ETraitsTypes.Continuous) {
            return PhenotypeAssociationVLRService.createContinuousAssociation(association, phenotypeData, collapsingModel, id);
        }
        throw new Error('Cannot create PhenotypeAssociation object, unsupported level result or traits type');
    }
}

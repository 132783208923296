import { createSlice } from '@reduxjs/toolkit';
import { logger } from 'Common/utils/logger/logger';
import { EProvider, PROVIDER_NAME } from 'Common/consts';
import { fetchAPIData } from './actions';
export const initialState = {
    isLoading: false,
    selectedDatasetId: null,
    providers: {},
    datasets: {},
    providersList: [],
    datasetsLists: {},
};
const providerList = Object.values(EProvider);
const datasets = createSlice({
    name: 'datasets',
    initialState,
    reducers: {
        initState(state) {
            state.isLoading = initialState.isLoading;
            state.selectedDatasetId = initialState.selectedDatasetId;
            state.providers = initialState.providers;
            state.datasets = initialState.datasets;
            state.providersList = initialState.providersList;
            state.datasetsLists = initialState.datasetsLists;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        selectDatasetByVersionId(state, action) {
            var _a, _b;
            state.selectedDatasetId = (_b = (_a = state.datasets[action.payload]) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null;
        },
        addDatasets(state, action) {
            var _a, _b;
            state.providers = {};
            state.datasets = {};
            state.providersList = [];
            state.datasetsLists = {};
            const { datasetsResponse, datasetVersionId } = action.payload;
            datasetsResponse.forEach(({ version_name: versionName, version_id: versionId, provider, metadata: description, }) => {
                const providerId = provider;
                if (!providerList.includes(providerId)) {
                    logger.warn(`Received unsupported provider id "${provider}": skipping`);
                    return;
                }
                const id = versionId; // Uniquely identifies dataset
                state.datasets[id] = {
                    id,
                    name: versionName,
                    versionId,
                    providerId,
                    description,
                };
                if (!state.providers[providerId]) {
                    state.providers[providerId] = {
                        id: providerId,
                        name: PROVIDER_NAME[providerId],
                    };
                    state.providersList.push(providerId);
                }
                if (!state.datasetsLists[providerId]) {
                    state.datasetsLists[providerId] = [];
                }
                state.datasetsLists[providerId].push(id);
            });
            state.selectedDatasetId = (_b = (_a = state.datasets[datasetVersionId]) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null;
            state.isLoading = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAPIData.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(fetchAPIData.rejected, (state, { meta }) => {
            if (meta.aborted) {
                return;
            }
            state.isLoading = false;
        })
            .addDefaultCase((state) => state);
    },
});
export const { initState, setIsLoading, addDatasets, selectDatasetByVersionId, } = datasets.actions;
export default datasets.reducer;

import { ManhattanPlotDataPoint } from 'Shared/plots/models';
/**
 * DataPointAdapter adapter for store data item
 *
 * @extends ManhattanPlotDataPoint<GeneAssociation>
 */
export class DataPointAdapter extends ManhattanPlotDataPoint {
    constructor(dataItem) {
        super(dataItem, [undefined, dataItem.pvalueLog], dataItem.id, dataItem.datasetVersionId, dataItem.levelResult, dataItem.traitsType);
    }
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ExternalLinkWithIcon__link__N9n9g{align-items:flex-end;color:var(--primary-contrast-color);display:flex;font-size:.875rem;font-weight:500;padding-right:1.325rem;text-decoration:none}.ExternalLinkWithIcon__link__N9n9g:last-child{padding-right:0}.ExternalLinkWithIcon__link__N9n9g:hover{color:var(--footer-link-hover-color)}.ExternalLinkWithIcon__icon__1LOBu{fill:var(--primary-contrast-color);height:.875rem;width:.875rem}.ExternalLinkWithIcon__link__N9n9g:hover .ExternalLinkWithIcon__icon__1LOBu{fill:var(--footer-link-hover-color)}.ExternalLinkWithIcon__label__3y2tk{line-height:1;padding:0 .1875rem}", "",{"version":3,"sources":["webpack://./src/common/components/ExternalLinkWithIcon/ExternalLinkWithIcon.css"],"names":[],"mappings":"AAAA,mCAEE,oBAAqB,CAErB,mCAAoC,CAHpC,YAAa,CAMb,iBAAmB,CADnB,eAAgB,CADhB,sBAAuB,CAFvB,oBAaF,CAPE,8CACE,eACF,CAEA,yCACE,oCACF,CAGF,mCACE,kCAAmC,CAEnC,cAAgB,CADhB,aAEF,CAEA,4EACE,mCACF,CAEA,oCAEE,aAAc,CADd,kBAEF","sourcesContent":[".link {\n  display: flex;\n  align-items: flex-end;\n  text-decoration: none;\n  color: var(--primary-contrast-color);\n  padding-right: 1.325rem;\n  font-weight: 500;\n  font-size: 0.875rem;\n\n  &:last-child {\n    padding-right: 0;\n  }\n\n  &:hover {\n    color: var(--footer-link-hover-color);\n  }\n}\n\n.icon {\n  fill: var(--primary-contrast-color);\n  width: 0.875rem;\n  height: 0.875rem;\n}\n\n.link:hover .icon {\n  fill: var(--footer-link-hover-color);\n}\n\n.label {\n  padding: 0 0.1875rem;\n  line-height: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "ExternalLinkWithIcon__link__N9n9g",
	"icon": "ExternalLinkWithIcon__icon__1LOBu",
	"label": "ExternalLinkWithIcon__label__3y2tk"
};
export default ___CSS_LOADER_EXPORT___;

import { EDataPointShape } from 'Shared/plots/types';
import { getBinaryPlotDataPointShape, getContinuousPlotDataPointShapeGLR, getContinuousPlotDataPointShapeVLR, } from 'Shared/plots/utils';
import { ELevelResult, ETraitsTypes } from 'Shared/types';
export const getPlotDataPointShape = (dataPoint) => {
    const { levelResult, traitsType } = dataPoint;
    if (!(levelResult && traitsType)) {
        return EDataPointShape.Circle;
    }
    if (traitsType === ETraitsTypes.Binary) {
        return getBinaryPlotDataPointShape(dataPoint);
    }
    if (levelResult === ELevelResult.Gene) {
        return getContinuousPlotDataPointShapeGLR(dataPoint);
    }
    if (levelResult === ELevelResult.Variant) {
        return getContinuousPlotDataPointShapeVLR(dataPoint);
    }
    return EDataPointShape.Circle;
};

import { setFilters as setFiltersAction, setTablePageSize as setTablePageSizeAction, setTableSortBy as setTableSortByAction, } from '../slice';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getTraitActions = (traitsType) => {
    const setFilters = (payload) => (setFiltersAction(Object.assign(Object.assign({}, payload), { traitsType })));
    const setTablePageSize = (payload) => (setTablePageSizeAction(Object.assign(Object.assign({}, payload), { traitsType })));
    const setTableSortBy = (payload) => (setTableSortByAction(Object.assign(Object.assign({}, payload), { traitsType })));
    return {
        setFilters,
        setTablePageSize,
        setTableSortBy,
    };
};

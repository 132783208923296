var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState, useCallback, } from 'react';
import { useDebounce, useUtag } from 'Shared/hooks';
import { getSearchClickUtagData } from 'Shared/utils';
import { Search } from 'API/resources';
import { logger, ELogEnvironment } from 'Common/utils/logger/logger';
import { searchSuggestionsConverter, SearchSuggestions } from 'Search/models';
import { ESearchSuggestionsType } from 'Search/types';
import { TEALIUM_EVENT } from 'Types/analytics';
const removeFullMatchItemFrom = (suggestions, searchValue) => {
    var _a;
    return (Object.assign(Object.assign({}, suggestions), { collection: Object.assign(Object.assign({}, suggestions.collection), { [ESearchSuggestionsType.Phenotype]: ((_a = suggestions.collection.BINARY_PHENOTYPE) === null || _a === void 0 ? void 0 : _a.filter(({ name }) => name !== searchValue)) }) }));
};
const enchanceByFullMatchSuggestions = (suggestions, searchValue) => {
    var _a;
    const newCollection = Object.assign({}, suggestions);
    newCollection.collection[ESearchSuggestionsType.FullMatch] = ((_a = newCollection.collection.BINARY_PHENOTYPE) === null || _a === void 0 ? void 0 : _a.filter(({ name }) => name === searchValue));
    return removeFullMatchItemFrom(newCollection, searchValue);
};
/**
 * Hook for search suggestions fetching
 *
 * @param searchValue - Search pattern
 * @param delay - Delay before suggestions fetch request will be sent
 * @returns Public API for SearchSuggestions hook
 *   - Search suggestions collection
 *   - Attribute which shows if suggestions are loaded
 *   - Attribute which shows if suggestions are loading
 *   - Attribute which shows if suggestions could be displayed
 *   - Method for forbidding suggestions displaying
 */
export function useSearchSuggestions(searchValue, delay) {
    const [searchSuggestionsCanBeDisplayed, setSearchSuggestionsCanBeDisplayed,] = useState(false);
    const utag = useUtag();
    const [searchSuggestions, setSearchSuggestions] = useState(SearchSuggestions.empty);
    const [searchSuggestionsAreLoaded, setSearchSuggestionsLoaded] = useState(false);
    const [searchSuggestionsAreLoading, setSearchSuggestionsLoading] = useState(false);
    /**
     * Fetches suggestions from backend API
     *
     * @async
     * @private
     *
     * @param searchPattern - Search pattern
     */
    const fetch = useCallback((searchPattern) => __awaiter(this, void 0, void 0, function* () {
        const query = searchPattern.trim();
        let suggestions = SearchSuggestions.empty;
        if (query) {
            setSearchSuggestionsLoading(true);
            try {
                const suggestionsData = yield Search.resource.getData({}, { query });
                suggestions = searchSuggestionsConverter.convertFromAPI(suggestionsData);
            }
            catch (err) {
                logger.error(err.message, ELogEnvironment.All);
            }
            finally {
                setSearchSuggestionsLoading(false);
            }
        }
        utag.link(getSearchClickUtagData({
            tealiumEvent: TEALIUM_EVENT.SEARCH,
            query,
        }));
        setSearchSuggestions(enchanceByFullMatchSuggestions(suggestions, searchPattern));
        setSearchSuggestionsLoaded(true);
    }), [utag]);
    /**
     * Debounced fetch method
     *
     * @async
     * @private
     *
     * @param searchPattern - Search pattern
     */
    const debouncedFetchSuggestions = useDebounce(fetch, delay);
    useEffect(() => {
        if (searchValue.trim().length > 0) {
            setSearchSuggestionsCanBeDisplayed(true);
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            debouncedFetchSuggestions(searchValue);
        }
    }, [
        searchValue,
        debouncedFetchSuggestions,
    ]);
    /**
     * Forbids displaying search suggestions in popup
     */
    const forbidSearchSuggestionsDisplay = useCallback(() => {
        if (searchSuggestionsCanBeDisplayed) {
            setSearchSuggestionsCanBeDisplayed(false);
        }
    }, [searchSuggestionsCanBeDisplayed]);
    return {
        searchSuggestions,
        searchSuggestionsAreLoaded,
        searchSuggestionsAreLoading,
        searchSuggestionsCanBeDisplayed,
        forbidSearchSuggestionsDisplay,
    };
}

import { getMock } from 'API/utils';
import resource from './resource';
export const chromosomesResponseMock = {
    chromosomes: [
        {
            number: 10,
            location: 1673279085,
            length: 133626742,
            genes: [{
                    name: 'A1CF',
                    id: 'test_id_A1CF',
                    location: 50806729,
                }],
        },
        {
            number: 12,
            location: 1941293486,
            length: 133234356,
            genes: [{
                    name: 'A2CF',
                    id: 'test_id_A2M',
                    location: 9067823,
                }],
        },
        {
            number: 19,
            location: 2649724809,
            length: 58571389,
            genes: [{
                    name: 'A1BG',
                    id: 'test_id_A1BG',
                    location: 58347022,
                }],
        },
        {
            number: 5,
            location: 10227441,
            length: 58571389,
            genes: [{
                    name: 'FAM173B',
                    id: 'test_id_FAM173B',
                    location: 58347022,
                }],
        },
        {
            number: 8,
            location: 142741143,
            length: 58571389,
            genes: [{
                    name: 'SLURP1',
                    id: 'test_id_SLURP1',
                    location: 58347022,
                }],
        },
        {
            number: 17,
            location: 7420385,
            length: 58571389,
            genes: [{
                    name: 'SPEM1',
                    id: 'test_id_SPEM1',
                    location: 58347022,
                }],
        },
        {
            number: 7,
            location: 150687060,
            length: 58571389,
            genes: [{
                    name: 'GIMAP2',
                    id: 'test_id_GIMAP2',
                    location: 58347022,
                }],
        },
        {
            number: 17,
            location: 50135331,
            length: 58571389,
            genes: [{
                    name: 'PPP1R9B',
                    id: 'test_id_PPP1R9B',
                    location: 58347022,
                }],
        },
        {
            number: 6,
            location: 127970227,
            length: 58571389,
            genes: [{
                    name: 'PTPRK',
                    id: 'test_id_PTPRK',
                    location: 58347022,
                }],
        },
        {
            number: 11,
            location: 78436670,
            length: 58571389,
            genes: [{
                    name: 'NARS2',
                    id: 'test_id_NARS2',
                    location: 58347022,
                }],
        },
        {
            number: 20,
            location: 44756134,
            length: 58571389,
            genes: [{
                    name: 'RIMS4',
                    id: 'test_id_RIMS4',
                    location: 58347022,
                }],
        },
        {
            number: 22,
            location: 44756134,
            length: 58571389,
            genes: [{
                    name: 'RIMS5',
                    id: 'test_id_RIMS5',
                    location: 58347022,
                }],
        },
    ],
};
export const chromosomesEmptyResponseMock = {
    chromosomes: [],
};
export const requestParams = {};
const mockedURL = {
    [resource.URLFactory(requestParams)]: {
        full: chromosomesResponseMock,
        empty: chromosomesEmptyResponseMock,
    },
};
export const fixtures = getMock(mockedURL);

export const COLUMNS_TO_HIDE = [
    'AACases',
    'ABCases',
    'BBCases',
    'ABOrBBCasesPercentage',
    'BBCasesPercentage',
    'AAControls',
    'ABControls',
    'BBControls',
    'ABOrBBControlsPercentage',
    'BBControlsPercentage',
];
export const FINNGEN_DATASETS_TO_VALIDATE = ['FinnGen R8', 'FinnGen R10', 'FinnGen R12'];
/**
 * Hides defined columns for specific datasets
 *
 * @param columns columns for validation
 * @param datasetName the name of dataset for with the validation applied
 * @returns validated columns
 */
export const validateColumnsForFinngen = (columns, datasetName) => {
    if (!datasetName) {
        return columns;
    }
    if (FINNGEN_DATASETS_TO_VALIDATE.includes(datasetName)) {
        return columns.filter((column) => !COLUMNS_TO_HIDE.includes(column.accessor));
    }
    return columns;
};

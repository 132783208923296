import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Footer } from 'Shared/components';
import Menu from 'Common/components/Menu/Menu';
import PageErrorBoundary from '../PageErrorBoundary/PageErrorBoundary';
import styles from './PageView.css';
export const PageView = memo(({ children, className, pageName, withErrorBoundary = true, referrerState, }) => {
    const page = (_jsx("article", Object.assign({ className: className }, { children: _jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsx("section", Object.assign({ className: styles.content }, { children: children }), void 0), _jsx(Footer, Object.assign({ className: styles.footer }, { children: _jsx(Menu, { referrerState: referrerState }, void 0) }), void 0)] }), void 0) }), void 0));
    return withErrorBoundary
        ? (_jsx(PageErrorBoundary, Object.assign({ pageName: pageName }, { children: page }), void 0))
        : page;
});
PageView.displayName = 'PageView';

import { ETraitsTypes } from 'Shared/types';
import { MANHATTAN_PLOT_BINARY_LEGEND_CONFIG, MANHATTAN_PLOT_CONTINUOUS_LEGEND_CONFIG } from 'Common/consts/plot';
export const TRAITS_TYPE_ERROR_MESSAGE = 'Traits type is incorrect or not provided!';
export const getManhattanPlotLegendConfig = (traitsType) => {
    if (traitsType === ETraitsTypes.Binary) {
        return MANHATTAN_PLOT_BINARY_LEGEND_CONFIG;
    }
    if (traitsType === ETraitsTypes.Continuous) {
        return MANHATTAN_PLOT_CONTINUOUS_LEGEND_CONFIG;
    }
    throw new Error(TRAITS_TYPE_ERROR_MESSAGE);
};

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { DESCRIPTION_INTERNAL, DESCRIPTION_PUBLIC, DISCLAIMER_INTERNAL, } from '../../consts';
import { ParagraphBolded, ParagraphWithMargin } from '../../containers';
import DescriptionMapper from '../DescriptionMapper';
const DescriptionSection = ({ isPublic = false, }) => {
    const descriptionText = isPublic
        ? DESCRIPTION_PUBLIC
        : DESCRIPTION_INTERNAL;
    return (_jsxs(_Fragment, { children: [!isPublic && (_jsx(ParagraphBolded, { children: DISCLAIMER_INTERNAL.map(({ description, key }) => (_jsx(Fragment, { children: _jsx(DescriptionMapper, { data: description, keyPrefix: key }, void 0) }, key))) }, void 0)), _jsx(ParagraphWithMargin, { children: descriptionText }, void 0)] }, void 0));
};
export default DescriptionSection;

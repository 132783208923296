/**
 * Checks if the string is a variant name
 *
 * @param str - String to check
 * @returns boolean value
 */
export const isVariantName = (str) => {
    if (!str) {
        return false;
    }
    const regExp = /^(?:[XY]|[1-9]|1[0-9]|2[0-2])-[0-9]+-[ATGC]+-[ATGC]+$/;
    return !!regExp.exec(str);
};

export var ETableColumnAccessor;
(function (ETableColumnAccessor) {
    ETableColumnAccessor["Variant"] = "variant_name";
    ETableColumnAccessor["Class"] = "Class";
    ETableColumnAccessor["Disease"] = "Inheritance";
    ETableColumnAccessor["CDNA"] = "HGVS_cdna";
    ETableColumnAccessor["Protein"] = "HGVS_protein";
    ETableColumnAccessor["Journal"] = "Journal";
    ETableColumnAccessor["Year"] = "Year";
    ETableColumnAccessor["PMID"] = "PMID";
})(ETableColumnAccessor || (ETableColumnAccessor = {}));
export var HgmdClass;
(function (HgmdClass) {
    HgmdClass["DM"] = "DM";
    HgmdClass["LikelyDM"] = "DM?";
    HgmdClass["DP"] = "DP";
    HgmdClass["DFP"] = "DFP";
    HgmdClass["FP"] = "FP";
    HgmdClass["R"] = "R";
})(HgmdClass || (HgmdClass = {}));

import { APIError } from './APIError';
export class FetchError extends APIError {
    constructor(error) {
        let message = 'Error fetching data from API';
        if (error) {
            message = `${message}: ${error.message}`;
        }
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
    }
}

import { numberFormatter } from 'Shared/utils/formatters/numberFormatter';
import { NO_DATA_CHAR } from 'Common/consts';
/**
 *
 * @param effectSize - The result value of the "getEffectSize" util
 * @returns The value with the dependency of the type of "effectSize",
 * formatted number or "NO_DATA_CHAR"
 */
export const getFormattedEffectSize = (effectSize) => {
    if (typeof effectSize === 'number') {
        return numberFormatter({ value: effectSize });
    }
    return NO_DATA_CHAR;
};

import { EDirectionOfEffect } from '../consts';
/**
 * Get the binary direction of effect
 *
 * @param oddsRatio - Number
 * @returns Binary direction of effect
 */
const getBinaryDirectionOfEffect = (oddsRatio) => {
    if (!oddsRatio) {
        return EDirectionOfEffect.NoDirectionOfEffect;
    }
    if (oddsRatio > 1) {
        return EDirectionOfEffect.Increase;
    }
    if (oddsRatio < 1) {
        return EDirectionOfEffect.Decrease;
    }
    return EDirectionOfEffect.NoDirectionOfEffect;
};
export default getBinaryDirectionOfEffect;

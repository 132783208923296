import { jsx as _jsx } from "react/jsx-runtime";
import { v4 as uuidv4 } from 'uuid';
import { ExternalLink } from 'Shared/components';
import { BIOBANK_URL, ARTICLE_MEDRXIV_2023_09_18_23295715V2_URL, ARTICLE_NATURE_622_PAGES_339_347_URL, ARTICLE_BIORXIV_2022_10_09_511476V1_URL, ARTICLE_AJHG_VOLUME_110_ISSUE_3_PAGES_487_498_URL, ARTICLE_MEDRXIV_2023_12_06_23299426V1_URL, SIGNIFICANT_ASSOCIATIONS_THRESHOLD_TEXT, SUGGESTIVE_ASSOCIATIONS_THRESHOLD_TEXT, ARTICLE_NATURE_GENETICS_56_PAGES_1832_1840_URL, PUBLICATION_URL, RARE_VARIANT_ASSOCIATIONS_WITH_PLASMA_PROTEIN_LEVELS_UKB_TITLE, } from 'Common/consts';
export const UK_BIOBANK_TERMS_SECTION_TITLE = 'UK Biobank Terms';
export const UK_BIOBANK_TERMS_TEXT_BEFORE_LINK = 'These data were generated using UK Biobank data accessed as part of applications 26041, 52293 and 65851, and use of these data is bound by all terms of usage of the UK Biobank: more information can be found ';
export const UK_BIOBANK_TERMS_LINK_LABEL = 'here';
export const UK_BIOBANK_TERMS_TEXT_AFTER_LINK = '. These data are summary statistics only. Whilst considerable effort has been taken to prevent the possibility of re-identification of participants, all users of these data agree to these terms of use and are bound by obligations to not attempt to re-identify participants.';
export const UK_BIOBANK_METHODS_INTERNAL_SECTION_TITLE = 'UK Biobank Methods';
export const UK_BIOBANK_METHODS_INTERNAL_SECTION_DESCRIPTION = `We used the subset of the ~470,000 exome sequenced participants released by the UK Biobank that are high quality, predominantly unrelated, and of European ancestry (n = 419,391) to evaluate the association between protein-coding variants with ~16K binary and ~5K continuous phenotypes using variant-level and gene-level phenome-wide association studies. These phenotypes include some AZ-defined ‘smart phenotypes’, NMR-based blood metabolites generated on a subset of UK Biobank participants, telomere length and Olink proteomics. A small number of potentially sensitive phenotypes have been excluded. This portal contains the subset of variant-level associations for which p ≤ 0.01 and the subset of gene-level associations for which p ≤ 0.1. Please note that no LD pruning has been performed on these associations. Qualifying variants are available for the subset of gene-level associations for which p ≤ 0.005. The significant p-value threshold (p≤${SIGNIFICANT_ASSOCIATIONS_THRESHOLD_TEXT}) is based on the n-of-1 permutation ExWAS results described in Wang, Dhindsa, Carss et al. (2021), where the expected number of ExWAS PheWAS false positives was 58 out of the 46,947 observed significant associations, corresponding to a false-positive rate of 0.1%. The suggestive threshold (p≤${SUGGESTIVE_ASSOCIATIONS_THRESHOLD_TEXT}) is conservatively motivated by the single phenotype-collapsing model correction p < (0.05/18500 genes). Full methods are described in the publication.`;
const UK_BIOBANK_METHODS_500K_WGS_V2_TITLE = 'Methods 500K WGS (v2)';
const UK_BIOBANK_METHODS_470K_V5_TITLE = 'Methods 470K (v5)';
const UK_BIOBANK_METHODS_470K_V5_DESCRIPTION = 'We used the subset of the ~470,000 exome sequenced participants released by the UK Biobank that are high quality, predominantly unrelated, and of European ancestry (n = 419,391) to evaluate the association between protein-coding variants with ~10K binary and ~3.5K continuous phenotypes using variant-level and gene-level phenome-wide association studies. A small number of potentially sensitive phenotypes have been excluded. This portal contains the subset of variant-level associations for which p ≤ 0.01 and the subset of gene-level associations for which p ≤ 0.1. Only variants identified in at least 20 samples were included in the variant-level analysis. Please note that no LD pruning has been performed on these associations. Qualifying variants are available for the subset of gene-level associations for which p ≤ 0.005 and there are more than 100 cases (for binary traits). The significant p-value threshold (p≤1e-8) is based on the n-of-1 permutation ExWAS results described in Wang, Dhindsa, Carss et al. (2021), where the expected number of ExWAS PheWAS false positives was 58 out of the 46,947 observed significant associations, corresponding to a false-positive rate of 0.1%. The suggestive threshold (p≤1e-6) is conservatively motivated by the single phenotype-collapsing model correction p < (0.05/18500 genes). Full  methods are described in the publication.';
const UK_BIOBANK_METHODS_460K_WES_WGS_TITLE = 'Methods (460k WES and 460k WGS)';
const UK_BIOBANK_METHODS_450K_V4_TITLE = 'Methods 450K (v4)';
const UK_BIOBANK_METHODS_300K_V3_TITLE = 'Methods 300K (v3)';
const KEY_PREFIX_INTERNAL = 'about-view-internal_uk-biobank-methods';
const KEY_PREFIX_500K_WGS_V2 = 'about-view-public_uk-biobank-methods-500K_WGS_V2';
const KEY_PREFIX_470K_V5 = 'about-view-public_uk-biobank-methods-470K_V5';
const KEY_PREFIX_460K_WES_WGS = 'about-view-public_uk-biobank-methods-460K_WES_WGS';
const KEY_PREFIX_450K_V4 = 'about-view-public_uk-biobank-methods-450K_V4';
const KEY_PREFIX_300K_V3 = 'about-view-public_uk-biobank-methods-300K_V3';
export const UK_BIOBANK_METHODS_INTERNAL = [
    {
        key: `${KEY_PREFIX_INTERNAL}_paragraph-1_${uuidv4()}`,
        description: [
            'Methods for the NMR metabolites can be found in the publication: Nag et al. Effects of protein-coding variants on blood metabolite measurements and clinical biomarkers in the UK Biobank (2023), ',
            _jsx(ExternalLink, Object.assign({ to: ARTICLE_AJHG_VOLUME_110_ISSUE_3_PAGES_487_498_URL, title: BIOBANK_URL, isTextFieldLink: true }, { children: ARTICLE_AJHG_VOLUME_110_ISSUE_3_PAGES_487_498_URL }), `${KEY_PREFIX_INTERNAL}_article-ajhg-volume-110-issue-3-pages-487-498-link_${uuidv4()}`),
            '. Please note that the results on the portal were generated in a slightly larger number of European ancestry individuals (N= 101,139) than those in the publication, (N=99,283 pan-ancestry subjects).',
        ],
    },
    {
        key: `${KEY_PREFIX_INTERNAL}_paragraph-2_${uuidv4()}`,
        description: [
            'As part of the UKBiobank Pharma Proteomics Project (UKB-PPP) 2,923 unique plasma proteins were measured using the Olink Explore 3072 platform. Results for 46,327 exome sequenced UK Biobank participants of European ancestry were included in this analysis. Methods are described in our preprint publication: Dhindsa R.S., Burren O.S. et al. Influences of rare protein-coding genetic variants on the human plasma proteome in 49,736 UK Biobank participants (2022), ',
            _jsx(ExternalLink, Object.assign({ to: ARTICLE_BIORXIV_2022_10_09_511476V1_URL, title: BIOBANK_URL, isTextFieldLink: true }, { children: ARTICLE_BIORXIV_2022_10_09_511476V1_URL }), `${KEY_PREFIX_INTERNAL}_article-biorxiv-2022-10-09-511476v1-link_${uuidv4()}`),
            '.',
        ],
    },
    {
        key: `${KEY_PREFIX_INTERNAL}_paragraph-3_${uuidv4()}`,
        description: [
            'Results for combined qPCR and WGS (whole genome sequencing) telomere length in European ancestry (NFE) individuals from UK Biobank (n=439,483) are based on the whole genome sequence data. For more information, please see the following preprint publications: Burren, Dhindsa, Deevi et al. (2023) Genetic architecture of telomere length in 462,675 UK Biobank whole genome sequences: ',
            _jsx(ExternalLink, Object.assign({ to: ARTICLE_MEDRXIV_2023_09_18_23295715V2_URL, title: ARTICLE_MEDRXIV_2023_09_18_23295715V2_URL, isTextFieldLink: true }, { children: ARTICLE_MEDRXIV_2023_09_18_23295715V2_URL }), `${KEY_PREFIX_INTERNAL}_article-medrxiv-2023-09-18-23295715v2-link_${uuidv4()}`),
            ' and Whole-genome sequencing of half-a-million UK Biobank participants (2023): ',
            _jsx(ExternalLink, Object.assign({ to: ARTICLE_MEDRXIV_2023_12_06_23299426V1_URL, title: ARTICLE_MEDRXIV_2023_12_06_23299426V1_URL, isTextFieldLink: true }, { children: ARTICLE_MEDRXIV_2023_12_06_23299426V1_URL }), `${KEY_PREFIX_INTERNAL}_article-medrxiv-2023-12-06-23299426v1-link_${uuidv4()}`),
            '.',
        ],
    },
];
const UK_BIOBANK_METHODS_500K_WGS_V2 = [
    {
        key: `${KEY_PREFIX_500K_WGS_V2}_paragraph-1_${uuidv4()}`,
        description: [
            'We used the subset of the ~500,000 whole genome sequenced participants released by the UK Biobank that are high quality and predominantly unrelated to evaluate the association between protein-coding variants with ~13K binary and ~5K continuous phenotypes using variant-level and gene-level phenome-wide association studies in 6 ancestry groups (European, Askenazi Jewish, Admixed American, African, East Asian, South Asian). A small number of potentially sensitive phenotypes have been excluded. This portal contains the subset of variant-level associations for which p ≤ 0.01 and the subset of gene-level associations for which p ≤ 0.1. Only variants identified in at least 20 samples were included in the variant-level analysis. Please note that no LD pruning has been performed on these associations. Qualifying variants are available for the subset of gene-level associations for which p ≤ 0.005 and there are more than 100 cases (for binary traits). The rationale for the significant (p≤1e-8) and suggestive (p≤1e-6) thresholds is described below and based on the publication Wang, Dhindsa, Carss et al. (2021): ',
            _jsx(ExternalLink, Object.assign({ to: PUBLICATION_URL, title: PUBLICATION_URL, isTextFieldLink: true }, { children: PUBLICATION_URL }), `${KEY_PREFIX_500K_WGS_V2}_article-nature-597_2021_pages-527-532-link_${uuidv4()}`),
            '.',
        ],
    },
    {
        key: `${KEY_PREFIX_500K_WGS_V2}_paragraph-2_${uuidv4()}`,
        description: [
            'Olink plasma proteins measured as part of the UKBiobank Pharma Proteomics Project (UKB-PPP), NMR metabolites (standard UKB release phenotypes) and telomere length are also included in this release. Please refer to Dhindsa R.S., Burren O.S. Sun B.B. et al. Rare variant associations with plasma protein levels in the UK Biobank (2023) ',
            _jsx(ExternalLink, Object.assign({ to: ARTICLE_NATURE_622_PAGES_339_347_URL, title: RARE_VARIANT_ASSOCIATIONS_WITH_PLASMA_PROTEIN_LEVELS_UKB_TITLE, isTextFieldLink: true, target: "_blank" }, { children: ARTICLE_NATURE_622_PAGES_339_347_URL }), `${KEY_PREFIX_500K_WGS_V2}_article-nature-622-pages-339-347-link_${uuidv4()}`),
            ' for more information on the plasma proteins, and Burren, Dhindsa, Deevi et al. (2024) Genetic architecture of telomere length in 462,666 UK Biobank whole-genome sequences: ',
            _jsx(ExternalLink, Object.assign({ to: ARTICLE_NATURE_GENETICS_56_PAGES_1832_1840_URL, title: ARTICLE_NATURE_GENETICS_56_PAGES_1832_1840_URL, isTextFieldLink: true }, { children: ARTICLE_NATURE_GENETICS_56_PAGES_1832_1840_URL }), `${KEY_PREFIX_500K_WGS_V2}_article-nature-genetics-2024-08-27_1832-1840-link_${uuidv4()}`),
            ' for more information on the telomere length analysis.',
        ],
    },
    {
        key: `${KEY_PREFIX_500K_WGS_V2}_paragraph-3_${uuidv4()}`,
        description: [
            'For more information on the whole genome sequence data, please refer to our preprint publication: Li, Carss, Halldorsson, Cortes, UK Biobank Whole-Genome Sequencing Consortium. Whole-genome sequencing of half-a-million UK Biobank participants (2023) ',
            _jsx(ExternalLink, Object.assign({ to: ARTICLE_MEDRXIV_2023_12_06_23299426V1_URL, title: ARTICLE_MEDRXIV_2023_12_06_23299426V1_URL, isTextFieldLink: true }, { children: ARTICLE_MEDRXIV_2023_12_06_23299426V1_URL }), `${KEY_PREFIX_500K_WGS_V2}_article-medrxiv-2023-12-06-23299426v1-link_${uuidv4()}`),
            '.',
        ],
    },
];
const UK_BIOBANK_METHODS_470K_V5 = [
    {
        key: `${KEY_PREFIX_470K_V5}_paragraph-1_${uuidv4()}`,
        description: [
            'Methods for the NMR metabolites can be found in the publication: Nag et al. Effects of protein-coding variants on blood metabolite measurements and clinical biomarkers in the UK Biobank (2023), ',
            _jsx(ExternalLink, Object.assign({ to: ARTICLE_AJHG_VOLUME_110_ISSUE_3_PAGES_487_498_URL, title: BIOBANK_URL, isTextFieldLink: true }, { children: ARTICLE_AJHG_VOLUME_110_ISSUE_3_PAGES_487_498_URL }), `${KEY_PREFIX_470K_V5}_article-ajhg-volume-110-issue-3-pages-487-498-link_${uuidv4()}`),
            '. The results on the portal were generated in a slightly larger number of European ancestry individuals (N= 101,139) than those in the publication, (N=99,283 pan-ancestry subjects).',
        ],
    },
    {
        key: `${KEY_PREFIX_470K_V5}_paragraph-2_${uuidv4()}`,
        description: [
            'As part of the UKBiobank Pharma Proteomics Project (UKB-PPP) 2,923 plasma proteins were measured using the Olink Explore 3072 platform. Results for 46,327 exome sequenced UK Biobank participants of European ancestry were included in this analysis. Methods are described in our publication: Dhindsa R.S., Burren O.S. Sun B.B. et al. Rare variant associations with plasma protein levels in the UK Biobank (2023), ',
            _jsx(ExternalLink, Object.assign({ to: ARTICLE_NATURE_622_PAGES_339_347_URL, title: BIOBANK_URL, isTextFieldLink: true }, { children: ARTICLE_NATURE_622_PAGES_339_347_URL }), `${KEY_PREFIX_470K_V5}_article-nature-622-pages-339-347-link_${uuidv4()}`),
            '.',
        ],
    },
    {
        key: `${KEY_PREFIX_470K_V5}_paragraph-3_${uuidv4()}`,
        description: [
            'Results for combined qPCR and WGS (whole genome sequencing) telomere length in European ancestry (NFE) individuals from UK Biobank (n=439,483) are based on the whole genome sequence data. For more information, please see the following publications: Burren, Dhindsa, Deevi et al. (2024) Genetic architecture of telomere length in 462,666 UK Biobank whole-genome sequences: ',
            _jsx(ExternalLink, Object.assign({ to: ARTICLE_NATURE_GENETICS_56_PAGES_1832_1840_URL, title: ARTICLE_NATURE_GENETICS_56_PAGES_1832_1840_URL, isTextFieldLink: true }, { children: ARTICLE_NATURE_GENETICS_56_PAGES_1832_1840_URL }), `${KEY_PREFIX_470K_V5}_article-nature-genetics-2024-08-27_1832-1840-link_${uuidv4()}`),
            ' and Whole-genome sequencing of half-a-million UK Biobank participants (2023, preprint): ',
            _jsx(ExternalLink, Object.assign({ to: ARTICLE_MEDRXIV_2023_12_06_23299426V1_URL, title: ARTICLE_MEDRXIV_2023_12_06_23299426V1_URL, isTextFieldLink: true }, { children: ARTICLE_MEDRXIV_2023_12_06_23299426V1_URL }), `${KEY_PREFIX_470K_V5}_article-medrxiv-2023-12-06-23299426v1-link_${uuidv4()}`),
            '.',
        ],
    },
];
const UK_BIOBANK_METHODS_460K_WES_WGS = [
    {
        key: `${KEY_PREFIX_460K_WES_WGS}_paragraph-1_${uuidv4()}`,
        description: [
            'We used a subset of 460,552 exome and genome sequenced participants released by the UK Biobank to evaluate the association between protein-coding variants and 687 binary and 64 continuous phenotypes using a gene-level phenome-wide association analysis. We included participants from five ancestry groups (NFE, ASJ, AFR, EAS, SAS) and performed a pan-ancestry meta-analysis across ancestries for each phenotype. Binary phenotypes with < 100 cases in UK Biobank have been excluded. This portal contains the subset of gene-level meta-analysis results for which p ≤ 0.1. Full methods are described in the preprint publication, ',
            _jsx(ExternalLink, Object.assign({ to: ARTICLE_MEDRXIV_2023_12_06_23299426V1_URL, title: BIOBANK_URL, isTextFieldLink: true, target: "_blank" }, { children: ARTICLE_MEDRXIV_2023_12_06_23299426V1_URL }), `${KEY_PREFIX_460K_WES_WGS}_article-medrxiv-2023-12-06-23299426v1-link_${uuidv4()}`),
            '.',
        ],
    },
];
const UK_BIOBANK_METHODS_450K_V4 = [
    {
        key: `${KEY_PREFIX_450K_V4}_paragraph-1_${uuidv4()}`,
        description: [
            'We used the subset of the ~450,000 exome sequenced participants released by the UK Biobank that are high quality, predominantly unrelated, and of European ancestry (n = 394,695) to evaluate the association between protein-coding variants with ~15.5K binary and ~1.5K continuous phenotypes using variant-level and gene-level phenome-wide association studies. A small number of potentially sensitive phenotypes have been excluded, and binary phenotypes with < 30 cases have been excluded. This portal contains the subset of variant-level associations for which p ≤ 0.01 and the subset of gene-level associations for which p ≤ 0.1. Only variants identified in at least 30 samples were included in the variant-level analysis. Please note that no LD pruning has been performed on these associations. Qualifying variants are available for the subset of gene-level associations for which p ≤ 0.005 and there are more than 100 cases (for binary traits). Qualifying variants for some binary phenotypes are currently unavailable for this dataset. Full methods are described in the publication.',
        ],
    },
];
const UK_BIOBANK_METHODS_300K_V3 = [
    {
        key: `${KEY_PREFIX_300K_V3}_paragraph-1_${uuidv4()}`,
        description: [
            'We used the subset of the ~300,000 exome sequenced participants released by the UK Biobank that are high quality, predominantly unrelated, and of European ancestry (n = 269,171) to evaluate the association between protein-coding variants with ~17K binary and ~1,4K continuous phenotypes using variant-level and gene-level phenome-wide association studies. A small number of potentially sensitive phenotypes have been excluded, and binary phenotypes with < 30 cases have been excluded. This portal contains the subset of variant-level associations for which p ≤ 0.0001 and the subset of gene-level associations for which p ≤ 0.1. Only variants identified in at least 30 samples were included in the variant-level analysis. Please note that no LD pruning has been performed on these associations. Qualifying variants are available for the subset of gene-level associations for which p ≤ 0.005 and there are more than 100 cases (for binary traits). Full methods are described in the publication.',
        ],
    },
];
export const UK_BIOBANK_METHODS_PUBLIC_DATA = [
    {
        key: `${KEY_PREFIX_500K_WGS_V2}_data_${uuidv4()}`,
        title: UK_BIOBANK_METHODS_500K_WGS_V2_TITLE,
        data: UK_BIOBANK_METHODS_500K_WGS_V2,
        hasParagraphsWithMargin: true,
    },
    {
        key: `${KEY_PREFIX_470K_V5}_data_${uuidv4()}`,
        title: UK_BIOBANK_METHODS_470K_V5_TITLE,
        summary: UK_BIOBANK_METHODS_470K_V5_DESCRIPTION,
        data: UK_BIOBANK_METHODS_470K_V5,
        hasParagraphsWithMargin: true,
    },
    {
        key: `${KEY_PREFIX_460K_WES_WGS}_data_${uuidv4()}`,
        title: UK_BIOBANK_METHODS_460K_WES_WGS_TITLE,
        data: UK_BIOBANK_METHODS_460K_WES_WGS,
        hasParagraphsWithMargin: true,
    },
    {
        key: `${KEY_PREFIX_450K_V4}_data_${uuidv4()}`,
        title: UK_BIOBANK_METHODS_450K_V4_TITLE,
        data: UK_BIOBANK_METHODS_450K_V4,
        hasParagraphsWithMargin: true,
    },
    {
        key: `${KEY_PREFIX_300K_V3}_data_${uuidv4()}`,
        title: UK_BIOBANK_METHODS_300K_V3_TITLE,
        data: UK_BIOBANK_METHODS_300K_V3,
        hasParagraphsWithMargin: true,
    },
];

import { ETraitsTypes } from 'Shared/types';
/**
 * Checks if `valueToCheck` is below threshold, depending on traits type
 *
 * @param valueToCheck - Value to be checked
 * @param traitsType - Traits type
 * @returns Returns `true`, if valueToCheck is below the threshold, otherwise `false`
 */
export const filterByDirectionOfEffect = (valueToCheck, traitsType) => {
    if (valueToCheck === null)
        return false;
    return traitsType === ETraitsTypes.Binary
        ? valueToCheck < 1
        : valueToCheck < 0;
};

import { ELevelResult, ETraitsTypes } from 'Shared/types';
export var EProvider;
(function (EProvider) {
    EProvider["UKB"] = "ukb";
    EProvider["FinnGen"] = "finngen";
})(EProvider || (EProvider = {}));
export const PROVIDER_NAME = {
    [EProvider.UKB]: 'UK Biobank',
    [EProvider.FinnGen]: 'FinnGen',
};
export var EDatasetsNames;
(function (EDatasetsNames) {
    EDatasetsNames["PUBLIC_200K"] = "200k Public";
    EDatasetsNames["PUBLIC_300K"] = "300k (v3) Public";
    EDatasetsNames["PUBLIC_450K"] = "450k (v4) Public";
    EDatasetsNames["PUBLIC_470K"] = "470k (v5) Public";
    EDatasetsNames["PUBLIC_500K"] = "500k WGS (v2) Public";
    EDatasetsNames["INTERNAL_200K"] = "200k Internal";
    EDatasetsNames["INTERNAL_300K"] = "300k (v3) Internal";
    EDatasetsNames["INTERNAL_450K"] = "450k (v4) Internal";
    EDatasetsNames["INTERNAL_470K"] = "470k (v5) Internal";
    EDatasetsNames["INTERNAL_500K"] = "500k WGS (v2) Internal";
    EDatasetsNames["FINNGEN_R6_GWAS"] = "FinnGen R6 GWAS";
    EDatasetsNames["FINNGEN_R8"] = "FinnGen R8";
    EDatasetsNames["FINNGEN_R10"] = "FinnGen R10";
    EDatasetsNames["FINNGEN_R12"] = "FinnGen R12";
})(EDatasetsNames || (EDatasetsNames = {}));
export const LEVEL_RESULT_LABEL = {
    [ELevelResult.Gene]: 'Gene',
    [ELevelResult.Variant]: 'Variant',
    [ELevelResult.Phenotype]: 'Phenotype',
};
export const DATASETS_TOOLTIP_TEXTS = {
    [ELevelResult.Gene]: {
        [ETraitsTypes.Binary]: 'Collapsing analysis is a gene-based rare variant statistical test that has successfully identified known and novel gene-phenotype associations in many different phenotypes. Following selection of cases and controls, ‘qualifying variants’ are defined: that is, variants that fulfil a certain set of filtering criteria depending on the collapsing model. Next, we identify whether each individual carries a qualifying variant in each gene. Finally, we perform a statistical test (Fisher’s exact test for binary traits) to identify genes that are enriched for rare variants in cases compared to controls. Here, we have performed collapsing analysis across the available binary phenotypes in this dataset. Please note, these phenotypes are not independent of each other, instead many of them are highly correlated with each other. For each test, cases are defined as individuals who have that phenotype, and controls are individuals who do not have that phenotype. In the plot, each point represents a gene-phenotype association, the higher the point the more significant the association. The points above the significance line are considered statistically significant associations. The points are color coded according to phenotypic category.',
        [ETraitsTypes.Continuous]: 'Collapsing analysis is a gene-based rare variant statistical test that has successfully identified known and novel gene-phenotype associations in many different phenotypes. ‘Qualifying variants’ are defined: that is, variants that fulfil a certain set of filtering criteria depending on the collapsing model. Next, we identify whether each individual carries a qualifying variant in each gene. Finally, we perform a statistical test (regression for continuous traits) to identify genes in which rare variants are associated with the continuous trait. Here, we have performed collapsing analysis across the available continuous traits in this dataset. In the plot, each point represents a gene-phenotype association, the higher the point the more significant the association. The points above the significance line are considered statistically significant associations. The points are colour coded according to phenotypic category.',
    },
    [ELevelResult.Variant]: {
        [ETraitsTypes.Binary]: 'Variant-level association analysis has successfully identified many known and novel gene-phenotype associations. Variants are identified that fulfil a certain set of filtering criteria depending on the model. Next, we identify whether each individual carries that variant. Finally, we perform a Fisher’s exact test to identify variants that are enriched in cases or controls. Cases are defined as individuals who have that phenotype, and controls are individuals who do not have that phenotype. LD pruning has not been performed. Some of the phenotypes may be correlated with each other. In the plot, each point represents a variant-phenotype association, the higher the point the more significant the association. The points above the significance line are considered statistically significant associations. The points are colour coded according to phenotypic category.',
        [ETraitsTypes.Continuous]: 'Variant-level association analysis has successfully identified many known and novel gene-phenotype associations. Variants are identified that fulfil a certain set of filtering criteria depending on the model. Next, we identify whether each individual carries that variant. Finally, we perform a regression test to identify variants that are associated with the continuous trait. The sample numbers in this section describe those for which a measurement of the continuous trait are available. LD pruning has not been performed. Some of these phenotypes may be correlated with each other. In the plot, each point represents a variant-phenotype association, the higher the point the more significant the association. The points above the significance line are considered statistically significant associations. The points are colour coded according to phenotypic category.',
    },
    [ELevelResult.Phenotype]: {
        [ETraitsTypes.Binary]: 'Tooltip content',
        [ETraitsTypes.Continuous]: 'Tooltip content',
    },
};
export const DATASETS_TOOLTIP_TEXTS_FINNGEN = Object.assign(Object.assign({}, DATASETS_TOOLTIP_TEXTS), { [ELevelResult.Gene]: {
        [ETraitsTypes.Binary]: 'Variant-level association analysis has successfully identified many known and novel gene-phenotype associations. Variant-level association results from the FinnGen study were filtered to those with association p-value ≤ 0.01, imputation quality ≥ 0.6 and fall within a CCDS-based gene boundary +/ 8bp buffer. LD pruning has not been performed. Some of the phenotypes may be correlated with each other. In the plot, each point represents a variant-phenotype association, the higher the point the more significant the association. The points above the significance line are considered statistically significant associations. The points are colour coded according to phenotypic category.',
        [ETraitsTypes.Continuous]: 'Variant-level association analysis has successfully identified many known and novel gene-phenotype associations. Variant-level association results from the FinnGen study were filtered to those with association p-value ≤ 0.01, imputation quality ≥ 0.6 and fall within a CCDS-based gene boundary +/ 8bp buffer. LD pruning has not been performed. Some of the phenotypes may be correlated with each other. In the plot, each point represents a variant-phenotype association, the higher the point the more significant the association. The points above the significance line are considered statistically significant associations. The points are colour coded according to phenotypic category.',
    }, [ELevelResult.Variant]: {
        [ETraitsTypes.Binary]: 'Variant-level association analysis has successfully identified many known and novel gene-phenotype associations. Variant-level association results from the FinnGen study were filtered to those with association p-value ≤ 0.01, imputation quality ≥ 0.6 and fall within a CCDS-based gene boundary +/ 8bp buffer. LD pruning has not been performed. Some of the phenotypes may be correlated with each other. In the plot, each point represents a variant-phenotype association, the higher the point the more significant the association. The points above the significance line are considered statistically significant associations. The points are colour coded according to phenotypic category.',
        [ETraitsTypes.Continuous]: 'Variant-level association analysis has successfully identified many known and novel gene-phenotype associations. Variant-level association results from the FinnGen study were filtered to those with association p-value ≤ 0.01, imputation quality ≥ 0.6 and fall within a CCDS-based gene boundary +/ 8bp buffer. LD pruning has not been performed. Some of the phenotypes may be correlated with each other. In the plot, each point represents a variant-phenotype association, the higher the point the more significant the association. The points above the significance line are considered statistically significant associations. The points are colour coded according to phenotypic category.',
    } });
export const getIsProviderFinnGen = (providerId) => EProvider.FinnGen === providerId;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ErrorPlaceholder__layout__2i2pW{justify-content:center;min-height:100vh}.ErrorPlaceholder__content__1OmDl,.ErrorPlaceholder__layout__2i2pW{display:flex;flex-direction:column}.ErrorPlaceholder__content__1OmDl{align-items:center;justify-content:space-between;padding:5rem}.ErrorPlaceholder__title__11ffY{color:var(--primary-color);font-size:3.5rem;line-height:4rem;margin:0 0 2rem}.ErrorPlaceholder__primaryMessage__LFtLs{color:var(--font-color);font-size:2.5rem;line-height:3rem;margin:0 0 1.5rem;max-width:47.5rem;text-align:center}.ErrorPlaceholder__primaryMessage__LFtLs,.ErrorPlaceholder__title__11ffY{font-weight:700}.ErrorPlaceholder__errorImage__1jPFB{margin-left:4rem}", "",{"version":3,"sources":["webpack://./src/common/components/ErrorPlaceholder/ErrorPlaceholder.css"],"names":[],"mappings":"AAAA,iCAGE,sBAAuB,CACvB,gBACF,CAEA,mEANE,YAAa,CACb,qBAWF,CANA,kCAIE,kBAAmB,CADnB,6BAA8B,CAE9B,YACF,CAEA,gCAGE,0BAA2B,CAF3B,gBAAiB,CACjB,gBAAiB,CAEjB,eACF,CAEA,yCACE,uBAAwB,CACxB,gBAAiB,CACjB,gBAAiB,CACjB,iBAAkB,CAClB,iBAAkB,CAClB,iBACF,CAEA,yEAEE,eACF,CAEA,qCAKE,gBACF","sourcesContent":[".layout {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  min-height: 100vh;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  padding: 5rem;\n}\n\n.title {\n  font-size: 3.5rem;\n  line-height: 4rem;\n  color: var(--primary-color);\n  margin: 0 0 2rem;\n}\n\n.primaryMessage {\n  color: var(--font-color);\n  font-size: 2.5rem;\n  line-height: 3rem;\n  margin: 0 0 1.5rem;\n  max-width: 47.5rem;\n  text-align: center;\n}\n\n.title,\n.primaryMessage {\n  font-weight: 700;\n}\n\n.errorImage {\n  /*\n    Workaround to center an image\n    @TODO: Replace SVG with left margined one\n  */\n  margin-left: 4rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "ErrorPlaceholder__layout__2i2pW",
	"content": "ErrorPlaceholder__content__1OmDl",
	"title": "ErrorPlaceholder__title__11ffY",
	"primaryMessage": "ErrorPlaceholder__primaryMessage__LFtLs",
	"errorImage": "ErrorPlaceholder__errorImage__1jPFB"
};
export default ___CSS_LOADER_EXPORT___;

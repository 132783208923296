/* eslint-disable no-param-reassign */
/* This util is for use inside *.slice.ts files only */
import { current } from '@reduxjs/toolkit';
import { ELevelResult } from 'Shared/types';
import { AssociationsService } from 'PhenotypeView/services';
export const handleFilteringPLR = (state, worker) => {
    const { filters, associations, } = state;
    if (worker) {
        worker.postMessage({
            filters: current(filters),
            associations: current(associations),
            levelResult: ELevelResult.Phenotype,
        });
    }
    else {
        state.filteredAssociationsIds = AssociationsService.getFilteredDataPLR(associations, filters);
        state.isFiltering = false;
    }
};

import { PlotDomain } from 'Shared/plots/models/PlotDomain/PlotDomain';
/**
 * Adapter which transforms data from API service into store domains
 *
 * @returns Store domains
 */
export const domainsDataAdapter = (pfamData, transcriptsData) => {
    if (!pfamData || !transcriptsData) {
        return [];
    }
    let domainCounter = 1;
    const domainStorage = transcriptsData
        .reduce((storage, domainData) => {
        const updatedStorage = storage;
        const { pfamDomainId: id } = domainData;
        const pfamDomainData = pfamData.find((el) => el.pfamAccession === id);
        const genomeNexusData = Object.assign(Object.assign({}, pfamDomainData), domainData);
        const domain = PlotDomain.create(genomeNexusData, `${domainCounter}`);
        if (!storage[id]) {
            updatedStorage[id] = domain;
            domainCounter += 1;
        }
        else {
            updatedStorage[id] = PlotDomain.addIntervals(storage[id], domain.intervals);
        }
        return updatedStorage;
    }, {});
    return Object.values(domainStorage);
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Link__link__2VKu9{color:var(--link-color);text-decoration:none}.Link__textFieldLink__2Qww2{text-decoration:underline}.Link__textFieldLink__2Qww2:hover{color:var(--link-hover-color)}.Link__navLink__3MRHK{text-decoration:none}.Link__navLink__3MRHK:hover{color:var(--link-hover-color);text-decoration:underline}", "",{"version":3,"sources":["webpack://./src/common/components/Link/Link.css"],"names":[],"mappings":"AAAA,mBACE,uBAAwB,CACxB,oBACF,CAEA,4BACE,yBAKF,CAHE,kCACE,6BACF,CAGF,sBACE,oBAMF,CAJE,4BACE,6BAA8B,CAC9B,yBACF","sourcesContent":[".link {\n  color: var(--link-color);\n  text-decoration: none;\n}\n\n.textFieldLink {\n  text-decoration: underline;\n\n  &:hover {\n    color: var(--link-hover-color);\n  }\n}\n\n.navLink {\n  text-decoration: none;\n\n  &:hover {\n    color: var(--link-hover-color);\n    text-decoration: underline;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "Link__link__2VKu9",
	"textFieldLink": "Link__textFieldLink__2Qww2",
	"navLink": "Link__navLink__3MRHK"
};
export default ___CSS_LOADER_EXPORT___;

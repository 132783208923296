import { escapeFileName, getISODateTime, constructFileName, } from 'Shared/utils';
import { EExportFileFormat, TRAITS_TYPE_SUFFIX } from 'Common/consts';
import { getSuffixOfExportedFileName } from './getSuffixOfExportedFileName/getSuffixOfExportedFileName';
import { getExportDatasetName } from '../getExportDatasetName/getExportDatasetName';
/**
 * constructExportFileName:
 *
 * @param [object] with fields: root, suffixVersionTitle, prefix, traitsType,
 * levelResultSuffix, collapsingModelSuffix, plotFileLevelResultSuffix
 * @returns the object with the names of the exported files
 */
export const constructExportFileName = ({ prefix, root, suffixVersionTitle, allExportedData, traitsType = '', levelResultSuffix = '', collapsingModelSuffix = '', plotFileLevelResultSuffix, }) => {
    const ISODateTime = getISODateTime(true);
    const prefixArr = prefix ? prefix.split('_') : [''];
    const datasetPrefixName = prefixArr[0] ? `${getExportDatasetName(prefixArr[0])}` : '';
    const secondPrefixPart = prefixArr[1] ? `_${prefixArr[1]}` : '';
    const suffixOfTraitsType = traitsType ? TRAITS_TYPE_SUFFIX[traitsType] : '';
    const suffixOfExportedFileName = getSuffixOfExportedFileName(allExportedData, suffixOfTraitsType);
    return {
        archiveName: constructFileName({
            prefix: `${datasetPrefixName}${secondPrefixPart}`,
            str: `${escapeFileName(root)}`,
            suffix: `${collapsingModelSuffix}${levelResultSuffix}${suffixOfExportedFileName}_${ISODateTime}_${suffixVersionTitle}${EExportFileFormat.ZIP}`,
        }),
        tableFileName: constructFileName({
            prefix: `${datasetPrefixName}${secondPrefixPart}`,
            str: `${escapeFileName(root)}`,
            suffix: `${collapsingModelSuffix}${levelResultSuffix}${suffixOfExportedFileName}_${ISODateTime}_${suffixVersionTitle}${EExportFileFormat.CSV}`,
        }),
        plotFileName: constructFileName({
            prefix: `${datasetPrefixName}${secondPrefixPart}`,
            str: `${escapeFileName(root)}`,
            suffix: `${collapsingModelSuffix}${plotFileLevelResultSuffix || levelResultSuffix}${suffixOfExportedFileName}_${ISODateTime}_${suffixVersionTitle}${EExportFileFormat.SVG}`,
        }),
    };
};

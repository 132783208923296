import { ManhattanPlotData } from 'Shared/plots/models/ManhattanPlotData/ManhattanPlotData';
import { ManhattanPlotDataGroup } from 'Shared/plots/models/ManhattanPlotDataGroup/ManhattanPlotDataGroup';
import { ETraitsTypes } from 'Shared/types';
import { CHAPTER_NUMBER_PATTERN } from 'Common/consts';
/**
 * Returns Manhattan Plot Data
 * @param traitsType traits type
 * @param categories phenotypic categories array
 * @param mapper collection of data points for Manhattan plot grouped by phenotypicCategory
 * @returns manhattan plot data
 */
export const getPlotDataFromCategories = (traitsType, categories, mapper) => categories.reduce((acc, category, index) => {
    var _a;
    const { name, shortName } = category;
    const groupId = name;
    let groupLabel = shortName !== null && shortName !== void 0 ? shortName : `${index + 1}`;
    let groupName = name;
    // Roman numeral for binary traits if no short label was provided
    if (traitsType !== ETraitsTypes.Continuous && !shortName) {
        const CHAPTER_PATTERN = /^chapter\s/i;
        const [findMatch, findLabel] = CHAPTER_NUMBER_PATTERN.exec(name) || [];
        if (findLabel) {
            groupLabel = findLabel;
        }
        if (findMatch) {
            groupName = name
                .replace(CHAPTER_PATTERN, '')
                .replace(findMatch, '')
                .trim();
        }
    }
    if ((_a = mapper[name]) === null || _a === void 0 ? void 0 : _a.length) {
        acc.addGroup(new ManhattanPlotDataGroup(groupName, groupLabel, mapper[name].length, mapper[name], groupId));
    }
    return acc;
}, ManhattanPlotData.empty);

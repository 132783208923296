import { useRef, useCallback, } from 'react';
export default function useIntersection(intersectionChecker = ({ isIntersecting }) => isIntersecting, observerOptions) {
    const observerRef = useRef(null);
    const intersectionMapRef = useRef(new WeakMap());
    const intersectionCallbackMapRef = useRef(new WeakMap());
    function getObserver(callback, options) {
        if (!observerRef.current) {
            observerRef.current = new IntersectionObserver(callback, options);
        }
        return observerRef.current;
    }
    const observerCallback = useCallback((entries) => entries.forEach((entry) => {
        const isIntersected = intersectionChecker(entry);
        const isCurIntersected = intersectionMapRef.current.get(entry.target);
        if (isCurIntersected !== isIntersected) {
            intersectionMapRef.current.set(entry.target, isIntersected);
            const entryCallback = intersectionCallbackMapRef.current.get(entry.target);
            if (entryCallback) {
                entryCallback(isIntersected);
            }
        }
    }), [intersectionChecker]);
    const observer = getObserver(observerCallback, observerOptions);
    const subscribe = useCallback((targetRef, subscriptionCallback) => {
        if (subscriptionCallback) {
            intersectionCallbackMapRef.current.set(targetRef, subscriptionCallback);
        }
        intersectionMapRef.current.set(targetRef, false);
        observer.observe(targetRef);
    }, [observer]);
    const unsubscribe = useCallback((targetRef) => {
        intersectionMapRef.current.set(targetRef, false);
        observer.unobserve(targetRef);
    }, [observer]);
    return {
        subscribe,
        unsubscribe,
    };
}

export var EVariantSource;
(function (EVariantSource) {
    EVariantSource["OMIM"] = "OMIM";
    EVariantSource["HGMD"] = "HGMD";
    EVariantSource["CLIN_VAR"] = "CLIN_VAR";
    EVariantSource["EBI_GWAS"] = "EBI_GWAS";
    EVariantSource["GBMI"] = "GBMI";
})(EVariantSource || (EVariantSource = {}));
export var EVariantName;
(function (EVariantName) {
    EVariantName["OMIM"] = "OMIM";
    EVariantName["HGMD"] = "HGMD";
    EVariantName["ClinVar"] = "CLIN_VAR";
    EVariantName["NHGRI-EBI GWAS Catalog"] = "EBI_GWAS";
    EVariantName["GBMI"] = "GBMI";
})(EVariantName || (EVariantName = {}));

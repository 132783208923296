var _a;
import { DEFAULT_API_SERVICE_URI } from 'API/consts';
import { RESTResource } from '../RESTResource';
export class GeneStatsResource extends RESTResource {
    constructor(urlRoot) {
        super((urlParams) => {
            const { datasetVersionId, geneId, } = urlParams;
            return `${urlRoot}/v1/${datasetVersionId}/gene/${geneId}/stats`;
        });
    }
}
export default new GeneStatsResource((_a = process.env.VLR_SERVICE_URI) !== null && _a !== void 0 ? _a : DEFAULT_API_SERVICE_URI);

import { ESearchSuggestionsType } from '../types';
/**
 * Default delay value for suggestion debounced fetch request
 */
export const DEFAULT_SUGGESTIONS_FETCH_DELAY = 300;
/**
 * Key for recent search structure stored in localStorage
 */
export const RECENT_SEARCH_LS_KEY = 'CGRPTL__recent';
/**
 * Maximum number of recent search suggestions to be shown
 */
export const RECENT_SEARCH_LIMIT = 5;
/**
 * Maximum number of search suggestions received from API
 */
export const SEARCH_SUGGESTIONS_LIMIT = 20;
/**
 * Order in which suggestions will be shown by their type
 */
export const SUGGESTIONS_ORDER = [
    ESearchSuggestionsType.Gene,
    ESearchSuggestionsType.Phenotype,
    ESearchSuggestionsType.Variant,
];
/**
 * Suggestion types by group name mapper
 */
export const SUGGESTIONS_BY_CROUP_NAME = {
    genes: ESearchSuggestionsType.Gene,
    phenotypes: ESearchSuggestionsType.Phenotype,
    variants: ESearchSuggestionsType.Variant,
    fullFit: ESearchSuggestionsType.FullMatch,
};
/* eslint-disable no-bitwise */
/**
 * Bitwise suggestions filter mask
 */
export const SUGGESTIONS_FILTER = {
    [ESearchSuggestionsType.Gene]: 1 << 0,
    [ESearchSuggestionsType.Phenotype]: 1 << 1,
    [ESearchSuggestionsType.Variant]: 1 << 2,
};
/**
 * All suggestions filter
 */
export const SUGGESTIONS_FILTER_ALL = Object
    .values(SUGGESTIONS_FILTER)
    .reduce((filterAll, currentFilter) => filterAll | currentFilter, 0);
/* eslint-enable no-bitwise */
export const APP_VERSION_LOCAL_STORAGE_KEY = 'appVersion';
export const APP_VERSION_DEFAULT_VALUE = '';
export const RECENT_SUGGESTIONS_DEFAULT_VALUE = [];

import { ESearchSuggestionsType } from 'Search/types';
/**
 * Checks if dataset provider label should be displayed
 *
 * @param suggestionType - type of suggestion
 * @param datasetProviderName - dataset provider name
 * @returns Boolean value
 */
export const hasDatasetProviderLabel = (suggestionType, datasetProviderName) => {
    const dashboardNavigationEnabled = FEATURE_FLAGS.DASHBOARD_NAVIGATION_ENABLED
        && !FEATURE_FLAGS.PUBLIC_VERSION;
    return dashboardNavigationEnabled
        ? (!!datasetProviderName
            && suggestionType !== ESearchSuggestionsType.Gene
            && suggestionType !== ESearchSuggestionsType.Variant)
        : !!datasetProviderName;
};

import { Buffer } from 'buffer';
/**
 * This converts a string to Base64.
 * Firstly, it converts string to UTF-8 encoded byte array.
 * Secondly, it converts UTF-8 byte array to a Base64 string
 */
export const encodeStringToBase64 = (str = '') => {
    const utf8Buffer = Buffer.from(str, 'utf8');
    const base64String = utf8Buffer.toString('base64');
    return base64String;
};

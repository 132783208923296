import { ECombinedSuggestionsType } from './searchSuggestionEnums';
/**
 * Type guard for a recent suggestions collection
 *
 * @param collection - Suggestions collection to check
 * @returns `true` if the collection is `TRecentSuggestionsCollection`
 */
export function isRecentSuggestionsCollection(collection) {
    return ECombinedSuggestionsType.Recent in collection;
}

import { APIError } from '../APIError';
import { HTTPClientErrorFactory } from './client';
import { HTTPServerErrorFactory } from './server';
export class HTTPErrorFactory {
    static createFromResponse(response) {
        if (response.ok) {
            throw new APIError('Cannot create HTTP error object for a non error response');
        }
        const { status, statusText } = response;
        return HTTPErrorFactory.create(status, statusText);
    }
    static create(status, statusText) {
        if (status >= 400 && status < 500) {
            return HTTPClientErrorFactory.create(status, statusText);
        }
        if (status >= 500) {
            return HTTPServerErrorFactory.create(status, statusText);
        }
        throw new APIError(`Cannot create error object for status ${status}`);
    }
}

import { MANHATTAN_PLOT_LOWER_THRESHOLD, MANHATTAN_PLOT_UPPER_THRESHOLD } from './env';
export const EXPORTED_MANHATTAN_PLOT_UPPER_THRESHOLD_CONFIG = {
    value: MANHATTAN_PLOT_UPPER_THRESHOLD,
    label: 'SIGNIFICANT RESULTS',
};
export const EXPORTED_MANHATTAN_PLOT_LOWER_THRESHOLD_CONFIG = {
    value: MANHATTAN_PLOT_LOWER_THRESHOLD,
    label: 'SUGGESTIVE RESULTS',
};
export const MANHATTAN_PLOT_UPPER_THRESHOLD_CONFIG = {
    value: MANHATTAN_PLOT_UPPER_THRESHOLD,
    description: 'Significant Threshold',
};
export const MANHATTAN_PLOT_LOWER_THRESHOLD_CONFIG = {
    value: MANHATTAN_PLOT_LOWER_THRESHOLD,
    description: 'Suggestive Threshold',
};
export const GPA_MANHATTAN_PLOT_AXIS_CONFIG = {
    x: { label: 'Positions (bp)' },
    y: { label: '-log10 (P-value)' },
};
export const MANHATTAN_PLOT_AXIS_CONFIG = {
    x: { label: 'Phenotypic Categories' },
    y: { label: '-log10 (P-value)' },
};
export const MANHATTAN_PLOT_BINARY_LEGEND_CONFIG = {
    triangleUp: 'Odds ratio > 1',
    triangleDown: 'Odds ratio < 1',
};
export const MANHATTAN_PLOT_CONTINUOUS_LEGEND_CONFIG = {
    triangleUp: 'Effect size > 0',
    triangleDown: 'Effect size < 0',
};
export const FES_PLOT_VIEW_AVAILABILITY_MESSAGES = {
    AVAILABLE_ONLY_FOR_470K: 'Frequency vs Effect Size Plot is only available for 470K (v5) dataset',
    AVAILABLE_ONLY_FOR_450K_470K_500K: 'Frequency vs Effect Size Plot is only available for UK Biobank 450K (v4), 470K (v5) and 500k WGS (v2) datasets',
    DEFAULT: 'Frequency vs Effect Size Plot is not available for this phenotype',
};
export const MANHATTAN_PLOT_SECTION_LABEL = 'Manhattan plot';
export const PLOT_TOOLTIP_MISSING_LEVEL_RESULTS_OR_TRAITS_TYPE_ERROR_MESSAGE = 'Level result or traits type was not provided!';

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VersionSection__list__KRa2w{margin:0}.VersionSection__listContainer__1amj-{margin:.25rem 0 1.5rem}.VersionSection__listContainer__1amj-:last-child{margin-bottom:.5rem}.VersionSection__title__1dO7s{font-weight:700;margin:.5rem 0}", "",{"version":3,"sources":["webpack://./src/views/about/components/VersionSection/VersionSection.css"],"names":[],"mappings":"AAAA,6BACE,QACF,CAEA,sCACE,sBACF,CAEA,iDACE,mBACF,CAEA,8BACE,eAAiB,CACjB,cACF","sourcesContent":[".list {\n  margin: 0;\n}\n\n.listContainer {\n  margin: 0.25rem 0 1.5rem 0;\n}\n\n.listContainer:last-child {\n  margin-bottom: 0.5rem;\n}\n\n.title {\n  font-weight: bold;\n  margin: 0.5rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "VersionSection__list__KRa2w",
	"listContainer": "VersionSection__listContainer__1amj-",
	"title": "VersionSection__title__1dO7s"
};
export default ___CSS_LOADER_EXPORT___;

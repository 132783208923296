import { useEffect } from 'react';
import { v4 as uuid4 } from 'uuid';
import { sendStatistic } from './utils';
/**
 * Sends statistic on component mount or params change
 * @param params URL params
 * @returns void
 */
const useStatistic = (params) => {
    let sessionId = window.sessionStorage.getItem('sessionId') || '';
    if (!sessionId) {
        sessionId = uuid4();
        window.sessionStorage.setItem('sessionId', sessionId);
    }
    useEffect(() => {
        if (!FEATURE_FLAGS.PUBLIC_VERSION && !!process.env.ADMIN_PANEL_URI) {
            sendStatistic({ sessionId });
        }
    }, [sessionId, params]);
};
export default useStatistic;

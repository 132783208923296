/**
 * Description for collapsing models
 */
export const COLLAPSING_MODELS_DESCRIPTION = {
    flexdmg: 'For a variant to be included in collapsing analysis under this model it must be a moderately rare damaging non-synonymous variant of high quality. Non-synonymous variants are defined as any variant that is predicted to alter the sequence of a protein. This includes protein-truncating variants, missense variants, and in-frame insertions and deletions. The variant must also be predicted to be damaging to the protein (REVEL score ≥ 0.25). Additionally, several quality control filters are applied to ensure that only high-quality variant calls qualify. These include filters relating to coverage, mapping quality, genotype quality, and Hardy-Weinberg equilibrium. For a variant to qualify under this model it must also be moderately rare: that is, have a minor allele frequency of 0.001 or less both within the cohort and also within the GnomAD variant database. ',
    flexnonsyn: 'For a variant to be included in collapsing analysis under this model it must be a moderately rare non-synonymous variant of high quality. Non-synonymous variants are defined as any variant that is predicted to alter the sequence of a protein. This includes protein-truncating variants, missense variants, and in-frame insertions and deletions. Additionally, several quality control filters are applied to ensure that only high-quality variant calls qualify. These include filters relating to coverage, mapping quality, genotype quality, and Hardy-Weinberg equilibrium. For a variant to qualify under this model it must also be moderately rare: that is, have a minor allele frequency of 0.001 or less both within the cohort and also within the GnomAD variant database. ',
    flexnonsynmtr: 'For a variant to be included in collapsing analysis under this model it must be a moderately rare, non-synonymous variant of high quality that falls within a region constrained for missense variation. Non-synonymous variants are defined as any variant that is predicted to alter the sequence of a protein. This includes protein-truncating variants, missense variants, and in-frame insertions and deletions. Additionally, several quality control filters are applied to ensure that only high-quality variant calls qualify. These include filters relating to coverage, mapping quality, genotype quality, and Hardy-Weinberg equilibrium. For a variant to qualify under this model it must also be moderately rare: that is, have a minor allele frequency of 0.001 or less both within the cohort and also within the GnomAD variant database.',
    ptv: 'For a variant to be included in collapsing analysis under this model it must be a moderately rare protein-truncating variant (PTV) of high quality. PTVs are defined as any variant that is predicted to truncate the protein. They will usually, although not always, ablate the function of the protein and thus are also sometimes referred to as ‘loss of function’ variants. Additionally, several quality control filters are applied to ensure that only high-quality variant calls qualify. These include filters relating to coverage, mapping quality, genotype quality, and Hardy-Weinberg equilibrium. For a variant to qualify under this model it must also be moderately rare: that is, have a minor allele frequency of 0.001 or less both within the cohort and also within the GnomAD variant database. It is important to consider the position of the PTV in the gene because PTVs at the extreme 3’ ends of genes are less likely to ablate gene function.',
    ptv5pcnt: 'For a variant to be included in collapsing analysis under this model it must be a less common protein-truncating variant (PTV) of high quality. PTVs are defined as any variant that is predicted to truncate the protein. They will usually, although not always, ablate the function of the protein and thus are also sometimes referred to as ‘loss of function’ variants. Additionally, several quality control filters are applied to ensure that only high-quality variant calls qualify. These include filters relating to coverage, mapping quality, genotype quality, and Hardy-Weinberg equilibrium. For a variant to qualify under this model it must also be less common: that is, have a minor allele frequency of 0.05 or less both within the cohort and also within the GnomAD variant database. It is important to consider the position of the PTV in the gene because PTVs at the extreme 3’ ends of genes are less likely to ablate gene function.',
    ptv_all: 'For a variant to be included in collapsing analysis under this model it must be a protein-truncating variant (PTV) of high quality. PTVs are defined as any variant that is predicted to truncate the protein. They will usually, although not always, ablate the function of the protein and thus are also sometimes referred to as ‘loss of function’ variants. Additionally, several quality control filters are applied to ensure that only high-quality variant calls qualify. These include filters relating to coverage, mapping quality, genotype quality, and Hardy-Weinberg equilibrium. No allele frequency filter is applied to this model. Particular care must be taken in the interpretation of the output of this model because it is not restricted by allele frequency and thus is particularly susceptible to confounding factors such as population structure. It is also important to consider the position of the PTV in the gene because PTVs at the extreme 3’ ends of genes are less likely to ablate gene function.',
    ptvraredmg: 'For a variant to be included in collapsing analysis under this model it must be a rare damaging non-synonymous variant of high quality. Please note, in this model different allele frequency filters are applied to protein-truncating variants and non-protein-truncating variants. Non-synonymous variants are defined as any variant that is predicted to alter the sequence of a protein. This includes protein-truncating variants, missense variants, and in-frame insertions and deletions. The variant must also be predicted to be damaging to the protein (REVEL score ≥ 0.25). Additionally, several quality control filters are applied to ensure that only high-quality variant calls qualify. These include filters relating to coverage, mapping quality, genotype quality, and Hardy-Weinberg equilibrium. For a protein-truncating variant to qualify under this model it must also be moderately rare: that is, have a minor allele frequency of 0.001 or less both within the cohort and also within the GnomAD variant database. But for a non-protein-truncating variant to qualify it must be rarer: that is, it must have a minor allele frequency of 0.0005 or less both within the cohort and also within the GnomAD variant database.',
    raredmg: 'For a variant to be included in collapsing analysis under this model it must be a rare damaging missense variant of high quality. The variant must also be predicted to be damaging to the protein (REVEL score ≥ 0.25). Additionally, several quality control filters are applied to ensure that only high-quality variant calls qualify. These include filters relating to coverage, mapping quality, genotype quality, and Hardy-Weinberg equilibrium. For a variant to qualify under this model it must also be rare: that is, have a minor allele frequency of 0.0005 or less both within the cohort and also within the GnomAD variant database.',
    raredmgmtr: 'For a variant to be included in collapsing analysis under this model it must be a rare damaging missense variant of high quality that falls within a region constrained for missense variation. The variant must also be predicted to be damaging to the protein (REVEL score ≥ 0.25) and to fall within a sub-region constrained for missense variation according to the missense tolerance score metric (MTR < 0.78 or MTR_centile < 0.5). Additionally, several quality control filters are applied to ensure that only high-quality variant calls qualify. These include filters relating to coverage, mapping quality, genotype quality, and Hardy-Weinberg equilibrium. For a variant to qualify under this model it must also be rare: that is, have a minor allele frequency of 0.0005 or less both within the cohort and also within the GnomAD variant database.',
    rec: 'For a variant to be included in collapsing analysis under this model it must be a slightly rsare, non-synonymous variant of high quality, and it must either be homozygous, or there must be two or more such qualifying variants in the gene in the individual. Non-synonymous variants are defined as any variant that is predicted to alter the sequence of a protein. This includes protein-truncating variants, missense variants, and in-frame insertions and deletions. Additionally, several quality control filters are applied to ensure that only high-quality variant calls qualify. These include filters relating to coverage, mapping quality, genotype quality, and Hardy-Weinberg equilibrium. For a variant to qualify under this model it must also be slightly rare: that is, have a minor allele frequency of 0.005 or less both within the cohort and also within the GnomAD variant database. Particular care should be taken in the interpretation of this model because it has a higher allele frequency threshold than other models and is therefore more susceptible to confounding factors such as population structure. Additionally, be aware that the two qualifying variants in a gene in an individual may be on the same chromosome (in cis), and thus not in fact recessive.',
    syn: 'For a variant to be included in collapsing analysis under this model it must be a rare synonymous variant of high quality. Synonymous variants are defined as exonic variants that are not predicted to alter the sequence of the protein. These variants are not expected to contribute to phenotype, and therefore this model functions as a negative control. Additionally, several quality control filters are applied to ensure that only high-quality variant calls qualify. These include filters relating to coverage, mapping quality, genotype quality, and Hardy-Weinberg equilibrium. For a variant to qualify under this model it must also be rare: that is, have a minor allele frequency of 0.0005 or less both within the cohort and also within the GnomAD variant database. Please be aware that this model is a negative control and should not be investigated for functional phenotypic associations.',
    UR: 'For a variant to be included in collapsing analysis under this model it must be an ultra-rare non-synonymous variant of high quality. Non-synonymous variants are defined as any variant that is predicted to alter the sequence of a protein. This includes protein-truncating variants, missense variants, and in-frame insertions and deletions. Additionally, several quality control filters are applied to ensure that only high-quality variant calls qualify. These include filters relating to coverage, mapping quality, genotype quality, and Hardy-Weinberg equilibrium. For a variant to qualify under this model it must also be ultra-rare: that is, have a minor allele frequency of 0.00025 or less within the cohort and not be present at all within the GnomAD variant database.',
    URmtr: 'For a variant to be included in collapsing analysis under this model it must be an ultra-rare, non-synonymous variant of high quality that falls within a region constrained for missense variation. Non-synonymous variants are defined as any variant that is predicted to alter the sequence of a protein. This includes protein-truncating variants, missense variants, and in-frame insertions and deletions. Additionally, several quality control filters are applied to ensure that only high-quality variant calls qualify. These include filters relating to coverage, mapping quality, genotype quality, and Hardy-Weinberg equilibrium. For a variant to qualify under this model it must also be ultra-rare: that is, have a minor allele frequency of 0.00025 or less within the cohort and not be present at all within the GnomAD variant database. Finally, missense variants must also fall within a sub-region constrained for missense variation according to the missense tolerance score metric (MTR < 0.78 or MTR_centile < 0.5).',
};
export const FILTERS_LAYOUT_WIDTH = 1278; //  2px for border dropdown
export const SELECTED_FILTERS_TAGS_MAX_WIDTH = 1047;
export const COLLAPSING_MODELS_FILTER_MAX_WIDTH = 1032;
export const DEFAULT_GROUP = {
    id: Symbol('DEFAULT_GROUP_ID'),
    name: 'DEFAULT_GROUP_NAME',
};
export const NO_SELECTED_MODEL_FOUND_WARNING = 'Transferred is an unidentified model. The empty model will be returned.';

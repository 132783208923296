import { EDatasetsNames, FES_PLOT_VIEW_AVAILABILITY_MESSAGES } from 'Common/consts';
export const getFesPlotAvailabilityMessage = (isDatasetAvailable) => {
    if (isDatasetAvailable)
        return FES_PLOT_VIEW_AVAILABILITY_MESSAGES.DEFAULT;
    if (FEATURE_FLAGS.PUBLIC_VERSION
        && ALLOWED_DATASETS.FES_VIEW_AVAILABLE_DATASETS.includes(EDatasetsNames.PUBLIC_450K)) {
        return FES_PLOT_VIEW_AVAILABILITY_MESSAGES.AVAILABLE_ONLY_FOR_450K_470K_500K;
    }
    return FES_PLOT_VIEW_AVAILABILITY_MESSAGES.AVAILABLE_ONLY_FOR_470K;
};

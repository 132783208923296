import { jsx as _jsx } from "react/jsx-runtime";
import AboutPagePublic from './AboutView.public';
import AboutPageInternal from './AboutView.internal';
const AboutView = () => {
    if (FEATURE_FLAGS.PUBLIC_VERSION) {
        return _jsx(AboutPagePublic, {}, void 0);
    }
    return (_jsx(AboutPageInternal, {}, void 0));
};
export default AboutView;

import { createSelector } from '@reduxjs/toolkit';
/**
 * Avoid using TRootState due to dependency cycles
 */
export const getState = (rootState) => rootState.variantView;
export const getTraitsType = createSelector([getState], ({ traitsType }) => traitsType);
export const getLoadingStatus = createSelector([getState], ({ loadingStatus }) => loadingStatus);
export const getLoadingMessage = createSelector([getState], ({ loadingMessage }) => loadingMessage);
export const getErrorType = createSelector([getState], ({ errorType }) => errorType);
export const getCollapsingModels = createSelector([getState], ({ collapsingModels }) => Object.values(collapsingModels));
export const getCollapsingModelIds = createSelector([getState], ({ collapsingModelIds }) => collapsingModelIds);
export const getIsEmpty = createSelector([getState], ({ collapsingModels }) => Object.keys(collapsingModels).length === 0);
export const getSignificantIndicators = createSelector([getState], ({ significantIndicators }) => significantIndicators);
export const getDataExists = createSelector(getState, ({ dataExists }) => dataExists);
export const getVariantId = createSelector(getState, ({ variantId }) => variantId);

import { useState, useCallback, useMemo } from 'react';
import { ESelectedSuggestionsType, } from 'Search/types';
/**
 * Hook implements selector between multiple suggestions collections
 *
 * @param recentSuggestions - Recent suggestions collection
 * @param searchSuggestions - Search suggestions collection
 * @returns Public API for suggestions selector hook
 *  - Selected suggestions collection
 *  - Method which shows `Recent` suggestions
 *  - Method which shows `Search` suggestions
 *  - Method which hides any suggestions
 *  - Attribute which shows if any suggestions are visible
 *  - Attribute which shows if `Recent` suggestions are visible
 *  - Attribute which shows if `Search` suggestions are visible
 */
export function useSuggestionsSelector(recentSuggestions, searchSuggestions) {
    const [type, setType] = useState(ESelectedSuggestionsType.Null);
    const suggestions = useMemo(() => {
        switch (type) {
            case ESelectedSuggestionsType.Recent:
                return recentSuggestions;
            case ESelectedSuggestionsType.Search:
                return searchSuggestions;
            default:
                return null;
        }
    }, [
        type,
        recentSuggestions,
        searchSuggestions,
    ]);
    /**
     * Shows `Search` suggestions
     */
    const showSearchSuggestions = useCallback(() => {
        if (type !== ESelectedSuggestionsType.Search) {
            setType(ESelectedSuggestionsType.Search);
        }
    }, [type]);
    /**
     * Shows `Recent` suggestions
     */
    const showRecentSuggestions = useCallback(() => {
        if (type !== ESelectedSuggestionsType.Recent) {
            setType(ESelectedSuggestionsType.Recent);
        }
    }, [type]);
    /**
     * Hides any suggestions
     */
    const hideSuggestions = useCallback(() => {
        if (type !== ESelectedSuggestionsType.Null) {
            setType(ESelectedSuggestionsType.Null);
        }
    }, [type]);
    const areSuggestionsVisible = useMemo(() => type !== ESelectedSuggestionsType.Null, [type]);
    const areRecentSuggestionsVisible = useMemo(() => type === ESelectedSuggestionsType.Recent, [
        type,
    ]);
    const areSearchSuggestionsVisible = useMemo(() => type === ESelectedSuggestionsType.Search, [
        type,
    ]);
    return {
        suggestions,
        showSearchSuggestions,
        showRecentSuggestions,
        hideSuggestions,
        areSuggestionsVisible,
        areRecentSuggestionsVisible,
        areSearchSuggestionsVisible,
    };
}

import { emptyResponse } from 'GeneView/store/consts';
/**
 * Get non empty gene associations
 * @param geneResponse object with gene data
 * @returns an object with key: collapsingModel and value: geneAssociations
 */
export const getNonEmptyAssociations = (geneData) => {
    const { associations } = geneData !== null && geneData !== void 0 ? geneData : emptyResponse;
    return Object.keys(associations).reduce((acc, curr) => {
        if (associations[curr].length) {
            acc[curr] = associations[curr];
        }
        return acc;
    }, {});
};

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ACKNOWLEDGEMENTS_FIRST_PARAGRAPH_INTERNAL, ACKNOWLEDGEMENTS_FIRST_PARAGRAPH_PUBLIC, ACKNOWLEDGEMENTS_SECOND_PARAGRAPH, ACKNOWLEDGEMENTS_SECTION_TITLE, } from '../../consts';
import { ParagraphWithMargin } from '../../containers';
import Title from '../Title';
const AcknowledgementsSection = ({ isPublic = false, }) => {
    const firstParagraph = isPublic
        ? ACKNOWLEDGEMENTS_FIRST_PARAGRAPH_PUBLIC
        : ACKNOWLEDGEMENTS_FIRST_PARAGRAPH_INTERNAL;
    return (_jsxs(_Fragment, { children: [_jsx(Title, { label: ACKNOWLEDGEMENTS_SECTION_TITLE }, void 0), _jsx(ParagraphWithMargin, { children: firstParagraph }, void 0), _jsx(ParagraphWithMargin, { children: ACKNOWLEDGEMENTS_SECOND_PARAGRAPH }, void 0)] }, void 0));
};
export default AcknowledgementsSection;

import { DatasetsService } from 'Common/services';
/**
 * Return full dataset label
 *
 * @param providerName the name of the dataset provider
 * @param datasetName the name of the dataset
 * @returns full dataset label
 */
export const getDatasetFullLabel = (providerName, datasetName) => {
    const isDatasetNameStartsWithProviderName = datasetName.startsWith(DatasetsService.getProviderName(providerName));
    const datasetRowName = isDatasetNameStartsWithProviderName
        ? `${datasetName}`
        : `${DatasetsService.getProviderName(providerName)} ${datasetName}`;
    return datasetRowName;
};

import { v4 as uuidv4 } from 'uuid';
import { ETraitsTypes } from 'Shared/types';
import { VariantService } from 'Common/services/Variant';
import { PhenotypeService } from 'Common/services/Phenotype';
import { GeneService } from 'Common/services/Gene';
import { PhenotypicCategoryService } from 'Common/services/PhenotypicCategory';
/**
 * Class service with static methods for work with PhenotypeAssociationVLR
 */
export class PhenotypeAssociationVLRService {
    /**
     * Creates a base properties for VLR phenotypeAssociations
     *
     * @static
     * @param association - response data from API
     * @param phenotypeData - phenotypeData response from API
     * @param collapsingModel - CollapsingModel object
     * @param id - custom id for phenotype object (uuid v4 is used by default)
     * @returns base properties object
     */
    static createCommonProperties(association, phenotypeData, collapsingModel, id = uuidv4()) {
        const pvalue = parseFloat(association.pvalue);
        return {
            id,
            pvalue,
            pvalueLog: -Math.log10(pvalue),
            collapsingModel,
            gene: GeneService.create(association.gene_name, association.gene_id),
            phenotypicCategory: PhenotypicCategoryService.create(phenotypeData.category_name, phenotypeData.category_short_name, phenotypeData.category_id),
            variant: VariantService.create(association.variant_id, association.variant_name, association.variant_type),
            phenotype: PhenotypeService.create(phenotypeData.phenotype_name, phenotypeData.phenotype_id),
            ancestry: association.ancestry,
        };
    }
    /**
     * Creates a PhenotypeBinaryAssociationVLR object
     *
     * @static
     * @param association - binary response data from API
     * @param phenotypeData - phenotypeData response from API
     * @param collapsingModel - collapsingModel for phenotype association
     * @param id - custom id for phenotype object (uuid v4 is used by default)
     * @returns PhenotypeBinaryAssociationVLR object
     */
    static createBinaryAssociation(association, phenotypeData, collapsingModel, id) {
        const { stats } = association;
        return Object.assign(Object.assign({}, PhenotypeAssociationVLRService.createCommonProperties(association, phenotypeData, collapsingModel, id)), { traitsType: ETraitsTypes.Binary, cases: stats.no_cases, AACases: stats.no_aa_cases, ABCases: stats.no_ab_cases, BBCases: stats.no_bb_cases, caseMAF: stats.case_maf, caseAAF: stats.case_aaf, ABOrBBCasesPercentage: stats.ab_or_bb_cases, BBCasesPercentage: stats.bb_cases, controls: stats.no_controls, AAControls: stats.no_aa_controls, ABControls: stats.no_ab_controls, BBControls: stats.no_bb_controls, controlMaf: stats.control_maf, controlAaf: stats.control_aaf, ABOrBBControlsPercentage: stats.ab_or_bb_controls, BBControlsPercentage: stats.bb_controls, oddsRatio: stats.odds_ratio, oddsRatioLCI: stats.odds_ratio_lci, oddsRatioUCI: stats.odds_ratio_uci, transcriptId: stats.transcript_id, CDNAChange: stats.cdna_change, AAChange: stats.aa_change, exonRank: stats.exon_rank, consequenceType: stats.consequence_type });
    }
    /**
     * Creates a PhenotypeContinuousAssociationVLR object
     *
     * @static
     * @param association - continuous response data from API
     * @param phenotypeData - phenotypeData response from API
     * @param collapsingModel - collapsingModel for phenotype association
     * @param id - custom id for phenotype object (uuid v4 is used by default)
     * @returns PhenotypeContinuousAssociationVLR object
     */
    static createContinuousAssociation(association, phenotypeData, collapsingModel, id) {
        const { stats } = association;
        return Object.assign(Object.assign({}, PhenotypeAssociationVLRService.createCommonProperties(association, phenotypeData, collapsingModel, id)), { traitsType: ETraitsTypes.Continuous, noSamples: stats.no_samples, noAAGenotypes: stats.no_aa_genotypes, noABGenotypes: stats.no_ab_genotypes, noBBGenotypes: stats.no_bb_genotypes, MAF: stats.maf, AAF: stats.aaf, ABOrBBGenotypes: stats.ab_or_bb_genotypes, BBGenotypes: stats.bb_genotypes, effectSize: stats.effect_size, effectSizeStandardError: stats.effect_size_standard_error, effectSizeLCI: stats.effect_size_lci, effectSizeUCI: stats.effect_size_uci, transcriptId: stats.transcript_id, CDNAChange: stats.cdna_change, AAChange: stats.aa_change, exonRank: stats.exon_rank, consequenceType: stats.consequence_type });
    }
}

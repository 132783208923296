import { ETraitsTypes } from 'Shared/types';
import { geneIdMock, geneNameMock, datasetVersionIdMock, phenotypeNameMock, collapsingModelIdMock, collapsingModelNameMock, phenotypeIdMock, phenotypicCategoryIdMock, phenotypicCategoryNameMock, phenotypicCategoryShortNameMock, variantIdMock, variantNameMock, variantTypeMock, ancestryMock, } from 'Common/mocks';
import { createMockedURL, getMock } from 'API/utils';
import resource from './resource';
export const emptyQVResponseMock = {
    ancestry: ancestryMock,
    gene_id: geneIdMock,
    gene_name: geneNameMock,
    phenotype_id: phenotypeIdMock,
    phenotype_name: phenotypeNameMock,
    category_id: phenotypicCategoryIdMock,
    category_name: phenotypicCategoryNameMock,
    category_short_name: phenotypicCategoryShortNameMock,
    collapsing_model_id: collapsingModelIdMock,
    collapsing_model_name: collapsingModelNameMock,
    traits_type: ETraitsTypes.Binary,
    pvalue: '0.1',
    variants: [],
};
export const baseQVResponseMock = {
    variant_id: variantIdMock,
    variant_name: variantNameMock,
    variant_type: variantTypeMock,
    consequence_type: 'stop_gained',
    transcript_id: 'ENST00000305877',
    cdna_change: 'c.34A>T',
    aa_change: 'p.Lys12*',
    exon_rank: '1/23',
    missing_genotype: 100000,
};
export const binaryQVResponseMock = Object.assign(Object.assign({}, emptyQVResponseMock), { variants: [
        Object.assign(Object.assign({}, baseQVResponseMock), { homo_genotype_major_cases: 1, hetero_genotype_cases: 4, homo_genotype_minor_cases: 2, minor_allele_cases: 2, homo_genotype_major_controls: 1, hetero_genotype_controls: 1, homo_genotype_minor_controls: 7, minor_allele_controls: 1 }),
        {
            variant_id: 'a153cbe1-84ac-4e3b-8b84-c968261caf8f',
            variant_name: '19-44908822-C-T',
            variant_type: 'SNV',
            consequence_type: 'conservative_inframe_deletion',
            transcript_id: 'ENST00000305877',
            cdna_change: 'c.34A>T',
            aa_change: 'p.Lys32*',
            exon_rank: '1/23',
            missing_genotype: 6,
            homo_genotype_major_cases: 1,
            hetero_genotype_cases: 6,
            homo_genotype_minor_cases: 5,
            minor_allele_cases: 2,
            homo_genotype_major_controls: 1,
            hetero_genotype_controls: 1,
            homo_genotype_minor_controls: 3,
            minor_allele_controls: 1,
        },
        {
            variant_id: '5e97c6e3-58e4-4821-9693-c6b6cc62b09e',
            variant_name: '19-44892887-C-T',
            variant_type: 'SNV',
            consequence_type: 'synonymous_variant',
            transcript_id: 'ENST00000305877',
            cdna_change: 'c.34A>T',
            aa_change: 'p.Lys52*',
            exon_rank: '1/23',
            missing_genotype: 3,
            homo_genotype_major_cases: 4,
            hetero_genotype_cases: 2,
            homo_genotype_minor_cases: 1,
            minor_allele_cases: 2,
            homo_genotype_major_controls: 2,
            hetero_genotype_controls: 1,
            homo_genotype_minor_controls: 1,
            minor_allele_controls: 1,
        },
        {
            variant_id: '19fa3a33-2304-4602-9d99-43c38836b75e',
            variant_name: '19-44812188-G-A',
            variant_type: 'SNV',
            consequence_type: 'stop_gained',
            transcript_id: 'ENST00000305877',
            cdna_change: 'c.34A>T',
            aa_change: 'p.Lys72*',
            exon_rank: '1/23',
            missing_genotype: 2,
            homo_genotype_major_cases: 4,
            hetero_genotype_cases: 6,
            homo_genotype_minor_cases: 2,
            minor_allele_cases: 2,
            homo_genotype_major_controls: 1,
            hetero_genotype_controls: 1,
            homo_genotype_minor_controls: 2,
            minor_allele_controls: 1,
        },
    ] });
export const continuousQVResponseMock = Object.assign(Object.assign({}, emptyQVResponseMock), { traits_type: ETraitsTypes.Continuous, variants: [
        Object.assign(Object.assign({}, baseQVResponseMock), { homo_genotype_major: 1, hetero_genotype: 4, homo_genotype_minor: 2, minor_allele: 2 }),
        {
            variant_id: 'b2e67987-82fa-49cc-ab3f-067ba0030853',
            variant_name: '19-44813331-G-A',
            variant_type: 'SNV',
            consequence_type: 'stop_gained',
            transcript_id: 'ENST00000305877',
            cdna_change: 'c.34A>T',
            aa_change: 'p.Lys32*',
            exon_rank: '1/23',
            missing_genotype: 1,
            homo_genotype_major: 1,
            hetero_genotype: 5,
            homo_genotype_minor: 2,
            minor_allele: 2,
        },
    ] });
export const binaryQVariantResponseMock = binaryQVResponseMock.variants[0];
export const continuousQVarinatResponseMock = continuousQVResponseMock.variants[0];
// Fetch mocks
export const requestParams = {
    datasetVersionId: datasetVersionIdMock,
};
const mockedURL = {
    [createMockedURL(resource, requestParams)]: {
        full: binaryQVResponseMock,
        empty: emptyQVResponseMock,
    },
};
export const fixtures = getMock(mockedURL);

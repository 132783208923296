export class ActionError extends Error {
    constructor(type = 3 /* Unknown */) {
        super(`Action error (type = ${type})`);
        this.type = type;
        Object.setPrototypeOf(this, new.target.prototype);
    }
    static createSerialized(type = 3 /* Unknown */) {
        return {
            type,
        };
    }
    toSerialized() {
        return {
            type: this.type,
        };
    }
}

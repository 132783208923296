import { EProvider } from 'Common/consts';
import { getMock } from 'API/utils';
import resource from './resource';
export const DatasetVersionIds = {
    ukb450: 'b142d575-23ae-4adb-934b-1eedf3894f8e',
    ukb300: 'ce2e9026-257f-48aa-9d67-5d35c70ea347',
    finngenR8: 'd12c7ff0-3f24-444a-bf77-5f36507216c5',
};
export const ListResponseMock = {
    data: {
        dataset_catalogs: [
            {
                provider: EProvider.UKB,
                catalogs: [
                    {
                        dataset_id: '0c13a145-4a35-4c3b-b4b8-c9b07b0532f6',
                        dataset_name: 'CGR INTERNAL PHEWAS',
                        version_id: DatasetVersionIds.ukb450,
                        version_name: '450k Public',
                        provider: EProvider.UKB,
                        metadata: [
                            'Phewas CGR_PHESANT v1.0.2',
                            'UKB Phenotypic Release: v23',
                            'Other extra statistics: 10000',
                        ],
                    },
                    {
                        dataset_id: '06db5df1-4226-4d5a-bf79-0d847a9a90c1',
                        dataset_name: 'CGR INTERNAL PHEWAS',
                        version_id: DatasetVersionIds.ukb300,
                        version_name: '300k Public',
                        provider: EProvider.UKB,
                        metadata: [
                            'Build: PEACOK v1.07',
                            'UKB Phenotypic Release: v27390 plus hospital episode statistics',
                            'UKB Exome Count: 269,171',
                        ],
                    },
                ],
            },
            {
                provider: EProvider.FinnGen,
                catalogs: [
                    {
                        dataset_id: '1074bb02-150a-492f-a4ac-30a1e5e062bb',
                        dataset_name: 'FinnGen R8',
                        version_id: DatasetVersionIds.finngenR8,
                        version_name: 'R8',
                        provider: EProvider.FinnGen,
                        metadata: [
                            'FinnGen R8 GWAS',
                            'FinnGen phenotypes analysed: 2,202',
                            'FinnGen Sample Count: 342,499',
                        ],
                    },
                ],
            },
        ],
    },
};
export const requestParams = {};
const mockedURL = {
    [resource.URLFactory(requestParams)]: {
        full: ListResponseMock,
        empty: {},
    },
};
export const fixtures = getMock(mockedURL);

import { DEFAULT_API_SERVICE_URI } from 'API/consts';
import { RESTResource } from '../RESTResource';
export const competitiveIntelligence = new RESTResource(({ geneId }) => {
    var _a;
    const urlRoot = (_a = process.env.GENES_SERVICE_URI) !== null && _a !== void 0 ? _a : DEFAULT_API_SERVICE_URI;
    return `${urlRoot}/v1/genes/${geneId}/competitive_intelligence/`;
});
export default {
    competitiveIntelligence,
};

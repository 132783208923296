import { createMockedURL, getMock } from 'API/utils';
import geneToleranceResource from './resource';
export const geneToleranceResponseMock = {
    chromosome: '6',
    ensembl_gene_id: 'ENSG00000012048',
    protein_coding_bases: '1708',
    coverage_exacv2: '0.9105',
    rvis_exacv2: '0.2004',
    mtr_genic_tile_exac: '0.2294',
    pli_exac: '0.91',
    lof_fdr_exac: '0.0016',
    prec_exac: '0.08',
    pcgerp_tile: '0.0525',
};
export const geneToleranceEmptyMock = {
    chromosome: '',
    protein_coding_bases: '',
    coverage_exacv2: '',
    rvis_exacv2: '',
    mtr_genic_tile_exac: '',
    pli_exac: '',
    lof_fdr_exac: '',
    prec_exac: '',
    pcgerp_tile: '',
    ensembl_gene_id: '',
};
export const requestParams = {};
const mockedURL = {
    [createMockedURL(geneToleranceResource, requestParams)]: {
        full: geneToleranceResponseMock,
        empty: geneToleranceEmptyMock,
    },
};
export const fixtures = getMock(mockedURL);

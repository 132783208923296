var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, memo, useRef, useEffect, useContext, useState, } from 'react';
import classnames from 'classnames';
import { HighlightedText } from 'Shared/components/HighlightedText';
import { escapeRegExp } from 'Shared/utils';
import { Context } from '../SearchBox/SearchBox';
import SearchSuggestion from '../SearchSuggestion/SearchSuggestion';
import { MIN_LENGTH_SEARCH_PATTERN } from './consts';
import styles from './SuggestionWithHighlights.css';
const SuggestionWithHighlights = (props) => {
    const { searchPattern } = props, otherProps = __rest(props, ["searchPattern"]);
    const targetRef = useRef(null);
    const [isIntersected, setIsIntersected] = useState(false);
    const { unsubscribe, subscribe, } = useContext(Context);
    useEffect(() => {
        const target = targetRef.current;
        if (!target) {
            return () => { };
        }
        subscribe(target, setIsIntersected);
        return () => {
            unsubscribe(target);
        };
    }, [
        subscribe,
        unsubscribe,
        targetRef,
    ]);
    const getPhenotypeName = useCallback((suggestion) => {
        const { name } = suggestion;
        if (!searchPattern || searchPattern.length < MIN_LENGTH_SEARCH_PATTERN) {
            return [name];
        }
        const [phenotypeID, ...otherText] = name.split(' ');
        const phenotypeName = otherText.join(' ');
        const regexp = new RegExp(`(?!<=\\s)(\\w*)(${escapeRegExp(searchPattern)}).*$`, 'gim');
        const matchPhenotypeNameResult = regexp.exec(phenotypeName);
        const matchPhenotypeIDResult = regexp.exec(phenotypeID);
        if (name.includes(searchPattern)) {
            return [_jsx(HighlightedText, { text: name, pattern: searchPattern }, name)];
        }
        if (!matchPhenotypeNameResult && !matchPhenotypeIDResult) {
            return [name];
        }
        if (!isIntersected) {
            const highlightedLabel = _jsx(HighlightedText, { text: name, pattern: searchPattern }, void 0);
            return [highlightedLabel];
        }
        const highlightedPhenotypeID = matchPhenotypeIDResult
            ? _jsx(HighlightedText, { text: phenotypeID, pattern: searchPattern }, void 0)
            : phenotypeID;
        if (matchPhenotypeNameResult) {
            const [matchInputValue] = matchPhenotypeNameResult;
            const highlightedPart = _jsx(HighlightedText, { text: matchInputValue, pattern: searchPattern }, void 0);
            return [
                highlightedPhenotypeID,
                matchPhenotypeNameResult.index ? '...' : ' ',
                highlightedPart,
            ];
        }
        return [highlightedPhenotypeID, ' ', phenotypeName];
    }, [isIntersected, searchPattern]);
    const phenotypeClassName = classnames(otherProps.className, {
        [styles.ellipsis]: isIntersected,
    });
    return (_jsx(SearchSuggestion, Object.assign({ ref: targetRef }, otherProps, { renderValue: getPhenotypeName, className: phenotypeClassName }), void 0));
};
export default memo(SuggestionWithHighlights);

import { jsx as _jsx } from "react/jsx-runtime";
import { v4 as uuidv4 } from 'uuid';
import { ExternalLink } from 'Shared/components';
import { MANTIS_ML_URL, PROTEOGENOMICS_PORTAL_URL } from 'Common/consts';
export const VERSION_SECTION_TITLE = 'Version';
const KEY_PREFIX_INTERNAL = 'about-view-internal_version';
const KEY_PREFIX_PUBLIC = 'about-view-public_version';
export const VERSIONS_INTERNAL = [
    {
        key: `${KEY_PREFIX_INTERNAL}_1.6.4_${uuidv4()}`,
        title: 'Software version 1.6.4: updated 26 January 2023',
        description: [
            'Updated p-value thresholds, variant dashboard, phenotype catalogue, links to Columbus and improvements to functionality.',
        ],
    },
    {
        key: `${KEY_PREFIX_INTERNAL}_17_${uuidv4()}`,
        title: 'Software 17, Data Internal 5.0: updated 04 September 2023',
        description: [
            'Updated UK Biobank to 470k (v5) release and FinnGen to R10',
            'Effect allele is now the alternative allele.',
            'Added Manhattan plot to variant dashboard, MTR plot to gene dashboard and minor bug fixes',
        ],
    },
    {
        key: `${KEY_PREFIX_INTERNAL}_17.1_${uuidv4()}`,
        title: 'Software 17.1, Data Internal 5.0: updated 19 December 2023',
        description: [
            'Added ability to filter variants by consequence type on variant-level views',
        ],
    },
    {
        key: `${KEY_PREFIX_INTERNAL}_18_${uuidv4()}`,
        title: 'Software 18, Data Internal 5.1: updated 21 February 2024',
        description: [
            'Added new section to show clinically relevant variants (OMIM, HGMD and ClinVar) and publicly available common variant associations (NHGRI-EBI GWAS Catalog and Global Biobank Meta-analysis Initiative) to the gene dashboard.',
        ],
    },
    {
        key: `${KEY_PREFIX_INTERNAL}_19_${uuidv4()}`,
        title: 'Software 19, Data Internal 5.2: updated 09 April 2024',
        description: [
            'Updated FinnGen to R12 with results for continuous phenotypes height, weight, BMI and proteins measured using Olink and SomaScan (release 2023_10_11).',
            'Frequency vs Effect Size (FES) plots were updated to prioritise gene-level results with p-value ≤ 1e-4.',
            'Deprecated a small number of duplicated or inverted phenotypes.',
        ],
    },
    {
        key: `${KEY_PREFIX_INTERNAL}_20_${uuidv4()}`,
        title: 'Software 20, Data Internal 5.3: updated 02 May 2024',
        description: [
            'Added filter for direction of effect.',
            'Added new telomere length phenotype to UK Biobank 470k (v5) results.',
            'Improvements to the gene dashboard.',
        ],
    },
    {
        key: `${KEY_PREFIX_INTERNAL}_20.1_${uuidv4()}`,
        title: 'Software 20.1, Data Internal 5.4: updated 27 June 2024',
        description: [
            'Added link to a competitive intelligence tool with high level summary information and a link to the CGR Proteomics Atlas to the AZ resources section.',
            'Updated clinically relevant variant OMIM data on the gene dashboard to version Q2 2024.',
            'Updated the MTR scores used in the MTR plots on the gene dashboard and contributing variant pages to Version 2.',
        ],
    },
    {
        key: `${KEY_PREFIX_INTERNAL}_20.2_${uuidv4()}`,
        title: 'Software 20.2, Data Internal 5.5: updated 30 July 2024',
        description: [
            [
                'Added link to ',
                _jsx(ExternalLink, Object.assign({ to: MANTIS_ML_URL, title: MANTIS_ML_URL, isTextFieldLink: true, target: "_blank" }, { children: "Mantis-ML 2.0" }), `${KEY_PREFIX_INTERNAL}_mantis-ml-link_${uuidv4()}`),
                ' to the AZ resources section on the gene dashboard.',
            ],
            [
                'Added link to the ',
                _jsx(ExternalLink, Object.assign({ to: PROTEOGENOMICS_PORTAL_URL, title: PROTEOGENOMICS_PORTAL_URL, isTextFieldLink: true, target: "_blank" }, { children: "Proteogenomics Portal" }), `${KEY_PREFIX_INTERNAL}_proteogenomics-portal-link_${uuidv4()}`),
                ' to the AZ resources section on the gene dashboard.',
            ],
        ],
    },
    {
        key: `${KEY_PREFIX_INTERNAL}_21_${uuidv4()}`,
        title: 'Software 21, Data Internal 5.6: updated 18 September 2024',
        description: [
            'Improved functionality of global filters on gene- and variant-level results pages: toggle to select the "Most significant model" will select the most significant gene-phenotype/variant-phenotype combination from available data based on all other selected global filters.',
            'Moved annotation of MTR-percentiles on the MTR plots to improve visibility.',
            'Added functionality to export links to results pages using the phenotype and variant names.',
            'Updated Frequency vs Effect Size (FES) plots to display available results when either gene- or variant-level results are unavailable.',
            'Updated gene dashboard to only display links to the CGR Proteomics Atlas when data is available.',
            'Deprecated the phenotype "union#L209#Atopic dermatitis unspecified".',
            'Bug fix to display all results for genes with lowercase letters.',
        ],
    },
];
export const VERSIONS_PUBLIC = [
    {
        key: `${KEY_PREFIX_PUBLIC}_30_${uuidv4()}`,
        title: 'Software 30, Data Public 6.0: updated 05 November 2024',
        description: [
            'Added multi-ancestry results from the UK Biobank whole genome sequencing 500k (v2) release.',
            'Added gene-level non-coding UTR results from the UK Biobank 460k WGS dataset.',
        ],
    },
    {
        key: `${KEY_PREFIX_PUBLIC}_29_${uuidv4()}`,
        title: 'Software 29, Data Public 5.5: updated 18 September 2024',
        description: [
            'Improved functionality of global filters on gene-, variant-, and phenotype-level results pages: toggle to select the "Most significant model" will select the most significant gene-phenotype/variant-phenotype combination from available data based on all other selected global filters.',
            'Updated the MTR scores used in the MTR plots on the gene dashboard and contributing variant pages to Version 2 and moved annotation of MTR-percentiles to improve visibility.',
            'Added functionality to export links to results pages using the phenotype and variant names.',
            'Updated Frequency vs Effect Size (FES) plots to display available results when either gene- or variant-level results are unavailable.',
            'Deprecated the phenotype "union#L209#Atopic dermatitis unspecified".',
            'Bug fix to display all results for genes with lowercase letters.',
        ],
    },
    {
        key: `${KEY_PREFIX_PUBLIC}_28_${uuidv4()}`,
        title: 'Software 28, Data Public 5.4: updated 23 May 2024',
        description: [
            'Added filter for direction of effect.',
            'Added new telomere length phenotype to UK Biobank 470k (v5) results.',
        ],
    },
    {
        key: `${KEY_PREFIX_PUBLIC}_27.2_${uuidv4()}`,
        title: 'Software 27.2, Data Public 5.3: updated 09 April 2024',
        description: [
            'Phenotype Frequency vs Effect Size (FES) plots were updated to prioritise gene-level results with p-value ≤ 1e-4.',
            'Deprecated a small number of duplicated or inverted phenotypes.',
        ],
    },
    {
        key: `${KEY_PREFIX_PUBLIC}_27_${uuidv4()}`,
        title: 'Software 27, Data Public 5.3: 07 December 2023',
        description: [
            'Added gene-level results from UK Biobank 460k WGS and WES datasets',
        ],
    },
    {
        key: `${KEY_PREFIX_PUBLIC}_26_${uuidv4()}`,
        title: 'Software 26, Data Public 5.1: 03 October 2023',
        description: [
            'Added results from UK Biobank 470k (v5) release including ~3k plasma proteins. Effect allele is now the alternative allele.',
        ],
    },
    {
        key: `${KEY_PREFIX_PUBLIC}_2.5_${uuidv4()}`,
        title: '2.5: updated 13 December 2022',
        description: [
            'Temporarily removed all variants from the UK Biobank 300k (v3) & 450k (v4) variant-level results for which the alternative allele is not the minor allele.',
        ],
    },
];

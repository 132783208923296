export var EGeneToleranceMetric;
(function (EGeneToleranceMetric) {
    EGeneToleranceMetric["Chromosome"] = "chromosome";
    EGeneToleranceMetric["ProteinCodingBases"] = "proteinCodingBases";
    EGeneToleranceMetric["ProteinCodingSeqCovered"] = "proteinCodingSeqCovered";
    EGeneToleranceMetric["RVIS"] = "RVIS";
    EGeneToleranceMetric["MTR"] = "MTR";
    EGeneToleranceMetric["PLI"] = "pLI";
    EGeneToleranceMetric["LoFDepletionFDR"] = "LoFDepletionFDR";
    EGeneToleranceMetric["PREC"] = "pREC";
    EGeneToleranceMetric["PCGERP"] = "pcGERP";
})(EGeneToleranceMetric || (EGeneToleranceMetric = {}));

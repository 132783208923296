import { ELevelResult, } from 'Shared/types';
import { DEFAULT_API_SERVICE_URI } from 'API/consts';
import { RESTResource } from '../RESTResource';
/* eslint-disable @typescript-eslint/indent */
const searchParams = {
    collapsingModelId: 'collapsing_model_ids',
    traitsType: 'traits_type',
    minPValue: 'min_pvalue',
    maxPValue: 'max_pvalue',
};
export const phenotypeResourceGLR = new RESTResource(({ datasetVersionId, phenotypeId }) => {
    var _a;
    const urlRoot = (_a = process.env.GLR_SERVICE_URI) !== null && _a !== void 0 ? _a : DEFAULT_API_SERVICE_URI;
    return `${urlRoot}/v1/${datasetVersionId}/phenotype/${phenotypeId}/associations`;
}, 'get', searchParams);
export const phenotypeResourceVLR = new RESTResource(({ datasetVersionId, phenotypeId }) => {
    var _a;
    const urlRoot = (_a = process.env.VLR_SERVICE_URI) !== null && _a !== void 0 ? _a : DEFAULT_API_SERVICE_URI;
    return `${urlRoot}/v1/${datasetVersionId}/phenotype/${phenotypeId}/associations`;
}, 'get', searchParams);
// TODO check it when backend will be ready
export const phenotypeResourcePLR = new RESTResource(({ datasetVersionId, phenotypeId }) => {
    var _a;
    const urlRoot = (_a = process.env.PLR_SERVICE_URI) !== null && _a !== void 0 ? _a : DEFAULT_API_SERVICE_URI;
    return `${urlRoot}/v1/${datasetVersionId}/phenotype/${phenotypeId}/associations`;
}, 'get', searchParams);
export default (levelResult) => {
    switch (levelResult) {
        case ELevelResult.Gene:
            return phenotypeResourceGLR;
        case ELevelResult.Variant:
            return phenotypeResourceVLR;
        case ELevelResult.Phenotype:
            return phenotypeResourcePLR;
        default:
            throw new Error(`Unknown level result type provided for fetching phenotype data: ${levelResult}`);
    }
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { logger, ELogEnvironment } from 'Common/utils/logger/logger';
import { Datasets } from 'API/resources';
import { HTTPClientError, HTTPNotFoundError } from 'API/errors';
import { ActionError } from 'Store/errors/ActionError';
import { getState as getDatasetsState } from './selectors';
export const addDatasets = createAction('datasets/addDatasets');
export const fetchAPIData = createAsyncThunk('datasets/fetchAPIData', ({ datasetVersionId, resourceId, datasetType, }, { dispatch, getState, rejectWithValue, signal, }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const datasetsResponse = yield Datasets
            .getResource(datasetType)
            .getData({ resourceId }, undefined, undefined, signal);
        if (!datasetsResponse.length) {
            throw new HTTPNotFoundError();
        }
        dispatch(addDatasets({ datasetsResponse, datasetVersionId }));
        const { selectedDatasetId } = getDatasetsState(getState());
        if (!selectedDatasetId) {
            throw new HTTPNotFoundError();
        }
        return {
            datasetsResponse,
            datasetVersionId,
            datasetType,
        };
    }
    catch (err) {
        if (signal.aborted) {
            return rejectWithValue(ActionError.createSerialized(4 /* Aborted */));
        }
        if (err instanceof HTTPClientError || err instanceof HTTPNotFoundError) {
            return rejectWithValue(ActionError.createSerialized(0 /* NotFound */));
        }
        logger.error(err, ELogEnvironment.All);
        return rejectWithValue(ActionError.createSerialized(3 /* Unknown */));
    }
}));

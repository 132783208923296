import { v4 as uuidv4 } from 'uuid';
import { createSlice } from '@reduxjs/toolkit';
import { ELoadingStatus } from 'API/types';
import { EProvider } from 'Common/consts';
import { fetchAPIData, fetchDatasetsList } from './actions';
import { getInitialState } from './initialState';
const phenotypeCatalogue = createSlice({
    name: 'phenotypeCatalogueView',
    initialState: getInitialState(),
    reducers: {
        cleanup(state) {
            const { listFetchingError, loadingStatus, datasetsCollection, dataCollection, } = getInitialState();
            state.listFetchingError = listFetchingError;
            state.loadingStatus = loadingStatus;
            state.datasetsCollection = datasetsCollection;
            state.dataCollection = dataCollection;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAPIData.pending, (state, action) => {
            action.meta.arg.versionIds.forEach((versionId) => {
                state.errorCollection[versionId] = false;
            });
        })
            .addCase(fetchAPIData.rejected, (state, action) => {
            if (action.meta.aborted) {
                return;
            }
            action.meta.arg.versionIds.forEach((versionId) => {
                state.errorCollection[versionId] = true;
            });
        })
            .addCase(fetchAPIData.fulfilled, (state, action) => {
            state.dataCollection[action.payload.dataset_catalogs[0].catalogs[0].version_id] = {
                id: action.payload.dataset_catalogs[0].catalogs[0].version_id,
                name: action.payload.dataset_catalogs[0].catalogs[0].version_name,
                provider: action.payload.dataset_catalogs[0].catalogs[0].provider,
                description: action.payload.dataset_catalogs[0].catalogs[0].metadata,
                data: action.payload.dataset_catalogs[0].catalogs[0].catalog.map((category) => ({
                    id: category.category_id || uuidv4(),
                    name: category.category_name,
                    bold: true,
                    data: action.payload.dataset_catalogs[0].provider === EProvider.UKB
                        ? category.subcategories_and_phenotypes.map((subcategory) => ({
                            id: subcategory.subcategory,
                            name: subcategory.subcategory,
                            data: subcategory.phenotypes.map((phenotype) => ({
                                id: phenotype.phenotype_id,
                                name: phenotype.phenotype_name,
                                levelResult: phenotype.source,
                            })),
                        }))
                        : category.subcategories_and_phenotypes[0].phenotypes.map((phenotype) => ({
                            datasetVersionId: action.payload.dataset_catalogs[0].catalogs[0].version_id,
                            id: phenotype.phenotype_id,
                            name: phenotype.phenotype_name,
                            levelResult: phenotype.source,
                        })),
                })),
            };
        })
            .addCase(fetchDatasetsList.pending, (state) => {
            state.listFetchingError = false;
            state.loadingStatus = ELoadingStatus.Loading;
        })
            .addCase(fetchDatasetsList.rejected, (state, action) => {
            if (action.meta.aborted) {
                return;
            }
            state.listFetchingError = true;
            state.loadingStatus = ELoadingStatus.Finished;
        })
            .addCase(fetchDatasetsList.fulfilled, (state, action) => {
            state.loadingStatus = ELoadingStatus.Finished;
            action.payload.dataset_catalogs.forEach((providerSet) => {
                state.datasetsCollection[providerSet.provider] = {};
                providerSet.catalogs.forEach((dataset) => {
                    state.datasetsCollection[providerSet.provider][dataset.version_id] = {
                        id: dataset.version_id,
                        name: dataset.version_name,
                        provider: dataset.provider,
                        description: dataset.metadata,
                    };
                });
            });
        })
            .addDefaultCase((state) => state);
    },
});
export const { cleanup, } = phenotypeCatalogue.actions;
export default phenotypeCatalogue.reducer;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { HTTPClientError, HTTPNotFoundError, HTTPServerError } from 'API/errors';
import { Gene, GeneStats } from 'API/resources';
import { ActionError } from 'Store/errors';
import { ELevelResult } from 'Shared/types';
import { logger, ELogEnvironment } from 'Common/utils/logger/logger';
import { SIGNIFICANT_ASSOCIATIONS_THRESHOLD } from 'Common/consts';
import { GeneStatsService, TraitsTypeService } from 'Common/services';
import { getSelectors } from 'GeneView/store/selectors/traits';
import { getCollapsingModels, getSignificantIndicators, getIsEmpty as getIsEmptyCollapsingModels } from '../selectors/common';
import { getNonEmptyAssociations } from '../utils';
import { setData } from './common';
const typePrefix = 'geneView/fetchTraitsData';
export const fetchTraitsData = createAsyncThunk(typePrefix, ({ datasetVersionId, geneName, levelResult, traitsType, }, { rejectWithValue, dispatch, getState, signal, }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const collapsingModelsData = getCollapsingModels(getState());
        const collapsingModelIds = collapsingModelsData.reduce((acc, curr) => {
            if (curr.levelResult === levelResult) {
                acc.push(curr.id);
            }
            return acc;
        }, []);
        if (!collapsingModelIds.length) {
            throw new HTTPNotFoundError();
        }
        const geneData = yield Gene.resource(levelResult).getData({
            datasetVersionId,
            geneName,
            traitsType,
        }, {
            collapsingModelId: collapsingModelIds,
        }, undefined, signal);
        let consequenceTypes = [];
        if (levelResult === ELevelResult.Variant) {
            const geneStats = yield GeneStats.resource.getData({ datasetVersionId, geneId: geneName }, undefined, undefined, signal);
            consequenceTypes = GeneStatsService.create(geneStats).consequenceTypes;
        }
        const oppositeTraitsType = TraitsTypeService.getOppositeTraits(traitsType);
        const significantIndicators = getSignificantIndicators(getState());
        const oppositeTraitsData = significantIndicators[traitsType]
            || significantIndicators[oppositeTraitsType]
            ? null
            : yield Gene.resource(levelResult).getData({
                datasetVersionId,
                geneName,
                traitsType: oppositeTraitsType,
            }, {
                collapsingModelId: collapsingModelIds,
                maxPValue: SIGNIFICANT_ASSOCIATIONS_THRESHOLD,
            }, undefined, signal);
        const nonEmptyAssociations = getNonEmptyAssociations(geneData);
        const nonEmptyOppositeTraitsAssosications = getNonEmptyAssociations(oppositeTraitsData);
        if (Object.keys(nonEmptyAssociations).length === 0) {
            throw new HTTPNotFoundError();
        }
        dispatch(setData({
            geneName,
            geneId: geneData.gene_id,
            consequenceTypes,
            associations: nonEmptyAssociations,
            oppositeTraitsAssosications: nonEmptyOppositeTraitsAssosications,
            traitsType,
            levelResult,
        }));
    }
    catch (err) {
        if (signal.aborted) {
            return rejectWithValue(ActionError.createSerialized(4 /* Aborted */));
        }
        if (err instanceof HTTPClientError || err instanceof HTTPNotFoundError) {
            return rejectWithValue(ActionError.createSerialized(1 /* EmptyData */));
        }
        if (err instanceof HTTPServerError) {
            return rejectWithValue(ActionError.createSerialized(5 /* ServerError */));
        }
        logger.error(err.message, ELogEnvironment.All);
        return rejectWithValue(ActionError.createSerialized(3 /* Unknown */));
    }
}), {
    condition: ({ traitsType }, { getState }) => {
        const { getIsEmpty } = getSelectors(traitsType);
        const state = getState();
        return !getIsEmptyCollapsingModels(state) && getIsEmpty(state);
    },
});

import { EDirectionOfEffect } from '../consts';
/**
 * Get the continuous direction of effect
 *
 * @param effectSize - Number
 * @returns Continuous direction of effect gor variant level result
 */
const getContinuousDirectionOfEffectVLR = (effectSize) => {
    if (effectSize === undefined) {
        return EDirectionOfEffect.NoDirectionOfEffect;
    }
    if (effectSize > 0) {
        return EDirectionOfEffect.Increase;
    }
    if (effectSize < 0) {
        return EDirectionOfEffect.Decrease;
    }
    return EDirectionOfEffect.NoDirectionOfEffect;
};
export default getContinuousDirectionOfEffectVLR;

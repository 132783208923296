/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Checks if plot tooltip value is a valid value
 *
 * @param pvalue - Value to check
 * @returns boolean value
 */
export const isPlotTooltipValueValid = (value) => {
    if (value === null || value === undefined) {
        return false;
    }
    if (value === '') {
        return false;
    }
    if (typeof value === 'number' && Number.isNaN(value)) {
        return false;
    }
    return true;
};

/**
 * Gets max possible value for the plot
 *
 * Transform lowest values into -log10 and find the max value after transformation
 *
 * @param lowestX - lowest value for X axis
 * @param lowestY - Lowest value for Y
 * @returns Arrow of umbers or null
 */
export const getMaxPlotValues = (lowestX, lowestY) => {
    if (!(lowestX && lowestY)) {
        return null;
    }
    const maxX = Math.ceil(-Math.log10(lowestX));
    const maxY = Math.ceil(-Math.log10(lowestY));
    return { x: maxX, y: maxY };
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ELogEnvironment } from 'Shared/utils';
import { ActionError } from 'Store/errors';
import { PhenotypeCatalogue } from 'API/resources';
import { HTTPClientError, HTTPNotFoundError, HTTPServerError } from 'API/errors';
import { logger } from 'Common/utils';
export const typePrefix = 'phenotypeCatalogueView/fetchAPIData';
export const fetchAPIData = createAsyncThunk(typePrefix, ({ versionIds, }, { rejectWithValue, signal }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        return yield PhenotypeCatalogue
            .getResource
            .getData({}, { versionIds });
    }
    catch (err) {
        if (signal.aborted) {
            return rejectWithValue(ActionError.createSerialized(4 /* Aborted */));
        }
        if (err instanceof HTTPClientError || err instanceof HTTPNotFoundError) {
            return rejectWithValue(ActionError.createSerialized(0 /* NotFound */));
        }
        if (err instanceof HTTPServerError) {
            return rejectWithValue(ActionError.createSerialized(5 /* ServerError */));
        }
        logger.error(err.message, ELogEnvironment.All);
        return rejectWithValue(ActionError.createSerialized(3 /* Unknown */));
    }
}));

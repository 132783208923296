// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AboutPageLink__infoIcon__9WyRL{fill:var(--primary-contrast-color);height:.875rem;width:.875rem}.AboutPageLink__link__eSrIt{align-items:flex-end;color:var(--footer-color);cursor:pointer;display:flex;font-size:.875rem;font-weight:500;padding-right:1.325rem;text-decoration:none}.AboutPageLink__link__eSrIt:hover{color:var(--footer-link-hover-color)}.AboutPageLink__link__eSrIt:hover .AboutPageLink__infoIcon__9WyRL{fill:var(--footer-link-hover-color)}.AboutPageLink__link__eSrIt .AboutPageLink__contact__3xABt{font-size:inherit;line-height:1;padding:0 .1875rem}", "",{"version":3,"sources":["webpack://./src/common/components/AboutPageLink/AboutPageLink.css"],"names":[],"mappings":"AAAA,gCACE,kCAAmC,CAEnC,cAAgB,CADhB,aAEF,CAEA,4BAGE,oBAAqB,CAFrB,yBAA0B,CAG1B,cAAe,CAFf,YAAa,CAKb,iBAAmB,CADnB,eAAgB,CAEhB,sBAAuB,CAHvB,oBAQF,CAHE,kCACE,oCACF,CAGF,kEACE,mCACF,CAEA,2DAGE,iBAAkB,CADlB,aAAc,CADd,kBAGF","sourcesContent":[".infoIcon {\n  fill: var(--primary-contrast-color);\n  width: 0.875rem;\n  height: 0.875rem;\n}\n\n.link {\n  color: var(--footer-color);\n  display: flex;\n  align-items: flex-end;\n  cursor: pointer;\n  text-decoration: none;\n  font-weight: 500;\n  font-size: 0.875rem;\n  padding-right: 1.325rem;\n\n  &:hover {\n    color: var(--footer-link-hover-color);\n  }\n}\n\n.link:hover .infoIcon {\n  fill: var(--footer-link-hover-color);\n}\n\n.link .contact {\n  padding: 0 0.1875rem;\n  line-height: 1;\n  font-size: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoIcon": "AboutPageLink__infoIcon__9WyRL",
	"link": "AboutPageLink__link__eSrIt",
	"contact": "AboutPageLink__contact__3xABt"
};
export default ___CSS_LOADER_EXPORT___;

import { getMock } from 'API/utils';
import { variantIdMock, variantNameMock } from 'Common/mocks';
import resource from './resource';
export const variantIdResponseMock = {
    variant_id: variantIdMock,
};
export const requestParams = { variantName: variantNameMock };
const mockedURL = {
    [resource.URLFactory(requestParams)]: {
        full: variantIdResponseMock,
        empty: { variant_id: '' },
    },
};
export const fixtures = getMock(mockedURL);

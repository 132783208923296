export const splitMetadataItems = (metadata) => {
    const result = {};
    if (!Array.isArray(metadata))
        return result;
    metadata.forEach((item) => {
        const [key, value] = item === null || item === void 0 ? void 0 : item.split(':');
        if (typeof key !== 'string' || typeof value !== 'string') {
            return;
        }
        const trimmedKey = key.trim();
        const trimmedValue = value.trim();
        result[trimmedKey] = trimmedValue;
    });
    return result;
};

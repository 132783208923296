import { createSlice, current } from '@reduxjs/toolkit';
import { ESortType } from 'Shared/components/Table/types';
import { ELevelResult, ETraitsTypes } from 'Shared/types';
import { PhenotypicCategoryService } from 'Common/services/PhenotypicCategory';
import { GeneService, TraitsTypeService, GeneAssociationService, GeneAssociationFactory, CollapsingModelsService, } from 'Common/services';
import { getHasAncestryData, sortByPhenotypicCategory, sortAssociations, } from 'Common/utils';
import { DATASETS_LOADING_MESSAGE, SIGNIFICANT_ASSOCIATIONS_THRESHOLD, } from 'Common/consts';
import { ELoadingStatus } from 'API/types';
import { fetchAPIData } from 'GeneView/store/actions/fetchAPIData';
import { fetchTraitsData } from 'GeneView/store/actions/fetchTraitsData';
import { getInitialState } from './initialState';
import { ECleanup, } from './types';
let worker;
const genes = createSlice({
    name: 'geneView',
    initialState: getInitialState(),
    reducers: {
        setDataExists(state, action) {
            state.dataExists = action.payload;
        },
        setLoadingMessage(state, action) {
            state.loadingMessage = action.payload;
        },
        initWorker(_, action) {
            worker = action.payload;
        },
        terminateWorker() {
            worker === null || worker === void 0 ? void 0 : worker.terminate();
            worker = null;
        },
        setFilters(state, action) {
            const { payload: { traitsType, phenotype, variant, categories, ancestries, collapsingModels, maxPValue, isMostSignificantModel, isDirectionOfEffectFilterActive, consequenceTypes, }, } = action;
            state[traitsType].isFiltering = true;
            if (phenotype !== undefined) {
                state[traitsType].filters.phenotype = phenotype;
            }
            if (variant !== undefined) {
                state[traitsType].filters.variant = variant;
            }
            if (maxPValue !== undefined) {
                state[traitsType].filters.maxPValue = maxPValue;
            }
            if (categories !== undefined) {
                state[traitsType].filters.categories = categories;
            }
            if (ancestries !== undefined) {
                state[traitsType].filters.ancestries = ancestries;
            }
            if (collapsingModels !== undefined) {
                state[traitsType].filters.collapsingModels = collapsingModels;
            }
            if (isMostSignificantModel !== undefined) {
                state[traitsType].filters.isMostSignificantModel = isMostSignificantModel;
            }
            if (isDirectionOfEffectFilterActive !== undefined) {
                state[traitsType].filters.isDirectionOfEffectFilterActive = isDirectionOfEffectFilterActive;
            }
            if (consequenceTypes !== undefined) {
                state[traitsType].filters.consequenceTypes = consequenceTypes;
            }
            const { associations, filters, phenotypesMostSignificantModels, } = current(state[traitsType]);
            if (worker) {
                worker.postMessage({
                    associations,
                    filters,
                    traitsType,
                    phenotypesMostSignificantModels,
                });
            }
            else {
                const filteredAssociationIds = GeneAssociationService.getFilteredData({
                    associations,
                    filters,
                    phenotypesMostSignificantModels,
                });
                state[traitsType].filteredAssociationIds = filteredAssociationIds;
                state[traitsType].isFiltering = false;
            }
        },
        updateFilteredData(state, action) {
            const { payload: { traitsType, filteredAssociationIds, }, } = action;
            state[traitsType].filteredAssociationIds = filteredAssociationIds;
            state[traitsType].isFiltering = false;
        },
        setTableSortBy(state, action) {
            const { payload: { columnId, sortType, traitsType, }, } = action;
            // TODO Migrate that logic to table related adapter
            state[traitsType].tableSettings.sortBy = (sortType !== ESortType.None
                ? [{
                        id: columnId,
                        desc: sortType === ESortType.Desc,
                    }]
                : []);
        },
        setTablePageSize(state, action) {
            const { traitsType, pageSize } = action.payload;
            state[traitsType].tableSettings.pageSize = pageSize;
        },
        cleanup(state, action) {
            const { errorType, loadingStatus, loadingMessage, collapsingModels, consequenceTypes, significantIndicators, [ETraitsTypes.Binary]: binaryTraitsState, [ETraitsTypes.Continuous]: continuousTraitsState, } = getInitialState();
            state.significantIndicators = significantIndicators;
            state[ETraitsTypes.Binary] = binaryTraitsState;
            state[ETraitsTypes.Continuous] = continuousTraitsState;
            if (action.payload === ECleanup.All) {
                state.errorType = errorType;
                state.loadingStatus = loadingStatus;
                state.loadingMessage = loadingMessage;
                state.collapsingModels = collapsingModels;
                state.consequenceTypes = consequenceTypes;
            }
        },
        setData(state, action) {
            const { payload: { geneName, geneId, associations: geneAssociations, oppositeTraitsAssosications, levelResult, traitsType, consequenceTypes, }, } = action;
            const traits = TraitsTypeService.create(traitsType);
            const oppositeTraits = TraitsTypeService.getOppositeTraits(traits);
            const viewState = state[traits];
            const collapsingModelsCollection = current(state).collapsingModels;
            state.consequenceTypes = consequenceTypes;
            const phenotypicCategoriesCollection = {};
            const ancestries = [];
            const collapsingModels = [];
            const phenotypesGLR = {};
            const phenotypesVLR = {};
            const phenotypesGLRMostSignificantModels = {};
            const phenotypesVLRMostSignificantModels = {};
            const associationsCollection = {};
            const oppositeTraitsAssociationsCollection = {};
            viewState.associations.collection = {};
            viewState.associations.order = [];
            viewState.filters.consequenceTypes = consequenceTypes;
            const geneModel = GeneService.create(geneName, geneId);
            Object.keys(geneAssociations).forEach((id) => {
                const associations = geneAssociations[id];
                if (!associations.length) {
                    return;
                }
                const collapsingModel = collapsingModelsCollection[id];
                collapsingModels.push(collapsingModel);
                associations.forEach((association) => {
                    var _a, _b, _c;
                    const { phenotype_name: phenotypeName, category_id: phenotypicCategoryId, category_name: phenotypicCategoryName, category_short_name: phenotypicCategoryShortName, ancestry, } = association;
                    if (!phenotypicCategoriesCollection[phenotypicCategoryName]) {
                        phenotypicCategoriesCollection[phenotypicCategoryName] = (PhenotypicCategoryService.create(phenotypicCategoryName, phenotypicCategoryShortName, phenotypicCategoryId));
                    }
                    if (!!ancestry && !ancestries.includes(ancestry)) {
                        ancestries.push(ancestry);
                    }
                    const geneAssociation = GeneAssociationFactory.create(levelResult, traits, association, geneModel, collapsingModel);
                    const { pvalue, phenotype } = geneAssociation;
                    if (phenotypeName) {
                        if (levelResult === ELevelResult.Gene) {
                            const currentModelGLR = phenotypesGLR[phenotypeName];
                            if (!currentModelGLR) {
                                phenotypesGLRMostSignificantModels[phenotypeName] = [];
                            }
                            phenotypesGLRMostSignificantModels[phenotypeName].push({
                                ancestry,
                                collapsingModelId: collapsingModel.id,
                                categoryName: phenotypicCategoryName,
                                pvalue,
                                beta: (_a = geneAssociation.beta) !== null && _a !== void 0 ? _a : null,
                                oddsRatio: (_b = geneAssociation.oddsRatio) !== null && _b !== void 0 ? _b : null,
                            });
                            phenotypesGLRMostSignificantModels[phenotypeName].sort((a, b) => a.pvalue - b.pvalue);
                            if (!currentModelGLR
                                || (currentModelGLR.pvalue === pvalue
                                    && currentModelGLR.collapsingModel.name.localeCompare(collapsingModel.name) === 1)
                                || currentModelGLR.pvalue > pvalue) {
                                phenotypesGLR[phenotypeName] = { collapsingModel, pvalue };
                            }
                        }
                        if (levelResult === ELevelResult.Variant) {
                            const variantName = association.variant_name;
                            if (variantName) {
                                if (!phenotypesVLR[phenotypeName]) {
                                    phenotypesVLR[phenotypeName] = {};
                                    phenotypesVLRMostSignificantModels[phenotypeName] = {};
                                }
                                const currentModelVLR = (_c = phenotypesVLR[phenotypeName]) === null || _c === void 0 ? void 0 : _c[variantName];
                                if (!phenotypesVLRMostSignificantModels[phenotypeName][variantName]) {
                                    phenotypesVLRMostSignificantModels[phenotypeName][variantName] = [];
                                }
                                phenotypesVLRMostSignificantModels[phenotypeName][variantName].push({
                                    ancestry,
                                    collapsingModelId: collapsingModel.id,
                                    categoryName: phenotypicCategoryName,
                                    pvalue,
                                    effectSize: geneAssociation.effectSize,
                                    oddsRatio: geneAssociation.oddsRatio,
                                });
                                phenotypesVLRMostSignificantModels[phenotypeName][variantName]
                                    .sort((a, b) => a.pvalue - b.pvalue);
                                if (!currentModelVLR
                                    || (currentModelVLR.pvalue === pvalue
                                        && currentModelVLR.collapsingModel.name
                                            .localeCompare(collapsingModel.name) === 1)
                                    || currentModelVLR.pvalue > pvalue) {
                                    phenotypesVLR[phenotypeName][variantName] = { collapsingModel, pvalue };
                                }
                            }
                        }
                    }
                    if (pvalue <= SIGNIFICANT_ASSOCIATIONS_THRESHOLD) {
                        associationsCollection[phenotype.id] = phenotype.id;
                    }
                    viewState.associations.collection[geneAssociation.id] = geneAssociation;
                    viewState.associations.order.push(geneAssociation.id);
                });
            });
            Object.keys(oppositeTraitsAssosications).forEach((id) => {
                const associations = oppositeTraitsAssosications[id];
                if (!associations.length) {
                    return;
                }
                associations.forEach((association) => {
                    oppositeTraitsAssociationsCollection[association.phenotype_id] = association.phenotype_id;
                });
            });
            if (levelResult === ELevelResult.Gene) {
                Object.keys(phenotypesGLR).forEach((phenotypeName) => {
                    viewState.phenotypes[phenotypeName] = phenotypesGLR[phenotypeName].collapsingModel.id;
                });
                viewState.phenotypesMostSignificantModels = phenotypesGLRMostSignificantModels;
            }
            if (levelResult === ELevelResult.Variant) {
                Object.keys(phenotypesVLR).forEach((phenotypeName) => {
                    viewState.phenotypes[phenotypeName] = Object.entries(phenotypesVLR[phenotypeName])
                        .reduce((acc, [variantName, model]) => (Object.assign(Object.assign({}, acc), { [variantName]: model.collapsingModel.id })), {});
                });
                viewState.phenotypesMostSignificantModels = phenotypesVLRMostSignificantModels;
            }
            if (!state.significantIndicators[traits]
                && !state.significantIndicators[oppositeTraits]) {
                state.significantIndicators[traits] = Object.values(associationsCollection).length;
                state.significantIndicators[oppositeTraits] = Object.values(oppositeTraitsAssociationsCollection).length;
            }
            sortAssociations(viewState.associations);
            const categories = Object
                .values(phenotypicCategoriesCollection)
                .sort(sortByPhenotypicCategory);
            const ancestriesSorted = ancestries.sort((a, b) => a.localeCompare(b));
            viewState.collapsingModels = CollapsingModelsService.getSortedByName(collapsingModels);
            viewState.categories = categories;
            viewState.ancestries = ancestriesSorted;
            viewState.filters.categories = categories;
            viewState.filters.ancestries = ancestriesSorted;
            viewState.filters.collapsingModels = viewState.collapsingModels;
            viewState.filters.hasAncestryData = getHasAncestryData(ancestries);
            viewState.filteredAssociationIds = GeneAssociationService.getFilteredData({
                associations: viewState.associations,
                filters: viewState.filters,
                phenotypesMostSignificantModels: viewState.phenotypesMostSignificantModels,
            });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAPIData.pending, (state) => {
            state.errorType = null;
            state.loadingStatus = ELoadingStatus.Loading;
            state.loadingMessage = DATASETS_LOADING_MESSAGE;
        })
            .addCase(fetchAPIData.fulfilled, (state, action) => {
            state.collapsingModels = CollapsingModelsService.createCollection(action.payload);
            state.loadingStatus = ELoadingStatus.Finished;
            state.loadingMessage = '';
        })
            .addCase(fetchAPIData.rejected, (state, action) => {
            if (action.meta.aborted) {
                return;
            }
            const { type: errorType } = action.payload;
            state.errorType = errorType;
            state.loadingStatus = ELoadingStatus.Finished;
            state.loadingMessage = '';
        })
            .addCase(fetchTraitsData.pending, (state, action) => {
            const { traitsType } = action.meta.arg;
            const traits = TraitsTypeService.create(traitsType);
            const traitsState = state[traits];
            traitsState.loadingStatus = ELoadingStatus.Loading;
            traitsState.loadingMessage = `Loading ${traits} associations...`;
        })
            .addCase(fetchTraitsData.fulfilled, (state, action) => {
            const { traitsType, levelResult } = action.meta.arg;
            state.levelResult = levelResult;
            const traits = TraitsTypeService.create(traitsType);
            const traitsState = state[traits];
            traitsState.loadingStatus = ELoadingStatus.Finished;
            traitsState.loadingMessage = '';
        })
            .addCase(fetchTraitsData.rejected, (state, action) => {
            if (action.meta.aborted) {
                return;
            }
            const { traitsType } = action.meta.arg;
            const traits = TraitsTypeService.create(traitsType);
            const traitsState = state[traits];
            const { type: errorType } = action.payload;
            traitsState.errorType = errorType;
            traitsState.loadingStatus = ELoadingStatus.Finished;
            traitsState.loadingMessage = '';
        })
            .addDefaultCase((state) => state);
    },
});
export const { initWorker, terminateWorker, setLoadingMessage, setFilters, updateFilteredData, setTableSortBy, setTablePageSize, cleanup, setData, } = genes.actions;
export default genes.reducer;

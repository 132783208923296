/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { NA_CHAR } from 'Common/consts';
import { isPlotTooltipValueValid } from '../isPlotTooltipValueValid/isPlotTooltipValueValid';
/**
 * Validates the plot tooltip value and returns fallback for invalid value
 *
 * @param value - Values to validate
 * @fallbackValue - Value as a fallback
 * @returns ReactNode
 */
export const validatePlotTooltipValue = (value, fallbackValue = NA_CHAR) => (isPlotTooltipValueValid(value) ? value : fallbackValue);

import { convertRomanToNumber } from 'Shared/utils/convertRomanToNumber/convertRomanToNumber';
import { CHAPTER_NUMBER_PATTERN } from 'Common/consts';
/**
 * Function, which sorting category by Roman numerals
 *
 * @param a  TPhenotypicCategory,
 * @param b  TPhenotypicCategory,
 * @returns number, which means a greater then b or not
 */
export function sortByPhenotypicCategory(a, b) {
    const numberA = CHAPTER_NUMBER_PATTERN.exec(a.name);
    const numberB = CHAPTER_NUMBER_PATTERN.exec(b.name);
    const convertedNumberA = numberA ? convertRomanToNumber(numberA[1]) : 0;
    const convertedNumberB = numberB ? convertRomanToNumber(numberB[1]) : 0;
    return convertedNumberA - convertedNumberB;
}

import { DEFAULT_API_SERVICE_URI } from 'API/consts';
import { RESTResource } from '../RESTResource';
export const genesResource = new RESTResource(({ resourceId }) => {
    var _a;
    const urlRoot = (_a = process.env.GENES_SERVICE_URI) !== null && _a !== void 0 ? _a : DEFAULT_API_SERVICE_URI;
    return `${urlRoot}/v1/genes/${resourceId}/datasets`;
});
export const phenotypesResource = new RESTResource(({ resourceId }) => {
    var _a;
    const urlRoot = (_a = process.env.PHENOTYPES_SERVICE_URI) !== null && _a !== void 0 ? _a : DEFAULT_API_SERVICE_URI;
    return `${urlRoot}/v1/phenotypes/${resourceId}/datasets`;
});
export const variantsResource = new RESTResource(({ resourceId }) => {
    var _a;
    const urlRoot = (_a = process.env.VARIANTS_SERVICE_URI) !== null && _a !== void 0 ? _a : DEFAULT_API_SERVICE_URI;
    return `${urlRoot}/v1/variants/${resourceId}/datasets`;
});
const getResource = (datasetType) => {
    switch (datasetType) {
        case 0 /* Gene */:
            return genesResource;
        case 1 /* Phenotype */:
            return phenotypesResource;
        case 2 /* Variant */:
            return variantsResource;
        default:
            throw new Error(`Unknown dataset type provided for fetching datasets info: ${datasetType}`);
    }
};
export default getResource;

import { createSlice } from '@reduxjs/toolkit';
import { ELoadingStatus } from 'API/types';
import { ELevelResult, ETraitsTypes } from 'Shared/types';
import { getDashboardDatasets, sortAssociations, sortByPhenotypicCategory } from 'Common/utils';
import { CollapsingModelsService, VariantAssociationService, VariantMetadataService, } from 'Common/services';
import { DASHBOARD_PVALUE_THRESHOLD, SIGNIFICANT_ASSOCIATIONS_COUNT, SIGNIFICANT_ASSOCIATIONS_THRESHOLD, SUGGESTIVE_ASSOCIATIONS_THRESHOLD, UKB_DEFAULT_DATASET, } from 'Common/consts';
import { fetchAPIData } from './actions/fetchAPIData';
import { setLoadingMessage } from './actions/common';
import { getInitialState } from './initialState';
export const initialState = getInitialState();
const variantDashboardView = createSlice({
    name: 'variantDashboardView',
    initialState,
    reducers: {
        setDataExists(state, action) {
            state.dataExists = action.payload;
        },
        cleanup(state) {
            const { dataExists, loadingStatus, loadingMessage, errorType, variantId, datasets, details, significantAssociations, countedPhenotypes, associations, categories, significantIndicators, } = initialState;
            state.dataExists = dataExists;
            state.loadingMessage = loadingMessage;
            state.loadingStatus = loadingStatus;
            state.errorType = errorType;
            state.variantId = variantId;
            state.datasets = datasets;
            state.details = details;
            state.significantAssociations = significantAssociations;
            state.countedPhenotypes = countedPhenotypes;
            state.associations = associations;
            state.categories = categories;
            state.significantIndicators = significantIndicators;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(setLoadingMessage, (state, action) => {
            state.loadingMessage = action.payload;
        })
            .addCase(fetchAPIData.pending, (state) => {
            state.errorType = null;
            state.loadingStatus = ELoadingStatus.Loading;
            state.loadingMessage = 'Loading data...';
        })
            .addCase(fetchAPIData.fulfilled, (state, { payload }) => {
            state.loadingMessage = '';
            state.loadingStatus = ELoadingStatus.Finished;
            const { variantId, datasets, variantData, collapsingModelsData, } = payload;
            state.variantId = variantId;
            state.datasets = getDashboardDatasets(datasets, UKB_DEFAULT_DATASET);
            const collapsingModelsCollection = (CollapsingModelsService.createCollection(collapsingModelsData));
            const significantAssociations = {};
            const significantIndicators = {};
            const phenotypicCategoriesCollection = {};
            const significantBinaryPhenotypes = {};
            const significantContinuousPhenotypes = {};
            const suggestiveBinaryPhenotypes = {};
            const suggestiveContinuousPhenotypes = {};
            const variantMetadata = variantData.length
                ? VariantMetadataService.create(variantData[0].response, Object.keys(variantData[0].response.associations)[0])
                : VariantMetadataService.empty;
            state.details.variantMetadata = variantMetadata;
            variantData.forEach(({ datasetVersionId, levelResult, traitsType, response, }) => {
                if (!response) {
                    return;
                }
                const collapsingModels = Object.keys(response.associations);
                const associationsCollection = {};
                const significantAssociationsCollection = {};
                collapsingModels.forEach((collapsingModelId) => {
                    const associations = response.associations[collapsingModelId];
                    const collapsingModel = collapsingModelsCollection[collapsingModelId];
                    if (!(associations === null || associations === void 0 ? void 0 : associations.length)) {
                        return;
                    }
                    associations
                        .forEach((association) => {
                        const { category_id: phenotypicCategoryId, category_name: phenotypicCategoryName, category_short_name: phenotypicCategoryShortName, } = association;
                        if (!phenotypicCategoriesCollection[phenotypicCategoryShortName]) {
                            phenotypicCategoriesCollection[phenotypicCategoryShortName] = {
                                id: phenotypicCategoryId,
                                name: phenotypicCategoryName,
                                shortName: phenotypicCategoryShortName !== null && phenotypicCategoryShortName !== void 0 ? phenotypicCategoryShortName : undefined,
                            };
                        }
                        const variantAssociation = VariantAssociationService.create(association, collapsingModel, VariantMetadataService.create(response, collapsingModelId), datasetVersionId);
                        variantAssociation.levelResult = ELevelResult.Variant;
                        const { phenotype: { id }, pvalue } = variantAssociation;
                        if (pvalue <= SIGNIFICANT_ASSOCIATIONS_THRESHOLD) {
                            significantAssociationsCollection[id] = id;
                        }
                        if (pvalue <= SIGNIFICANT_ASSOCIATIONS_THRESHOLD
                            && traitsType === ETraitsTypes.Binary) {
                            significantBinaryPhenotypes[id] = id;
                        }
                        if (pvalue <= SIGNIFICANT_ASSOCIATIONS_THRESHOLD
                            && traitsType === ETraitsTypes.Continuous) {
                            significantContinuousPhenotypes[id] = id;
                        }
                        if (pvalue <= SUGGESTIVE_ASSOCIATIONS_THRESHOLD
                            && traitsType === ETraitsTypes.Binary) {
                            suggestiveBinaryPhenotypes[id] = id;
                        }
                        if (pvalue <= SUGGESTIVE_ASSOCIATIONS_THRESHOLD
                            && traitsType === ETraitsTypes.Continuous) {
                            suggestiveContinuousPhenotypes[id] = id;
                        }
                        if (pvalue <= DASHBOARD_PVALUE_THRESHOLD) {
                            let currentAssociation = associationsCollection[variantAssociation.phenotype.id];
                            if (!currentAssociation) {
                                associationsCollection[id] = variantAssociation;
                                if (collapsingModels.length === 1) {
                                    currentAssociation = associationsCollection[variantAssociation.phenotype.id];
                                }
                            }
                            if (currentAssociation && variantAssociation.pvalue < currentAssociation.pvalue) {
                                associationsCollection[id] = variantAssociation;
                            }
                            if (currentAssociation
                                && variantAssociation.pvalue === currentAssociation.pvalue) {
                                const [actualAssociation] = [variantAssociation, currentAssociation]
                                    .sort((a, b) => a.collapsingModel.name.localeCompare(b.collapsingModel.name));
                                associationsCollection[id] = actualAssociation;
                            }
                            if (currentAssociation) {
                                switch (currentAssociation.traitsType) {
                                    case ETraitsTypes.Binary:
                                        currentAssociation.geneName = response.gene_name;
                                        break;
                                    case ETraitsTypes.Continuous:
                                        currentAssociation.gene = {
                                            name: response.gene_name,
                                            id: response.gene_id,
                                        };
                                        break;
                                    default:
                                        break;
                                }
                                state.associations.collection[currentAssociation.id] = currentAssociation;
                                state.associations.order.push(currentAssociation.id);
                            }
                        }
                    });
                    const associationsKey = `${datasetVersionId}-${levelResult}-${traitsType}`;
                    significantIndicators[associationsKey] = Object
                        .values(significantAssociationsCollection).length;
                    significantAssociations[associationsKey] = Object
                        .values(associationsCollection)
                        .sort((a, b) => a.pvalue - b.pvalue)
                        .slice(0, SIGNIFICANT_ASSOCIATIONS_COUNT);
                });
            });
            sortAssociations(state.associations);
            state.significantAssociations = Object.values(significantAssociations)
                .reduce((acc, curr) => acc.concat(curr), []);
            state.significantIndicators = significantIndicators;
            state.countedPhenotypes.significantBinaryPhenotypesCount = Object
                .keys(significantBinaryPhenotypes).length || 0;
            state.countedPhenotypes.significantContinuousPhenotypesCount = Object
                .keys(significantContinuousPhenotypes).length || 0;
            state.countedPhenotypes.suggestiveBinaryPhenotypesCount = Object
                .keys(suggestiveBinaryPhenotypes).length || 0;
            state.countedPhenotypes.suggestiveContinuousPhenotypesCount = Object
                .keys(suggestiveContinuousPhenotypes).length || 0;
            state.categories = Object
                .values(phenotypicCategoriesCollection)
                .sort(sortByPhenotypicCategory);
        })
            .addCase(fetchAPIData.rejected, (state, action) => {
            if (action.meta.aborted) {
                return;
            }
            const { type: errorType } = action.payload;
            state.errorType = errorType;
            state.loadingStatus = ELoadingStatus.Finished;
        })
            .addDefaultCase((state) => state);
    },
});
export const { cleanup } = variantDashboardView.actions;
export default variantDashboardView.reducer;

import { createSlice } from '@reduxjs/toolkit';
import { ETraitsTypes } from 'Shared/types';
import { CollapsingModelsService, GeneAssociationFactory, GeneService, GeneToleranceService, } from 'Common/services';
import { sortAssociations, sortByPhenotypicCategory, getDashboardDatasets, } from 'Common/utils';
import { DASHBOARD_PVALUE_THRESHOLD, SIGNIFICANT_ASSOCIATIONS_COUNT, SIGNIFICANT_ASSOCIATIONS_THRESHOLD, SUGGESTIVE_ASSOCIATIONS_THRESHOLD, UKB_DEFAULT_DATASET, } from 'Common/consts';
import { ELoadingStatus } from 'API/types';
import { MTRPlotDataAdapter } from 'QVView/store/utils';
import { getInitialState } from './initialState';
import { setLoadingMessage } from './actions/common';
import { fetchAPIData } from './actions/fetchAPIData';
import { fetchMTRData } from './actions/fetchMTRData';
export const initialState = getInitialState();
const geneDashboardView = createSlice({
    name: 'geneDashboardView',
    initialState,
    reducers: {
        setDataExists(state, action) {
            state.dataExists = action.payload;
        },
        cleanup(state) {
            const { dataExists, loadingStatus, loadingMessage, errorType, gene, geneEnsembleId, datasets, associations, categories, significantAssociations, significantIndicators, countedPhenotypes, competitiveIntelligence, proteomicsAtlas, mantisMl, proteogenomicsPortal, } = initialState;
            state.dataExists = dataExists;
            state.loadingMessage = loadingMessage;
            state.loadingStatus = loadingStatus;
            state.errorType = errorType;
            state.gene = gene;
            state.geneEnsembleId = geneEnsembleId;
            state.datasets = datasets;
            state.associations = associations;
            state.categories = categories;
            state.significantAssociations = significantAssociations;
            state.significantIndicators = significantIndicators;
            state.countedPhenotypes = countedPhenotypes;
            state.competitiveIntelligence = competitiveIntelligence;
            state.proteomicsAtlas = proteomicsAtlas;
            state.mantisMl = mantisMl;
            state.proteogenomicsPortal = proteogenomicsPortal;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(setLoadingMessage, (state, action) => {
            state.loadingMessage = action.payload;
        })
            .addCase(fetchAPIData.pending, (state) => {
            state.errorType = null;
            state.loadingStatus = ELoadingStatus.Loading;
            state.loadingMessage = 'Loading data...';
        })
            .addCase(fetchAPIData.fulfilled, (state, { payload }) => {
            var _a, _b, _c, _d, _e;
            state.loadingMessage = '';
            state.loadingStatus = ELoadingStatus.Finished;
            const { geneName, geneTolerance, geneData, datasets, collapsingModelsData, proteinLength, transcriptId, externalVariantSourcesData, externalVariantSourcesDatasets, competitiveIntelligenceData, proteomicsAtlasData, mantisMlData, proteogenomicsPortalData, } = payload;
            const { data: externalVariantSourcesStats, hasError: hasEVSError, } = externalVariantSourcesData;
            const { data: geneCompetitiveIntelligenceData, hasError: hasCompetitiveIntelligenceError, } = competitiveIntelligenceData;
            const { 
            /* eslint-disable-next-line @typescript-eslint/naming-convention */
            data: { uni_prot_kb }, hasError: hasProteomicsAtlasError, } = proteomicsAtlasData;
            const { hasError: hasMantisMlError } = mantisMlData;
            const { hasError: hasProteogenomicsPortalError } = proteogenomicsPortalData;
            state.transcriptId = transcriptId;
            state.maxTickValues = { x: proteinLength };
            const collapsingModelsCollection = (CollapsingModelsService.createCollection(collapsingModelsData));
            state.geneTolerance = GeneToleranceService.create(geneTolerance);
            state.gene = GeneService.create(geneName);
            state.geneEnsembleId = geneTolerance.ensembl_gene_id;
            state.datasets = getDashboardDatasets(datasets, UKB_DEFAULT_DATASET);
            state.proteinLength = proteinLength;
            state.externalVariantSources = {
                externalVariantSourcesDatasets,
                count: {
                    clinVar: externalVariantSourcesStats.clin_var,
                    ebiGwas: externalVariantSourcesStats.ebi_gwas,
                    gbmi: externalVariantSourcesStats.gbmi,
                    hgmd: externalVariantSourcesStats.hgmd,
                    omim: externalVariantSourcesStats.omim,
                },
                hasError: hasEVSError,
            };
            state.competitiveIntelligence = {
                data: {
                    uniqueDrugsLaunched: (_a = geneCompetitiveIntelligenceData === null || geneCompetitiveIntelligenceData === void 0 ? void 0 : geneCompetitiveIntelligenceData.Num_uniq_drugs_Launched) !== null && _a !== void 0 ? _a : 0,
                    uniqueDrugsActive: (_b = geneCompetitiveIntelligenceData === null || geneCompetitiveIntelligenceData === void 0 ? void 0 : geneCompetitiveIntelligenceData.Num_uniq_drugs_Active) !== null && _b !== void 0 ? _b : 0,
                    uniqueDrugsPhaseIIOrIII: (_c = geneCompetitiveIntelligenceData === null || geneCompetitiveIntelligenceData === void 0 ? void 0 : geneCompetitiveIntelligenceData.Num_uniq_drugs_Phase_II_or_III) !== null && _c !== void 0 ? _c : 0,
                    uniqueDrugsPhaseIIOrIIIPerDisease: (_d = geneCompetitiveIntelligenceData === null || geneCompetitiveIntelligenceData === void 0 ? void 0 : geneCompetitiveIntelligenceData.Num_uniq_drugs_Phase_II_or_III_per_Disease) !== null && _d !== void 0 ? _d : 0,
                    latestStage: (_e = geneCompetitiveIntelligenceData === null || geneCompetitiveIntelligenceData === void 0 ? void 0 : geneCompetitiveIntelligenceData.Latest_Stage) !== null && _e !== void 0 ? _e : '',
                },
                hasError: hasCompetitiveIntelligenceError,
            };
            state.proteomicsAtlas = {
                data: {
                    uniprotkb: uni_prot_kb,
                },
                hasError: hasProteomicsAtlasError,
            };
            state.mantisMl = {
                hasError: hasMantisMlError,
            };
            state.proteogenomicsPortal = {
                hasError: hasProteogenomicsPortalError,
            };
            state.associations.collection = {};
            state.associations.order = [];
            const phenotypicCategoriesCollection = {};
            const significantAssociations = {};
            const significantIndicators = {};
            const significantBinaryPhenotypes = {};
            const significantContinuousPhenotypes = {};
            const suggestiveBinaryPhenotypes = {};
            const suggestiveContinuousPhenotypes = {};
            geneData.forEach(({ datasetVersionId, levelResult, traitsType, response, }) => {
                const collapsingModels = Object.keys(response.associations);
                const associationsCollection = {};
                const significantAssociationsCollection = {};
                collapsingModels.forEach((collapsingModelId) => {
                    const associations = response.associations[collapsingModelId];
                    const collapsingModel = collapsingModelsCollection[collapsingModelId];
                    if (!(associations === null || associations === void 0 ? void 0 : associations.length)) {
                        return;
                    }
                    associations
                        .forEach((association) => {
                        const { category_id: phenotypicCategoryId, category_name: phenotypicCategoryName, category_short_name: phenotypicCategoryShortName, } = association;
                        if (!phenotypicCategoriesCollection[phenotypicCategoryShortName]) {
                            phenotypicCategoriesCollection[phenotypicCategoryShortName] = {
                                id: phenotypicCategoryId,
                                name: phenotypicCategoryName,
                                shortName: phenotypicCategoryShortName !== null && phenotypicCategoryShortName !== void 0 ? phenotypicCategoryShortName : undefined,
                            };
                        }
                        const geneAssociation = GeneAssociationFactory.create(levelResult, traitsType, association, state.gene, collapsingModel, datasetVersionId);
                        const { phenotype: { id }, pvalue } = geneAssociation;
                        if (pvalue <= SIGNIFICANT_ASSOCIATIONS_THRESHOLD) {
                            significantAssociationsCollection[id] = id;
                        }
                        if (pvalue <= SIGNIFICANT_ASSOCIATIONS_THRESHOLD
                            && traitsType === ETraitsTypes.Binary) {
                            significantBinaryPhenotypes[id] = id;
                        }
                        if (pvalue <= SIGNIFICANT_ASSOCIATIONS_THRESHOLD
                            && traitsType === ETraitsTypes.Continuous) {
                            significantContinuousPhenotypes[id] = id;
                        }
                        if (pvalue <= SUGGESTIVE_ASSOCIATIONS_THRESHOLD
                            && traitsType === ETraitsTypes.Binary) {
                            suggestiveBinaryPhenotypes[id] = id;
                        }
                        if (pvalue <= SUGGESTIVE_ASSOCIATIONS_THRESHOLD
                            && traitsType === ETraitsTypes.Continuous) {
                            suggestiveContinuousPhenotypes[id] = id;
                        }
                        if (pvalue <= DASHBOARD_PVALUE_THRESHOLD) {
                            const currentAssociation = associationsCollection[id];
                            if (!currentAssociation) {
                                associationsCollection[id] = geneAssociation;
                            }
                            if (currentAssociation && geneAssociation.pvalue < currentAssociation.pvalue) {
                                associationsCollection[id] = geneAssociation;
                            }
                            if (currentAssociation && geneAssociation.pvalue === currentAssociation.pvalue) {
                                const [actualAssociation] = [geneAssociation, currentAssociation]
                                    .sort((a, b) => a.collapsingModel.name.localeCompare(b.collapsingModel.name));
                                associationsCollection[id] = actualAssociation;
                            }
                            state.associations.collection[geneAssociation.id] = geneAssociation;
                            state.associations.order.push(geneAssociation.id);
                        }
                    });
                    const associationsKey = `${datasetVersionId}-${levelResult}-${traitsType}`;
                    significantIndicators[associationsKey] = Object
                        .values(significantAssociationsCollection).length;
                    significantAssociations[associationsKey] = Object
                        .values(associationsCollection)
                        .sort((a, b) => a.pvalue - b.pvalue)
                        .slice(0, SIGNIFICANT_ASSOCIATIONS_COUNT);
                });
            });
            sortAssociations(state.associations);
            state.significantAssociations = Object.values(significantAssociations)
                .reduce((acc, curr) => acc.concat(curr), []);
            state.significantIndicators = significantIndicators;
            state.countedPhenotypes.significantBinaryPhenotypesCount = Object
                .keys(significantBinaryPhenotypes).length || 0;
            state.countedPhenotypes.significantContinuousPhenotypesCount = Object
                .keys(significantContinuousPhenotypes).length || 0;
            state.countedPhenotypes.suggestiveBinaryPhenotypesCount = Object
                .keys(suggestiveBinaryPhenotypes).length || 0;
            state.countedPhenotypes.suggestiveContinuousPhenotypesCount = Object
                .keys(suggestiveContinuousPhenotypes).length || 0;
            state.categories = Object
                .values(phenotypicCategoriesCollection)
                .sort(sortByPhenotypicCategory);
        })
            .addCase(fetchAPIData.rejected, (state, action) => {
            if (action.meta.aborted) {
                return;
            }
            const { type: errorType } = action.payload;
            state.errorType = errorType;
            state.loadingStatus = ELoadingStatus.Finished;
        })
            .addCase(fetchMTRData.pending, (state) => {
            state.mtrLoadingStatus = ELoadingStatus.Loading;
        })
            .addCase(fetchMTRData.fulfilled, (state, { payload }) => {
            state.mtrLoadingStatus = ELoadingStatus.Finished;
            state.MTRData = MTRPlotDataAdapter(payload);
        })
            .addCase(fetchMTRData.rejected, (state, action) => {
            if (action.meta.aborted) {
                return;
            }
            const { type: errorType } = action.payload;
            state.mtrLoadingStatus = ELoadingStatus.Finished;
            if (state.mtrErrorType === null) {
                state.mtrErrorType = errorType;
            }
        })
            .addDefaultCase((state) => state);
    },
});
export const { cleanup } = geneDashboardView.actions;
export default geneDashboardView.reducer;

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { ESearchSuggestionsType } from 'Search/types';
import { SuggestionWithHighlights } from 'Search/components/SuggestionWithHighlights';
import SearchSuggestion from 'Search/components/SearchSuggestion/SearchSuggestion';
const SearchSuggestionFactory = (_a) => {
    var { searchPattern } = _a, itemProps = __rest(_a, ["searchPattern"]);
    const { suggestion: { type } } = itemProps;
    if (type === ESearchSuggestionsType.Gene) {
        return (_jsx(SearchSuggestion, Object.assign({}, itemProps), void 0));
    }
    return (_jsx(SuggestionWithHighlights, Object.assign({ searchPattern: searchPattern }, itemProps), void 0));
};
export default memo(SearchSuggestionFactory);

import { EDirectionOfEffect } from '../consts';
/**
 * Get the continuous direction of effect
 *
 * @param beta - Number
 * @returns Continuous direction of effect gor gene level result
 */
const getContinuousDirectionOfEffectGLR = (beta) => {
    if (beta === undefined) {
        return EDirectionOfEffect.NoDirectionOfEffect;
    }
    if (beta > 0) {
        return EDirectionOfEffect.Increase;
    }
    if (beta < 0) {
        return EDirectionOfEffect.Decrease;
    }
    return EDirectionOfEffect.NoDirectionOfEffect;
};
export default getContinuousDirectionOfEffectGLR;

export const CONTRIBUTORS_SECTION_TITLE = 'Contributors';
export const CONTRIBUTORS_INTERNAL_PREFIX_KEY = 'about-view-internal_contributors';
export const CONTRIBUTORS_PUBLIC_PREFIX_KEY = 'about-view-public_contributors';
export const CONTRIBUTORS_INTERNAL = [
    'We thank the following people who have contributed to the development of the Portal or the definition of smart phenotypes.',
    'Portal team: Keren Carss, Sebastian Wasilewski, Joel Okae, Eleanor Wheeler, Quanli Wang, Fengyuan Hu, Dimitrios Vitsios, Katherine Smith, Slavé Petrovski.',
    'Smart phenotypes team: Karyn Mégy, Amanda O’Neill, Gulum Alamgir, Shikta Das, Rasmus Agren, Hussein Al-Mossawi, Bastian Angermann, Javier Armisen Garrido, Adnan Azim, Kamen Bliznashki, Irena Brookes-Smith, Lisa Buvall, Junmei Cairn, Niedzica Camacho, Ben Challis, Chris Chamberlain, Anders Gabrielsen, Ulf Gehrmann, Benjamin Georgi, Zara Ghazoui, Richard Hanna, Ian Henry, Chanchal Kumar, Glenda Lassi, Mark Lazarus, Alex Mackay, Iain MacPhee, Cyrielle Maroteau, Rob Menzies, Erik Michaelsson, Daniel Muthas, Sambit Nanda, Jess Neisen, Andre Nogueira da Costa, Kris Ostridge, Adam Platt, Anna Reznichenko, Keith Tan, Robert, Unwin, Viji Varma, Anna Walentinsson, Qing-Dong Wang, Xia Wang, Thomas White, Kevin Woollard, Carolina Haefliger, Andrew Harper, Athena Matakidou, Dirk Paul, Coralie Viollet, Margarete Fabre, Jen Harrow, Sebastian Wasilewski, Quanli Wang, Dimitrios Vitsios, Slavé Petrovski.',
];
export const CONTRIBUTORS_PUBLIC = [
    'We thank the following people who have contributed to the development of the Portal: Keren Carss, Eleanor Wheeler, Sebastian Wasilewski, Joel Okae, Quanli Wang, Dimitrios Vitsios, Katherine Smith, Slavé Petrovski.',
];

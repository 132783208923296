import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment } from 'react';
import { VERSIONS_INTERNAL, VERSIONS_PUBLIC, VERSION_SECTION_TITLE, } from '../../consts';
import Title from '../Title';
import DescriptionMapper from '../DescriptionMapper';
import styles from './VersionSection.css';
const VersionSection = ({ isPublic = false, }) => {
    const data = isPublic
        ? VERSIONS_PUBLIC
        : VERSIONS_INTERNAL;
    return (_jsxs(_Fragment, { children: [_jsx(Title, { label: VERSION_SECTION_TITLE }, void 0), data.map(({ key, title, description }) => (_jsxs(Fragment, { children: [_jsx("p", Object.assign({ className: styles.title }, { children: title }), void 0), _jsx("p", Object.assign({ className: styles.listContainer }, { children: isPublic ? (_jsx("ul", Object.assign({ className: styles.list }, { children: description.map((item, index) => (
                            /* eslint-disable-next-line react/no-array-index-key */
                            _jsx(Fragment, { children: _jsx("li", { children: item }, void 0) }, `${key}_version_item_${index}`))) }), void 0)) : (_jsx(DescriptionMapper, { addBreaks: true, data: description, keyPrefix: key }, void 0)) }), void 0)] }, key)))] }, void 0));
};
export default VersionSection;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import classnames from 'classnames';
import MailIcon from 'Shared/assets/icons/mail.svg';
import { COOKIE_NOTICE_URL, PRIVACY_NOTICE_URL, CGR_INFORMATICS_SUPPORT_EMAIL, LEGAL_NOTICE_URL, } from 'Common/consts';
import ExternalLinkWithIcon from '../ExternalLinkWithIcon/ExternalLinkWithIcon';
import List from '../List/List';
import AboutPageLink from '../AboutPageLink/AboutPageLink';
const Menu = ({ customIconClassName, customLinkClassName, customListClassName, hideAboutPage, referrerState, }) => (_jsxs(List, Object.assign({ className: customListClassName }, { children: [_jsx(ExternalLinkWithIcon, { label: "Contact us", to: `mailto:${CGR_INFORMATICS_SUPPORT_EMAIL}`, customIconClassName: customIconClassName, customLinkClassName: customLinkClassName, target: "_self", icon: MailIcon }, void 0), _jsx(ExternalLinkWithIcon, { label: "Legal Notice", to: LEGAL_NOTICE_URL, customIconClassName: customIconClassName, customLinkClassName: customLinkClassName, target: "_self" }, void 0), _jsx(ExternalLinkWithIcon, { label: "Privacy Notice", to: PRIVACY_NOTICE_URL, customIconClassName: customIconClassName, customLinkClassName: customLinkClassName }, void 0), COOKIE_NOTICE_URL && (_jsx(ExternalLinkWithIcon, { label: "Cookie Notice", to: COOKIE_NOTICE_URL, customIconClassName: customIconClassName, 
            /* Additional class name is required for cookie banner integration */
            customLinkClassName: classnames(customLinkClassName, 'CookieReportsLink') }, void 0)), !hideAboutPage && (_jsx(AboutPageLink, { referrerState: referrerState, customIconClassName: customIconClassName, customLinkClassName: customLinkClassName }, void 0))] }), void 0));
export default memo(Menu);

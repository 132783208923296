import { NO_DATA_CHAR } from 'Common/consts';
export const VALID_STRING_VALUES = ['Y', 'X', NO_DATA_CHAR];
/**
 * Constructs a table data for the GeneToleranceTable component
 *
 * @param geneToleranceData - input props for GeneToleranceTable component
 * @param geneToleranceConfig - fixed data for a table
 * @returns a object of table data
 */
export const getGeneToleranceTableData = (geneToleranceData, geneToleranceConfig) => Object.keys(geneToleranceData)
    .reduce((acc, curr) => {
    const id = curr;
    const value = geneToleranceData[id];
    const { formatter, name, description, interpretation, } = geneToleranceConfig[id];
    const isValidStringValue = VALID_STRING_VALUES.includes(value);
    const validatedValue = isValidStringValue
        ? value
        : parseFloat(value);
    const displayValue = !!formatter && !isValidStringValue
        ? formatter(value)
        : value;
    acc.push({
        id,
        name,
        value: validatedValue,
        displayValue,
        description,
        interpretation,
    });
    return acc;
}, []);

import { ETraitsTypes } from 'Shared/types';
import { VariantService } from 'Common/services/Variant';
import { GeneService } from 'Common/services/Gene';
import { TraitsTypeService } from 'Common/services/TraitsType';
/**
 * Class service with static methods for work with VariantMetadata
 */
export class VariantMetadataService {
    /**
     * Gets an VariantMetadata object
     * @static
     * @param variantResponse - The data from the variant response
     * @param collapsingModelId - The id of the collapsing model
     * @returns VariantMetadata object
     */
    static create({ traits_type: traitsType, variant_id: variantId, variant_name: variantName, variant_type: variantType, gene_id: geneId, gene_name: geneName, associations, }, collapsingModelId) {
        var _a;
        const metadata = VariantMetadataService.empty;
        const traits = TraitsTypeService.create(traitsType);
        metadata.traitsType = traits;
        metadata.variant = VariantService.create(variantId, variantName, variantType);
        metadata.gene = GeneService.create(geneName, geneId);
        if ((_a = associations[collapsingModelId]) === null || _a === void 0 ? void 0 : _a.length) {
            const { consequence_type: consequenceType, transcript_id: transcriptId, cdna_change: CDNAChange, aa_change: AAChange, exon_rank: exonRank, } = associations[collapsingModelId][0].stats;
            metadata.consequenceType = consequenceType;
            metadata.transcriptId = transcriptId;
            metadata.CDNAChange = CDNAChange;
            metadata.AAChange = AAChange;
            metadata.exonRank = exonRank;
        }
        return metadata;
    }
    /**
     * Gets an empty VariantMetadata object
     * @static
     * @returns Empty variantMetadata object
     */
    static get empty() {
        return {
            traitsType: ETraitsTypes.Binary,
            variant: {
                id: '',
                name: '',
                type: '',
            },
            gene: {
                id: '',
                name: '',
            },
            consequenceType: '',
            transcriptId: '',
            CDNAChange: '',
            AAChange: '',
            exonRank: '',
        };
    }
}

import { DataPointAdapter } from 'GeneView/models/DataPointAdapter';
/**
 * Mapper function for mapping grouped data points from associations array
 * @param tableData GeneAssociations array
 * @returns collection of data points for Manhattan plot grouped by phenotypicCategory
 */
export const getTableMapper = (tableData) => (tableData.reduce((acc, association) => {
    const { phenotypicCategory: { name } } = association;
    const updatedAcc = Object.assign({}, acc);
    if (!updatedAcc[name]) {
        updatedAcc[name] = [];
    }
    updatedAcc[name].push(new DataPointAdapter(association));
    return updatedAcc;
}, {}));

import { basicSort, COMMON_TABLE_COLUMN_WIDTHS, numericSort, TableCellInteger, TableWidths, variantLinkSort, } from 'Shared/components/Table';
import styles from 'Shared/components/Table/components/TableView/TableView.css';
export var ETableColumnAccessor;
(function (ETableColumnAccessor) {
    ETableColumnAccessor["VariantId"] = "variant_id";
    ETableColumnAccessor["VariantName"] = "variant_name";
    ETableColumnAccessor["VariantType"] = "variant_type";
    ETableColumnAccessor["ConsequenceType"] = "consequence_type";
    ETableColumnAccessor["TranscriptId"] = "transcript_id";
    ETableColumnAccessor["CDNAChange"] = "cdna_change";
    ETableColumnAccessor["AAChange"] = "aa_change";
    ETableColumnAccessor["ExonRank"] = "exon_rank";
    ETableColumnAccessor["ABCases"] = "hetero_genotype_cases";
    ETableColumnAccessor["BBCases"] = "homo_genotype_minor_cases";
    ETableColumnAccessor["CaseMAC"] = "minor_allele_cases";
    ETableColumnAccessor["ABControls"] = "hetero_genotype_controls";
    ETableColumnAccessor["BBControls"] = "homo_genotype_minor_controls";
    ETableColumnAccessor["ControlMAC"] = "minor_allele_controls";
})(ETableColumnAccessor || (ETableColumnAccessor = {}));
/**
 * Get table columns
 * @param props - An object with the following fields:
 * - areForExport - Boolean value which identify getting columns for UI or for exports file
 * @returns formatted table columns
 */
export const getTableColumns = ({ areForExport, }) => {
    const columns = [
        {
            Header: 'Variant',
            accessor: ETableColumnAccessor.VariantName,
            description: 'Variant ID: Chromosome-GRCh38 coordinate (position)-reference allele-alternate allele',
            fixed: true,
            width: COMMON_TABLE_COLUMN_WIDTHS.VARIANT,
            exported: {
                Header: 'Variant',
                accessor: ETableColumnAccessor.VariantName,
            },
            sortType: variantLinkSort,
        },
        {
            Header: 'Variant type',
            accessor: ETableColumnAccessor.VariantType,
            description: 'SNV or indel',
            width: COMMON_TABLE_COLUMN_WIDTHS.VARIANT_TYPE,
            sortType: basicSort,
        },
        {
            Header: 'Consequence type',
            accessor: ETableColumnAccessor.ConsequenceType,
            description: 'Highest impact consequence type with respect to the specified gene',
            width: COMMON_TABLE_COLUMN_WIDTHS.CONSEQUENCE_TYPE,
            sortType: basicSort,
        },
        {
            Header: 'Transcript',
            accessor: ETableColumnAccessor.TranscriptId,
            description: 'Ensembl ID of transcript associated with annotated consequence',
            width: COMMON_TABLE_COLUMN_WIDTHS.TRANSCRIPT,
            sortType: basicSort,
        },
        {
            Header: 'cDNA change',
            accessor: ETableColumnAccessor.CDNAChange,
            description: 'cDNA change with respect to transcript',
            width: COMMON_TABLE_COLUMN_WIDTHS.CDNA_CHANGE,
            sortType: basicSort,
        },
        {
            Header: 'Amino acid change',
            accessor: ETableColumnAccessor.AAChange,
            description: 'Amino acid change with respect to transcript',
            width: COMMON_TABLE_COLUMN_WIDTHS.AMINO_ACID_CHANGE,
            sortType: basicSort,
        },
        {
            Header: 'Exon rank',
            accessor: ETableColumnAccessor.ExonRank,
            description: 'Exon rank with respect to transcript',
            width: COMMON_TABLE_COLUMN_WIDTHS.EXON_RANK,
            sortType: basicSort,
        },
        {
            Header: 'Hetero Genotype Cases',
            accessor: ETableColumnAccessor.ABCases,
            description: 'No. cases with a heterozygous genotype',
            Cell: TableCellInteger,
            sortType: numericSort,
            className: styles.alignRight,
            width: TableWidths.WIDTH_160,
        },
        {
            Header: 'Homo Genotype Minor Cases',
            accessor: ETableColumnAccessor.BBCases,
            description: 'No. cases with a minor homozygous genotype',
            Cell: TableCellInteger,
            sortType: numericSort,
            className: styles.alignRight,
            width: TableWidths.WIDTH_160,
        },
        {
            Header: 'Case MAC',
            accessor: ETableColumnAccessor.CaseMAC,
            description: 'Case minor allele count',
            Cell: TableCellInteger,
            sortType: numericSort,
            className: styles.alignRight,
            width: TableWidths.WIDTH_120,
        },
        {
            Header: 'Hetero Genotype Controls',
            accessor: ETableColumnAccessor.ABControls,
            description: 'No. controls with a heterozygous genotype',
            Cell: TableCellInteger,
            sortType: numericSort,
            className: styles.alignRight,
            width: TableWidths.WIDTH_160,
        },
        {
            Header: 'Homo Genotype Minor Controls',
            accessor: ETableColumnAccessor.BBControls,
            description: 'No. controls with a minor homozygous genotype',
            Cell: TableCellInteger,
            sortType: numericSort,
            className: styles.alignRight,
            width: TableWidths.WIDTH_160,
        },
        {
            Header: 'Control MAC',
            accessor: ETableColumnAccessor.ControlMAC,
            description: 'Control minor allele count',
            Cell: TableCellInteger,
            sortType: numericSort,
            className: styles.alignRight,
            width: TableWidths.WIDTH_120,
        },
    ];
    /**
     * Setting `undefined` value explicitly throws renderer error
     */
    if (!FEATURE_FLAGS.QV_VARIANT_LINKS_ENABLED && !areForExport) {
        delete columns[0].Cell;
    }
    return columns;
};

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import useRouter from 'Router/index';
import { Route } from 'Router/models';
/**
 * Creates LinkToView component using specific UI component for displaying it
 *
 * @param route - Route to view
 * @param LinkTo - React component which will be used for rendering
 * @returns LinkToView component
 */
export const createLinkToView = (route, LinkTo) => {
    const LinkToView = (linkToViewProps) => {
        const { getPath, getLocation } = useRouter();
        const { pathname } = getLocation();
        const { routeParams, referrerData } = linkToViewProps, restProps = __rest(linkToViewProps, ["routeParams", "referrerData"]);
        const fullRoute = useMemo(() => {
            let routePath;
            if (typeof route === 'function') {
                routePath = route(routeParams);
            }
            else if (route instanceof Route) {
                routePath = route.getPath(routeParams);
            }
            else {
                routePath = getPath(route, routeParams);
            }
            return {
                pathname: routePath,
                state: {
                    referrerURI: pathname,
                    referrerData,
                },
            };
        }, [pathname, referrerData, routeParams, getPath]);
        return (_jsx(LinkTo, Object.assign({ route: fullRoute }, restProps), void 0));
    };
    return LinkToView;
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ELevelResult } from 'Shared/types';
import { SIGNIFICANT_ASSOCIATIONS_THRESHOLD } from 'Common/consts';
import { TraitsTypeService } from 'Common/services';
import { logger, ELogEnvironment } from 'Common/utils/logger/logger';
import { HTTPClientError, HTTPNotFoundError, HTTPServerError } from 'API/errors';
import { Variant } from 'API/resources';
import { ActionError } from 'Store/errors';
import { ELoadingStatus } from 'API/types';
import { setData, } from './common';
import { getCollapsingModelIds, getIsEmpty as getIsEmptyCollapsingModels } from '../selectors/common';
import { getTraitsSelectors } from '../selectors';
import { getNonEmptyAssociations } from '../utils';
const typePrefix = 'variantView/fetchTraitsData';
export const fetchTraitsData = createAsyncThunk(typePrefix, ({ datasetVersionId, variantId, traitsType, }, { dispatch, getState, rejectWithValue, signal, }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const state = getState();
        const collapsingModelIds = getCollapsingModelIds(state);
        const variantData = yield Variant.resource(ELevelResult.Variant).getData({
            datasetVersionId,
            variantId,
            traitsType,
        }, {
            collapsingModelId: collapsingModelIds,
        }, undefined, signal);
        const oppositeTraitType = TraitsTypeService.getOppositeTraits(traitsType);
        const { getErrorType, getLoadingStatus } = getTraitsSelectors(oppositeTraitType);
        const oppositeTraitErrorType = getErrorType(state);
        const oppositeTraitLoadingStatus = getLoadingStatus(state);
        const oppositeTraitData = oppositeTraitErrorType
            || oppositeTraitLoadingStatus === ELoadingStatus.Finished
            ? null
            : yield Variant.resource(ELevelResult.Variant).getData({
                datasetVersionId,
                variantId,
                traitsType: oppositeTraitType,
            }, {
                collapsingModelId: collapsingModelIds,
                maxPValue: SIGNIFICANT_ASSOCIATIONS_THRESHOLD,
            }, undefined, signal);
        const nonEmptyAssociations = getNonEmptyAssociations(variantData);
        const nonEmptyOppositeTraitAssosications = getNonEmptyAssociations(oppositeTraitData);
        if (Object.keys(nonEmptyAssociations).length === 0) {
            throw new HTTPNotFoundError();
        }
        dispatch(setData({
            variantData,
            associations: nonEmptyAssociations,
            oppositeTraitsAssosications: nonEmptyOppositeTraitAssosications,
            variantId,
            traitsType,
        }));
    }
    catch (err) {
        if (signal.aborted) {
            return rejectWithValue(ActionError.createSerialized(4 /* Aborted */));
        }
        if (err instanceof ActionError) {
            return rejectWithValue(ActionError.createSerialized(err.type));
        }
        if (err instanceof HTTPClientError || err instanceof HTTPNotFoundError) {
            return rejectWithValue(ActionError.createSerialized(1 /* EmptyData */));
        }
        if (err instanceof HTTPServerError) {
            return rejectWithValue(ActionError.createSerialized(5 /* ServerError */));
        }
        logger.error(err.message, ELogEnvironment.All);
        return rejectWithValue(ActionError.createSerialized(3 /* Unknown */));
    }
}), {
    condition: ({ traitsType }, { getState }) => {
        const { getIsEmpty } = getTraitsSelectors(traitsType);
        const state = getState();
        return !getIsEmptyCollapsingModels(state) && getIsEmpty(state);
    },
});

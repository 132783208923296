import { ETraitsTypes } from 'Shared/types';
export var EExportFileFormat;
(function (EExportFileFormat) {
    EExportFileFormat["ZIP"] = ".zip";
    EExportFileFormat["CSV"] = ".csv";
    EExportFileFormat["SVG"] = ".svg";
})(EExportFileFormat || (EExportFileFormat = {}));
export const EXPORT_NAME_SUFFIX = FEATURE_FLAGS.PUBLIC_VERSION
    ? 'AZ_PheWAS_Portal'
    : 'Genomics_Initiative_Portal';
export const EXPORT_DATASET_NAMES = {
    '300k (v3) Public': 'UKB300K',
    '450k (v4) Public': 'UKB450K',
    '300k (v3) Internal': 'UKB300K',
    '450k (v4) Internal': 'UKB450K',
    'FinnGen R6 GWAS': 'FGR6',
    'FinnGen R8 GWAS': 'FGR8',
    'FinnGen R8': 'FGR8',
};
export const TRAITS_TYPE_SUFFIX = {
    [ETraitsTypes.Binary]: 'binary',
    [ETraitsTypes.Continuous]: 'cont',
};

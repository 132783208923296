import { ETraitsTypes, ELevelResult } from 'Shared/types';
import { GeneAssociationService } from '../GeneAssociation/GeneAssociation.service';
/**
 * Class service with static methods for work with GeneAssociationsGLR
 */
export class GeneAssociationGLRService {
    /**
     * Get a GeneBinaryAssociationGLR object
     *
     * @static
     * @param associationData - Response data from API
     * @param gene - Gene for GeneAssociation
     * @param collapsingModel - Collapsing model for GeneAssociation
     * @param id - Custom id for GeneAssociation (uuid v4 is used by default)
     * @returns GeneBinaryAssociationGLR object
     */
    static createBinaryAssociation(associationData, gene, collapsingModel, datasetVersionId, id) {
        const { stats } = associationData;
        return Object.assign(Object.assign(Object.assign({}, GeneAssociationService.createBaseProperties(associationData, gene, collapsingModel, datasetVersionId, id)), GeneAssociationGLRService.addCommonProperties(associationData, ETraitsTypes.Binary)), { cases: stats.cases, controls: stats.controls, caseFrequency: stats.case_frequency, controlFrequency: stats.control_frequency, oddsRatio: stats.odds_ratio, QVCases: stats.qv_cases, QVControls: stats.qv_controls, oddsRatioLCI: stats.odds_ratio_lci, oddsRatioUCI: stats.odds_ratio_uci });
    }
    /**
     * Get a GeneContinuousAssociationGLR object
     *
     * @static
     * @param associationData - Response data from API
     * @param gene - Gene for GeneAssociation
     * @param collapsingModel - Collapsing model for GeneAssociation
     * @param id - Custom id for GeneAssociation (uuid v4 is used by default)
     * @returns GeneContinuousAssociationGLR object
     */
    static createContinuousAssociation(associationData, gene, collapsingModel, datasetVersionId, id) {
        const { stats } = associationData;
        return Object.assign(Object.assign(Object.assign({}, GeneAssociationService.createBaseProperties(associationData, gene, collapsingModel, datasetVersionId, id)), GeneAssociationGLRService.addCommonProperties(associationData, ETraitsTypes.Continuous)), { caseMedian: stats.case_median, controlMedian: stats.control_median, beta: stats.beta, betaSTD: stats.beta_std, yesQV: stats.yes_qv, noQV: stats.no_qv, oddsRatioLCI: stats.odds_ratio_lci, oddsRatioUCI: stats.odds_ratio_uci });
    }
    /**
     * Get a common properties for GeneAssociationsGLR
     *
     * @static
     * @private
     * @param associationData - Response data from API
     * @param traitsType - Type trains (Binary or Continuous)
     * @returns - Common properties for GeneAssociationsGLR
     */
    static addCommonProperties(associationData, traitsType) {
        return {
            levelResult: ELevelResult.Gene,
            samples: associationData.samples,
            traitsType,
        };
    }
}

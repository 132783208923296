import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CITATIONS_INTERNAL, CITATIONS_PUBLIC, CITATIONS_SECTION_DESCRIPTION_INTERNAL, CITATIONS_SECTION_DESCRIPTION_PUBLIC, CITATIONS_SECTION_TITLE_INTERNAL, CITATIONS_SECTION_TITLE_PUBLIC, } from '../../consts';
import { ParagraphWithMargin } from '../../containers';
import Title from '../Title';
import DescriptionMapper from '../DescriptionMapper';
import styles from './CitationsSection.css';
const CitationsSection = ({ isPublic = false, }) => {
    const titleText = isPublic
        ? CITATIONS_SECTION_TITLE_PUBLIC
        : CITATIONS_SECTION_TITLE_INTERNAL;
    const descriptionText = isPublic
        ? CITATIONS_SECTION_DESCRIPTION_PUBLIC
        : CITATIONS_SECTION_DESCRIPTION_INTERNAL;
    const data = isPublic
        ? CITATIONS_PUBLIC
        : CITATIONS_INTERNAL;
    return (_jsxs(_Fragment, { children: [_jsx(Title, { label: titleText }, void 0), _jsx(ParagraphWithMargin, { children: descriptionText }, void 0), data.map(({ description, key, title }) => (_jsxs(ParagraphWithMargin, { children: [title && (_jsx("span", Object.assign({ className: styles.titleBolded }, { children: title }), void 0)), _jsx(DescriptionMapper, { data: description, keyPrefix: key }, void 0)] }, key)))] }, void 0));
};
export default CitationsSection;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IndicatorsTag__tag__3-Plm{background-color:var(--tag-background-color);border-radius:3px;color:var(--font-color);cursor:default;font-size:.875rem;font-weight:500;line-height:.9375rem;margin-left:.375rem;max-height:1.125rem;padding:.125rem .5rem .0625rem}.IndicatorsTag__tag__3-Plm:hover{background-color:var(--tag-background-hover-color)}.IndicatorsTag__tooltip__2pGMH{font-size:1rem;font-weight:400;line-height:1.1875rem}", "",{"version":3,"sources":["webpack://./src/common/components/IndicatorsTag/IndicatorsTag.css"],"names":[],"mappings":"AAAA,2BAOE,4CAA6C,CAF7C,iBAAkB,CAClB,uBAAwB,CAExB,cAAe,CAPf,iBAAmB,CAQnB,eAAgB,CAPhB,oBAAsB,CAQtB,mBAAqB,CAPrB,mBAAoB,CACpB,8BAOF,CAEA,iCACE,kDACF,CAEA,+BACE,cAAe,CAEf,eAAgB,CADhB,qBAEF","sourcesContent":[".tag {\n  font-size: 0.875rem;\n  line-height: 0.9375rem;\n  max-height: 1.125rem;\n  padding: 0.125rem 0.5rem 0.0625rem 0.5rem;\n  border-radius: 3px;\n  color: var(--font-color);\n  background-color: var(--tag-background-color);\n  cursor: default;\n  font-weight: 500;\n  margin-left: 0.375rem;\n}\n\n.tag:hover {\n  background-color: var(--tag-background-hover-color);\n}\n\n.tooltip {\n  font-size: 1rem;\n  line-height: 1.1875rem;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": "IndicatorsTag__tag__3-Plm",
	"tooltip": "IndicatorsTag__tooltip__2pGMH"
};
export default ___CSS_LOADER_EXPORT___;

import { v4 as uuidv4 } from 'uuid';
import { GeneService } from 'Common/services/Gene';
/**
 * Class service with static methods for work with Quantile
 */
export class QuantileService {
    /**
     * Gets an Quantile object
     * @static
     * @param quantileData - quantile data
     * @returns Quantile object
     */
    static create(quantileData) {
        return {
            id: uuidv4(),
            expectedPValueLog: -Math.log10(quantileData.p_perm),
            observedPValueLog: -Math.log10(quantileData.p_observed),
            gene: GeneService.create(quantileData.gene_name, quantileData.gene_id),
            pvalue: quantileData.p_observed,
            ancestry: quantileData.ancestry,
        };
    }
}

import { ESearchSuggestionsType, ECombinedSuggestionsType } from 'Search/types';
import { GeneSuggestion, PhenotypeSuggestion, VariantSuggestion, } from 'Search/models';
export const convertToRecentSuggestionsCollection = (suggestions) => ({
    [ECombinedSuggestionsType.Recent]: suggestions
        .map((searchSuggestion) => {
        const { type } = searchSuggestion;
        /**
         * Prototypes of the recent suggestions should be explicitly restored
         * because of the type casting over the abstract base class
         */
        if (type === ESearchSuggestionsType.Gene) {
            const geneSuggestion = searchSuggestion;
            Object.setPrototypeOf(geneSuggestion, GeneSuggestion.prototype);
            return geneSuggestion;
        }
        if (type === ESearchSuggestionsType.Phenotype) {
            const phenotypeSuggestion = searchSuggestion;
            Object.setPrototypeOf(phenotypeSuggestion, PhenotypeSuggestion.prototype);
            return phenotypeSuggestion;
        }
        if (type === ESearchSuggestionsType.Variant) {
            const variantSuggestion = searchSuggestion;
            Object.setPrototypeOf(variantSuggestion, VariantSuggestion.prototype);
            return variantSuggestion;
        }
        throw new Error(`Unsupported suggestions type: ${type}`);
    }),
});

import { encodeStringToBase64 } from 'Common/utils';
import { PhenotypeViewRoute } from 'Router/index';
import { ESearchSuggestionsType } from 'Search/types';
import { SearchSuggestion } from '../SearchSuggestion/SearchSuggestion';
/**
 * Basic class for PhenotypeSuggestion
 *
 * @extends SearchSuggestion
 */
export class PhenotypeSuggestion extends SearchSuggestion {
    /**
     * Navigate to phenotype view
     *
     * @param navigateTo navigation callback
     * @param referrerURI url of referrer
     * @param referrerData specific data for referrer
     */
    navigate(navigateTo, referrerURI, referrerData) {
        /**
         * @todo Handle navigation to specific level-results tab depending on provider's id
         */
        navigateTo(PhenotypeViewRoute, {
            phenotypeId: this.id,
            encodedPhenotypeName: encodeStringToBase64(this.name),
            datasetVersionId: this.datasetVersionId,
            levelResult: this.levelResult,
        }, referrerURI, referrerData);
    }
    /**
     * Creates instance of PhenotypeSuggestion
     *
     * @param id - id for PhenotypeSuggestion instance
     * @param name - name for PhenotypeSuggestion instance
     * @param dataset - Dataset
     */
    constructor(id, name, dataset) {
        super(id, name, dataset, ESearchSuggestionsType.Phenotype);
    }
}

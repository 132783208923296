import { DEFAULT_API_SERVICE_URI } from 'API/consts';
import { RESTResource } from '../RESTResource';
const phenotypeCatalogueResourceSearchParams = {
    versionIds: 'version_id',
};
export const catalogueResource = new RESTResource(() => {
    var _a;
    const urlRoot = (_a = process.env.PHENOTYPES_SERVICE_URI) !== null && _a !== void 0 ? _a : DEFAULT_API_SERVICE_URI;
    return `${urlRoot}/v1/catalog`;
}, 'get', phenotypeCatalogueResourceSearchParams);
export default catalogueResource;

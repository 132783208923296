import { ELevelResult, ETraitsTypes } from 'Shared/types';
import { EDirectionOfEffect } from './consts';
import getBinaryDirectionOfEffect from './getBinaryDirectionOfEffect';
import getContinuousDirectionOfEffectGLR from './getContinuousDirectionOfEffectGLR';
import getContinuousDirectionOfEffectVLR from './getContinuousDirectionOfEffectVLR';
/**
 * Get the direction of effect with the dependency of the traits type and level result
 *
 * @param data - Object: significant associations
 * @returns Direction of effect with the dependency of the traits type and level result
 */
export const getDirectionOfEffect = ({ traitsType, levelResult, oddsRatio, effectSize, beta, }) => {
    if (traitsType === ETraitsTypes.Continuous
        && levelResult === ELevelResult.Gene) {
        return getContinuousDirectionOfEffectGLR(beta);
    }
    if (traitsType === ETraitsTypes.Continuous
        && levelResult === ELevelResult.Variant) {
        return getContinuousDirectionOfEffectVLR(effectSize);
    }
    if (traitsType === ETraitsTypes.Binary) {
        return getBinaryDirectionOfEffect(oddsRatio);
    }
    return EDirectionOfEffect.NoDirectionOfEffect;
};

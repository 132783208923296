import { jsx as _jsx } from "react/jsx-runtime";
import { v4 as uuidv4 } from 'uuid';
import { ExternalLink } from 'Shared/components';
import { ARTICLE_NATURE_622_PAGES_339_347_URL, PUBLIC_PORTAL_NAME, RARE_VARIANT_ASSOCIATIONS_WITH_PLASMA_PROTEIN_LEVELS_UKB_TITLE, } from 'Common/consts';
import PublicationLink from 'Common/components/PublicationLink/PublicationLink';
export const CITATIONS_SECTION_TITLE_INTERNAL = 'Citations';
export const CITATIONS_SECTION_TITLE_PUBLIC = 'Citations and data access';
export const CITATIONS_SECTION_DESCRIPTION_INTERNAL = 'If you use this Portal in your work, please cite the below publication:';
export const CITATIONS_SECTION_DESCRIPTION_PUBLIC = `The repository was made available by AstraZeneca for public research, and is for research use only. If you use the ${PUBLIC_PORTAL_NAME} in your work, please cite the below publication:`;
const KEY_PREFIX_INTERNAL = 'about-view-internal_citations';
const KEY_PREFIX_PUBLIC = 'about-view-public_citations';
export const CITATIONS_INTERNAL = [
    {
        key: `${KEY_PREFIX_INTERNAL}_paragraph-1_${uuidv4()}`,
        title: 'Rare variant contribution to human disease in 281,104 UK Biobank exomes,',
        description: [
            ' Quanli Wang, Ryan S. Dhindsa, Keren Carss, Andrew R. Harper, Abhishek Nag, Ioanna Tachmazidou, Dimitrios Vitsios, Sri V. V. Deevi, Alex Mackay, Daniel Muthas, Michael Hühn, Sue Monkley, Henric Olsson, AstraZeneca Genomics Initiative, Sebastian Wasilewski, Katherine R. Smith, Ruth March, Adam Platt, Carolina Haefliger, Slavé Petrovski, Nature (2021): ',
            _jsx(PublicationLink, {}, `${KEY_PREFIX_INTERNAL}_publication-link_${uuidv4()}`),
        ],
    },
    {
        key: `${KEY_PREFIX_INTERNAL}_paragraph-2_${uuidv4()}`,
        description: [
            'If you use rare variant proteomics results in your work, please the please cite the below publication:',
        ],
    },
    {
        key: `${KEY_PREFIX_INTERNAL}_paragraph-3_${uuidv4()}`,
        title: 'Rare variant associations with plasma protein levels in the UK Biobank.',
        description: [
            ' Ryan S. Dhindsa*, Oliver S. Burren*, Benjamin B. Sun*, Bram P. Prins, Dorota Matelska, Eleanor Wheeler, Jonathan Mitchell, Erin Oerton, Ventzislava A. Hristova, Katherine R. Smith, Keren Carss, Sebastian Wasilewski, Andrew R. Harper, Dirk S. Paul, Margarete A. Fabre, Heiko Runz, Coralie Viollet, Benjamin Challis, Adam Platt, AstraZeneca Genomics Initiative, Dimitrios Vitsios, Euan A. Ashley, Christopher D. Whelan, Menelas N. Pangalos, Quanli Wang, Slavé Petrovski. Nature (2023): ',
            _jsx(ExternalLink, Object.assign({ to: ARTICLE_NATURE_622_PAGES_339_347_URL, title: RARE_VARIANT_ASSOCIATIONS_WITH_PLASMA_PROTEIN_LEVELS_UKB_TITLE, isTextFieldLink: true, target: "_blank" }, { children: ARTICLE_NATURE_622_PAGES_339_347_URL }), `${KEY_PREFIX_INTERNAL}_article-nature-622-pages-339-347-link_${uuidv4()}`),
        ],
    },
];
export const CITATIONS_PUBLIC = [
    {
        key: `${KEY_PREFIX_PUBLIC}_paragraph-1_${uuidv4()}`,
        title: 'Rare variant contribution to human disease in 281,104 UK Biobank exomes',
        description: [
            '. Quanli Wang, Ryan S. Dhindsa, Keren Carss, Andrew R. Harper, Abhishek Nag, Ioanna Tachmazidou, Dimitrios Vitsios, Sri V. V. Deevi, Alex Mackay, Daniel Muthas, Michael Hühn, Sue Monkley, Henric Olsson, AstraZeneca Genomics Initiative, Sebastian Wasilewski, Katherine R. Smith, Ruth March, Adam Platt, Carolina Haefliger, Slavé Petrovski. Nature (2021): ',
            _jsx(PublicationLink, {}, `${KEY_PREFIX_PUBLIC}_publication-link_${uuidv4()}`),
            '.',
        ],
    },
    {
        key: `${KEY_PREFIX_PUBLIC}_paragraph-2_${uuidv4()}`,
        description: [
            'If you use rare variant proteomics results in your work, please cite the below publication:',
        ],
    },
    {
        key: `${KEY_PREFIX_PUBLIC}_paragraph-3_${uuidv4()}`,
        title: 'Rare variant associations with plasma protein levels in the UK Biobank',
        description: [
            '. Ryan S. Dhindsa*, Oliver S. Burren*, Benjamin B. Sun*, Bram P. Prins, Dorota Matelska, Eleanor Wheeler, Jonathan Mitchell, Erin Oerton, Ventzislava A. Hristova, Katherine R. Smith, Keren Carss, Sebastian Wasilewski, Andrew R. Harper, Dirk S. Paul, Margarete A. Fabre, Heiko Runz, Coralie Viollet, Benjamin Challis, Adam Platt, AstraZeneca Genomics Initiative, Dimitrios Vitsios, Euan A. Ashley, Christopher D. Whelan, Menelas N. Pangalos, Quanli Wang, Slavé Petrovski. Nature (2023): ',
            _jsx(ExternalLink, Object.assign({ to: ARTICLE_NATURE_622_PAGES_339_347_URL, title: RARE_VARIANT_ASSOCIATIONS_WITH_PLASMA_PROTEIN_LEVELS_UKB_TITLE, isTextFieldLink: true, target: "_blank" }, { children: ARTICLE_NATURE_622_PAGES_339_347_URL }), `${KEY_PREFIX_PUBLIC}_article-nature-622-pages-339-347-link_${uuidv4()}`),
            '.',
        ],
    },
    {
        key: `${KEY_PREFIX_PUBLIC}_paragraph-4_${uuidv4()}`,
        description: [
            'Full phenotype-level results for legacy datasets 300K (v3), 450K (v4) and 470K (v5) are available through the phenotype catalogue.',
        ],
    },
];

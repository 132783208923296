import { createSlice, current } from '@reduxjs/toolkit';
import { ELoadingStatus } from 'API/types';
import { ESortType } from 'Shared/components/Table/types';
import { QVariantsService, TraitsTypeService } from 'Common/services';
import { initStore as initStoreHelper } from 'QVView/store/utils/initStore/initStore';
import { fetchAPIData, fetchMTRData } from './actions';
import { getInitialState } from './initialState';
import { MTRPlotDataAdapter } from './utils';
export const initialState = getInitialState();
let worker;
const qv = createSlice({
    name: 'qvView',
    initialState,
    reducers: {
        initWorker(_, action) {
            worker = action.payload;
        },
        terminateWorker() {
            worker === null || worker === void 0 ? void 0 : worker.terminate();
            worker = null;
        },
        cleanup(state) {
            const { isFiltering, errorType, loadingStatus, loadingMessage, filters, consequenceTypes, domains, MTRData, proteinLength, datasets, datasetVersionId, gene, phenotype, collapsingModel, phenotypicCategory, pvalue, traitsType, tableSettings, transcriptId, variants, filteredVariantsIds, phenotypeStats, ancestry, } = getInitialState();
            state.loadingStatus = loadingStatus;
            state.loadingMessage = loadingMessage;
            state.isFiltering = isFiltering;
            state.errorType = errorType;
            state.filters = filters;
            state.consequenceTypes = consequenceTypes;
            state.domains = domains;
            state.MTRData = MTRData;
            state.proteinLength = proteinLength;
            state.datasetVersionId = datasetVersionId;
            state.datasets = datasets;
            state.gene = gene;
            state.phenotype = phenotype;
            state.collapsingModel = collapsingModel;
            state.phenotypicCategory = phenotypicCategory;
            state.pvalue = pvalue;
            state.traitsType = traitsType;
            state.tableSettings = tableSettings;
            state.transcriptId = transcriptId;
            state.variants = variants;
            state.filteredVariantsIds = filteredVariantsIds;
            state.phenotypeStats = phenotypeStats;
            state.ancestry = ancestry;
        },
        setLoadingMessage(state, action) {
            state.loadingMessage = action.payload;
        },
        setTableSortBy(state, action) {
            state.tableSettings.sortBy = action.payload.sortType !== ESortType.None
                ? [{
                        id: action.payload.columnId,
                        desc: action.payload.sortType === ESortType.Desc,
                    }]
                : [];
        },
        setTablePageSize(state, action) {
            state.tableSettings.pageSize = action.payload;
        },
        setVariantsFilter(state, action) {
            state.filters.variants = action.payload.pattern;
            state.filteredVariantsIds = QVariantsService.filterBy(state.variants, state.filters.variants, state.filters.consequenceTypes);
        },
        setConsequenceTypeFilter(state, action) {
            state.filters.consequenceTypes = action.payload.pattern;
            const { filters: { variants, consequenceTypes, }, } = state;
            state.isFiltering = true;
            const variantsCollection = current(state.variants);
            if (worker) {
                worker.postMessage({
                    variants,
                    consequenceTypes,
                    variantsCollection,
                });
            }
            else {
                state.filteredVariantsIds = QVariantsService.filterBy(state.variants, variants, consequenceTypes);
                state.isFiltering = false;
            }
        },
        updateFilteredData(state, action) {
            state.filteredVariantsIds = action.payload;
            state.isFiltering = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAPIData.pending, (state) => {
            state.errorType = null;
            state.loadingStatus = ELoadingStatus.Loading;
            state.loadingMessage = 'Loading CV data...';
        })
            .addCase(fetchAPIData.fulfilled, (state, { payload }) => {
            state.errorType = null;
            state.loadingStatus = ELoadingStatus.Finished;
            state.loadingMessage = '';
            const { ancestry, filters, consequenceTypes, domains, datasetVersionId, datasets, gene, phenotype, collapsingModel, pvalue, phenotypicCategory, traitsType, tableSettings, transcriptId, proteinLength, variants, filteredVariantsIds, } = initStoreHelper(payload);
            const traits = TraitsTypeService.create(traitsType);
            const { phenotypeStats, } = initStoreHelper(payload);
            state.filters = filters;
            state.errorType = null;
            state.consequenceTypes = consequenceTypes;
            state.domains = domains;
            state.datasetVersionId = datasetVersionId;
            state.datasets = datasets;
            state.ancestry = ancestry;
            state.gene = gene;
            state.phenotype = phenotype;
            state.collapsingModel = collapsingModel;
            state.pvalue = pvalue;
            state.phenotypicCategory = phenotypicCategory;
            state.traitsType = traits;
            state.tableSettings = tableSettings;
            state.transcriptId = transcriptId;
            state.proteinLength = proteinLength;
            state.variants = variants;
            state.filteredVariantsIds = filteredVariantsIds;
            state.phenotypeStats = phenotypeStats;
        })
            .addCase(fetchAPIData.rejected, (state, action) => {
            if (action.meta.aborted) {
                return;
            }
            const { type: errorType } = action.payload;
            state.loadingStatus = ELoadingStatus.Finished;
            if (state.errorType === null) {
                state.errorType = errorType;
            }
        })
            .addCase(fetchMTRData.pending, (state) => {
            state.mtrLoadingStatus = ELoadingStatus.Loading;
        })
            .addCase(fetchMTRData.fulfilled, (state, { payload }) => {
            state.mtrLoadingStatus = ELoadingStatus.Finished;
            state.MTRData = MTRPlotDataAdapter(payload);
        })
            .addCase(fetchMTRData.rejected, (state, action) => {
            if (action.meta.aborted) {
                return;
            }
            const { type: errorType } = action.payload;
            state.mtrLoadingStatus = ELoadingStatus.Finished;
            if (state.mtrErrorType === null) {
                state.mtrErrorType = errorType;
            }
        })
            .addDefaultCase((state) => state);
    },
});
export const { setLoadingMessage, initWorker, terminateWorker, updateFilteredData, setTableSortBy, setTablePageSize, setVariantsFilter, setConsequenceTypeFilter, } = qv.actions;
export default qv.reducer;

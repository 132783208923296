import { GeneDashboardViewRoute, GeneViewRoute } from 'Router/index';
import { ESearchSuggestionsType } from 'Search/types';
import { SearchSuggestion } from '../SearchSuggestion/SearchSuggestion';
/**
 * Basic class for GeneSuggestion
 *
 * @extends SearchSuggestion
 */
export class GeneSuggestion extends SearchSuggestion {
    /**
     * Navigate to gene view
     *
     * @param navigateTo navigation callback
     * @param referrerURI url of referrer
     * @param referrerData specific data for referrer
     */
    navigate(navigateTo, referrerURI, referrerData) {
        if (FEATURE_FLAGS.DASHBOARD_NAVIGATION_ENABLED && !FEATURE_FLAGS.PUBLIC_VERSION) {
            navigateTo(GeneDashboardViewRoute, { geneName: this.name }, referrerURI, referrerData);
        }
        else {
            navigateTo(GeneViewRoute, {
                geneName: this.name,
                datasetVersionId: this.datasetVersionId,
                levelResult: this.levelResult,
            }, referrerURI, referrerData);
        }
    }
    /**
     * Creates instance of GeneSuggestion
     *
     * @param id - id for GeneSuggestion instance
     * @param name - name for GeneSuggestion instance
     * @param dataset - Dataset
     */
    constructor(id, name, dataset) {
        super(id, name, dataset, ESearchSuggestionsType.Gene);
    }
}

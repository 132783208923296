import { TITLE_CLINVAR, TITLE_EBIGWAS, TITLE_GBMI, TITLE_HGMD, TITLE_OMIM, TOOLTIP_CLINVAR, TOOLTIP_EBIGWAS, TOOLTIP_GBMI, TOOLTIP_HGMD, TOOLTIP_OMIM, ZERO_COUNT_TOOLTIP_CLINVAR, ZERO_COUNT_TOOLTIP_EBIGWAS, ZERO_COUNT_TOOLTIP_GBMI, ZERO_COUNT_TOOLTIP_HGMD, ZERO_COUNT_TOOLTIP_OMIM, } from 'GeneDashboardView/consts';
export const getVariantSourcesTabData = (({ omim, clinVar, hgmd, gbmi, ebiGwas, }) => ({
    crv: [
        {
            name: TITLE_OMIM,
            tooltip: TOOLTIP_OMIM,
            zeroCountTooltip: ZERO_COUNT_TOOLTIP_OMIM,
            count: omim,
        },
        {
            name: TITLE_HGMD,
            tooltip: TOOLTIP_HGMD,
            zeroCountTooltip: ZERO_COUNT_TOOLTIP_HGMD,
            count: hgmd,
        },
        {
            name: TITLE_CLINVAR,
            tooltip: TOOLTIP_CLINVAR,
            zeroCountTooltip: ZERO_COUNT_TOOLTIP_CLINVAR,
            count: clinVar,
        },
    ],
    pav: [
        {
            name: TITLE_EBIGWAS,
            tooltip: TOOLTIP_EBIGWAS,
            zeroCountTooltip: ZERO_COUNT_TOOLTIP_EBIGWAS,
            count: ebiGwas,
        },
        {
            name: TITLE_GBMI,
            tooltip: TOOLTIP_GBMI,
            zeroCountTooltip: ZERO_COUNT_TOOLTIP_GBMI,
            count: gbmi,
        },
    ],
}));

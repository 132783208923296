import { SUGGESTIONS_ORDER } from 'Search/consts';
export class SearchSuggestions {
    /**
     * Creates new instance
     *
     * @param suggestions - Array of suggestion instances
     * @returns New instance
     */
    constructor(suggestions, order = SUGGESTIONS_ORDER) {
        this.collection = suggestions.reduce((acc, suggestion) => {
            var _a;
            if (!acc[suggestion.type]) {
                acc[suggestion.type] = [];
            }
            (_a = acc[suggestion.type]) === null || _a === void 0 ? void 0 : _a.push(suggestion);
            return acc;
        }, {});
        this.order = order;
        return this;
    }
    /**
     * Creates empty instance
     *
     * @static
     * @returns Empty instance
     */
    static get empty() {
        if (!SearchSuggestions._empty) {
            SearchSuggestions._empty = new SearchSuggestions([]);
        }
        return SearchSuggestions._empty;
    }
    /**
     * Gets all suggestions of the specific type
     *
     * @param type - Suggestions type
     * @returns Array of suggestions
     */
    getByType(type) {
        var _a;
        return (_a = this.collection[type]) !== null && _a !== void 0 ? _a : [];
    }
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CitationsSection__titleBolded__2J7O3{font-weight:700}", "",{"version":3,"sources":["webpack://./src/views/about/components/CitationsSection/CitationsSection.css"],"names":[],"mappings":"AAAA,sCACE,eACF","sourcesContent":[".titleBolded {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleBolded": "CitationsSection__titleBolded__2J7O3"
};
export default ___CSS_LOADER_EXPORT___;

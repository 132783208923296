import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from './initialState';
const initialState = getInitialState();
const cacheData = createSlice({
    name: 'cacheData',
    initialState,
    reducers: {
        setChromosomes(state, action) {
            state.chromosomes = action.payload;
        },
    },
});
export const { setChromosomes } = cacheData.actions;
export default cacheData.reducer;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useUtag } from 'Shared/hooks';
import { getViewUtagData } from 'Shared/utils';
import { Section, ESectionType } from 'Shared/components';
import Header from 'Common/components/Header/Header';
import { PageView } from 'Common/components/PageView/PageView';
import { EPageName } from 'Common/types';
import { TEALIUM_EVENT } from 'Types/analytics';
import BackToReferrerButton from 'Common/components/BackToReferrerButton/BackToReferrerButton';
import DataLicense from '../DataLicense';
import VersionSection from '../VersionSection';
import AcknowledgementsSection from '../AcknowledgementsSection';
import ContributorsSection from '../ContributorsSection';
import UkBiobankTermsSection from '../UkBiobankTermsSection';
import UkBiobankMethodsSection from '../UkBiobankMethodsSection';
import CitationsSection from '../CitationsSection';
import DescriptionSection from '../DescriptionSection';
import styles from './AboutView.css';
const utagData = getViewUtagData({ tealiumEvent: TEALIUM_EVENT.ABOUT_VIEW });
const AboutViewPublic = () => {
    const utag = useUtag();
    useEffect(() => {
        utag.view(utagData);
    }, [utag]);
    return (_jsxs(PageView, Object.assign({ pageName: EPageName.About }, { children: [_jsx(Header, { withSearch: false }, void 0), _jsx(Section, Object.assign({ type: ESectionType.Minimized, className: styles.subHeader }, { children: _jsx(BackToReferrerButton, {}, void 0) }), void 0), _jsx("h3", Object.assign({ className: styles.aboutMainTitle }, { children: "About" }), void 0), _jsxs(Section, Object.assign({ className: styles.content }, { children: [_jsx(DescriptionSection, { isPublic: true }, void 0), _jsx(DataLicense, {}, void 0), _jsx(CitationsSection, { isPublic: true }, void 0), _jsx(UkBiobankMethodsSection, { isPublic: true }, void 0), _jsx(UkBiobankTermsSection, {}, void 0), _jsx(ContributorsSection, { isPublic: true }, void 0), _jsx(AcknowledgementsSection, { isPublic: true }, void 0), _jsx(VersionSection, { isPublic: true }, void 0)] }), void 0)] }), void 0));
};
export default AboutViewPublic;

import { v4 as uuidv4 } from 'uuid';
import { escapeRegExp } from 'Shared/utils';
import { ETraitsTypes } from 'Shared/types';
import { PhenotypeService } from 'Common/services/Phenotype';
import { PhenotypicCategoryService } from 'Common/services/PhenotypicCategory';
import { filterByDirectionOfEffect } from 'Common/utils/filterByDirectionOfEffect';
/**
 * Class service with static methods for work with GeneAssociation
 */
export class GeneAssociationService {
    /**
     * Get a base properties for all kinds of GeneAssociation
     *
     * @static
     * @param associationData - Response data from API
     * @param gene - Gene for GeneAssociation
     * @param collapsingModel - Collapsing model for GeneAssociation
     * @param id - Custom id for GeneAssociation (uuid v4 is used by default)
     * @returns BaseProperties object
     */
    static createBaseProperties(associationData, gene, collapsingModel, datasetVersionId = '', id = uuidv4()) {
        const pvalue = parseFloat(associationData.pvalue);
        return {
            id,
            gene,
            phenotype: PhenotypeService.create(associationData.phenotype_name, associationData.phenotype_id),
            phenotypicCategory: PhenotypicCategoryService.create(associationData.category_name, associationData.category_short_name, associationData.category_id),
            collapsingModel,
            pvalue,
            pvalueLog: -Math.log10(pvalue),
            datasetVersionId,
            ancestry: associationData.ancestry,
        };
    }
    /**
     * Gets an boolean value which identify does association have GLR type
     * @static
     * @param association - associations data
     * @returns boolean value
     */
    static isGLR(association) {
        var _a;
        const associationVLRCandidate = association;
        return ((_a = associationVLRCandidate.variant) !== null && _a !== void 0 ? _a : null) === null;
    }
    /**
     * Gets an boolean value which identify does association have VLR type
     * @static
     * @param association - associations data
     * @returns boolean value
     */
    static isVLR(association) {
        return !GeneAssociationService.isGLR(association);
    }
    /**
     * Gets variants unique names array
     *
     * @static
     * @param collection - collection of associations
     * @returns An array of variants names
     */
    static getVariantsNames(collection) {
        const variants = Object.values(collection).reduce((acc, association) => {
            if (GeneAssociationService.isVLR(association)) {
                acc.push(association.variant.name);
            }
            return acc;
        }, []);
        return Array.from(new Set(variants));
    }
    /**
     * Finds most significant association with current filters
     *
     * @param data - Most significant model data for phenotype, for GLR or VLR
     * @param associationCollapsingModelId - Id of collapsing model for an association
     * @param traitsType - Traits type
     * @param selectedCollapsingModelIds - Collapsing models selected in Global filters
     * @param selectedCategoriesNames - Phenotypic categories selected in Global filters
     * @param selectedAncestries - Ancestries selected in Global filters
     * @param maxPValue - pValue provided in Global filters
     * @param isDirectionOfEffectFilterActive - Boolean defining if direction of effect filter
     *                                          is active
     * @param isGlrAssociation - Boolean defining if association is of GLR
     * @param hasAncestryData - Boolean defining if association contains ancestry data
     * @returns Returns `true`, if phenotype has most significant model
     *          with current filters, otherwise `false`
     */
    static filterByMostSignificantModel(data = [], associationCollapsingModelId, traitsType, selectedCollapsingModelIds, selectedCategoriesNames, selectedAncestries, maxPValue, isDirectionOfEffectFilterActive, isGlrAssociation, hasAncestryData) {
        const filteredCollapsingModelIds = data.reduce((acc, item) => {
            const { ancestry, collapsingModelId, categoryName, pvalue, oddsRatio, } = item;
            const continuousTraitsDirectionOfEffectValue = isGlrAssociation
                ? item.beta
                : item.effectSize;
            if (selectedCollapsingModelIds.has(collapsingModelId)
                && (!hasAncestryData || selectedAncestries.has(ancestry))
                && selectedCategoriesNames.has(categoryName)
                && (maxPValue === null || pvalue <= maxPValue)
                && (!isDirectionOfEffectFilterActive || filterByDirectionOfEffect(traitsType === ETraitsTypes.Binary
                    ? oddsRatio
                    : continuousTraitsDirectionOfEffectValue, traitsType))) {
                acc.push(collapsingModelId);
            }
            return acc;
        }, []);
        return filteredCollapsingModelIds[0] === (associationCollapsingModelId);
    }
    /**
     * Returns array of association Ids based on provided filters
     *
     * @param o.associations - Associations collection and so
     * @param o.filters - Applied filters
     * @param o.phenotypesMostSignificantModels - Collection of most significant models data
     *                                            per phenotype
     * @returns Updated (filtered) data Ids
     */
    static getFilteredData({ associations, filters, phenotypesMostSignificantModels, }) {
        const { ancestries, collapsingModels, consequenceTypes, categories, maxPValue, phenotype, variant, isMostSignificantModel, isDirectionOfEffectFilterActive, hasAncestryData, } = filters;
        const selectedCollapsingModelIds = new Set(collapsingModels.map((model) => model.id));
        const selectedCategoriesNames = new Set(categories.map((category) => category.name));
        const selectedAncestries = new Set(ancestries);
        const selectedConsequenceTypes = new Set(consequenceTypes);
        let filteredAssociations = associations.order
            .map((id) => associations.collection[id])
            .filter(({ collapsingModel }) => selectedCollapsingModelIds.has(collapsingModel.id))
            .filter(({ phenotypicCategory }) => selectedCategoriesNames.has(phenotypicCategory.name));
        if (hasAncestryData) {
            filteredAssociations = filteredAssociations.filter(({ ancestry }) => selectedAncestries.has(ancestry));
        }
        if (phenotype !== null) {
            filteredAssociations = filteredAssociations.filter((association) => new RegExp(escapeRegExp(phenotype), 'i').test(association.phenotype.name));
        }
        if (variant !== null) {
            filteredAssociations = filteredAssociations.filter((association) => {
                if (GeneAssociationService.isGLR(association)) {
                    return false;
                }
                return new RegExp(escapeRegExp(variant), 'i').test(association.variant.name);
            });
        }
        if (maxPValue !== null) {
            filteredAssociations = filteredAssociations.filter(({ pvalue }) => pvalue <= maxPValue);
        }
        if (consequenceTypes) {
            filteredAssociations = filteredAssociations.filter((association) => {
                if (GeneAssociationService.isGLR(association)) {
                    return true;
                }
                return association.consequenceType == null
                    || selectedConsequenceTypes.has(association.consequenceType);
            });
        }
        if (isDirectionOfEffectFilterActive) {
            filteredAssociations = filteredAssociations.filter((association) => {
                var _a;
                const { traitsType } = association;
                const continuousTraitsValueToCheck = GeneAssociationService.isGLR(association)
                    ? association.beta
                    : association.effectSize;
                return filterByDirectionOfEffect(traitsType === ETraitsTypes.Binary
                    ? (_a = association
                        .oddsRatio) !== null && _a !== void 0 ? _a : null
                    : continuousTraitsValueToCheck !== null && continuousTraitsValueToCheck !== void 0 ? continuousTraitsValueToCheck : null, traitsType);
            });
        }
        if (isMostSignificantModel) {
            filteredAssociations = filteredAssociations.filter((association) => {
                const isGlrAssociation = GeneAssociationService.isGLR(association);
                const mostSignificantModelsData = isGlrAssociation
                    ? phenotypesMostSignificantModels[association.phenotype.name]
                    : phenotypesMostSignificantModels[association.phenotype.name][association.variant.name];
                return (GeneAssociationService.filterByMostSignificantModel(mostSignificantModelsData, association.collapsingModel.id, association.traitsType, selectedCollapsingModelIds, selectedCategoriesNames, selectedAncestries, maxPValue, isDirectionOfEffectFilterActive, isGlrAssociation, hasAncestryData));
            });
        }
        return filteredAssociations.map(({ id }) => id);
    }
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BackToReferrerButton__referrerButton__2qbTQ{max-width:700px}.BackToReferrerButton__withSection__1NA0u{height:3.125rem;padding:.75rem 5rem}", "",{"version":3,"sources":["webpack://./src/common/components/BackToReferrerButton/BackToReferrerButton.css"],"names":[],"mappings":"AAAA,6CACE,eACF,CAEA,0CACE,eAAgB,CAChB,mBACF","sourcesContent":[".referrerButton {\n  max-width: 700px;\n}\n\n.withSection {\n  height: 3.125rem;\n  padding: 0.75rem 5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"referrerButton": "BackToReferrerButton__referrerButton__2qbTQ",
	"withSection": "BackToReferrerButton__withSection__1NA0u"
};
export default ___CSS_LOADER_EXPORT___;

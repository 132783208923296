import { v4 as uuidv4 } from 'uuid';
import { PhenotypicCategoryService } from 'Common/services/PhenotypicCategory';
import { PhenotypeService } from 'Common/services/Phenotype';
/**
 * Class service with static methods for work with PhenotypeAssociationGLR
 */
export class PhenotypeAssociationPLRService {
    /**
     * Creates a PhenotypeAssociationPLR object
     *
     * @static
     * @param association - response data from API
     * @param id - custom id for phenotype object (uuid v4 is used by default)
     * @returns PhenotypeAssociationGLR object
     */
    static create(association, id = uuidv4()) {
        const { stats } = association;
        const pvalue = parseFloat(association.pvalue);
        return {
            id,
            pvalue,
            pvalueLog: -Math.log10(pvalue),
            category: PhenotypicCategoryService.create(association.category_name, association.category_short_name, association.category_id),
            phenotype: PhenotypeService.create(association.phenotype_name, association.phenotype_id),
            noSamples: stats.no_samples,
            cases: stats.no_cases,
            controls: stats.no_controls,
            effectSize: stats.effect_size,
            effectSizeStandardError: stats.effect_size_standard_error,
            effectSizeLCI: stats.effect_size_lci,
            effectSizeUCI: stats.effect_size_uci,
        };
    }
}

import { ELevelResult } from 'Shared/types';
import { PhenotypeAssociationGLRService } from 'Common/services/PhenotypeAssociations';
/**
 * Class service with static methods for work with ContinuousAssociationGLR
 */
export class ContinuousAssociationGLRService {
    /**
     * Creates continuous phenotype association glr object
     * @static
     * @param association - Original phenotype association data received from API
     * @param phenotypeData - Original phenotype data received from API
     * @param collapsingModel - Collapsing model object
     */
    static create(association, phenotypeData, collapsingModel) {
        const continuousAssociation = PhenotypeAssociationGLRService.createContinuousAssociation(association, phenotypeData, collapsingModel);
        const { beta, yesQV, noQV } = continuousAssociation;
        const proportionWithQV = yesQV && noQV
            ? yesQV / (yesQV + noQV)
            : 1;
        return Object.assign(Object.assign({}, continuousAssociation), { levelResult: ELevelResult.Gene, coordinates: {
                x: Math.log10(proportionWithQV),
                y: beta !== null && beta !== void 0 ? beta : 0,
            } });
    }
}

import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import useRouter from 'Router/index';
/**
 * Use referrer url for transition to previous view
 *
 * @todo Replace with a custom method from models
 *
 * @param referrerURI - Parsed route referrerURI
 * @returns View params related to previous view
 */
export function useReferrer() {
    const { getLocation, getRouteState } = useRouter();
    const navigate = useNavigate();
    const { state } = getLocation();
    const currentRouteHistory = state === null || state === void 0 ? void 0 : state.routeHistory;
    const routeHistoryLastElement = currentRouteHistory === null || currentRouteHistory === void 0 ? void 0 : currentRouteHistory[currentRouteHistory.length - 1];
    const { name } = (routeHistoryLastElement === null || routeHistoryLastElement === void 0 ? void 0 : routeHistoryLastElement.referrerData) || { name: '' };
    const referrerURI = (routeHistoryLastElement === null || routeHistoryLastElement === void 0 ? void 0 : routeHistoryLastElement.referrerURI) || '';
    const goBack = useCallback(() => {
        const { routeHistory, deleteFromRouteHistory } = getRouteState();
        deleteFromRouteHistory();
        navigate(referrerURI, { state: { routeHistory } });
    }, [navigate, referrerURI, getRouteState]);
    if (referrerURI) {
        return {
            goBack,
            name,
        };
    }
    return null;
}

const base64Regex = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{4}|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)$/;
export const isBase64String = (str) => {
    if (typeof str !== 'string' || !base64Regex.test(str)) {
        return false;
    }
    try {
        atob(str);
        return true;
    }
    catch (e) {
        return false;
    }
};

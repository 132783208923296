import { datasetVersionIdMock, collapsingModelIdMock, collapsingModelNameMock, collapsingModelDescriptionMock, collapsingModelKindMock, } from 'Common/mocks';
import { getMock } from 'API/utils';
import { ECollapsingModelKind } from './types';
import { FinnGenDatasetVersionId, UKBDatasetVersionId } from '../datasets/mocks';
import collapsingModelsResource from './resource';
export const collapsingModelsResponseMock = [
    {
        id: collapsingModelIdMock,
        name: collapsingModelNameMock,
        description: collapsingModelDescriptionMock,
        kind: collapsingModelKindMock,
    },
    {
        id: '6b555c62-b960-4f67-9623-f27bbd58e520',
        name: 'flexnonsyn',
        description: 'Flexible nonsynonymous, moderately rare',
        kind: ECollapsingModelKind.Gene,
    },
    {
        id: '5bc0cd5d-4e55-4e99-bf03-52e12f826958',
        name: 'flexnonsynmtr',
        description: 'Flexible nonsynonymous, moderately rare, Missense Tolerance Ratio constrained',
        kind: ECollapsingModelKind.Variant,
    },
    {
        id: 'fe92aace-2370-4235-a45b-436f622af63a',
        name: 'ptv',
        description: 'Protein-truncating, moderately rare',
        kind: ECollapsingModelKind.Variant,
    },
    {
        id: '0554174c-decb-4605-ad9f-e8f670bbdc09',
        name: 'ptv5pcnt',
        description: 'Protein-truncating, maximum frequency 5%',
        kind: ECollapsingModelKind.Phenotype,
    },
    {
        id: '165e33f4-b244-4d46-ad7e-88657dde6b47',
        name: 'syn',
        description: 'Protein-truncating, all frequencies',
        kind: ECollapsingModelKind.Phenotype,
    },
];
export const collapsingModelsEmptyResponseMock = [];
export const collapsingModelsIds = collapsingModelsResponseMock.map(({ id }) => id);
export const requestParams = {
    datasetVersionId: datasetVersionIdMock,
};
export const FinnGenrequestParams = {
    datasetVersionId: FinnGenDatasetVersionId,
};
export const UKBrequestParams = {
    datasetVersionId: UKBDatasetVersionId,
};
const mockedURL = {
    [collapsingModelsResource.URLFactory(requestParams)]: {
        full: collapsingModelsResponseMock,
        empty: collapsingModelsEmptyResponseMock,
    },
    [collapsingModelsResource.URLFactory(FinnGenrequestParams)]: {
        full: collapsingModelsResponseMock,
        empty: collapsingModelsEmptyResponseMock,
    },
    [collapsingModelsResource.URLFactory(UKBrequestParams)]: {
        full: collapsingModelsResponseMock,
        empty: collapsingModelsEmptyResponseMock,
    },
};
export const fixtures = getMock(mockedURL);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageView__wrapper__1tuYh{background-color:var(--page-background-color);justify-content:space-between;margin:0 auto;min-height:100vh;width:1440px}.PageView__content__WJIdz,.PageView__wrapper__1tuYh{display:flex;flex-direction:column}.PageView__content__WJIdz{flex:1 1 auto;width:100%}.PageView__footer__SYZ2q{align-items:center;color:var(--footer-color);display:flex;justify-content:space-between}.PageView__logo__iVwCl{margin:0 -2rem 0 0;padding:0;transform:scale(.5) translate(-6rem,-.6rem)}.PageView__leftBlock__jg0pv{align-items:center;display:flex}", "",{"version":3,"sources":["webpack://./src/common/components/PageView/PageView.css"],"names":[],"mappings":"AAAA,0BACE,6CAA8C,CAG9C,6BAA8B,CAE9B,aAAc,CACd,gBAAiB,CAFjB,YAGF,CAEA,oDARE,YAAa,CACb,qBAYF,CALA,0BAGE,aAAc,CACd,UACF,CAEA,yBAGE,kBAAmB,CACnB,yBAA0B,CAH1B,YAAa,CACb,6BAGF,CAEA,uBAIE,kBAAmB,CADnB,SAAU,CADV,2CAGF,CAEA,4BAEE,kBAAmB,CADnB,YAEF","sourcesContent":[".wrapper {\n  background-color: var(--page-background-color);\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  width: 1440px;\n  margin: 0 auto;\n  min-height: 100vh;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  flex: 1 1 auto;\n  width: 100%;\n}\n\n.footer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  color: var(--footer-color);\n}\n\n.logo {\n  /* Transform is used instead width change because of the resolution */\n  transform: scale(0.5) translate(-6rem, -0.6rem);\n  padding: 0;\n  margin: 0 -2rem 0 0;\n}\n\n.leftBlock {\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "PageView__wrapper__1tuYh",
	"content": "PageView__content__WJIdz",
	"footer": "PageView__footer__SYZ2q",
	"logo": "PageView__logo__iVwCl",
	"leftBlock": "PageView__leftBlock__jg0pv"
};
export default ___CSS_LOADER_EXPORT___;

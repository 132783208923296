import { DatasetsService, DatasetsConverterService, } from 'Common/services/Datasets';
import { ELogEnvironment, logger } from 'Common/utils/logger/logger';
import { DEFAULT_DATASETS_CONFIG } from 'Common/consts';
import { ESearchSuggestionsType } from 'Search/types';
import { SUGGESTIONS_ORDER, SUGGESTIONS_BY_CROUP_NAME } from 'Search/consts';
import { GeneSuggestion } from '../GeneSuggestion/GeneSuggestion';
import { PhenotypeSuggestion } from '../PhenotypeSuggestion/PhenotypeSuggestion';
import { VariantSuggestion } from '../VariantSuggestion/VariantSuggestion';
import { SearchSuggestions } from './SearchSuggestions';
const suggestionTypesMapper = {
    [ESearchSuggestionsType.Gene]: {
        getter: ({ results: { genes }, }) => genes,
        Class: GeneSuggestion,
    },
    [ESearchSuggestionsType.Phenotype]: {
        getter: ({ results: { phenotypes }, }) => phenotypes,
        Class: PhenotypeSuggestion,
    },
    [ESearchSuggestionsType.Variant]: {
        getter: ({ results: { variants }, }) => variants,
        Class: VariantSuggestion,
    },
    [ESearchSuggestionsType.FullMatch]: {
        getter: () => [],
        Class: VariantSuggestion,
    },
};
export class SearchSuggestionsConverter {
    /**
     * Creates new instance
     *
     * @param suggestionTypes - Supported suggestion types. Unsupported types will be ignored
     * @param datasetsConverter - Converter used to convert datasets response
     * @returns New instance
     */
    constructor(suggestionTypes = Object.keys(suggestionTypesMapper), datasetsConverter = DatasetsConverterService.getSupportedProviders()) {
        this.supportedTypes = suggestionTypes;
        this.datasetsConverter = datasetsConverter;
    }
    /**
     * Converts search suggestion order from API response to SearchSuggestions order format
     *
     * @param order - the order of suggestion groups
     * @returns SearchSuggestions order
     */
    static convertOrderFromAPI(order) {
        if (!order) {
            return SUGGESTIONS_ORDER;
        }
        return order.reduce((acc, curr) => {
            const suggestionName = curr;
            if (SUGGESTIONS_BY_CROUP_NAME[suggestionName]) {
                acc.push(SUGGESTIONS_BY_CROUP_NAME[suggestionName]);
            }
            return acc;
        }, []);
    }
    /**
     * Converts search results API response to SearchSuggestions model
     *
     * @param response - API response
     * @returns SearchSuggestions model
     */
    convertFromAPI(response) {
        if (typeof response !== 'object') {
            logger.warn(`Invalid search results response format: expected object instead of ${response}. Empty suggestions will be used instead`);
            return SearchSuggestions.empty;
        }
        const order = SearchSuggestionsConverter.convertOrderFromAPI(response === null || response === void 0 ? void 0 : response.order);
        order.unshift(SUGGESTIONS_BY_CROUP_NAME.fullFit);
        return new SearchSuggestions(this.supportedTypes.reduce((suggestions, type) => {
            let suggestionsResponse = [];
            try {
                suggestionsResponse = suggestionTypesMapper[type].getter(response);
            }
            catch (err) {
                logger.warn(`Invalid search results response format: cannot find data for type ${type}. Suggestions will be skipped`);
            }
            if (!Array.isArray(suggestionsResponse)) {
                logger.warn(`Invalid search results response format for type ${type}: expected array instead of ${suggestionsResponse}. Suggestions will be skipped`);
                suggestionsResponse = [];
            }
            return suggestions.concat(suggestionsResponse.reduce((items, { id, name, datasets }) => {
                const datasetsConverted = DatasetsConverterService.convertFromAPI(datasets, this.datasetsConverter);
                const datasetSuggestions = DatasetsService
                    .getDefaultForProvider(datasetsConverted, DEFAULT_DATASETS_CONFIG)
                    .reduce((acc, dataset) => {
                    try {
                        // TODO remove this logic after implementation of all dashboards
                        // fix to avoid duplication of gene and variant suggestions
                        // for different dataset providers
                        if (FEATURE_FLAGS.DASHBOARD_NAVIGATION_ENABLED
                            && (type === ESearchSuggestionsType.Gene
                                || type === ESearchSuggestionsType.Variant) && acc.length > 0) {
                            return acc;
                        }
                        acc.push(new suggestionTypesMapper[type].Class(id, name, dataset));
                        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                    }
                    catch ({ message }) {
                        logger.error(`Error creating search suggestion: ${message}`, ELogEnvironment.All);
                    }
                    return acc;
                }, []);
                return items.concat(datasetSuggestions);
            }, []));
        }, []), order);
    }
}
export default new SearchSuggestionsConverter();

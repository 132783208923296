import { createSelector } from '@reduxjs/toolkit';
export const getState = (rootState) => rootState.geneView;
export const getDataExists = createSelector(getState, ({ dataExists }) => dataExists);
export const getLoadingStatus = createSelector([getState], ({ loadingStatus }) => loadingStatus);
export const getLoadingMessage = createSelector([getState], ({ loadingMessage }) => loadingMessage);
export const getErrorType = createSelector([getState], ({ errorType }) => errorType);
export const getCollapsingModels = createSelector([getState], ({ collapsingModels }) => Object.values(collapsingModels));
export const getIsEmpty = createSelector([getState], ({ collapsingModels }) => Object.keys(collapsingModels).length === 0);
export const getSignificantIndicators = createSelector([getState], ({ significantIndicators }) => significantIndicators);
export const getLevelResult = createSelector(getState, ({ levelResult }) => levelResult);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header__layout__3LGT_{background-color:var(--header-background-color);border-bottom:1px solid var(--border-color);color:var(--header-font-color);justify-content:space-between;padding:.625rem 5rem}.Header__layout__3LGT_,.Header__link__1Oai0{align-items:center;display:flex}.Header__link__1Oai0{color:inherit;flex:0 0 auto;margin-right:1rem;outline:none;text-decoration:none}.Header__logo__SXH6M{height:2.25rem;margin-right:.25rem;width:2.25rem}.Header__title__3pfb4{font-size:1rem;font-weight:700;line-height:1.25rem;margin:.5rem 0;text-transform:uppercase}.Header__children__3UbyB{margin-left:auto;margin-right:.625rem}", "",{"version":3,"sources":["webpack://./src/common/components/Header/Header.css"],"names":[],"mappings":"AAAA,uBACE,+CAAgD,CAEhD,2CAA4C,CAI5C,8BAA+B,CAF/B,6BAA8B,CAH9B,oBAMF,CAEA,4CAJE,kBAAmB,CAFnB,YAcF,CARA,qBAME,aAAc,CAJd,aAAc,CACd,iBAAkB,CAIlB,YAAa,CAFb,oBAGF,CAEA,qBAEE,cAAe,CACf,mBAAqB,CAFrB,aAGF,CAEA,sBACE,cAAe,CAEf,eAAgB,CADhB,mBAAoB,CAGpB,cAAgB,CADhB,wBAEF,CAEA,yBACE,gBAAiB,CACjB,oBACF","sourcesContent":[".layout {\n  background-color: var(--header-background-color);\n  padding: 0.625rem 5rem;\n  border-bottom: 1px solid var(--border-color);\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  color: var(--header-font-color);\n}\n\n.link {\n  display: flex;\n  flex: 0 0 auto;\n  margin-right: 1rem;\n  align-items: center;\n  text-decoration: none;\n  color: inherit;\n  outline: none;\n}\n\n.logo {\n  width: 2.25rem;\n  height: 2.25rem;\n  margin-right: 0.25rem;\n}\n\n.title {\n  font-size: 1rem;\n  line-height: 1.25rem;\n  font-weight: 700;\n  text-transform: uppercase;\n  margin: 0.5rem 0;\n}\n\n.children {\n  margin-left: auto;\n  margin-right: 0.625rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "Header__layout__3LGT_",
	"link": "Header__link__1Oai0",
	"logo": "Header__logo__SXH6M",
	"title": "Header__title__3pfb4",
	"children": "Header__children__3UbyB"
};
export default ___CSS_LOADER_EXPORT___;

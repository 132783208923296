const calculateMaf = (AAF) => {
    const precision = (AAF.toString().split('.')[1] || '').length;
    const MAF = 1 - AAF;
    return Number(MAF.toFixed(precision));
};
export const getEvaluatedMafValue = ({ AAF, MAF, }) => {
    const isMafValuePresent = !!MAF;
    if (isMafValuePresent)
        return MAF;
    const isAafValuePresent = !!AAF;
    if (isAafValuePresent) {
        const aafParsed = typeof AAF === 'number'
            ? AAF
            : parseFloat(AAF || '');
        if (aafParsed) {
            return aafParsed > 0.5
                ? calculateMaf(aafParsed)
                : AAF;
        }
    }
    return 1;
};

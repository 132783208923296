import { EProvider } from 'Common/consts';
import { logger } from 'Common/utils/logger/logger';
import { DatasetsService } from './Datasets.service';
/**
 * Class service with static methods for work with DatasetsConverter
 */
export class DatasetsConverterService {
    /**
     * Creates set of supported providers
     *
     * @static
     * @param providers - Array or set of supported providers. Unsupported providers will be ignored
     * @returns get set of supported providers
     */
    static getSupportedProviders(providers) {
        let defaultProviders = providers;
        if (!defaultProviders) {
            defaultProviders = [EProvider.UKB, EProvider.FinnGen];
        }
        return Array.isArray(defaultProviders)
            ? new Set(defaultProviders)
            : defaultProviders;
    }
    /**
     * Converts API response into Datasets
     * @static
     * @param response - Datasets response
     * @param supportedProviders - set of supported providers
     * @returns array of Datasets
     */
    static convertFromAPI(response, supportedProviders) {
        if (!Array.isArray(response)) {
            logger.warn(`Invalid response format: expected array instead of ${response}. Empty array will be returned`);
            return DatasetsService.empty;
        }
        return response.reduce((acc, datasetResponse) => {
            if (typeof datasetResponse !== 'object') {
                logger.warn(`Invalid dataset response format: expected object instead of ${datasetResponse}. Dataset will be ignored`);
                return acc;
            }
            const { version_id: versionId, version_name: versionName, provider, metadata: description, } = datasetResponse;
            if (!supportedProviders.has(provider)) {
                logger.warn(`Invalid provider from dataset response: '${provider}' provider is unsupported. Dataset will be ignored`);
                return acc;
            }
            acc.push({
                id: versionId,
                name: versionName,
                description,
                versionId,
                providerId: provider,
            });
            return acc;
        }, []);
    }
}

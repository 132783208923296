import { jsx as _jsx } from "react/jsx-runtime";
import { v4 as uuidv4 } from 'uuid';
import { ExternalLink } from 'Shared/components';
import { CGR_INFORMATICS_SUPPORT_EMAIL, PUBLIC_PORTAL_NAME, } from 'Common/consts';
const KEY_PREFIX_INTERNAL = 'about-view-internal_disclaimer';
export const DISCLAIMER_INTERNAL = [
    {
        key: `${KEY_PREFIX_INTERNAL}_paragraph-1_${uuidv4()}`,
        description: [
            'The data in this Portal have been generated internally or licensed by AstraZeneca for internal use only. Please consult with ',
            _jsx(ExternalLink, Object.assign({ to: `mailto:${CGR_INFORMATICS_SUPPORT_EMAIL}`, isTextFieldLink: true }, { children: CGR_INFORMATICS_SUPPORT_EMAIL }), `${KEY_PREFIX_INTERNAL}_cgr-informatics-support-email_${uuidv4()}`),
            ' before sharing/communicating data from this Portal.',
        ],
    },
];
export const DESCRIPTION_INTERNAL = 'This Portal is a repository of gene-phenotype and variant-phenotype associations computed on exomes released by UK Biobank and genotypes released by FinnGen. All genomic coordinates in this Portal are based on GRCh38. Continuous phenotypes are rank-based inverse normal transformed before analysis. The data are made available for AZ research use only.';
export const DESCRIPTION_PUBLIC = `${PUBLIC_PORTAL_NAME} is a repository of gene-phenotype associations for phenotypes derived from electronic health records, questionnaire data, and continuous traits computed on exomes/genomes released by UK Biobank. All genomic coordinates in this Portal are based on GRCh38. Continuous phenotypes are rank-based inverse normal transformed before analysis.`;

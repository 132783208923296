/**
 * getSuffixOfExportedFileName:
 *
 * @param isAllData any data as boolean
 * @param traitsType traits type suffix
 * @returns suffix of the exported file
 */
export const getSuffixOfExportedFileName = (isAllData, traitsType) => {
    if (traitsType) {
        return isAllData ? `${traitsType}` : `${traitsType}_filt`;
    }
    return isAllData ? '' : '_filt';
};

import { range } from 'Shared/utils';
/**
 * Amount quantity of generated data
 */
export const DEFAULT_AMOUNT = 10;
/**
 * Repeats mock data give amount of times
 *
 * @params mockData - Collection of elements which will be generate in data collection
 * @params [amount] - Amount of data to generate
 * @returns - Data collection
 */
export const generateData = function generateData(mockData, amount = DEFAULT_AMOUNT) {
    return range(amount)
        .reduce((acc) => [...acc, ...mockData], []);
};

import { convertRomanToNumber } from 'Shared/utils/convertRomanToNumber/convertRomanToNumber';
import { CHAPTER_NUMBER_PATTERN } from 'Common/consts';
/**
 * Function, which sorting order field in associations object by keys array
 *
 * @param associations TSortedCollection,
 * @param sortBy ESortAssociationsBy[],
 * @returns void
 */
export var ESortAssociationsBy;
(function (ESortAssociationsBy) {
    ESortAssociationsBy["CollapsingModel"] = "collapsingModel";
    ESortAssociationsBy["PhenotypicCategory"] = "phenotypicCategory";
    ESortAssociationsBy["Phenotype"] = "phenotype";
    ESortAssociationsBy["Gene"] = "gene";
    ESortAssociationsBy["Variant"] = "variant";
})(ESortAssociationsBy || (ESortAssociationsBy = {}));
function getSortOrder(association1, association2, keyForSort) {
    if (keyForSort === ESortAssociationsBy.PhenotypicCategory) {
        const phenotypicCategory1 = association1.phenotypicCategory;
        const phenotypicCategory2 = association2.phenotypicCategory;
        if (!phenotypicCategory1 || !phenotypicCategory2) {
            return 0;
        }
        const numbersA = CHAPTER_NUMBER_PATTERN.exec(phenotypicCategory1.name);
        const numbersB = CHAPTER_NUMBER_PATTERN.exec(phenotypicCategory2.name);
        const convertedNumberA = numbersA ? convertRomanToNumber(numbersA[1]) : 0;
        const convertedNumberB = numbersB ? convertRomanToNumber(numbersB[1]) : 0;
        return convertedNumberA - convertedNumberB;
    }
    const value1 = association1[keyForSort];
    const value2 = association2[keyForSort];
    if (!value1 || !value2) {
        return 0;
    }
    return value1.name.localeCompare(value2.name);
}
export function sortAssociations(associations, sortBy = Object.values(ESortAssociationsBy)) {
    associations.order.sort((id1, id2) => {
        const association1 = associations.collection[id1];
        const association2 = associations.collection[id2];
        const sortOrder = sortBy.find((key) => getSortOrder(association1, association2, key) !== 0);
        if (!sortOrder) {
            return 0;
        }
        return getSortOrder(association1, association2, sortOrder);
    });
}

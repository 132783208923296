import { Route } from 'Router/models';
export const GeneViewRoute = new Route('/geneView/:datasetVersionId/:geneName/:levelResult?/:traitsType?');
export const VariantViewRoute = new Route('/variantView/:datasetVersionId/:variantName/:levelResult?/:traitsType?');
export const VariantDashboardViewRoute = new Route('/variantDashboardView/:variantId');
export const PhenotypeViewRoute = new Route('/phenotypeView/:datasetVersionId/:encodedPhenotypeName/:levelResult?');
export const QVViewRoute = new Route('/QVView/:datasetVersionId/:geneId/:phenotypeId/:collapsingModelId/:phenotypicCategoryId/:traitsType?/:ancestryEnum?');
export const QQViewRoute = new Route('/phenotypeView/:datasetVersionId/:encodedPhenotypeName/:levelResult/qq/:collapsingModelId');
export const FESViewRoute = new Route('/phenotypeView/:datasetVersionId/:encodedPhenotypeName/fes');
export const GeneDashboardViewRoute = new Route('/geneDashboard/:geneName');
/**
 * Factory which builds routes path
 */
export const PathFactory = {
    [0 /* Search */]: () => '/',
    [2 /* QVView */]: (params = {}) => QVViewRoute.getPath(params),
    [3 /* PhenotypeView */]: (params = {}) => PhenotypeViewRoute.getPath(params),
    [4 /* PhenotypeCatalogueView */]: () => '/phenotypeCatalogue',
    [5 /* VariantView */]: (params = {}) => VariantViewRoute.getPath(params),
    [1 /* NotFound */]: () => '/404',
    [6 /* AboutView */]: () => '/about',
    [7 /* ServerError */]: () => '/500',
    [8 /* ModelDefinitions */]: () => '/modelDefinitions',
};
export const TABS_BINARY_NAME = 'binary';
export const TABS_CONTINUOUS_NAME = 'continuous';
// Path aliases
export const SEARCH_PATH = PathFactory[0 /* Search */]();
export const GENE_VIEW_PATHS_ARRAY = GeneViewRoute.getPathsWithOptionalParams();
export const VARIANT_VIEW_PATHS_ARRAY = VariantViewRoute.getPathsWithOptionalParams();
export const VARIANT_DASHBOARD_VIEW_PATH = VariantDashboardViewRoute.getPath();
export const NOT_FOUND_PATH = PathFactory[1 /* NotFound */]();
export const SERVER_ERROR_PATH = PathFactory[7 /* ServerError */]();
export const ABOUT_VIEW_PATH = PathFactory[6 /* AboutView */]();
export const QV_VIEW_PATHS_ARRAY = QVViewRoute.getPathsWithOptionalParams();
export const PHENOTYPE_VIEW_PATHS_ARRAY = PhenotypeViewRoute.getPathsWithOptionalParams();
export const PHENOTYPE_CATALOGUE_VIEW_PATH = PathFactory[4 /* PhenotypeCatalogueView */]();
export const QQ_VIEW_PATH = QQViewRoute.getPath();
export const FES_VIEW_PATH = FESViewRoute.getPath();
export const GENE_DASHBOARD_VIEW_PATH = GeneDashboardViewRoute.getPath();
export const MODEL_DEFINITIONS_PATH = PathFactory[8 /* ModelDefinitions */]();
export const GO_BACK = -1;

import { ELevelResult } from 'Shared/types';
import { PhenotypeAssociationGLRService } from 'Common/services/PhenotypeAssociations/PhenotypeAssociationGLR/PhenotypeAssociationGLR.service';
/**
 * Class service with static methods for work with BinaryAssociationGLR
 */
export class BinaryAssociationGLRService {
    /**
     * Creates binary phenotype association glr object
     * @static
     * @param association - Original phenotype association data received from API
     * @param phenotypeData - Original phenotype data received from API
     * @param collapsingModel - Collapsing model object
     */
    static create(association, phenotypeData, collapsingModel) {
        const binaryAssociation = PhenotypeAssociationGLRService.createBinaryAssociation(association, phenotypeData, collapsingModel);
        const { oddsRatio, controlFrequency } = binaryAssociation;
        return Object.assign(Object.assign({}, binaryAssociation), { levelResult: ELevelResult.Gene, coordinates: {
                x: Math.log10(controlFrequency || 1),
                y: Math.log10(oddsRatio || 1),
            } });
    }
}

import { QVariantsService, TraitsTypeService, PhenotypicCategoryService, GeneService, PhenotypeService, CollapsingModelService, DatasetsConverterService, } from 'Common/services';
import { getInitialState } from 'QVView/store/initialState';
import { sortQVariants } from 'QVView/utils';
import { domainsDataAdapter, } from '..';
export const initStore = ({ variantsData, pfamDomainsData, transcriptsData, APIDataParams: { datasetVersionId, }, phenotypeStats, datasets, }) => {
    const store = getInitialState();
    if (!variantsData) {
        return store;
    }
    const { gene_id: geneId, gene_name: geneName, phenotype_id: phenotypeId, phenotype_name: phenotypeName, category_id: phenotypicCategoryId, category_name: phenotypicCategoryName, category_short_name: phenotypicCategoryShortName, collapsing_model_id: collapsingModelId, collapsing_model_name: collapsingModelName, traits_type: traits, pvalue, variants, ancestry, } = variantsData;
    const traitsType = TraitsTypeService.create(traits);
    const datasetsConverter = DatasetsConverterService.getSupportedProviders();
    const variantsCollection = QVariantsService.createCollection(variants, sortQVariants, traitsType);
    const storeVariantsFilter = store.filters.variants;
    const consequenceTypes = QVariantsService.getUniqueConsequenceTypes(variantsCollection.collection);
    const consequenceTypesFilter = consequenceTypes;
    store.phenotypeStats = phenotypeStats;
    store.consequenceTypes = consequenceTypes;
    store.filters.consequenceTypes = consequenceTypesFilter;
    store.datasetVersionId = datasetVersionId;
    store.traitsType = traitsType;
    store.gene = GeneService.create(geneName, geneId);
    store.phenotype = PhenotypeService.create(phenotypeName, phenotypeId);
    store.collapsingModel = CollapsingModelService.create(collapsingModelId, collapsingModelName);
    store.pvalue = pvalue ? parseFloat(pvalue) : null;
    store.datasets = DatasetsConverterService.convertFromAPI(datasets, datasetsConverter);
    store.phenotypicCategory = (phenotypicCategoryId && phenotypicCategoryName)
        ? PhenotypicCategoryService.create(phenotypicCategoryName, phenotypicCategoryShortName, phenotypicCategoryId)
        : null;
    store.transcriptId = transcriptsData.transcriptId;
    store.proteinLength = transcriptsData.proteinLength;
    store.domains = domainsDataAdapter(pfamDomainsData, transcriptsData === null || transcriptsData === void 0 ? void 0 : transcriptsData.pfamDomains);
    store.variants = variantsCollection;
    store.filteredVariantsIds = QVariantsService.filterBy(variantsCollection, storeVariantsFilter, consequenceTypesFilter);
    store.ancestry = ancestry;
    return store;
};

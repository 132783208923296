import { isBinaryTypeGuard } from './isBinaryTypeGuard/isBinaryTypeGuard';
/**
 * getValidatedCombinedPlotData
 *
 * @param plotData Array of the gene associations(binary and continuos)
 * @returns Array without associations with falsy oddsRatio
 */
export const getValidatedCombinedPlotData = (plotData) => plotData.reduce((acc, association) => {
    if ((isBinaryTypeGuard(association) && (association === null || association === void 0 ? void 0 : association.oddsRatio))
        || !isBinaryTypeGuard(association)) {
        acc.push(association);
    }
    return acc;
}, []);

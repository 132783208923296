import { ELevelResult, ETraitsTypes } from 'Shared/types';
import { GeneAssociationGLRService } from './GeneAssociationGLR/GeneAssociationGLR.service';
import { GeneAssociationVLRService } from './GeneAssociationVLR/GeneAssociationVLR.service';
/**
 * Class service with static methods for create GeneAssociation objects
 */
export class GeneAssociationFactory {
    /**
     * Get a GeneAssociation object
     *
     * @static
     * @param levelResult - Short view identifier (used ELevelResult)
     * @param traitsType - Type traits (used ETraitsTypes)
     * @param associationData - Response data from API
     * @param gene - Gene for GeneAssociation
     * @param collapsingModel - Collapsing model for GeneAssociation
     * @param id - Custom id for GeneAssociation (uuid v4 is used by default)
     * @returns GeneAssociation object or throws Error
     */
    static create(levelResult, traitsType, associationData, gene, collapsingModel, datasetVersionId, id) {
        if (levelResult === ELevelResult.Gene && traitsType === ETraitsTypes.Binary) {
            return GeneAssociationGLRService.createBinaryAssociation(associationData, gene, collapsingModel, datasetVersionId, id);
        }
        if (levelResult === ELevelResult.Gene && traitsType === ETraitsTypes.Continuous) {
            return GeneAssociationGLRService.createContinuousAssociation(associationData, gene, collapsingModel, datasetVersionId, id);
        }
        if (levelResult === ELevelResult.Variant && traitsType === ETraitsTypes.Binary) {
            return GeneAssociationVLRService.createBinaryAssociation(associationData, gene, collapsingModel, datasetVersionId, id);
        }
        if (levelResult === ELevelResult.Variant && traitsType === ETraitsTypes.Continuous) {
            return GeneAssociationVLRService.createContinuousAssociation(associationData, gene, collapsingModel, datasetVersionId, id);
        }
        throw new Error('Cannot create GeneAssociation object, unsupported level result or traits type');
    }
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { isPlotTooltipValueValid } from '../isPlotTooltipValueValid/isPlotTooltipValueValid';
/**
 * Checks if all the plot tooltip values is a valid values
 *
 * @param value - Values to check (could be several values)
 * @returns boolean value
 */
export const arePlotTooltipValuesValid = (...args) => args.every(isPlotTooltipValueValid);

import { ELevelResult } from 'Shared/types';
import { DatasetsService } from 'Common/services/Datasets';
import { logger } from 'Common/utils/logger/logger';
/**
 * Basic class for SearchSuggestion
 *
 * @abstract
 */
export class SearchSuggestion {
    /**
     * Creates SearchSuggestion instance
     *
     * @param id - Unique id for SearchSuggestion instance
     * @param name - Original SearchSuggestion name
     * @param dataset - Dataset
     * @param type - type of the suggestion
     * @returns SearchSuggestion instance
     */
    constructor(id, name, dataset, type) {
        this.id = id;
        this.name = name;
        this.type = type;
        this.datasetVersionId = dataset.versionId;
        this.levelResult = ELevelResult.Gene;
        try {
            this.datasetProviderName = DatasetsService.getProviderName(dataset.providerId);
        }
        catch ({ message }) {
            logger.warn(`Suggestion "${name}": ${message} - no provider name will be shown`);
        }
        try {
            if (FEATURE_FLAGS.VLR_TAB_ENABLED) {
                this.levelResult = DatasetsService.getProviderDefaultLevelResult(dataset.providerId);
            }
        }
        catch ({ message }) {
            logger.warn(`Suggestion "${name}": ${message} - default route will be used instead`);
        }
    }
}

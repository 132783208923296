import { ETraitsTypes } from 'Shared/types';
import { DEFAULT_EDGE_COORDINATES } from 'FESView/consts';
import { ELoadingStatus } from 'API/types';
export const getInitialState = () => ({
    dataExists: false,
    loadingStatus: ELoadingStatus.Init,
    loadingMessage: '',
    errorType: null,
    phenotype: {
        id: '',
        name: '',
    },
    traitsType: ETraitsTypes.Continuous,
    plotData: [],
    plotSettings: DEFAULT_EDGE_COORDINATES,
    phenotypeStats: {},
    ancestries: [],
    filters: {
        maxPValue: null,
        ancestries: [],
    },
});

import { EXPORT_DATASET_NAMES } from 'Common/consts';
import { logger } from '../logger/logger';
import { EMPTY_DATASET_NAME_WARN, UNKNOWN_DATASET_NAME_WARN } from './consts';
/**
 * getExportDatasetName:
 *
 * @param selectedDatasetName the name of the selected dataset
 * @returns dataset name in the export file name
 */
export const getExportDatasetName = (selectedDatasetName) => {
    if (!selectedDatasetName) {
        logger.warn(EMPTY_DATASET_NAME_WARN);
        return '';
    }
    const exportDatasetName = EXPORT_DATASET_NAMES[selectedDatasetName];
    if (!exportDatasetName) {
        logger.warn(UNKNOWN_DATASET_NAME_WARN);
        return '';
    }
    return exportDatasetName;
};

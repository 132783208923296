import { ETraitsTypes, ELevelResult } from 'Shared/types';
import { VariantService } from 'Common/services/Variant';
import { GeneAssociationService } from '../GeneAssociation/GeneAssociation.service';
/**
 * Class service with static methods for work with GeneAssociationsVLR
 */
export class GeneAssociationVLRService {
    /**
     * Get a GeneBinaryAssociationVLR object
     *
     * @static
     * @param associationData - Response data from API
     * @param gene - Gene for GeneAssociation
     * @param collapsingModel - Collapsing model for GeneAssociation
     * @param id - Custom id for GeneAssociation (uuid v4 is used by default)
     * @returns GeneBinaryAssociationVLR object
     */
    static createBinaryAssociation(associationData, gene, collapsingModel, datasetVersionId, id) {
        const { stats } = associationData;
        return Object.assign(Object.assign(Object.assign({}, GeneAssociationService.createBaseProperties(associationData, gene, collapsingModel, datasetVersionId, id)), GeneAssociationVLRService.addCommonProperties(associationData, ETraitsTypes.Binary)), { cases: stats.no_cases, AACases: stats.no_aa_cases, ABCases: stats.no_ab_cases, BBCases: stats.no_bb_cases, caseMAF: stats.case_maf, caseAAF: stats.case_aaf, ABOrBBCasesPercentage: stats.ab_or_bb_cases, BBCasesPercentage: stats.bb_cases, controls: stats.no_controls, AAControls: stats.no_aa_controls, ABControls: stats.no_ab_controls, BBControls: stats.no_bb_controls, controlMaf: stats.control_maf, controlAaf: stats.control_aaf, ABOrBBControlsPercentage: stats.ab_or_bb_controls, BBControlsPercentage: stats.bb_controls, oddsRatio: stats.odds_ratio, oddsRatioLCI: stats.odds_ratio_lci, oddsRatioUCI: stats.odds_ratio_uci });
    }
    /**
     * Get a GeneContinuousAssociationVLR object
     *
     * @static
     * @param associationData - Response data from API
     * @param gene - Gene for GeneAssociation
     * @param collapsingModel - Collapsing model for GeneAssociation
     * @param id - Custom id for GeneAssociation (uuid v4 is used by default)
     * @returns GeneContinuousAssociationVLR object
     */
    static createContinuousAssociation(associationData, gene, collapsingModel, datasetVersionId, id) {
        const { stats } = associationData;
        return Object.assign(Object.assign(Object.assign({}, GeneAssociationService.createBaseProperties(associationData, gene, collapsingModel, datasetVersionId, id)), GeneAssociationVLRService.addCommonProperties(associationData, ETraitsTypes.Continuous)), { samples: stats.no_samples, noAAGenotypes: stats.no_aa_genotypes, noABGenotypes: stats.no_ab_genotypes, noBBGenotypes: stats.no_bb_genotypes, MAF: stats.maf, AAF: stats.aaf, ABOrBBGenotypes: stats.ab_or_bb_genotypes, BBGenotypes: stats.bb_genotypes, effectSize: stats.effect_size, effectSizeStandardError: stats.effect_size_standard_error, effectSizeLCI: stats.effect_size_lci, effectSizeUCI: stats.effect_size_uci });
    }
    /**
     * Get a common properties for GeneAssociationsVLR
     *
     * @static
     * @private
     * @param associationData - Response data from API
     * @param traitsType - Type trains (Binary or Continuous)
     * @returns - Common properties for GeneAssociationsVLR
     */
    static addCommonProperties(associationData, traitsType) {
        const { stats } = associationData;
        return {
            levelResult: ELevelResult.Variant,
            traitsType,
            variant: VariantService.create(associationData.variant_id, associationData.variant_name, associationData.variant_type),
            transcriptId: stats.transcript_id,
            CDNAChange: stats.cdna_change,
            AAChange: stats.aa_change,
            exonRank: stats.exon_rank,
            consequenceType: stats.consequence_type,
        };
    }
}

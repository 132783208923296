import { HgmdClass } from 'GeneDashboardView/components/ExternalVariantSources/components/TabContent/tabs/HGMD/table/types';
export const externalVariantSourcesStatsResponseMock = {
    clin_var: {
        total: 41,
        confident: 0,
    },
    ebi_gwas: {
        total: 6,
        confident: 0,
    },
    gbmi: {
        total: 5,
        confident: 0,
    },
    hgmd: {
        total: 110,
        confident: 0,
    },
    omim: {
        total: 5,
    },
};
export const externalVariantSourcesStoreMock = {
    count: {
        clinVar: {
            total: 41,
            confident: 0,
        },
        ebiGwas: {
            total: 6,
            confident: 0,
        },
        gbmi: {
            total: 5,
            confident: 0,
        },
        hgmd: {
            total: 110,
            confident: 0,
        },
        omim: {
            total: 5,
        },
    },
};
export const omimResponseDataMock = {
    data: [
        {
            phenotype: 'Union#BlockZ00-Z13#Z00-Z13 Persons encountering health services for examination and investigation',
            mim_number: '604121',
            inheritance: 'AD',
            gene_mim_number: '120120',
        },
        {
            phenotype: 'Union#BlockZ00-Z13#Z00-Z13 Persons encountering health services for examination and investigation',
            mim_number: '604121',
            inheritance: 'AD',
            gene_mim_number: '120120',
        },
    ],
};
export const gbmiResponseDataMock = {
    data: [
        {
            hg38_variant: '3-195790253-G-A',
            rsid: 'rs147678978',
            phenotype: 'Chronic obstructive pulmonary disease (COPD)',
            inv_var_meta_p: '6.1111e-8',
            inv_var_meta_beta: '0.56',
            pubmed: ['30595370', '30595371', '30595372'],
            consequence: 'Text string',
        },
        {
            hg38_variant: '3-195790253-G-A',
            rsid: 'rs147678978',
            phenotype: 'Chronic obstructive pulmonary disease (COPD)',
            inv_var_meta_p: '3.1111e-8',
            inv_var_meta_beta: '0.56',
            pubmed: ['30595370', '30595371', '30595372'],
            consequence: 'Text string',
        },
        {
            hg38_variant: '3-195790253-G-A',
            rsid: 'rs147678978',
            phenotype: 'Chronic obstructive pulmonary disease (COPD)',
            inv_var_meta_p: '8.1111e-8',
            inv_var_meta_beta: '0.56',
            pubmed: ['30595370', '30595371', '30595372'],
            consequence: 'Text string',
        },
    ],
};
const baseHgmdData = {
    disease: 'Neurological disorder',
    hgvs_cdna: 'NM_001130823.3:c.1640T>A',
    hgvs_protein: 'NP_001124295.1:p.I547N',
    journal: 'BRAIN',
    year: 2015,
    pmid: 25678562,
};
export const hgmdResponseDataMock = {
    data: [
        Object.assign(Object.assign({}, baseHgmdData), { hg38_variant: '19-10154909-A-T', class: HgmdClass.DFP }),
        Object.assign(Object.assign({}, baseHgmdData), { hg38_variant: 'X-10154909-A-T', class: HgmdClass.DM }),
        Object.assign(Object.assign({}, baseHgmdData), { hg38_variant: '18-10154909-A-T', class: HgmdClass.DM }),
        Object.assign(Object.assign({}, baseHgmdData), { hg38_variant: '9-10154909-A-T', class: HgmdClass.DP }),
        Object.assign(Object.assign({}, baseHgmdData), { hg38_variant: '19-10154909-A-T', class: HgmdClass.LikelyDM }),
        Object.assign(Object.assign({}, baseHgmdData), { hg38_variant: '19-10154909-A-T', class: HgmdClass.FP }),
        Object.assign(Object.assign({}, baseHgmdData), { hg38_variant: '9-10154909-A-T', class: HgmdClass.DFP }),
        Object.assign(Object.assign({}, baseHgmdData), { hg38_variant: '19-10154909-A-T', class: HgmdClass.DM }),
        Object.assign(Object.assign({}, baseHgmdData), { hg38_variant: '3-10154909-A-T', class: HgmdClass.FP }),
        Object.assign(Object.assign({}, baseHgmdData), { hg38_variant: '16-10154909-A-T', class: HgmdClass.DP }),
        Object.assign(Object.assign({}, baseHgmdData), { hg38_variant: '18-10154909-A-T', class: HgmdClass.DFP }),
        Object.assign(Object.assign({}, baseHgmdData), { hg38_variant: '18-10154909-A-T', class: HgmdClass.DP }),
        Object.assign(Object.assign({}, baseHgmdData), { hg38_variant: '1-10154909-A-T', class: HgmdClass.FP }),
        Object.assign(Object.assign({}, baseHgmdData), { hg38_variant: '19-10154909-A-T', class: HgmdClass.R }),
        Object.assign(Object.assign({}, baseHgmdData), { hg38_variant: '5-10154909-A-T', class: HgmdClass.R }),
        Object.assign(Object.assign({}, baseHgmdData), { hg38_variant: '9-10154909-A-T', class: HgmdClass.LikelyDM }),
        Object.assign(Object.assign({}, baseHgmdData), { hg38_variant: '1-10154909-A-T', class: HgmdClass.DFP }),
        Object.assign(Object.assign({}, baseHgmdData), { hg38_variant: '19-10154909-A-T', class: HgmdClass.DP }),
        Object.assign(Object.assign({}, baseHgmdData), { hg38_variant: '18-10154909-A-T', class: HgmdClass.FP }),
        Object.assign(Object.assign({}, baseHgmdData), { hg38_variant: '18-10154909-A-T', class: HgmdClass.R }),
        Object.assign(Object.assign({}, baseHgmdData), { hg38_variant: '12-10154909-A-T', class: HgmdClass.LikelyDM }),
        Object.assign(Object.assign({}, baseHgmdData), { hg38_variant: '1-10154909-A-T', class: HgmdClass.R }),
    ],
};
export const snpChrPosUncommonValuesMock = [
    '1 x 1:11111111 x 11111111',
    '*A 11:01-?',
    '15q25.1-?',
    '1kg_7_37384979-?',
    '2-62564875-G',
    '6p21.32-?',
    'A*01:01-?',
    'AA_DPB1_205-?',
    'Affx-19716376-C',
    'B*07:02-?',
    'c14.20107162.b37p0-?',
    'Chr:1:107661894-?',
    'rs1000005-C',
    'XX:146986184:A_AAA-?',
    null,
    undefined,
];
const ebiGwasDataWithUncommonSnpChrPos = snpChrPosUncommonValuesMock.map((value) => ({
    pubmed_id: 30595370,
    mapped_trait: 'body height',
    disease_trait: 'Height',
    reported_gene: 'DNMT1',
    mapped_gene: 'DNMT1',
    strongest_snp_risk_allele_rsid: 'rs10409243-T',
    strongest_snp_risk_allele_position: value,
    context: '19-10154909-A-T',
    p_value: 4.11111e-8,
    or_or_beta: '1.12',
    is_beta: false,
}));
export const ebiGwasResponseDataMock = {
    data: [
        ...ebiGwasDataWithUncommonSnpChrPos,
        {
            pubmed_id: 30595370,
            mapped_trait: 'body height',
            disease_trait: 'Height',
            reported_gene: 'DNMT1',
            mapped_gene: 'DNMT1',
            strongest_snp_risk_allele_rsid: 'rs10409243-T',
            strongest_snp_risk_allele_position: '1:11111111',
            context: '19-10154909-A-T',
            p_value: 4.11111e-8,
            or_or_beta: '1.12',
            is_beta: false,
        },
        {
            pubmed_id: 30595370,
            mapped_trait: 'body height',
            disease_trait: 'Height',
            reported_gene: 'DNMT1',
            mapped_gene: 'DNMT1',
            strongest_snp_risk_allele_rsid: 'rs10409243-T',
            strongest_snp_risk_allele_position: 'rs78333947-?',
            context: '19-10154909-A-T',
            p_value: 4.11111e-8,
            or_or_beta: undefined,
            is_beta: false,
        },
        {
            pubmed_id: 30595370,
            mapped_trait: 'body height',
            disease_trait: 'Height',
            reported_gene: 'DNMT1',
            mapped_gene: 'DNMT1',
            strongest_snp_risk_allele_rsid: 'rs10409243-T',
            strongest_snp_risk_allele_position: 'chr1:116901670-?',
            context: '19-10154909-A-T',
            p_value: 2.11111e-8,
            or_or_beta: '0.457453741',
            is_beta: true,
        },
        {
            pubmed_id: 30595370,
            mapped_trait: 'body height',
            disease_trait: 'Height',
            reported_gene: 'DNMT1',
            mapped_gene: 'DNMT1',
            strongest_snp_risk_allele_rsid: 'rs10409243-T',
            strongest_snp_risk_allele_position: 'chr15:37290970-T',
            context: '19-10154909-A-T',
            p_value: 2.11111e-8,
            or_or_beta: '0.457453741',
            is_beta: true,
        },
        {
            pubmed_id: 30595370,
            mapped_trait: 'body height',
            disease_trait: 'Height',
            reported_gene: 'DNMT1',
            mapped_gene: 'DNMT1',
            strongest_snp_risk_allele_rsid: 'rs10409243-T',
            strongest_snp_risk_allele_position: '2:11111111',
            context: '19-10154909-A-T',
            p_value: 2.11111e-8,
            or_or_beta: '0.457453741',
            is_beta: true,
        },
        {
            pubmed_id: 30595370,
            mapped_trait: 'body height',
            disease_trait: 'Height',
            reported_gene: 'DNMT1',
            mapped_gene: 'DNMT1',
            strongest_snp_risk_allele_rsid: 'rs10409243-T',
            strongest_snp_risk_allele_position: 'rs78333947',
            context: '19-10154909-A-T',
            p_value: 4.11111e-8,
            or_or_beta: null,
            is_beta: false,
        },
        {
            pubmed_id: 30595370,
            mapped_trait: 'body height',
            disease_trait: 'Height',
            reported_gene: 'DNMT1',
            mapped_gene: 'DNMT1',
            strongest_snp_risk_allele_rsid: 'rs10409243-T',
            strongest_snp_risk_allele_position: 'X:11111111',
            context: '19-10154909-A-T',
            p_value: 4.11111e-8,
            or_or_beta: '1.12',
            is_beta: false,
        },
        {
            pubmed_id: 30595370,
            mapped_trait: 'body height',
            disease_trait: 'Height',
            reported_gene: 'DNMT1',
            mapped_gene: 'DNMT1',
            strongest_snp_risk_allele_rsid: 'rs10409243-T',
            strongest_snp_risk_allele_position: 'CHRXY:11-69-01-670',
            context: '19-10154909-A-T',
            p_value: 2.11111e-8,
            or_or_beta: '0.457453741',
            is_beta: true,
        },
        {
            pubmed_id: 30595370,
            mapped_trait: 'body height',
            disease_trait: 'Height',
            reported_gene: 'DNMT1',
            mapped_gene: 'DNMT1',
            strongest_snp_risk_allele_rsid: 'rs10409243-T',
            strongest_snp_risk_allele_position: 'chrX:116901670',
            context: '19-10154909-A-T',
            p_value: 2.11111e-8,
            or_or_beta: '0.457453741',
            is_beta: true,
        },
        {
            pubmed_id: 30595370,
            mapped_trait: 'body height',
            disease_trait: 'Height',
            reported_gene: 'DNMT1',
            mapped_gene: 'DNMT1',
            strongest_snp_risk_allele_rsid: 'rs10409243-T',
            strongest_snp_risk_allele_position: 'rs78333947',
            context: '19-10154909-A-T',
            p_value: 5.11111e-8,
            or_or_beta: '0.1231231',
            is_beta: true,
        },
        {
            pubmed_id: 30595370,
            mapped_trait: 'body height',
            disease_trait: 'Height',
            reported_gene: 'DNMT1',
            mapped_gene: 'DNMT1',
            strongest_snp_risk_allele_rsid: 'rs10409243-T',
            strongest_snp_risk_allele_position: '1:22222222',
            context: '19-10154909-A-T',
            p_value: 4.11111e-8,
            or_or_beta: '',
            is_beta: false,
        },
        {
            pubmed_id: 30595370,
            mapped_trait: 'body height',
            disease_trait: 'Height',
            reported_gene: 'DNMT1',
            mapped_gene: 'DNMT1',
            strongest_snp_risk_allele_rsid: 'rs10409243-T',
            strongest_snp_risk_allele_position: 'Y:116901670',
            context: '19-10154909-A-T',
            p_value: 2.11111e-8,
            or_or_beta: '0.457453741',
            is_beta: true,
        },
        {
            pubmed_id: 30595370,
            mapped_trait: 'body height',
            disease_trait: 'Height',
            reported_gene: 'DNMT1',
            mapped_gene: 'DNMT1',
            strongest_snp_risk_allele_rsid: 'rs10409243-T',
            strongest_snp_risk_allele_position: '5:332523423',
            context: '19-10154909-A-T',
            p_value: 4.11111e-8,
            or_or_beta: '0.234765867',
            is_beta: false,
        },
        {
            pubmed_id: 30595370,
            mapped_trait: 'body height',
            disease_trait: 'Height',
            reported_gene: 'DNMT1',
            mapped_gene: 'DNMT1',
            strongest_snp_risk_allele_rsid: 'rs10409243-T',
            strongest_snp_risk_allele_position: 'ChrY:11-69-01-670',
            context: '19-10154909-A-T',
            p_value: 2.11111e-8,
            or_or_beta: '0.457453741',
            is_beta: true,
        },
        {
            pubmed_id: 30595370,
            mapped_trait: 'body height',
            disease_trait: 'Height',
            reported_gene: 'DNMT1',
            mapped_gene: 'DNMT1',
            strongest_snp_risk_allele_rsid: 'rs10409243-T',
            strongest_snp_risk_allele_position: '22:7777777',
            context: '19-10154909-A-T',
            p_value: 4.11111e-8,
            or_or_beta: '0.234765867',
            is_beta: false,
        },
        {
            pubmed_id: 30595370,
            mapped_trait: 'body height',
            disease_trait: 'Height',
            reported_gene: 'DNMT1',
            mapped_gene: 'DNMT1',
            strongest_snp_risk_allele_rsid: 'rs10409243-T',
            strongest_snp_risk_allele_position: '7:1231235',
            context: '19-10154909-A-T',
            p_value: 4.11111e-8,
            or_or_beta: '0.234765867',
            is_beta: false,
        },
        {
            pubmed_id: 30595370,
            mapped_trait: 'body height',
            disease_trait: 'Height',
            reported_gene: 'DNMT1',
            mapped_gene: 'DNMT1',
            strongest_snp_risk_allele_rsid: 'rs10409243-T',
            strongest_snp_risk_allele_position: '2:22222222',
            context: '19-10154909-A-T',
            p_value: 4.11111e-8,
            or_or_beta: '0.234765867',
            is_beta: false,
        },
        {
            pubmed_id: 30595370,
            mapped_trait: 'body height',
            disease_trait: 'Height',
            reported_gene: 'DNMT1',
            mapped_gene: 'DNMT1',
            strongest_snp_risk_allele_rsid: 'rs10409243-T',
            strongest_snp_risk_allele_position: 'XY:116901670',
            context: '19-10154909-A-T',
            p_value: 2.11111e-8,
            or_or_beta: '0.457453741',
            is_beta: true,
        },
    ],
};
export const clinVarResponseDataMock = {
    data: [
        {
            allele_id: 65578,
            type: 'single nucleotide variant',
            name: 'NM_001130823.3(DNMT1):c.1816G>T (p.Val606Phe)',
            clinical_significance: 'Pathogenic',
            phenotypes: [
                'Hyperinsulinemic hypoglycemia, familial, 1',
                'Familial hyperinsulinism',
                'Diabetes mellitus, transient neonatal, 2',
                'Permanent neonatal diabetes mellitus',
                'Hereditary hyperinsulinism',
                'not provided',
                'ABCC8-related disorders',
                'Hyperinsulinemic hypoglycemia, familial, 1',
                'Familial hyperinsulinism',
                'Diabetes mellitus, transient neonatal, 2',
                'Permanent neonatal diabetes mellitus',
                'Hereditary hyperinsulinism',
                'not provided',
                'ABCC8-related disorders',
            ],
            origin: 'germline',
        },
        {
            allele_id: 65578,
            type: 'single nucleotide variant',
            name: 'NM_001130823.3(DNMT1):c.1816G>T (p.Val606Phe)',
            clinical_significance: 'Pathogenic',
            phenotypes: [
                'Interstitial lung disease due to ABCA3 deficiency',
                'not specified',
                'not provided',
            ],
            origin: 'germline',
        },
        {
            allele_id: 65578,
            type: 'single nucleotide variant',
            name: 'NM_001130823.3(DNMT1):c.1816G>T (p.Val606Phe)',
            clinical_significance: 'Pathogenic',
            phenotypes: [
                'Interstitial lung disease due to ABCA3 deficiency',
                'not specified',
                'not provided',
            ],
            origin: 'germline',
        },
        {
            allele_id: 65578,
            type: 'single nucleotide variant',
            name: 'NM_001130823.3(DNMT1):c.1816G>T (p.Val606Phe)',
            clinical_significance: 'Pathogenic',
            phenotypes: [
                'Interstitial lung disease due to ABCA3 deficiency',
                'not specified',
                'not provided',
            ],
            origin: 'germline',
        },
        {
            allele_id: 65578,
            type: 'single nucleotide variant',
            name: 'NM_001130823.3(DNMT1):c.1816G>T (p.Val606Phe)',
            clinical_significance: 'Pathogenic',
            phenotypes: [
                'Interstitial lung disease due to ABCA3 deficiency',
                'not specified',
                'not provided',
            ],
            origin: 'germline',
        },
        {
            allele_id: 65578,
            type: 'single nucleotide variant',
            name: 'NM_001130823.3(DNMT1):c.1816G>T (p.Val606Phe)',
            clinical_significance: 'Pathogenic',
            phenotypes: [
                'Interstitial lung disease due to ABCA3 deficiency',
                'not specified',
                'not provided',
            ],
            origin: 'germline',
        },
        {
            allele_id: 65578,
            type: 'single nucleotide variant',
            name: 'NM_001130823.3(DNMT1):c.1816G>T (p.Val606Phe)',
            clinical_significance: 'Pathogenic',
            phenotypes: [
                'Interstitial lung disease due to ABCA3 deficiency',
                'not specified',
                'not provided',
            ],
            origin: 'germline',
        },
        {
            allele_id: 65578,
            type: 'single nucleotide variant',
            name: 'NM_001130823.3(DNMT1):c.1816G>T (p.Val606Phe)',
            clinical_significance: 'Pathogenic',
            phenotypes: [
                'Interstitial lung disease due to ABCA3 deficiency',
                'not specified',
                'not provided',
            ],
            origin: 'germline',
        },
        {
            allele_id: 65578,
            type: 'single nucleotide variant',
            name: 'NM_001130823.3(DNMT1):c.1816G>T (p.Val606Phe)',
            clinical_significance: 'Pathogenic',
            phenotypes: [
                'Interstitial lung disease due to ABCA3 deficiency',
                'not specified',
                'not provided',
            ],
            origin: 'germline',
        },
        {
            allele_id: 65578,
            type: 'single nucleotide variant',
            name: 'NM_001130823.3(DNMT1):c.1816G>T (p.Val606Phe)',
            clinical_significance: 'Pathogenic',
            phenotypes: [
                'Interstitial lung disease due to ABCA3 deficiency',
                'not specified',
                'not provided',
            ],
            origin: 'germline',
        },
        {
            allele_id: 65578,
            type: 'single nucleotide variant',
            name: 'NM_001130823.3(DNMT1):c.1816G>T (p.Val606Phe)',
            clinical_significance: 'Pathogenic',
            phenotypes: [
                'Interstitial lung disease due to ABCA3 deficiency',
                'not specified',
                'not provided',
            ],
            origin: 'germline',
        },
        {
            allele_id: 65578,
            type: 'single nucleotide variant',
            name: 'NM_001130823.3(DNMT1):c.1816G>T (p.Val606Phe)',
            clinical_significance: 'Pathogenic',
            phenotypes: [
                'Interstitial lung disease due to ABCA3 deficiency',
                'not specified',
                'not provided',
            ],
            origin: 'germline',
        },
    ],
};

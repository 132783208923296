import { ELevelResult, ETraitsTypes } from 'Shared/types';
import { NO_DATA_CHAR } from 'Common/consts';
/**
 * Get effectSize, beta or oddsRatio with the dependency of traits types and level result
 *
 * @param data - Object: TSignificantAssociations
 * @returns Number: effectSize, beta or oddsRatio
 * with the dependency of traits types and level result. Or string: NO_DATA_CHAR - '-';
 */
export const getEffectSize = ({ traitsType, levelResult, effectSize, oddsRatio, beta, }) => {
    if (traitsType === ETraitsTypes.Binary && oddsRatio) {
        return oddsRatio;
    }
    if (traitsType === ETraitsTypes.Continuous
        && levelResult === ELevelResult.Gene && beta) {
        return beta;
    }
    if (traitsType === ETraitsTypes.Continuous
        && levelResult === ELevelResult.Variant && effectSize) {
        return effectSize;
    }
    return NO_DATA_CHAR;
};

import { ELevelResult, } from 'Shared/types';
import { DEFAULT_API_SERVICE_URI } from 'API/consts';
import { HTTPClientError } from 'API/errors';
import { RESTResource } from '../RESTResource';
const geneResourceSearchParams = {
    collapsingModelId: 'collapsing_model_ids',
    minPValue: 'min_pvalue',
    maxPValue: 'max_pvalue',
};
const errorHandler = (err) => {
    if (!(err instanceof HTTPClientError && err.status === 404 /* NotFound */)) {
        throw err;
    }
    return null;
};
/* eslint-disable @typescript-eslint/indent */
export const geneResourceGLR = new RESTResource((urlParams) => {
    var _a;
    const urlRoot = (_a = process.env.GLR_SERVICE_URI) !== null && _a !== void 0 ? _a : DEFAULT_API_SERVICE_URI;
    const { datasetVersionId, geneName, traitsType, } = urlParams;
    return `${urlRoot}/v1/${datasetVersionId}/gene/${geneName}/associations/${traitsType}`;
}, 'get', geneResourceSearchParams, errorHandler);
export const geneResourceVLR = new RESTResource((urlParams) => {
    var _a;
    const urlRoot = (_a = process.env.VLR_SERVICE_URI) !== null && _a !== void 0 ? _a : DEFAULT_API_SERVICE_URI;
    const { datasetVersionId, geneName, traitsType, } = urlParams;
    return `${urlRoot}/v1/${datasetVersionId}/gene/${geneName}/associations/${traitsType}`;
}, 'get', geneResourceSearchParams, errorHandler);
export default (levelResult) => {
    switch (levelResult) {
        case ELevelResult.Gene:
            return geneResourceGLR;
        case ELevelResult.Variant:
            return geneResourceVLR;
        default:
            throw new Error(`Unknown level result type provided for fetching phenotype data: ${levelResult}`);
    }
};

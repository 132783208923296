import { emptyResponse } from 'VariantView/store/consts';
/**
 * Get non empty variant associations
 * @param variantVLRResponse object with variant data
 * @returns an object with key: collapsingModel and value: variantAssociations
 */
export const getNonEmptyAssociations = (variantData) => {
    const { associations } = variantData !== null && variantData !== void 0 ? variantData : emptyResponse;
    return Object.keys(associations || emptyResponse).reduce((acc, curr) => {
        if (associations[curr].length) {
            acc[curr] = associations[curr];
        }
        return acc;
    }, {});
};

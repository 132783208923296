import { DASHBOARD_PVALUE_THRESHOLD_TEXT } from 'Common/consts';
import { ELevelResult } from 'Shared/types';
export const GENE_TOLERANCE_ERROR_MESSAGE_TEXT = 'Tolerance scores are not available for this gene';
export const TABLE_SECTION_ERROR_MESSAGE_TEXT = `There are no associations with p ≤ ${DASHBOARD_PVALUE_THRESHOLD_TEXT} for this gene`;
export const PLOT_SECTION_ERROR_MESSAGE_TEXT = `There are no associations with p ≤ ${DASHBOARD_PVALUE_THRESHOLD_TEXT} for this gene`;
export const initialLevelResultsCollection = {
    [ELevelResult.Gene]: true,
    [ELevelResult.Variant]: false,
};
export const levelResultNames = {
    [ELevelResult.Gene]: 'gene',
    [ELevelResult.Variant]: 'variant',
};
export const TITLE_CRV = 'Clinically relevant variants';
export const TITLE_PAV = 'Publicly available common variant associations';
export const TITLE_OMIM = 'OMIM';
export const TOOLTIP_OMIM = 'OMIM reported gene-phenotype relationships';
export const ZERO_COUNT_TOOLTIP_OMIM = 'No gene-phenotype relationships reported in OMIM for this gene';
export const TITLE_HGMD = 'HGMD';
export const TOOLTIP_HGMD = 'HGMD pathogenicity catalog';
export const ZERO_COUNT_TOOLTIP_HGMD = 'No gene-phenotype relationships reported in HGMD for this gene';
export const TITLE_CLINVAR = 'ClinVar';
export const TOOLTIP_CLINVAR = 'ClinVar pathogenicity catalog';
export const ZERO_COUNT_TOOLTIP_CLINVAR = 'No gene-phenotype relationships reported in ClinVar for this gene';
export const TITLE_EBIGWAS = 'NHGRI-EBI GWAS Catalog';
export const TOOLTIP_EBIGWAS = 'NHGRI-EBI Catalog of human genome-wide association studies';
export const ZERO_COUNT_TOOLTIP_EBIGWAS = 'No gene-phenotype relationships reported in NHGRI-EBI GWAS Catalog for this gene';
export const TITLE_GBMI = 'GBMI';
export const TOOLTIP_GBMI = 'Global Biobank Meta-Analysis Initiative results';
export const ZERO_COUNT_TOOLTIP_GBMI = 'Global Biobank Meta-Analysis Initiative results are not available for this gene';
export const FetchErrorMessages = {
    OMIM: 'Loading OMIM data failed, please try again later',
    HGMD: 'Loading HGMD data failed, please try again later',
    ClinVar: 'Loading ClinVar data failed, please try again later',
    'NHGRI-EBI GWAS Catalog': 'Loading NHGRI-EBI Gwas Catalog data failed, please try again later',
    GBMI: 'Loading GBMI data failed, please try again later',
};
export const LOADING_POSTFIX = '_LOADING';
export const ERROR_POSTFIX = '_ERROR';

import { DEFAULT_API_SERVICE_URI } from 'API/consts';
import { HTTPClientError } from 'API/errors';
import { RESTResource } from '../RESTResource';
const errorHandler = (err) => {
    if (!(err instanceof HTTPClientError && err.status === 404 /* NotFound */)) {
        throw err;
    }
    return null;
};
export default new RESTResource(({ phenotypeNameBase64 }) => {
    var _a;
    const urlRoot = (_a = process.env.PHENOTYPES_SERVICE_URI) !== null && _a !== void 0 ? _a : DEFAULT_API_SERVICE_URI;
    return `${urlRoot}/v1/phenotypes/${phenotypeNameBase64}`;
}, 'get', undefined, errorHandler);

import { ChromosomeService } from './Chromosome';
/**
 * Class service with static methods for work with Chromosomes collection
 *
 */
export class ChromosomesService {
    /**
     * Gets empty Chromosomes object
     *
     * @static
     * @returns Empty Chromosomes object
     */
    static get empty() {
        return {
            chromosomesCollection: {},
            genesCollection: {},
        };
    }
    /**
     * Returns true if chromosome collection is empty
     *
     * @param collection Chromosome collection
     * @returns boolean (is empty collection)
     */
    static isEmpty(collection) {
        return (Object.keys(collection.chromosomesCollection).length === 0
            && Object.keys(collection.genesCollection).length === 0);
    }
    /**
     * Creates Chromosomes object from API
     *
     * @static
     * @param rawData - Response data from API
     * @returns Chromosomes object
     */
    static fromResponse(rawData) {
        const genesCollection = {};
        const chromosomesCollection = rawData.chromosomes.reduce((chromosomesAcc, chromosomeResponse) => {
            const chromosome = ChromosomeService.fromResponse(chromosomeResponse);
            // For performance optimization
            chromosomeResponse.genes.forEach(({ name: geneName, location }) => {
                genesCollection[geneName] = {
                    location,
                    chromosomeId: chromosome.number,
                };
            });
            return Object.assign(Object.assign({}, chromosomesAcc), { [chromosome.number]: chromosome });
        }, {});
        return { chromosomesCollection, genesCollection };
    }
    /**
     * Returns Chromosome object by its id
     * If there are no object with such id, `null` is returned instead
     *
     * @static
     * @param collection - Chromosomes object
     * @param id - Chromosome id
     * @returns Chromosome object or `null` if not found
     */
    static getChromosome(collection, id) {
        var _a;
        return (_a = collection.chromosomesCollection[id]) !== null && _a !== void 0 ? _a : null;
    }
    /**
     * Returns id of chromosome which gene relates to by gene's name
     * If there are no chromosome associated with a gene, `null` is returned instead
     *
     * @static
     * @param collection - Chromosomes object
     * @param geneName - Name of gene
     * @returns Chromosome's id or `null` if not found
     */
    static getChromosomeId(collection, geneName) {
        var _a, _b;
        return (_b = (_a = collection.genesCollection[geneName]) === null || _a === void 0 ? void 0 : _a.chromosomeId) !== null && _b !== void 0 ? _b : null;
    }
    /**
     * Gene location in chromosome (relative to chromosome's length)
     *
     * @static
     * @param collection - Chromosomes object
     * @param geneName - Name of gene
     * @returns Position in chromosome
     */
    static getGenePosition(collection, geneName) {
        var _a, _b;
        return (_b = (_a = collection.genesCollection[geneName]) === null || _a === void 0 ? void 0 : _a.location) !== null && _b !== void 0 ? _b : null;
    }
}

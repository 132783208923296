import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
function useWorker(createWorker, initAction, terminateAction, updateAction) {
    const dispatch = useDispatch();
    const worker = useMemo(() => (createWorker()), [createWorker]);
    useEffect(() => {
        worker.onmessage = (event) => {
            if (event.data.isReady) {
                dispatch(initAction(worker));
            }
            else {
                dispatch(updateAction(event.data));
            }
        };
        worker.onerror = () => {
            dispatch(terminateAction());
            worker.terminate();
        };
        return () => {
            dispatch(terminateAction());
            worker.terminate();
        };
    }, [dispatch, worker, initAction, updateAction, terminateAction]);
}
export default useWorker;

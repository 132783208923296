import { useMemo, useCallback } from 'react';
import { CURRENT_APP_VERSION } from 'Common/utils';
import { useLocalStorage, useLRUCache } from 'Shared/hooks';
import { convertToRecentSuggestionsCollection } from 'Search/utils';
import { APP_VERSION_DEFAULT_VALUE, APP_VERSION_LOCAL_STORAGE_KEY, RECENT_SEARCH_LS_KEY, RECENT_SEARCH_LIMIT, RECENT_SUGGESTIONS_DEFAULT_VALUE, } from 'Search/consts';
/**
 * Hook implements recent suggestions API
 *
 * @param [localStorageUsage = true] - Use or not localStorage for storing recent suggestions
 * @param [localStorageKey = RECENT_SEARCH_LS_KEY] - Key for storing suggestions in localStorage
 * @returns Public API for RecentSuggestions hook
 *  - Recent suggestions collection
 *  - Method which adds a new recent suggestion
 *  - Method which removes recent suggestion
 */
export function useRecentSuggestions(localStorageUsage = true, localStorageKey = RECENT_SEARCH_LS_KEY) {
    const [recentSuggestionsLS, setRecentSuggestionsLS] = useLocalStorage(localStorageKey, RECENT_SUGGESTIONS_DEFAULT_VALUE, !localStorageUsage);
    const [appVersion, setAppVersion] = useLocalStorage(APP_VERSION_LOCAL_STORAGE_KEY, APP_VERSION_DEFAULT_VALUE, !localStorageUsage);
    const shouldResetSuggestions = CURRENT_APP_VERSION !== appVersion;
    if (shouldResetSuggestions) {
        setAppVersion(CURRENT_APP_VERSION !== null && CURRENT_APP_VERSION !== void 0 ? CURRENT_APP_VERSION : '');
        setRecentSuggestionsLS(RECENT_SUGGESTIONS_DEFAULT_VALUE);
    }
    /**
     * Factory for creating predicate for `Array.prototype.findIndex` method
     *
     * @private
     *
     * @param findItem - Item which will be compared with
     * @returns Predicate for find index method
     */
    const findIndexPredicateFactory = useCallback((findItem) => (item) => (item.type === findItem.type
        && item.name === findItem.name
        && item.datasetProviderName === findItem.datasetProviderName), []);
    const [recentSuggestionsItems, addRecentSuggestion, removeRecentSuggestion,] = useLRUCache(shouldResetSuggestions ? RECENT_SUGGESTIONS_DEFAULT_VALUE : recentSuggestionsLS, RECENT_SEARCH_LIMIT, findIndexPredicateFactory, setRecentSuggestionsLS);
    const recentSuggestions = useMemo(() => convertToRecentSuggestionsCollection(recentSuggestionsItems), [recentSuggestionsItems]);
    return {
        recentSuggestions,
        addRecentSuggestion,
        removeRecentSuggestion,
    };
}

import { EConsequenceType } from 'Shared/plots/types';
import { createMockedURL, getMock } from 'API/utils';
import { GenesResponseDatasetVersionIdMock, UKBDatasetVersionId } from 'API/resources/datasets';
import { geneNameMock } from 'Common/mocks';
import resource from './resource';
export const geneStatsResponseMock = {
    consequence_types: [
        EConsequenceType.StopGained,
        EConsequenceType.BidirectionalGeneFusion,
        EConsequenceType.ProteinAlteringVariant,
        EConsequenceType.MissenseVariant,
    ],
};
export const DefaultRequestParams = {
    datasetVersionId: GenesResponseDatasetVersionIdMock,
    geneId: geneNameMock,
};
export const UKBRequestParams = {
    datasetVersionId: UKBDatasetVersionId,
    geneId: geneNameMock,
};
const mockedURL = {
    [createMockedURL(resource, DefaultRequestParams)]: {
        full: geneStatsResponseMock,
        empty: { consequence_types: [] },
    },
    [createMockedURL(resource, UKBRequestParams)]: {
        full: geneStatsResponseMock,
        empty: { consequence_types: [] },
    },
};
export const fixtures = getMock(mockedURL);

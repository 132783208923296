import { v4 as uuidv4 } from 'uuid';
import { ETraitsTypes } from 'Shared/types';
import { PhenotypeService } from 'Common/services/Phenotype';
import { PhenotypicCategoryService } from 'Common/services/PhenotypicCategory';
/**
 * Class service with static methods for work with VariantAssociations
 */
export class VariantAssociationService {
    /**
     * Gets an boolean value which identify does variant association have binary type
     * @static
     * @param variantAssociation - variant associations data
     * @returns boolean value
     */
    static isBinary(variantAssociation) {
        return variantAssociation.traitsType === ETraitsTypes.Binary;
    }
    /**
     * Gets an boolean value which identify does variant association have continuous type
     * @static
     * @param variantAssociation - variant associations data
     * @returns boolean value
     */
    static isContinuous(variantAssociation) {
        return variantAssociation.traitsType === ETraitsTypes.Continuous;
    }
    /**
     * Gets an VariantAssociation object
     * @static
     * @param association - Variant associations data
     * @param collapsingModel - CollapsingModel object
     * @param metadata - The variant metadata object
     * @param id - The id of the variantAssociations
     * @returns Variant object
     */
    static create(association, collapsingModel, metadata, datasetVersionId, id = uuidv4()) {
        if (metadata.traitsType === ETraitsTypes.Binary) {
            return VariantAssociationService.createBinary(association, collapsingModel, metadata, datasetVersionId, id);
        }
        return VariantAssociationService.createContinuous(association, collapsingModel, metadata, datasetVersionId, id);
    }
    /**
     * Gets a binary VariantAssociation object
     * @static
     * @param response - Variant associations data
     * @param collapsingModel - CollapsingModel object
     * @param metadata - The variant metadata object
     * @param id - The id of the variantAssociations
     * @returns Binary Variant object
     */
    static createBinary(response, collapsingModel, { gene, variant, }, datasetVersionId = '', id = uuidv4()) {
        const pvalue = parseFloat(response.pvalue);
        return {
            id,
            traitsType: ETraitsTypes.Binary,
            phenotype: PhenotypeService.create(response.phenotype_name, response.phenotype_id),
            phenotypicCategory: PhenotypicCategoryService.create(response.category_name, response.category_short_name, response.category_id),
            variant,
            datasetVersionId,
            gene,
            ancestry: response.ancestry,
            collapsingModel,
            pvalue,
            pvalueLog: -Math.log10(pvalue),
            geneName: response.stats.gene_name,
            consequenceType: response.stats.consequence_type,
            transcriptId: response.stats.transcript_id,
            CDNAChange: response.stats.cdna_change,
            AAChange: response.stats.aa_change,
            exonRank: response.stats.exon_rank,
            cases: response.stats.no_cases,
            AACases: response.stats.no_aa_cases,
            ABCases: response.stats.no_ab_cases,
            BBCases: response.stats.no_bb_cases,
            caseMAF: response.stats.case_maf,
            caseAAF: response.stats.case_aaf,
            ABOrBBCasesPercentage: response.stats.ab_or_bb_cases,
            BBCasesPercentage: response.stats.bb_cases,
            controls: response.stats.no_controls,
            AAControls: response.stats.no_aa_controls,
            ABControls: response.stats.no_ab_controls,
            BBControls: response.stats.no_bb_controls,
            controlMaf: response.stats.control_maf,
            controlAaf: response.stats.control_aaf,
            ABOrBBControlsPercentage: response.stats.ab_or_bb_controls,
            BBControlsPercentage: response.stats.bb_controls,
            oddsRatio: response.stats.odds_ratio,
            oddsRatioLCI: response.stats.odds_ratio_lci,
            oddsRatioUCI: response.stats.odds_ratio_uci,
        };
    }
    /**
     * Gets a continuous VariantAssociation object
     * @static
     * @param response - Variant associations data
     * @param collapsingModel - CollapsingModel object
     * @param metadata - The variant metadata object
     * @param id - The id of the variantAssociations
     * @returns Continuous Variant object
     */
    static createContinuous(response, collapsingModel, { gene, variant, }, datasetVersionId = '', id = uuidv4()) {
        const pvalue = parseFloat(response.pvalue);
        return {
            id,
            traitsType: ETraitsTypes.Continuous,
            phenotype: PhenotypeService.create(response.phenotype_name, response.phenotype_id),
            phenotypicCategory: PhenotypicCategoryService.create(response.category_name, response.category_short_name, response.category_id),
            variant,
            datasetVersionId,
            gene,
            ancestry: response.ancestry,
            collapsingModel,
            pvalue,
            pvalueLog: -Math.log10(pvalue),
            consequenceType: response.stats.consequence_type,
            transcriptId: response.stats.transcript_id,
            CDNAChange: response.stats.cdna_change,
            AAChange: response.stats.aa_change,
            exonRank: response.stats.exon_rank,
            samples: response.stats.no_samples,
            noAAGenotypes: response.stats.no_aa_genotypes,
            noABGenotypes: response.stats.no_ab_genotypes,
            noBBGenotypes: response.stats.no_bb_genotypes,
            MAF: response.stats.maf,
            AAF: response.stats.aaf,
            ABOrBBGenotypes: response.stats.ab_or_bb_genotypes,
            BBGenotypes: response.stats.bb_genotypes,
            effectSize: response.stats.effect_size,
            effectSizeStandardError: response.stats.effect_size_standard_error,
            effectSizeLCI: response.stats.effect_size_lci,
            effectSizeUCI: response.stats.effect_size_uci,
        };
    }
}

import { escapeRegExp } from 'Shared/utils/escapeRegExp/escapeRegExp';
import { GPAManhattanPlotData, ManhattanPlotData, ManhattanPlotDataGroup } from 'Shared/plots/models/';
import { ELevelResult, ETraitsTypes } from 'Shared/types';
import { ChromosomeService, ChromosomesService, } from 'Common/services';
import { filterByDirectionOfEffect } from 'Common/utils/filterByDirectionOfEffect';
import { logger } from 'Common/utils/logger/logger';
import { CHAPTER_NUMBER_PATTERN } from 'Common/consts';
import { DataPointAdapter, DataPointAdapterGPA } from 'PhenotypeView/models';
/**
 * Class service with static methods for work with Associations
 */
export class AssociationsService {
    /**
     * Gets phenotype associations by filteredIds for the table
     *
     * @static
     * @param collection - Collection of associations
     * @param filteredAssociationsIds - Array of filtered associations ids
     * @returns An array of phenotype associations
     */
    static getTableData(collection, filteredAssociationsIds) {
        return filteredAssociationsIds.map((id) => collection[id]);
    }
    /**
     * Gets the data for the GPAManhattanPlot for GLR data
     *
     * @static
     * @param chromosomes - Chromosomes instance
     * @param filteredAssociationsIds - Array of filtered associations
     * @returns An array of phenotype associations
     */
    static getPlotDataGLR(chromosomes, filteredAssociations) {
        const filteredDataPointsCollection = filteredAssociations.reduce((acc, phenotypeAssociation) => {
            const updatedAcc = Object.assign({}, acc);
            const chromosomeId = ChromosomesService.getChromosomeId(chromosomes, phenotypeAssociation.gene.name);
            if (!chromosomeId) {
                logger.warn(`Chromosome id for gene ${phenotypeAssociation.gene.name} (${phenotypeAssociation.id}) was not found: skipping`);
                return updatedAcc;
            }
            if (!updatedAcc[chromosomeId]) {
                updatedAcc[chromosomeId] = [];
            }
            let genePosition = ChromosomesService.getGenePosition(chromosomes, phenotypeAssociation.gene.name);
            if (!genePosition) {
                logger.warn(`Gene position in chromosome for gene ${phenotypeAssociation.gene} (${phenotypeAssociation.id}) was not found: replacing with 0`);
                genePosition = 0;
            }
            updatedAcc[chromosomeId].push(new DataPointAdapterGPA(phenotypeAssociation, genePosition));
            return updatedAcc;
        }, {});
        return Object
            .entries(filteredDataPointsCollection)
            .reduce((plotDataAcc, [chromosomeId, filteredDataPoints]) => {
            var _a;
            const chromosome = ChromosomesService.getChromosome(chromosomes, chromosomeId);
            return plotDataAcc.addGroup(new ManhattanPlotDataGroup((_a = chromosome.label) !== null && _a !== void 0 ? _a : chromosomeId, chromosome.label, chromosome.length, filteredDataPoints, chromosomeId));
        }, GPAManhattanPlotData.empty);
    }
    /**
     * Gets the data for the ManhattanPlot for PLR data
     *
     * @static
     * @param categories - Array of filtered phenotypic category
     * @param associations - array of filtered associations PLR
     * @param traitsType - ETraitsType
     * @returns An array of phenotype associations
     */
    static getPlotDataPLR(categories, associations, traitsType) {
        const filteredDataPointsCollection = associations.reduce((acc, association) => {
            const { category: { id } } = association;
            const updatedAcc = Object.assign({}, acc);
            if (!updatedAcc[id]) {
                updatedAcc[id] = [];
            }
            updatedAcc[id].push(new DataPointAdapter(association));
            return updatedAcc;
        }, {});
        return categories.reduce((acc, category, index) => {
            const { name, shortName, id } = category;
            const groupId = id;
            let groupLabel = shortName !== null && shortName !== void 0 ? shortName : `${index + 1}`;
            let groupName = name;
            // Roman numeral for binary traits if no short label was provided
            if (traitsType !== ETraitsTypes.Continuous && !shortName) {
                const [findMatch, findLabel] = CHAPTER_NUMBER_PATTERN.exec(name) || [];
                if (findLabel) {
                    groupLabel = findLabel;
                }
                if (findMatch) {
                    groupName = name.replace(findMatch, '');
                }
            }
            if (filteredDataPointsCollection[id].length) {
                acc.addGroup(new ManhattanPlotDataGroup(groupName, groupLabel, filteredDataPointsCollection[id].length, filteredDataPointsCollection[id], groupId));
            }
            return acc;
        }, ManhattanPlotData.empty);
    }
    /**
     * Gets the data for the ManhattanPlot for VLR data
     *
     * @static
     * @param chromosomes - Chromosomes instance
     * @param associations - Array of filtered associations
     * @returns An array of phenotype associations
     */
    static getPlotDataVLR(chromosomes, associations) {
        const dataPointsCollection = associations.reduce((acc, phenotypeAssociation) => {
            const updatedAcc = Object.assign({}, acc);
            const variant = phenotypeAssociation.variant.name.split('-');
            const chromosomeId = ChromosomeService.getNumberFromId(variant[0]);
            if (!chromosomeId) {
                logger.warn(`Chromosome id for gene ${phenotypeAssociation.gene.name} (${phenotypeAssociation.id}) was not found: skipping`);
                return updatedAcc;
            }
            if (!updatedAcc[chromosomeId]) {
                updatedAcc[chromosomeId] = [];
            }
            let genePosition = parseFloat(variant[1]);
            if (!genePosition) {
                logger.warn(`Gene position in chromosome for gene ${phenotypeAssociation.gene} (${phenotypeAssociation.id}) was not found: replacing with 0`);
                genePosition = 0;
            }
            updatedAcc[chromosomeId].push(new DataPointAdapterGPA(phenotypeAssociation, genePosition));
            return updatedAcc;
        }, {});
        return Object
            .entries(dataPointsCollection)
            .reduce((plotDataAcc, [chromosomeId, filteredDataPoints]) => {
            var _a, _b;
            const chromosome = ChromosomesService.getChromosome(chromosomes, chromosomeId);
            if (chromosome) {
                return plotDataAcc.addGroup(new ManhattanPlotDataGroup((_a = chromosome.label) !== null && _a !== void 0 ? _a : chromosomeId, (_b = chromosome.label) !== null && _b !== void 0 ? _b : chromosomeId, chromosome.length, filteredDataPoints, chromosomeId));
            }
            return plotDataAcc;
        }, GPAManhattanPlotData.empty);
    }
    /**
     * Returns array of association ids based on provided filters
     *
     * @param associations - Associations collection
     * @param filters - Applied filters
     * @param levelResult - Level result tab
     * @param genesMostSignificantModels - Collection of most significant models data per gene
     * @param variantsMostSignificantModels - Collection of most significant models data per variant
     * @param traitsType - Traits type
     * @returns Updated (filtered) data Ids
     */
    static getFilteredData({ associations, filters, levelResult, genesMostSignificantModels, variantsMostSignificantModels, traitsType, }) {
        if (levelResult === ELevelResult.Gene) {
            return AssociationsService.getFilteredDataGLR(associations, filters, genesMostSignificantModels, traitsType);
        }
        if (levelResult === ELevelResult.Variant) {
            return AssociationsService.getFilteredDataVLR(associations, filters, variantsMostSignificantModels);
        }
        if (levelResult === ELevelResult.Phenotype) {
            return AssociationsService.getFilteredDataPLR(associations, filters);
        }
        throw new Error('Cannot filter phenotype association, unsupported level result');
    }
    /**
     * Filters by direction of effect for GLR
     *
     * @param association - GLR phenotype association
     * @param traitsType - Traits type
     * @returns Returns `true`, if association is below the threshold, otherwise `false`
     */
    static filterByDirectionOfEffectGLR(association, traitsType) {
        return filterByDirectionOfEffect(traitsType === ETraitsTypes.Binary
            ? association.oddsRatio
            : association.beta, traitsType);
    }
    /**
     * Finds most significant association with current filters for GLR
     *
     * @param data - Most significant model data for genes
     * @param associationCollapsingModelId - Id of collapsing model for an association
     * @param traitsType - Traits type
     * @param selectedCollapsingModelIds - Collapsing models selected in Global filters
     * @param selectedAncestries - Ancestries selected in Global filters
     * @param maxPValue - pValue provided in Global filters
     * @param isDirectionOfEffectFilterActive - Boolean defining if direction of effect filter
     *                                          is active
     * @param hasAncestryData - Boolean defining if ancestry data is present
     * @returns Returns `true`, if variant has most significant model with current filters,
     *          otherwise `false`
     */
    static filterByMostSignificantModelGLR(data = [], associationCollapsingModelId, traitsType, selectedCollapsingModelIds, selectedAncestries, maxPValue, isDirectionOfEffectFilterActive, hasAncestryData) {
        const filteredCollapsingModelIds = data.reduce((acc, item) => {
            const { ancestry, collapsingModelId, pvalue, beta, oddsRatio, } = item;
            const filterForDirectionOfEffectFilter = traitsType === ETraitsTypes.Binary
                ? oddsRatio !== null && oddsRatio < 1
                : beta !== null && beta < 0;
            if (selectedCollapsingModelIds.has(collapsingModelId)
                && (!hasAncestryData || selectedAncestries.has(ancestry))
                && (maxPValue === null || pvalue <= maxPValue)
                && (!isDirectionOfEffectFilterActive || filterForDirectionOfEffectFilter)) {
                acc.push(collapsingModelId);
            }
            return acc;
        }, []);
        return filteredCollapsingModelIds[0] === (associationCollapsingModelId);
    }
    /**
     * Gets filtered associations ids for GLR
     *
     * @param associations - Sorted collection of associations
     * @param filters - Filters applied for associations
     * @param genesMostSignificantModels - Collection of most significant models data per variant
     * @param traitsType - Traits type
     * @returns An array of filtered associations ids
     */
    static getFilteredDataGLR({ collection, order, }, { ancestries, collapsingModels, maxPValue, gene, isMostSignificantModel, isDirectionOfEffectFilterActive, hasAncestryData, }, genesMostSignificantModels, traitsType) {
        const selectedCollapsingModelIds = new Set(collapsingModels.map((model) => model.id));
        const selectedAncestries = new Set(ancestries);
        return order.filter((id) => {
            const association = collection[id];
            return (gene === null || gene === association.gene.name)
                && selectedCollapsingModelIds.has(association.collapsingModel.id)
                && (!hasAncestryData || selectedAncestries.has(association.ancestry))
                && (maxPValue === null || association.pvalue <= maxPValue)
                && (!isDirectionOfEffectFilterActive
                    || AssociationsService.filterByDirectionOfEffectGLR(association, traitsType))
                && (!isMostSignificantModel || AssociationsService.filterByMostSignificantModelGLR(genesMostSignificantModels[association.gene.name], association.collapsingModel.id, traitsType, selectedCollapsingModelIds, selectedAncestries, maxPValue, isDirectionOfEffectFilterActive, hasAncestryData));
        });
    }
    /**
     * Filters by direction of effect for VLR
     *
     * @param association - VLR phenotype association
     * @returns Returns `true`, if association is below the threshold, otherwise `false`
     */
    static filterByDirectionOfEffectVLR(association) {
        const { traitsType } = association;
        return filterByDirectionOfEffect(traitsType === ETraitsTypes.Binary
            ? association.oddsRatio
            : association.effectSize, traitsType);
    }
    /**
     * Finds most significant association with current filters for VLR
     *
     * @param data - Most significant model data for variants
     * @param associationCollapsingModelId - Id of collapsing model for an association
     * @param traitsType - Traits type
     * @param selectedCollapsingModelIds - Collapsing models selected in Global filters
     * @param selectedConsequenceTypes - Consequence types selected in Global filters
     * @param selectedAncestries - Ancestries selected in Global filters
     * @param maxPValue - pValue provided in Global filters
     * @param isDirectionOfEffectFilterActive - Boolean defining if direction of effect filter
     *                                          is active
     * @param hasAncestryData - Boolean defining if ancestry data is present
     * @returns Returns `true`, if variant has most significant model
     *          with current filters, otherwise `false`
     */
    static filterByMostSignificantModelVLR(data = [], associationCollapsingModelId, traitsType, selectedCollapsingModelIds, selectedConsequenceTypes, selectedAncestries, maxPValue, isDirectionOfEffectFilterActive, hasAncestryData) {
        const filteredCollapsingModelIds = data.reduce((acc, item) => {
            const { ancestry, collapsingModelId, consequenceType, pvalue, effectSize, oddsRatio, } = item;
            const filterForDirectionOfEffectFilter = traitsType === ETraitsTypes.Binary
                ? oddsRatio !== null && oddsRatio < 1
                : effectSize !== null && effectSize < 0;
            if (selectedCollapsingModelIds.has(collapsingModelId)
                && selectedConsequenceTypes.has(consequenceType)
                && (!hasAncestryData || selectedAncestries.has(ancestry))
                && (maxPValue === null || pvalue <= maxPValue)
                && (!isDirectionOfEffectFilterActive || filterForDirectionOfEffectFilter)) {
                acc.push(collapsingModelId);
            }
            return acc;
        }, []);
        return filteredCollapsingModelIds[0] === (associationCollapsingModelId);
    }
    /**
     * Gets filtered associations ids for VLR
     *
     * @param associations - Sorted collection of associations
     * @param filters - Filters applied for associations
     * @param variantsMostSignificantModels - Collection of most significant models data per variant
     * @returns An array of filtered associations ids
     */
    static getFilteredDataVLR({ collection, order, }, { ancestries, collapsingModels, consequenceTypes, maxPValue, gene, variant, isMostSignificantModel, isDirectionOfEffectFilterActive, hasAncestryData, }, variantsMostSignificantModels) {
        const selectedCollapsingModelIds = new Set(collapsingModels.map((model) => model.id));
        const selectedConsequenceTypes = new Set(consequenceTypes);
        const selectedAncestries = new Set(ancestries);
        return order.filter((id) => {
            const association = collection[id];
            return (gene === null || gene === association.gene.name)
                && (variant === null || new RegExp(escapeRegExp(variant), 'i').test(association.variant.name))
                && selectedCollapsingModelIds.has(association.collapsingModel.id)
                && (!hasAncestryData || selectedAncestries.has(association.ancestry))
                && (association.consequenceType == null
                    || selectedConsequenceTypes.has(association.consequenceType))
                && (maxPValue === null || association.pvalue <= maxPValue)
                && (!isDirectionOfEffectFilterActive
                    || AssociationsService.filterByDirectionOfEffectVLR(association))
                && (!isMostSignificantModel || AssociationsService.filterByMostSignificantModelVLR(variantsMostSignificantModels[association.variant.name], association.collapsingModel.id, association.traitsType, selectedCollapsingModelIds, selectedConsequenceTypes, selectedAncestries, maxPValue, isDirectionOfEffectFilterActive, hasAncestryData));
        });
    }
    /**
     * Gets filtered associations ids for PLR
     *
     * @param associations - Sorted collection of associations
     * @param filters - Filters applied for associations
     * @returns An array of filtered associations ids
     */
    static getFilteredDataPLR({ collection, order, }, { categories, phenotype, maxPValue, }) {
        const selectedCategoriesIds = new Set(categories.map((category) => category.id));
        return order.filter((id) => {
            const association = collection[id];
            return selectedCategoriesIds.has(association.category.id)
                && (maxPValue === null || association.pvalue <= maxPValue)
                && (phenotype === null || new RegExp(escapeRegExp(phenotype), 'i').test(association.phenotype.name));
        });
    }
}

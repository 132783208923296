import { useCallback, useMemo } from 'react';
import { isRecentSuggestionsCollection, ECombinedSuggestionsType, ESelectedSuggestionsType, } from 'Search/types';
import { SUGGESTIONS_ORDER } from 'Search/consts';
import { useIterator } from '../useIterator/useIterator';
/**
 * Gets adapter's type from suggestions collection
 *
 * @private
 *
 * @param suggestionsCollection - Suggestions collection or `null`
 * @returns Adapter's type
 */
function getType(suggestionsCollection) {
    if (!suggestionsCollection) {
        return ESelectedSuggestionsType.Null;
    }
    if (isRecentSuggestionsCollection(suggestionsCollection)) {
        return ESelectedSuggestionsType.Recent;
    }
    return ESelectedSuggestionsType.Search;
}
/**
 * Gets group indexes collection from suggestions collection
 *
 * @private
 *
 * @param suggestionsCollection - Suggestions collection or `null`
 * @param order - The order of suggestions
 * @returns Group indexes collection
 */
function getGroups(suggestionsCollection, order = SUGGESTIONS_ORDER) {
    if (!suggestionsCollection) {
        return {};
    }
    if (isRecentSuggestionsCollection(suggestionsCollection)) {
        return { 0: ECombinedSuggestionsType.Recent };
    }
    let groupIndex = 0;
    return order.reduce((collection, suggestionType) => {
        const updatedCollection = Object.assign({}, collection);
        if (Array.isArray(suggestionsCollection[suggestionType])) {
            updatedCollection[groupIndex] = suggestionType;
            groupIndex += (suggestionsCollection[suggestionType] || []).length;
        }
        return updatedCollection;
    }, {});
}
/**
 * Gets collection of typed suggestions items from suggestions collection
 *
 * @private
 *
 * @param suggestionsCollection - Suggestions collection or `null`
 * @param order - The order of suggestions
 * @returns Typed suggestions items collection
 */
function getValues(suggestionsCollection, order = SUGGESTIONS_ORDER) {
    if (!suggestionsCollection) {
        return [];
    }
    if (isRecentSuggestionsCollection(suggestionsCollection)) {
        return suggestionsCollection[ECombinedSuggestionsType.Recent];
    }
    return order.reduce((collection, suggestionType) => {
        if (Array.isArray(suggestionsCollection[suggestionType])) {
            const typedCollection = suggestionsCollection[suggestionType] || [];
            return [...collection, ...typedCollection];
        }
        return collection;
    }, []);
}
/**
 * Hook implements adapter for mixed suggestions collections
 * It transforms suggestions collections of different types and interfaces
 *  into a single typed collection which is used by suggestions popup
 *
 * @param suggestionsCollection - Suggestions collection or `null`
 * @returns Public API for suggestions adapter
 *  - Group indexes collection
 *  - Collection of typed suggestions items
 *  - Iterator over suggestions items collection
 *  - Method which shows if the adapter has `Recent` type
 *  - Method which shows if the adapter has `Search` type
 *  - Method which shows if the adapter has `Null` type
 */
export function useSuggestionsAdapter(suggestionsCollection, order = SUGGESTIONS_ORDER) {
    const type = useMemo(() => getType(suggestionsCollection), [suggestionsCollection]);
    const groups = useMemo(() => getGroups(suggestionsCollection, order), [suggestionsCollection, order]);
    const values = useMemo(() => getValues(suggestionsCollection, order), [suggestionsCollection, order]);
    const iterator = useIterator(values);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    useMemo(() => iterator.reset(), [suggestionsCollection]);
    const isRecent = useCallback(() => type === ESelectedSuggestionsType.Recent, [type]);
    const isSearch = useCallback(() => type === ESelectedSuggestionsType.Search, [type]);
    const isNull = useCallback(() => type === ESelectedSuggestionsType.Null, [type]);
    return {
        groups,
        values,
        iterator,
        isRecent,
        isSearch,
        isNull,
    };
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".List__container__2VHgl{align-items:center;display:flex;height:100%}", "",{"version":3,"sources":["webpack://./src/common/components/List/List.css"],"names":[],"mappings":"AAAA,wBAEE,kBAAmB,CADnB,YAAa,CAEb,WACF","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "List__container__2VHgl"
};
export default ___CSS_LOADER_EXPORT___;

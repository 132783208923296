import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CONTRIBUTORS_INTERNAL, CONTRIBUTORS_PUBLIC, CONTRIBUTORS_INTERNAL_PREFIX_KEY, CONTRIBUTORS_PUBLIC_PREFIX_KEY, CONTRIBUTORS_SECTION_TITLE, } from '../../consts';
import Title from '../Title';
import DescriptionMapper from '../DescriptionMapper';
import { ParagraphWithMargin } from '../../containers';
const ContributorsSection = ({ isPublic = false, }) => {
    const keyPrefix = isPublic
        ? CONTRIBUTORS_PUBLIC_PREFIX_KEY
        : CONTRIBUTORS_INTERNAL_PREFIX_KEY;
    const data = isPublic
        ? CONTRIBUTORS_PUBLIC
        : CONTRIBUTORS_INTERNAL;
    return (_jsxs(_Fragment, { children: [_jsx(Title, { label: CONTRIBUTORS_SECTION_TITLE }, void 0), _jsx(ParagraphWithMargin, { children: _jsx(DescriptionMapper, { addBreaks: true, data: data, keyPrefix: keyPrefix }, void 0) }, void 0)] }, void 0));
};
export default ContributorsSection;

var _a, _b, _c;
import { ELevelResult } from 'Shared/types';
import { DEFAULT_API_SERVICE_URI } from 'API/consts';
import { RESTResource } from '../RESTResource';
/* eslint-disable @typescript-eslint/indent */
export class PhenotypeStatsResource extends RESTResource {
    constructor(urlRoot) {
        super((urlParams) => {
            const { datasetVersionId, phenotypeId, } = urlParams;
            return `${urlRoot}/v1/${datasetVersionId}/phenotype/${phenotypeId}/stats`;
        });
    }
}
export const phenotypeStatsResourceGLR = new PhenotypeStatsResource((_a = process.env.GLR_SERVICE_URI) !== null && _a !== void 0 ? _a : DEFAULT_API_SERVICE_URI);
export const phenotypeStatsResourceVLR = new PhenotypeStatsResource((_b = process.env.VLR_SERVICE_URI) !== null && _b !== void 0 ? _b : DEFAULT_API_SERVICE_URI);
export const phenotypeStatsResourcePLR = new PhenotypeStatsResource((_c = process.env.PLR_SERVICE_URI) !== null && _c !== void 0 ? _c : DEFAULT_API_SERVICE_URI);
export default (levelResult) => {
    switch (levelResult) {
        case ELevelResult.Gene:
            return phenotypeStatsResourceGLR;
        case ELevelResult.Variant:
            return phenotypeStatsResourceVLR;
        case ELevelResult.Phenotype:
            return phenotypeStatsResourcePLR;
        default:
            throw new Error(`Unknown level result type provided for fetching phenotype stats data: ${levelResult}`);
    }
};

import { ELevelResult, ETraitsTypes } from 'Shared/types';
import { CollapsingModelsService } from 'Common/services';
import { SIGNIFICANT_INDICATORS_INIT } from 'Common/components/IndicatorsTag';
import { ELoadingStatus } from 'API/types';
export const getInitialState = () => ({
    dataExists: false,
    errorType: null,
    loadingStatus: ELoadingStatus.Init,
    loadingMessage: '',
    collapsingModels: CollapsingModelsService.empty,
    consequenceTypes: [],
    significantIndicators: SIGNIFICANT_INDICATORS_INIT,
    levelResult: ELevelResult.Gene,
    [ETraitsTypes.Binary]: {
        errorType: null,
        loadingStatus: ELoadingStatus.Init,
        loadingMessage: '',
        isFiltering: false,
        filters: {
            ancestries: [],
            collapsingModels: [],
            consequenceTypes: undefined,
            categories: [],
            maxPValue: null,
            phenotype: null,
            variant: null,
            isMostSignificantModel: false,
            isDirectionOfEffectFilterActive: false,
            hasAncestryData: false,
        },
        phenotypes: {},
        phenotypesMostSignificantModels: {},
        ancestries: [],
        categories: [],
        collapsingModels: [],
        associations: {
            collection: {},
            order: [],
        },
        filteredAssociationIds: [],
        tableSettings: {
            sortBy: [{
                    id: 'pvalue_binary',
                    desc: false,
                }],
            pageSize: 10,
        },
    },
    [ETraitsTypes.Continuous]: {
        errorType: null,
        loadingStatus: ELoadingStatus.Init,
        loadingMessage: '',
        isFiltering: false,
        filters: {
            ancestries: [],
            collapsingModels: [],
            consequenceTypes: undefined,
            categories: [],
            maxPValue: null,
            phenotype: null,
            variant: null,
            isMostSignificantModel: false,
            isDirectionOfEffectFilterActive: false,
            hasAncestryData: false,
        },
        phenotypes: {},
        phenotypesMostSignificantModels: {},
        collapsingModels: [],
        ancestries: [],
        categories: [],
        associations: {
            collection: {},
            order: [],
        },
        filteredAssociationIds: [],
        tableSettings: {
            sortBy: [{
                    id: 'pvalue_continuous',
                    desc: false,
                }],
            pageSize: 10,
        },
    },
});

import { ETraitsTypes } from 'Shared/types';
/**
 * isBinaryTypeGuard
 *
 * @param association Association object
 * @returns Boolean: is the association object is a type of binary association
 */
export const isBinaryTypeGuard = (association) => {
    var _a;
    return (((_a = (association)) === null || _a === void 0 ? void 0 : _a.traitsType) === ETraitsTypes.Binary);
};

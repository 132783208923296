var _a;
import { ELevelResult, } from 'Shared/types';
import { DEFAULT_API_SERVICE_URI } from 'API/consts';
import { HTTPClientError } from 'API/errors';
import { RESTResource } from '../RESTResource';
/* eslint-disable @typescript-eslint/indent */
const errorHandler = (err) => {
    if (!(err instanceof HTTPClientError && err.status === 404 /* NotFound */)) {
        throw err;
    }
    return null;
};
export class VariantResource extends RESTResource {
    constructor(urlRoot) {
        super((urlParams) => {
            const { datasetVersionId, variantId, traitsType, } = urlParams;
            return `${urlRoot}/v1/${datasetVersionId}/variant/${variantId}/associations/${traitsType}`;
        }, 'get', {
            collapsingModelId: 'collapsing_model_ids',
            minPValue: 'min_pvalue',
            maxPValue: 'max_pvalue',
        }, errorHandler);
    }
}
export const variantResourceVLR = new VariantResource((_a = process.env.VLR_SERVICE_URI) !== null && _a !== void 0 ? _a : DEFAULT_API_SERVICE_URI);
export default (levelResult) => {
    switch (levelResult) {
        case ELevelResult.Variant:
            return variantResourceVLR;
        default:
            throw new Error(`Unknown level result type provided for fetching variants data: ${levelResult}`);
    }
};

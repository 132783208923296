import { ETraitsTypes } from 'Shared/types';
import { EConsequenceType } from 'Shared/plots/types';
import { phenotypeIdMock, phenotypeNameMock, phenotypicCategoryIdMock, phenotypicCategoryNameMock, phenotypicCategoryShortNameMock, variantIdMock, variantNameMock, variantTypeMock, geneNameMock, geneIdMock, collapsingModelIdMock, ancestryMock, } from 'Common/mocks';
import { generateData, createMockedURL, getMock } from 'API/utils';
import { UKBDatasetVersionId, FinnGenDatasetVersionId, GenesResponseDatasetVersionIdMock, UBKWesDatasetVersionId, UBKWgsDatasetVersionId, } from '../datasets';
import { collapsingModelsResponseMock } from '../collapsingModels/mocks';
import { encodedPhenotypeNameMock } from '../phenotypeId/mocks';
import { phenotypeResourceGLR, phenotypeResourceVLR, phenotypeResourcePLR, } from './resource';
// Collections
export const phenotypeBinaryCollectionGLRResponseMock = {
    [collapsingModelsResponseMock[0].id]: generateData([
        {
            ancestry: ancestryMock,
            gene_name: geneNameMock,
            gene_id: geneIdMock,
            pvalue: '0.0001706196132176885',
            samples: 7591,
            stats: {
                odds_ratio_lci: 229411131931.95,
                odds_ratio_uci: 1139972,
                case_frequency: 0.00303951367781155,
                cases: 329,
                control_frequency: 0.000316172012453458,
                controls: 268841,
                odds_ratio: 9.6397,
                qv_cases: 1,
                qv_controls: 85,
            },
        },
        {
            ancestry: ancestryMock,
            gene_id: 'NARS2',
            gene_name: 'NARS2',
            pvalue: '0.0999',
            samples: 269170,
            stats: {
                case_frequency: 0.00911854103343465,
                cases: 329,
                control_frequency: 0.0033477036612719,
                controls: 268841,
                odds_ratio: 2.7397,
                odds_ratio_lci: 0.8774,
                odds_ratio_uci: 8.5551,
                qv_cases: 3,
                qv_controls: 900,
            },
        },
        {
            ancestry: ancestryMock,
            gene_id: 'RIMS4',
            gene_name: 'RIMS4',
            pvalue: '0.0999',
            samples: 269170,
            stats: {
                case_frequency: 0.00303951367781155,
                cases: 329,
                control_frequency: 0.000316172012453458,
                controls: 268841,
                odds_ratio: 9.6397,
                odds_ratio_lci: 1.3383,
                odds_ratio_uci: 69.433,
                qv_cases: 1,
                qv_controls: 85,
            },
        },
    ], 1),
    [collapsingModelsResponseMock[1].id]: generateData([
        {
            ancestry: ancestryMock,
            gene_id: 'SPEM1',
            gene_name: 'SPEM1',
            pvalue: '0.0001706196132176885',
            samples: 269170,
            stats: {
                case_frequency: 0.00303951367781155,
                cases: 329,
                control_frequency: 0.000316172012453458,
                controls: 268841,
                odds_ratio: -1.6397,
                odds_ratio_lci: 1.3383,
                odds_ratio_uci: 69.433,
                qv_cases: 1,
                qv_controls: 85,
            },
        },
        {
            ancestry: ancestryMock,
            gene_id: 'SLURP1',
            gene_name: 'SLURP1',
            pvalue: '0.0999',
            samples: 269170,
            stats: {
                case_frequency: 0.00303951367781155,
                cases: 329,
                control_frequency: 0.000316172012453458,
                controls: 268841,
                odds_ratio: 119.6397,
                odds_ratio_lci: 1.3383,
                odds_ratio_uci: 69.433,
                qv_cases: 1,
                qv_controls: 85,
            },
        },
        {
            ancestry: ancestryMock,
            gene_id: 'FAM173B',
            gene_name: 'FAM173B',
            pvalue: '0.0998',
            samples: 269170,
            stats: {
                case_frequency: 0.0060790273556231,
                cases: 329,
                control_frequency: 0.00161061742814526,
                controls: 268841,
                odds_ratio: 3.7913,
                odds_ratio_lci: 0.9412,
                odds_ratio_uci: 15.2727,
                qv_cases: 2,
                qv_controls: 433,
            },
        },
    ], 1),
    [collapsingModelsResponseMock[2].id]: generateData([
        {
            ancestry: ancestryMock,
            gene_id: 'PPP1R9B',
            gene_name: 'PPP1R9B',
            pvalue: '0.0997',
            samples: 269170,
            stats: {
                case_frequency: 0.00911854103343465,
                cases: 329,
                control_frequency: 0.00334398399053716,
                controls: 268841,
                odds_ratio: -2.7427,
                odds_ratio_lci: 0.8783,
                odds_ratio_uci: 8.5647,
                qv_cases: 3,
                qv_controls: 899,
            },
        },
        {
            ancestry: ancestryMock,
            gene_id: 'PTPRK1',
            gene_name: 'PTPRK1',
            pvalue: '0.0996',
            samples: 269170,
            stats: {
                case_frequency: 0.0151975683890578,
                cases: 329,
                control_frequency: 0.00739470542067616,
                controls: 268841,
                odds_ratio: 2.0715,
                odds_ratio_lci: 0.8555,
                odds_ratio_uci: 5.016,
                qv_cases: 5,
                qv_controls: 1988,
            },
        },
        {
            ancestry: ancestryMock,
            gene_id: 'GIMAP2',
            gene_name: 'GIMAP2',
            pvalue: '0.0995',
            samples: 269170,
            stats: {
                case_frequency: 0.0121580547112462,
                cases: 329,
                control_frequency: 0.0052968111262791,
                controls: 268841,
                odds_ratio: 2.3113,
                odds_ratio_lci: 0.8611,
                odds_ratio_uci: 6.204,
                qv_cases: 4,
                qv_controls: 1424,
            },
        },
    ], 1),
    [collapsingModelsResponseMock[3].id]: generateData([
        {
            ancestry: ancestryMock,
            gene_name: geneNameMock,
            gene_id: geneIdMock,
            pvalue: '0.0535706196132176885',
            samples: 7591,
            stats: {
                odds_ratio_lci: 229411131931.95,
                odds_ratio_uci: 1139972,
                case_frequency: 0.00303951367781155,
                cases: 329,
                control_frequency: 0.000316172012453458,
                controls: 268841,
                odds_ratio: 9.6397,
                qv_cases: 1,
                qv_controls: 85,
            },
        },
    ], 1),
    [collapsingModelsResponseMock[4].id]: [],
    [collapsingModelsResponseMock[5].id]: [],
};
export const phenotypeContinuousCollectionGLRResponseMock = {
    [collapsingModelsResponseMock[0].id]: generateData([
        {
            ancestry: ancestryMock,
            gene_name: geneNameMock,
            gene_id: geneIdMock,
            pvalue: '0.0001706196132176885',
            samples: 7591,
            stats: {
                odds_ratio_lci: 229411131931.95,
                odds_ratio_uci: 1139972,
                beta: -1.03823723604198,
                beta_std: 0.249981012496739,
                case_median: -1.35393993035186,
                control_median: 0,
                no_qv: 59047,
                yes_qv: 16,
            },
        },
        {
            ancestry: ancestryMock,
            gene_id: 'ADGRA1',
            gene_name: 'ADGRA1',
            pvalue: '0.0000468104158853929',
            samples: 59063,
            stats: {
                beta: 0.328433223686321,
                beta_std: 0.0806701686905312,
                case_median: 0.313855157789097,
                control_median: -0.00106002873287907,
                no_qv: 58909,
                odds_ratio_lci: 0.170319357961214,
                odds_ratio_uci: 0.486547089411428,
                yes_qv: 154,
            },
        },
        {
            ancestry: ancestryMock,
            gene_id: 'NHLRC1',
            gene_name: 'NHLRC1',
            pvalue: '0.0000672106982970207',
            samples: 59063,
            stats: {
                beta: 0.37039681569671,
                beta_std: 0.0929189647925386,
                case_median: 0.32579708865116,
                control_median: -0.00076322061883605,
                no_qv: 58947,
                odds_ratio_lci: 0.188275258732025,
                odds_ratio_uci: 0.552518372661396,
                yes_qv: 116,
            },
        },
    ], 1),
    [collapsingModelsResponseMock[1].id]: generateData([
        {
            ancestry: ancestryMock,
            gene_id: 'MAP3K7',
            gene_name: 'MAP3K7',
            pvalue: '0.000185870427165105',
            samples: 59063,
            stats: {
                beta: 0.253568763495355,
                beta_std: 0.0678404647568158,
                case_median: 0.355875344867669,
                control_median: -0.00106002873287907,
                no_qv: 58845,
                odds_ratio_lci: 0.120601170772978,
                odds_ratio_uci: 0.386536356217733,
                yes_qv: 218,
            },
        },
        {
            ancestry: ancestryMock,
            gene_id: 'ASGR2',
            gene_name: 'ASGR2',
            pvalue: '0.000194258614358018',
            samples: 59063,
            stats: {
                beta: -0.502653985301344,
                beta_std: 0.134882623313749,
                case_median: -0.436511086450332,
                control_median: 0.000360409709566116,
                no_qv: 59008,
                odds_ratio_lci: -0.767024487278285,
                odds_ratio_uci: -0.238283483324403,
                yes_qv: 55,
            },
        },
        {
            ancestry: ancestryMock,
            gene_id: 'CYP3A4',
            gene_name: 'CYP3A4',
            pvalue: '0.000239236933909972',
            samples: 59063,
            stats: {
                beta: 0.250975946006849,
                beta_std: 0.0683161955696549,
                case_median: 0.212881938650931,
                control_median: -0.00108122931596019,
                no_qv: 58848,
                odds_ratio_lci: 0.117075918915193,
                odds_ratio_uci: 0.384875973098504,
                yes_qv: 215,
            },
        },
    ], 1),
    [collapsingModelsResponseMock[2].id]: generateData([
        {
            ancestry: ancestryMock,
            gene_id: 'GRK1',
            gene_name: 'GRK1',
            pvalue: '0.000351932875249393',
            samples: 59063,
            stats: {
                beta: 0.240281218159694,
                beta_std: 0.0672315096423074,
                case_median: 0.295787981665185,
                control_median: -0.00139923812194247,
                no_qv: 58841,
                odds_ratio_lci: 0.108507179991261,
                odds_ratio_uci: 0.372055256328127,
                yes_qv: 222,
            },
        },
        {
            ancestry: ancestryMock,
            gene_id: 'NEU4',
            gene_name: 'NEU4',
            pvalue: '0.0003774524203439',
            samples: 59063,
            stats: {
                beta: -0.175754090760042,
                beta_std: 0.0494306107030123,
                case_median: -0.194083230116811,
                control_median: 0.00122963340972517,
                no_qv: 58651,
                odds_ratio_lci: -0.272638293065218,
                odds_ratio_uci: -0.0788698884548658,
                yes_qv: 412,
            },
        },
        {
            ancestry: ancestryMock,
            gene_id: 'CACNA1I',
            gene_name: 'CACNA1I',
            pvalue: '0.000510754895231854',
            samples: 59063,
            stats: {
                beta: 0.106566920255994,
                beta_std: 0.030664585917805,
                case_median: 0.0745678518695272,
                control_median: -0.00201405557737879,
                no_qv: 57980,
                odds_ratio_lci: 0.0464642044810497,
                odds_ratio_uci: 0.166669636030939,
                yes_qv: 1083,
            },
        },
    ], 1),
    [collapsingModelsResponseMock[3].id]: [],
    [collapsingModelsResponseMock[4].id]: [],
    [collapsingModelsResponseMock[5].id]: [],
};
export const phenotypeBinaryCollectionVLRResponseMock = {
    [collapsingModelsResponseMock[0].id]: generateData([
        {
            ancestry: ancestryMock,
            gene_name: geneNameMock,
            gene_id: geneIdMock,
            pvalue: '0.0001706196132176885',
            variant_id: variantIdMock,
            variant_type: variantTypeMock,
            variant_name: variantNameMock,
            stats: {
                transcript_id: 'ENST00000305877',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
                consequence_type: EConsequenceType.StopGained,
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 0.1231,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
            },
        },
        {
            ancestry: ancestryMock,
            pvalue: '0.000251570655403801',
            gene_name: 'A1CF',
            gene_id: 'A1CF',
            variant_id: 'fde0c4af-aab4-4ea0-bb90-4452e7c3f1fd',
            variant_name: '17-30205094-C-T',
            variant_type: 'snv',
            stats: {
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 2.31231,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
                consequence_type: EConsequenceType.StopGained,
                transcript_id: 'ENST00000232892',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
            },
        },
        {
            ancestry: ancestryMock,
            pvalue: '0.00035321394956733237',
            gene_name: 'A2CF',
            gene_id: 'A2CF',
            variant_id: '4ca880d1-f2b7-4a1f-99f8-b38edb376431',
            variant_name: '17-44205031-A-G',
            variant_type: 'snv',
            stats: {
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 1,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
                consequence_type: EConsequenceType.StopGained,
                transcript_id: 'ENST00000232892',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
            },
        },
    ], 1),
    [collapsingModelsResponseMock[1].id]: generateData([
        {
            ancestry: ancestryMock,
            pvalue: '0.000000000004269089747366948',
            gene_name: 'A1BG',
            gene_id: 'A1BG',
            variant_id: 'fee18b2e-5f65-4019-b38b-e56c08600340',
            variant_name: '12-72022455-A-T',
            variant_type: 'snv',
            stats: {
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 1.231,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
                consequence_type: EConsequenceType.StopGained,
                transcript_id: 'ENST00000232892',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
            },
        },
        {
            ancestry: ancestryMock,
            pvalue: '0.000551570655403801',
            gene_name: 'A1CF',
            gene_id: 'A1CF',
            variant_id: '06d222ce-78ce-4bfa-bb09-dbcc8a0d4795',
            variant_name: '11-56346117-C-A',
            variant_type: 'snv',
            stats: {
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 1,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
                consequence_type: EConsequenceType.StopGained,
                transcript_id: 'ENST00000232892',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
            },
        },
        {
            ancestry: ancestryMock,
            pvalue: '0.00065321394956733237',
            gene_name: 'A2CF',
            gene_id: 'A2CF',
            variant_id: '0073bd94-1a34-402e-a3a6-951ebdca5287',
            variant_name: '5-43235944-G-C',
            variant_type: 'snv',
            stats: {
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 0.31231,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
                consequence_type: EConsequenceType.StopGained,
                transcript_id: 'ENST00000232892',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
            },
        },
    ], 1),
    [collapsingModelsResponseMock[2].id]: generateData([
        {
            ancestry: ancestryMock,
            pvalue: '0.00007269089747366948',
            gene_name: 'A1BG',
            gene_id: 'A1BG',
            variant_id: '68b9a9d3-096c-48f9-90d0-8925f3e5ee63',
            variant_name: '19-40458488-C-T',
            variant_type: 'snv',
            stats: {
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 12.31,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
                consequence_type: EConsequenceType.StopGained,
                transcript_id: 'ENST00000232892',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
            },
        },
        {
            ancestry: ancestryMock,
            pvalue: '0.000851570655403801',
            gene_name: 'A1CF',
            gene_id: 'A1CF',
            variant_id: 'c7748302-2f6f-4d87-8831-29a5c374159e',
            variant_name: '19-50727420-C-T',
            variant_type: 'snv',
            stats: {
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 0.312,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
                consequence_type: EConsequenceType.StopGained,
                transcript_id: 'ENST00000232892',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
            },
        },
        {
            ancestry: ancestryMock,
            pvalue: '0.00095321394956733237',
            gene_name: 'A2CF',
            gene_id: 'A2CF',
            variant_id: '46889bb0-78bd-4c70-bb1d-b9b316024418',
            variant_name: '12-90503255-G-A',
            variant_type: 'snv',
            stats: {
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 1,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
                consequence_type: EConsequenceType.StopGained,
                transcript_id: 'ENST00000232892',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
            },
        },
        {
            ancestry: ancestryMock,
            pvalue: '0.00095321394956733237',
            gene_name: 'A2CF',
            gene_id: 'A2CF',
            variant_id: '46889bb0-78bd-4c70-bb1d-b9b316024418',
            variant_name: '32-90503255-G-A',
            variant_type: 'snv',
            stats: {
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 1,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
                consequence_type: EConsequenceType.StopGained,
                transcript_id: 'ENST00000232892',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
            },
        },
        {
            ancestry: ancestryMock,
            pvalue: '0.00095321394956733237',
            gene_name: 'A2CF',
            gene_id: 'A2CF',
            variant_id: '46889bb0-78bd-4c70-bb1d-b9b316024418',
            variant_name: '16-90503255-G-A',
            variant_type: 'snv',
            stats: {
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 1,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
                consequence_type: EConsequenceType.StopGained,
                transcript_id: 'ENST00000232892',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
            },
        },
    ], 1),
    [collapsingModelsResponseMock[3].id]: generateData([
        {
            ancestry: ancestryMock,
            gene_name: geneNameMock,
            gene_id: geneIdMock,
            pvalue: '0.0151706196132176885',
            variant_id: variantIdMock,
            variant_type: variantTypeMock,
            variant_name: variantNameMock,
            stats: {
                transcript_id: 'ENST00000305877',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
                consequence_type: EConsequenceType.StopGained,
                no_cases: 12300,
                no_aa_cases: 100,
                no_ab_cases: 200,
                no_bb_cases: 300,
                case_maf: 23.333,
                ab_or_bb_cases: 11.12345,
                bb_cases: 5.54545,
                no_controls: 20000,
                no_aa_controls: 1000,
                no_ab_controls: 2000,
                no_bb_controls: 3000,
                control_maf: 33.456,
                ab_or_bb_controls: 20.2222,
                bb_controls: 11.1111,
                odds_ratio: 0.1231,
                odds_ratio_lci: 23.23232,
                odds_ratio_uci: 45.454545,
            },
        },
    ], 1),
    [collapsingModelsResponseMock[4].id]: [],
    [collapsingModelsResponseMock[5].id]: [],
};
export const phenotypeContinuousCollectionVLRResponseMock = {
    [collapsingModelsResponseMock[0].id]: generateData([
        {
            ancestry: ancestryMock,
            gene_name: geneNameMock,
            gene_id: geneIdMock,
            pvalue: '0.0001706196132176885',
            variant_id: variantIdMock,
            variant_type: variantTypeMock,
            variant_name: variantNameMock,
            stats: {
                transcript_id: 'ENST00000305877',
                cdna_change: 'c.34A>T',
                aa_change: 'p.Lys12*',
                exon_rank: '1/23',
                consequence_type: EConsequenceType.StopGained,
                ab_or_bb_genotypes: 0.0335631318747113,
                bb_genotypes: 0.000256598867543665,
                effect_size: -0.097098852,
                effect_size_lci: -0.142095891,
                effect_size_standard_error: 0.02295762,
                effect_size_uci: -0.052101813,
                maf: 0.0169098653711275,
                no_aa_genotypes: 56495,
                no_ab_genotypes: 1947,
                no_bb_genotypes: 15,
                no_samples: 58457,
            },
        },
        {
            ancestry: ancestryMock,
            gene_id: 'RIT2',
            gene_name: 'RIT2',
            pvalue: '0.0000328',
            variant_id: '4670c7bf-170f-4d86-98ad-f42c537e9cab',
            variant_name: '18-42974155-A-G',
            variant_type: 'snv',
            stats: {
                aa_change: 'NA',
                ab_or_bb_genotypes: 0.154516640253566,
                bb_genotypes: 0.00714876317784056,
                cdna_change: 'c.161-8T>C',
                consequence_type: EConsequenceType.SpliceRegionVariant,
                effect_size: -0.047699713,
                effect_size_lci: -0.070210534,
                effect_size_standard_error: 0.011485084,
                effect_size_uci: -0.025188892,
                exon_rank: "'2/4'",
                maf: 0.0808327017157032,
                no_aa_genotypes: 49082,
                no_ab_genotypes: 8555,
                no_bb_genotypes: 415,
                no_samples: 58052,
                transcript_id: 'ENST00000326695',
            },
        },
        {
            ancestry: ancestryMock,
            gene_id: 'POLR2B',
            gene_name: 'POLR2B',
            pvalue: '0.0000199',
            variant_id: 'b832b536-dabb-4bf1-a30f-fa6f9e8e70ad',
            variant_name: '4-57024966-A-G',
            variant_type: 'snv',
            stats: {
                aa_change: 'p.Leu1015Leu',
                ab_or_bb_genotypes: 0.000626926492868711,
                bb_genotypes: 0,
                cdna_change: 'c.3045A>G',
                consequence_type: EConsequenceType.SynonymousVariant,
                effect_size: 0.710836396,
                effect_size_lci: 0.384262707,
                effect_size_standard_error: 0.166618778,
                effect_size_uci: 1.037410084,
                exon_rank: "'22/25'",
                maf: 0.000313463246434356,
                no_aa_genotypes: 57387,
                no_ab_genotypes: 36,
                no_bb_genotypes: 0,
                no_samples: 57423,
                transcript_id: 'ENST00000314595',
            },
        },
    ], 1),
    [collapsingModelsResponseMock[1].id]: generateData([
        {
            ancestry: ancestryMock,
            gene_id: 'ARHGEF28',
            gene_name: 'ARHGEF28',
            pvalue: '0.00000472',
            variant_id: '1799ec60-b233-4803-b852-97c0a9a81c3d',
            variant_name: '5-73909638-G-A',
            variant_type: 'snv',
            stats: {
                aa_change: 'p.Arg1463Gln',
                ab_or_bb_genotypes: 0.0716716615025143,
                bb_genotypes: 0.00147305328389292,
                cdna_change: 'c.4388G>A',
                consequence_type: EConsequenceType.MissenseVariant,
                effect_size: 0.07299793,
                effect_size_lci: 0.04173893,
                effect_size_standard_error: 0.015948436,
                effect_size_uci: 0.104256931,
                exon_rank: "'34/35'",
                maf: 0.0365723573932036,
                no_aa_genotypes: 54828,
                no_ab_genotypes: 4146,
                no_bb_genotypes: 87,
                no_samples: 59061,
                transcript_id: 'ENST00000296794',
            },
        },
        {
            ancestry: ancestryMock,
            gene_id: 'ARHGEF28',
            gene_name: 'ARHGEF28',
            pvalue: '0.0000113',
            variant_id: '9c4da04a-cbef-4f8e-bc61-cc724439058f',
            variant_name: '5-73909872-C-G',
            variant_type: 'snv',
            stats: {
                aa_change: 'p.Ala1541Gly',
                ab_or_bb_genotypes: 0.0703151474076584,
                bb_genotypes: 0.00145713317519485,
                cdna_change: 'c.4622C>G',
                consequence_type: EConsequenceType.MissenseVariant,
                effect_size: 0.070669941,
                effect_size_lci: 0.039119503,
                effect_size_standard_error: 0.016097128,
                effect_size_uci: 0.10222038,
                exon_rank: "'34/35'",
                maf: 0.0358861402914266,
                no_aa_genotypes: 54870,
                no_ab_genotypes: 4064,
                no_bb_genotypes: 86,
                no_samples: 59020,
                transcript_id: 'ENST00000296794',
            },
        },
        {
            ancestry: ancestryMock,
            gene_id: 'ARHGEF28',
            gene_name: 'ARHGEF28',
            pvalue: '0.0000175',
            variant_id: '1f9514bb-b032-4247-81f3-5a109f7cb0a8',
            variant_name: '5-73909892-C-T',
            variant_type: 'snv',
            stats: {
                aa_change: 'p.Pro1548Ser',
                ab_or_bb_genotypes: 0.0705537580675216,
                bb_genotypes: 0.00145681229143022,
                cdna_change: 'c.4642C>T',
                consequence_type: EConsequenceType.MissenseVariant,
                effect_size: 0.069004953,
                effect_size_lci: 0.03751145,
                effect_size_standard_error: 0.016068079,
                effect_size_uci: 0.100498455,
                exon_rank: '34/35',
                maf: 0.0360052851794759,
                no_aa_genotypes: 54868,
                no_ab_genotypes: 4079,
                no_bb_genotypes: 86,
                no_samples: 59033,
                transcript_id: 'ENST00000296794',
            },
        },
    ], 1),
    [collapsingModelsResponseMock[2].id]: generateData([
        {
            ancestry: ancestryMock,
            gene_id: 'ADGRV1',
            gene_name: 'ADGRV1',
            pvalue: '0.0000304',
            variant_id: 'ac107f4a-c2fb-4f63-8869-eed107d549fd',
            variant_name: '5-90810429-C-T',
            variant_type: 'snv',
            stats: {
                aa_change: 'p.Pro5057Ser',
                ab_or_bb_genotypes: 0.00193017507026515,
                bb_genotypes: 0,
                cdna_change: 'c.15169C>T',
                consequence_type: EConsequenceType.MissenseVariant,
                effect_size: 0.390936958,
                effect_size_lci: 0.207234618,
                effect_size_standard_error: 0.093725485,
                effect_size_uci: 0.574639297,
                exon_rank: '74/90',
                maf: 0.000965087535132573,
                no_aa_genotypes: 58948,
                no_ab_genotypes: 114,
                no_bb_genotypes: 0,
                no_samples: 59062,
                transcript_id: 'ENST00000405460',
            },
        },
        {
            ancestry: ancestryMock,
            gene_id: 'DAXX',
            gene_name: 'DAXX',
            pvalue: '0.0000228',
            variant_id: '1fdf56e0-b49e-4da1-9485-413900dd7b91',
            variant_name: '6-33319066-G-C',
            variant_type: 'snv',
            stats: {
                aa_change: 'p.Leu698Leu',
                ab_or_bb_genotypes: 0.000507932207981308,
                bb_genotypes: 0,
                cdna_change: 'c.2094C>G',
                consequence_type: EConsequenceType.SynonymousVariant,
                effect_size: -0.773385058,
                effect_size_lci: -1.131246085,
                effect_size_standard_error: 0.18258177,
                effect_size_uci: -0.415524031,
                exon_rank: '7/8',
                maf: 0.000253966103990654,
                no_aa_genotypes: 59033,
                no_ab_genotypes: 30,
                no_bb_genotypes: 0,
                no_samples: 59063,
                transcript_id: 'ENST00000266000',
            },
        },
        {
            ancestry: ancestryMock,
            gene_id: 'F9',
            gene_name: 'F9',
            pvalue: '0.0000316',
            variant_id: '113c51ac-5fc0-43e8-bdf0-e9129c2acf83',
            variant_name: 'X-139560836-T-C',
            variant_type: 'snv',
            stats: {
                aa_change: 'p.Val273Val',
                ab_or_bb_genotypes: 0.00298275649058402,
                bb_genotypes: 0.000600081187454773,
                cdna_change: 'c.819T>C',
                consequence_type: EConsequenceType.SynonymousVariant,
                effect_size: 0.713730179,
                effect_size_lci: 0.377608446,
                effect_size_standard_error: 0.171490168,
                effect_size_uci: 1.049851912,
                exon_rank: "'7/8'",
                maf: 0.0017914188390194,
                no_aa_genotypes: 56490,
                no_ab_genotypes: 135,
                no_bb_genotypes: 34,
                no_samples: 56659,
                transcript_id: 'ENST00000218099',
            },
        },
    ], 1),
    [collapsingModelsResponseMock[3].id]: [],
    [collapsingModelsResponseMock[4].id]: [],
    [collapsingModelsResponseMock[5].id]: [],
};
export const phenotypeCollectionPLRResponseMock = {
    [collapsingModelsResponseMock[0].id]: generateData([
        {
            phenotype_name: phenotypeNameMock,
            phenotype_id: phenotypeIdMock,
            category_id: phenotypicCategoryIdMock,
            category_name: phenotypicCategoryNameMock,
            category_short_name: phenotypicCategoryShortNameMock,
            pvalue: '0.0001706196132176885',
            stats: {
                no_samples: 58457,
                no_cases: 12300,
                no_controls: 20000,
                effect_size: -0.097098852,
                effect_size_standard_error: 0.02295762,
                effect_size_lci: -0.142095891,
                effect_size_uci: -0.052101813,
            },
        },
        {
            phenotype_name: 'TEST 41202#BlockF60-F69#F60-F69 Disorders of adult personality and behaviour',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826031',
            category_name: 'Chapter XIV of the genitourinary system',
            category_short_name: 'Genitourinary system',
            pvalue: '0.00002269089747366948',
            stats: {
                no_samples: 58052,
                no_cases: 12300,
                no_controls: 20000,
                effect_size: -0.047699713,
                effect_size_lci: -0.070210534,
                effect_size_standard_error: 0.011485084,
                effect_size_uci: -0.025188892,
            },
        },
        {
            phenotype_name: 'TEST 41202#BlockF60-F69#F60-F69 Disorders of adult personality and behaviour',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826028',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826028',
            category_name: 'Chapter VIII Diseases of the ear and mastoid process',
            category_short_name: 'Ear diseases',
            pvalue: '0.00002269089747366948',
            stats: {
                no_samples: 57423,
                no_cases: 12300,
                no_controls: 20000,
                effect_size: 0.710836396,
                effect_size_lci: 0.384262707,
                effect_size_standard_error: 0.166618778,
                effect_size_uci: 1.037410084,
            },
        },
    ], 1),
    [collapsingModelsResponseMock[1].id]: generateData([
        {
            phenotype_name: 'TEST 41202#BlockF60-F69#F60-F69 Disorders of adult personality and behaviour',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826028',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826028',
            category_name: 'Chapter VIII Diseases of the ear and mastoid process',
            category_short_name: 'Ear diseases',
            pvalue: '0.00002269089747366948',
            stats: {
                no_samples: 59061,
                no_cases: 12300,
                no_controls: 20000,
                effect_size: 0.07299793,
                effect_size_lci: 0.04173893,
                effect_size_standard_error: 0.015948436,
                effect_size_uci: 0.104256931,
            },
        },
        {
            phenotype_name: 'TEST 41202#BlockF60-F69#F60-F69 Disorders of adult personality and behaviour',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826027',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826027',
            category_name: 'Chapter IX Diseases of the eye and adnexa',
            category_short_name: 'Eye diseases',
            pvalue: '0.00002269089747366948',
            stats: {
                no_samples: 59020,
                no_cases: 12300,
                no_controls: 20000,
                effect_size: 0.070669941,
                effect_size_lci: 0.039119503,
                effect_size_standard_error: 0.016097128,
                effect_size_uci: 0.10222038,
            },
        },
        {
            phenotype_name: 'TEST 41202#BlockF60-F69#F60-F69 Disorders of adult personality and behaviour',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826026',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826026',
            category_name: 'Chapter XIII Diseases of the musculos system and connective tissue',
            category_short_name: 'Musculos system',
            pvalue: '0.00002269089747366948',
            stats: {
                no_samples: 59033,
                no_cases: 12300,
                no_controls: 20000,
                effect_size: 0.069004953,
                effect_size_lci: 0.03751145,
                effect_size_standard_error: 0.016068079,
                effect_size_uci: 0.100498455,
            },
        },
    ], 1),
    [collapsingModelsResponseMock[2].id]: generateData([
        {
            phenotype_name: 'TEST 41202#BlockF60-F69#F60-F69 Disorders of adult personality and behaviour',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826025',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826025',
            category_name: 'Chapter XVII Congenital malformations| deform d chromosomal abnormalities',
            category_short_name: 'Congenital malformations',
            pvalue: '0.00002269089747366948',
            stats: {
                no_samples: 59062,
                no_cases: 12300,
                no_controls: 20000,
                effect_size: 0.390936958,
                effect_size_lci: 0.207234618,
                effect_size_standard_error: 0.093725485,
                effect_size_uci: 0.574639297,
            },
        },
        {
            phenotype_name: 'TEST 41202#BlockF60-F69#F60-F69 Disorders of adult personality and behaviour',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826024',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826027',
            category_name: 'Chapter IX Diseases of the eye and adnexa',
            category_short_name: 'Eye diseases',
            pvalue: '0.00002269089747366948',
            stats: {
                no_samples: 59063,
                no_cases: 12300,
                no_controls: 20000,
                effect_size: -0.773385058,
                effect_size_lci: -1.131246085,
                effect_size_standard_error: 0.18258177,
                effect_size_uci: -0.415524031,
            },
        },
        {
            phenotype_name: 'TEST 41202#BlockF60-F69#F60-F69 Disorders of adult personality and behaviour',
            phenotype_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826023',
            category_id: '5bc0cd5d-4e55-4e99-bf03-52e12f826023',
            category_name: 'Chapter VIII Diseases of the ear and mastoid process',
            category_short_name: 'Ear diseases',
            pvalue: '0.0001315641053375005',
            stats: {
                no_samples: 56659,
                no_cases: 12300,
                no_controls: 20000,
                effect_size: 0.713730179,
                effect_size_lci: 0.377608446,
                effect_size_standard_error: 0.171490168,
                effect_size_uci: 1.049851912,
            },
        },
    ], 1),
    [collapsingModelsResponseMock[3].id]: [],
    [collapsingModelsResponseMock[4].id]: [],
    [collapsingModelsResponseMock[5].id]: [],
};
// Associations
export const phenotypeBinaryAssociationGLRResponseMock = (phenotypeBinaryCollectionGLRResponseMock[collapsingModelIdMock][0]);
export const phenotypeContinuousAssociationGLRResponseMock = (phenotypeContinuousCollectionGLRResponseMock[collapsingModelIdMock][0]);
export const phenotypeBinaryAssociationVLRResponseMock = (phenotypeBinaryCollectionVLRResponseMock[collapsingModelIdMock][0]);
export const phenotypeContinuousAssociationVLRResponseMock = (phenotypeContinuousCollectionVLRResponseMock[collapsingModelIdMock][0]);
export const phenotypeAssociationPLRResponseMock = (phenotypeCollectionPLRResponseMock[collapsingModelIdMock][0]);
// Response
export const phenotypeDataResponseMock = {
    phenotype_name: phenotypeNameMock,
    phenotype_id: phenotypeIdMock,
    category_id: phenotypicCategoryIdMock,
    category_name: phenotypicCategoryNameMock,
    category_short_name: phenotypicCategoryShortNameMock,
    traits_type: ETraitsTypes.Binary,
};
export const getEmptyPhenotypeResponse = (traits_type = ETraitsTypes.Binary) => (Object.assign(Object.assign({}, phenotypeDataResponseMock), { traits_type, associations: {} }));
export const phenotypeBinaryGLRResponseMock = (Object.assign(Object.assign({}, getEmptyPhenotypeResponse()), { associations: phenotypeBinaryCollectionGLRResponseMock }));
export const phenotypeContinuousGLRResponseMock = (Object.assign(Object.assign({}, getEmptyPhenotypeResponse(ETraitsTypes.Continuous)), { associations: phenotypeContinuousCollectionGLRResponseMock }));
export const phenotypeBinaryVLRResponseMock = (Object.assign(Object.assign({}, getEmptyPhenotypeResponse()), { associations: phenotypeBinaryCollectionVLRResponseMock }));
export const phenotypeContinuousVLRResponseMock = (Object.assign(Object.assign({}, getEmptyPhenotypeResponse(ETraitsTypes.Continuous)), { associations: phenotypeContinuousCollectionVLRResponseMock }));
export const phenotypePLRResponseMock = Object.assign(Object.assign({}, getEmptyPhenotypeResponse()), { associations: phenotypeCollectionPLRResponseMock });
// Fetch mocks
export const requestParamsUKB = {
    datasetVersionId: UKBDatasetVersionId,
    phenotypeId: phenotypeIdMock,
    encodedPhenotypeName: encodedPhenotypeNameMock,
};
export const requestParamsUKB300K = {
    datasetVersionId: GenesResponseDatasetVersionIdMock,
    phenotypeId: phenotypeIdMock,
    encodedPhenotypeName: encodedPhenotypeNameMock,
};
export const requestParamsFinnGen = {
    datasetVersionId: FinnGenDatasetVersionId,
    phenotypeId: phenotypeIdMock,
    encodedPhenotypeName: encodedPhenotypeNameMock,
};
export const requestParamsUKBWes = {
    datasetVersionId: UBKWesDatasetVersionId,
    phenotypeId: phenotypeIdMock,
    encodedPhenotypeName: encodedPhenotypeNameMock,
};
export const requestParamsUKBWgs = {
    datasetVersionId: UBKWgsDatasetVersionId,
    phenotypeId: phenotypeIdMock,
    encodedPhenotypeName: encodedPhenotypeNameMock,
};
const mockedURL = {
    // Gene level result
    [createMockedURL(phenotypeResourceGLR, requestParamsUKB)]: {
        full: phenotypeBinaryGLRResponseMock,
        empty: getEmptyPhenotypeResponse(),
    },
    [createMockedURL(phenotypeResourceGLR, requestParamsFinnGen)]: {
        full: phenotypeBinaryGLRResponseMock,
        empty: getEmptyPhenotypeResponse(),
    },
    [createMockedURL(phenotypeResourceGLR, requestParamsUKB300K)]: {
        full: phenotypeBinaryGLRResponseMock,
        empty: getEmptyPhenotypeResponse(),
    },
    // Variant level result
    [createMockedURL(phenotypeResourceVLR, requestParamsUKB)]: {
        full: phenotypeBinaryVLRResponseMock,
        empty: getEmptyPhenotypeResponse(),
    },
    [createMockedURL(phenotypeResourceVLR, requestParamsFinnGen)]: {
        full: phenotypeBinaryVLRResponseMock,
        empty: getEmptyPhenotypeResponse(),
    },
    [createMockedURL(phenotypeResourceVLR, requestParamsUKB300K)]: {
        full: phenotypeBinaryVLRResponseMock,
        empty: getEmptyPhenotypeResponse(),
    },
    // Phenotype level result
    [createMockedURL(phenotypeResourcePLR, requestParamsUKB)]: {
        full: phenotypePLRResponseMock,
        empty: getEmptyPhenotypeResponse(),
    },
    [createMockedURL(phenotypeResourcePLR, requestParamsFinnGen)]: {
        full: phenotypePLRResponseMock,
        empty: getEmptyPhenotypeResponse(),
    },
    [createMockedURL(phenotypeResourcePLR, requestParamsUKB300K)]: {
        full: phenotypePLRResponseMock,
        empty: getEmptyPhenotypeResponse(),
    },
};
export const fixtures = getMock(mockedURL);
export const AssociationsFilterUkb470Mock = [
    {
        id: '15e7b2db-8043-49f5-9c88-8d881c786a40',
        phenotype: {
            id: '7abb32d7-95ba-4f06-bdbf-8885b66cfa86',
            name: 'Lymphocyte count',
        },
        traitsType: ETraitsTypes.Continuous,
        AAF: 0.147068112848506,
        MAF: 0.147068112848506,
        coordinates: {
            x: -0.8324814803324845,
            y: -0.0879306084475774,
        },
    },
    {
        id: '15e7b2db-8043-49f5-9c88-8d881c786a40',
        phenotype: {
            id: '7abb32d7-95ba-4f06-bdbf-8885b66cfa86',
            name: 'Lymphocyte count',
        },
        traitsType: ETraitsTypes.Continuous,
        AAF: 0.67068112848506,
        MAF: 0.67068112848506,
        coordinates: {
            x: -0.8324814803324845,
            y: -0.0879306084475774,
        },
    },
    {
        id: '15e7b2db-8043-49f5-9c88-8d881c786a40',
        phenotype: {
            id: '7abb32d7-95ba-4f06-bdbf-8885b66cfa86',
            name: 'Lymphocyte count',
        },
        traitsType: ETraitsTypes.Binary,
        controlAaf: 0.7068112848506,
        controlMaf: 0.7068112848506,
        coordinates: {
            x: -0.8324814803324845,
            y: -0.0879306084475774,
        },
    },
    {
        id: '15e7b2db-8043-49f5-9c88-8d881c786a40',
        phenotype: {
            id: '7abb32d7-95ba-4f06-bdbf-8885b66cfa86',
            name: 'Lymphocyte count',
        },
        traitsType: ETraitsTypes.Binary,
        controlAaf: 0.17068112848506,
        controlMaf: 0.17068112848506,
        coordinates: {
            x: -0.8324814803324845,
            y: -0.0879306084475774,
        },
    },
];

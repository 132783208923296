import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import classnames from 'classnames';
import { AZLogo, ButtonLink, Copyright, ExternalLink, } from 'Shared/components';
import Logo from 'Shared/assets/images/cgrLogo.svg';
import Title from 'Common/components/Title/Title';
import Menu from 'Common/components/Menu/Menu';
import PageErrorBoundary from 'Common/components/PageErrorBoundary/PageErrorBoundary';
import { COLUMBUS_CGR_URL, COLUMBUS_LINK_LABEL, PORTAL_NAME } from 'Common/consts';
import { EPageName } from 'Common/types';
import useStatistic from 'Common/hooks/useStatistic/useStatistic';
import Link from 'Common/components/Link/Link';
import useRouter from 'Router/hooks/useRouter/useRouter';
import { PHENOTYPE_CATALOGUE_VIEW_PATH } from 'Router/consts';
import { SearchBox } from '../SearchBox';
import SearchDisclaimerPublic from '../SearchDisclaimer/SearchDisclaimer.public';
import ExpandableDisclaimer from '../ExpandableDisclaimer/ExpandableDisclaimer';
import styles from './SearchPage.css';
/* eslint-disable react/jsx-one-expression-per-line */
const SearchPage = () => {
    useStatistic();
    const { getLocation } = useRouter();
    const { pathname } = getLocation();
    const referrerState = useMemo(() => ({
        referrerURI: pathname,
        referrerData: {
            name: 'main page',
        },
    }), [pathname]);
    const isPublic = FEATURE_FLAGS.PUBLIC_VERSION;
    return (_jsx(PageErrorBoundary, Object.assign({ pageName: EPageName.Search }, { children: _jsxs("article", Object.assign({ className: styles.layout }, { children: [!isPublic && (_jsx(ExpandableDisclaimer, { title: "AstraZeneca Confidential" }, void 0)), _jsxs("div", Object.assign({ className: classnames(styles.content, { [styles.internalContent]: !isPublic }) }, { children: [_jsxs("main", Object.assign({ className: styles.main }, { children: [_jsx(Logo, { className: styles.logo, alt: PORTAL_NAME }, void 0), _jsx(Title, { className: styles.title }, void 0), isPublic && (_jsx("div", Object.assign({ className: styles.searchDiscaimer }, { children: _jsx(SearchDisclaimerPublic, {}, void 0) }), void 0)), _jsx("div", Object.assign({ className: styles.search }, { children: _jsx(SearchBox, { referrerState: referrerState }, void 0) }), void 0), _jsxs("div", Object.assign({ className: styles.linkContainer }, { children: [FEATURE_FLAGS.PHENOTYPE_CATALOGUE_ENABLED
                                            && (_jsx(ButtonLink, Object.assign({ link: Link, className: styles.navLink, to: PHENOTYPE_CATALOGUE_VIEW_PATH, referrerData: referrerState.referrerData, target: "_blank", withIcon: true }, { children: "Open Phenotype catalogue" }), void 0)), !FEATURE_FLAGS.PUBLIC_VERSION
                                            && (_jsx(ButtonLink, Object.assign({ link: ExternalLink, className: styles.navLink, to: COLUMBUS_CGR_URL, target: "_blank", withIcon: true }, { children: COLUMBUS_LINK_LABEL }), void 0))] }), void 0)] }), void 0), _jsxs("footer", Object.assign({ className: styles.footer }, { children: [_jsxs("div", Object.assign({ className: styles.listContainer }, { children: [_jsx(AZLogo, { altText: PORTAL_NAME, className: styles.footerLogo }, void 0), _jsx(Menu, { referrerState: referrerState, customListClassName: styles.list, customLinkClassName: styles.menuLinks, customIconClassName: styles.menuLinksIcon }, void 0)] }), void 0), _jsx(Copyright, { className: styles.copyrightContainer }, void 0)] }), void 0)] }), void 0)] }), void 0) }), void 0));
};
export default memo(SearchPage);

import { ELevelResult } from 'Shared/types';
/**
 * Regexp which is used to find chapter's number from its name
 */
export const CHAPTER_NUMBER_PATTERN = /\b([IVXLCDM]+)\b/i;
/**
 * Default delay before multiselect filters will be applied
 */
export const MULTISELECT_FILTERING_DELAY = 500;
export const INTERNAL_PORTAL_NAME = APP_CONFIG.INTERNAL_APP_TITLE;
export const PUBLIC_PORTAL_NAME = APP_CONFIG.PUBLIC_APP_TITLE;
/**
 * Name of the portal which will be used on UI
 * Separate variable is used in case UI name would differ from application's title
 */
export const PORTAL_NAME = FEATURE_FLAGS.PUBLIC_VERSION
    ? PUBLIC_PORTAL_NAME
    : INTERNAL_PORTAL_NAME;
/**
 * Label for Loading component
 */
export const DEFAULT_LOADING_LABEL = 'Data is loading';
export const LEVEL_RESULT_NAME = {
    [ELevelResult.Gene]: 'Gene-level results',
    [ELevelResult.Variant]: 'Variant-level results',
    [ELevelResult.Phenotype]: 'Phenotype-level results',
};
export const DEFAULT_LENGTH_LIMIT = 20;
export const CONSEQUENCE_TYPE_LENGTH_LIMIT = 60;
export const COLUMBUS_LINK_LABEL = 'Columbus: Build your own cohort';
export const RARE_VARIANT_ASSOCIATIONS_WITH_PLASMA_PROTEIN_LEVELS_UKB_TITLE = 'Rare variant associations with plasma protein levels in the UK Biobank';
export * from './http';
export * from './env';
export * from './export';
export * from './datasets';
export * from './chars';
export * from './dashboard';
export * from './links';
export * from './plot';
export * from './api';

import { X_CHROMOSOME_ID, Y_CHROMOSOME_ID, EChromosomes } from '../consts';
/**
 * Class service with static methods for work with Chromosome
 */
export class ChromosomeService {
    /**
     * Checks if provided id is a valid one
     *
     * Chromosome id should be a string representing a number from 1 to 24
     * or following labels: 'X', 'Y', 'XX', 'XY'
     *
     * @static
     * @private
     * @param id - Chromosome id
     * @returns Valid id or not
     */
    static isValidId(id) {
        if (id === EChromosomes.X || id === EChromosomes.Y
            || id === EChromosomes.XX || id === EChromosomes.XY) {
            return true;
        }
        const numericId = typeof id === 'string' ? parseInt(id, 10) : id;
        if (Number.isNaN(numericId) || numericId < 1 || numericId > Y_CHROMOSOME_ID) {
            return false;
        }
        return true;
    }
    /**
     * Transforms any valid form of 23 and 24 chromosomes into 'X' and 'Y' ids
     *
     * @static
     * @private
     * @param id - Chromosome id
     * @returns Normalized form of id
     */
    static normalizeId(id) {
        if (typeof id === 'number') {
            return `${id}`;
        }
        return id;
    }
    /**
     * Transforms any valid form of 'X' and 'Y' chromosome ids into '23' and '24' numbers
     *
     * @static
     * @param id - Chromosome id
     * @returns Normalized form of related number
     */
    static getNumberFromId(id) {
        if (id === EChromosomes.X) {
            return X_CHROMOSOME_ID;
        }
        if (id === EChromosomes.Y) {
            return Y_CHROMOSOME_ID;
        }
        if (typeof id === 'string') {
            return parseFloat(id);
        }
        return id;
    }
    /**
     * Generates label from id
     *
     * @static
     * @private
     * @param id - Chromosome id
     * @returns Label
     */
    static getLabelFromId(id) {
        if (id === EChromosomes.XX) {
            return EChromosomes.X;
        }
        if (id === EChromosomes.XY) {
            return EChromosomes.Y;
        }
        if (id === X_CHROMOSOME_ID) {
            return EChromosomes.X;
        }
        if (id === Y_CHROMOSOME_ID) {
            return EChromosomes.Y;
        }
        return ChromosomeService.normalizeId(id);
    }
    /**
     * Creates Chromosome object from API response
     *
     * @static
     * @param - Chromosome response
     * @returns Chromosome object
     */
    static fromResponse({ number, location, length }) {
        return ChromosomeService.create(number, location, length);
    }
    /**
     * Creates a Chromosome object
     *
     * @static
     * @param number - Chromosome id/number
     * @param location - Position of chromosome in chain
     * @param length - Length of chromosome
     * @returns Chromosome object
     */
    static create(number, location, length) {
        if (!ChromosomeService.isValidId(number)) {
            throw new Error(`Invalid number provided for Chromosome: ${number}. Should be either a number from 1 to ${X_CHROMOSOME_ID} or one other following string: ${EChromosomes.X}, ${EChromosomes.Y}, ${EChromosomes.XX}, ${EChromosomes.XY}`);
        }
        return {
            number: ChromosomeService.normalizeId(number),
            label: ChromosomeService.getLabelFromId(number),
            location,
            length,
        };
    }
}

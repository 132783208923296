// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SuggestionWithHighlights__ellipsis__2hE21{overflow:hidden;text-overflow:ellipsis}", "",{"version":3,"sources":["webpack://./src/views/search/components/SuggestionWithHighlights/SuggestionWithHighlights.css"],"names":[],"mappings":"AAAA,2CAEE,eAAgB,CADhB,sBAEF","sourcesContent":[".ellipsis {\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ellipsis": "SuggestionWithHighlights__ellipsis__2hE21"
};
export default ___CSS_LOADER_EXPORT___;

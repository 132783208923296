var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import SearchSuggestions from '../SearchSuggestions/SearchSuggestions';
import RecentSuggestion from '../RecentSuggestion/RecentSuggestion';
const RecentSuggestions = (props) => {
    const { suggestions, onRecentSuggestionRemove } = props, restSearchSuggestionsProps = __rest(props, ["suggestions", "onRecentSuggestionRemove"]);
    const renderItem = useCallback((itemProps) => (_jsx(RecentSuggestion, Object.assign({}, itemProps, { onRemove: onRecentSuggestionRemove }), void 0)), [onRecentSuggestionRemove]);
    if (suggestions.length === 0) {
        return null;
    }
    return (_jsx(SearchSuggestions, Object.assign({}, restSearchSuggestionsProps, { suggestions: suggestions, hasNoticeText: false, renderItem: renderItem }), void 0));
};
export default memo(RecentSuggestions);

import { EProvider } from 'Common/consts';
import { createMockedURL, getMock } from 'API/utils';
import resource from './resource';
export const DatasetVersionIds = {
    ukb450: 'b142d575-23ae-4adb-934b-1eedf3894f8e',
    ukb300: 'ce2e9026-257f-48aa-9d67-5d35c70ea347',
    finngenR8: 'd12c7ff0-3f24-444a-bf77-5f36507216c5',
};
export const UKB450kResponseMock = {
    data: {
        dataset_catalogs: [
            {
                provider: EProvider.UKB,
                catalogs: [
                    {
                        dataset_id: '0c13a145-4a35-4c3b-b4b8-c9b07b0532f6',
                        dataset_name: 'CGR INTERNAL PHEWAS',
                        version_id: DatasetVersionIds.ukb450,
                        version_name: '450k Public',
                        provider: EProvider.UKB,
                        metadata: [
                            'Phewas CGR_PHESANT v1.0.2',
                            'UKB Phenotypic Release: v23',
                            'Other extra statistics: 10000',
                        ],
                        catalog: [
                            {
                                category_id: 'b4ebb2df-0cb1-46ec-87e9-0f3df4f893b6',
                                category_name: 'Chapter I Certain infectious and parasitic diseases',
                                subcategories_and_phenotypes: [
                                    {
                                        subcategory: 'A15-A19 Tuberculosis long subchapter name, long subchapter name, long subchapter name, long subchapter name, long subchapter name,',
                                        phenotypes: [
                                            {
                                                phenotype_id: '80dfc202-c618-4421-857b-24fc5ce35d07',
                                                phenotype_name: 'Union#BlockA15-A19#A15-A19 Tuberculosis',
                                                source: 'glr',
                                            },
                                        ],
                                    },
                                    {
                                        subcategory: 'A92-A99 Arthropod-borne viral fevers and viral haemorrhagic fevers',
                                        phenotypes: [
                                            {
                                                phenotype_id: '9aa920c9-c749-4e0a-9106-ba0437e934bd',
                                                phenotype_name: 'Union#A95#A95 Yellow fever, long phenotype name, long phenotype name, long phenotype name, long phenotype name, long phenotype name',
                                                source: 'glr',
                                            },
                                            {
                                                phenotype_id: 'c537391b-29d8-47c0-b483-93d7ca907bb1',
                                                phenotype_name: 'Union#A97#A97 Dengue',
                                                source: 'glr',
                                            },
                                        ],
                                    },
                                    {
                                        subcategory: 'Other Phenotypes',
                                        phenotypes: [
                                            {
                                                phenotype_id: '51a34020-0097-4ca8-bad2-d5c1f1047f00',
                                                phenotype_name: 'BKV seropositivity for Human Polyomavirus BKV',
                                                source: 'glr',
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                category_id: '3f4f28c9-ae1e-4f4f-93a9-ab29ddda4314',
                                category_name: 'Chapter II Neoplasms',
                                subcategories_and_phenotypes: [
                                    {
                                        subcategory: 'C00-C14 Malignant neoplasms of lip, oral cavity and pharynx',
                                        phenotypes: [
                                            {
                                                phenotype_id: 'afa50c86-d817-4c3f-983e-2dbde6384425',
                                                phenotype_name: 'Union#BlockC00-C14#C00-C14 Malignant neoplasms of lip| oral cavity and pharynx',
                                                source: 'glr',
                                            },
                                        ],
                                    },
                                    {
                                        subcategory: 'D10-D36 Benign neoplasms',
                                        phenotypes: [
                                            {
                                                phenotype_id: '2a47a4c1-d83c-4d81-9f24-bab0f1008bc9',
                                                phenotype_name: 'Union#BlockD10-D36#D10-D36 Benign neoplasms',
                                                source: 'glr',
                                            },
                                        ],
                                    },
                                    {
                                        subcategory: 'Self Reported Conditions',
                                        phenotypes: [
                                            {
                                                phenotype_id: 'c570072b-a871-48c4-99ef-dd68ee4ba243',
                                                phenotype_name: '20001#1001#lung cancer',
                                                source: 'glr',
                                            },
                                            {
                                                phenotype_id: '64a05e4f-588e-44cb-bc6b-4cf31f19ab4b',
                                                phenotype_name: '20001#1002#breast cancer',
                                                source: 'glr',
                                            },
                                            {
                                                phenotype_id: 'ffcee424-f2e6-4172-a017-fcf36092d2cd',
                                                phenotype_name: '20001#1003#skin cancer',
                                                source: 'vlr',
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                category_name: 'Chapter XVIII Symptoms signs and abnormal clinical and laboratory findings not elsewhere classified',
                                subcategories_and_phenotypes: [],
                            },
                            {
                                category_id: '927db0c8-37d9-4210-98e6-eaa54b298b89',
                                category_name: 'Chapter III Diseases of the blood and blood-forming organs and certain disorders involving the immune mechanism',
                                subcategories_and_phenotypes: [
                                    {
                                        subcategory: 'D50-D53 Nutritional anaemias',
                                        phenotypes: [
                                            {
                                                phenotype_id: '113607df-82e9-4e91-9851-68a3da970e93',
                                                phenotype_name: 'Union#BlockD50-D53#D50-D53 Nutritional anaemias',
                                                source: 'vlr',
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                category_id: 'e40cad50-9864-4e62-a326-1e00eb24e099',
                                category_name: 'Chapter XXII Codes for special purposes',
                                subcategories_and_phenotypes: [
                                    {
                                        subcategory: 'U82-U85 Resistance to antimicrobial and antineoplastic drugs',
                                        phenotypes: [
                                            {
                                                phenotype_id: '5d842d61-9596-49c3-b0ae-b0f87665058f',
                                                phenotype_name: 'Union#BlockU82-U85#U82-U85 Resistance to antimicrobial and antineoplastic drugs',
                                                source: 'vlr',
                                            },
                                        ],
                                    },
                                    {
                                        subcategory: 'Other Phenotypes, Other Phenotypes, Other Phenotypes, Other Phenotypes, Other Phenotypes, Other Phenotypes, Other Phenotypes, Other Phenotypes',
                                        phenotypes: [],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
export const UKB300kResponseMock = {
    data: {
        dataset_catalogs: [
            {
                provider: EProvider.UKB,
                catalogs: [
                    {
                        dataset_id: '06db5df1-4226-4d5a-bf79-0d847a9a90c1',
                        dataset_name: 'CGR INTERNAL PHEWAS',
                        version_id: DatasetVersionIds.ukb300,
                        version_name: '300k Public',
                        provider: EProvider.UKB,
                        metadata: [
                            'Build: PEACOK v1.07',
                            'UKB Phenotypic Release: v27390 plus hospital episode statistics',
                            'UKB Exome Count: 269,171',
                        ],
                        catalog: [
                            {
                                category_id: '3f4f28c9-ae1e-4f4f-93a9-ab29ddda4314',
                                category_name: 'Chapter II Neoplasms',
                                subcategories_and_phenotypes: [
                                    {
                                        subcategory: 'Other Phenotypes long subchapter name, long subchapter name, long subchapter name, long subchapter name, long subchapter name, long subchapter name,',
                                        phenotypes: [
                                            {
                                                phenotype_id: '11f66d05-01cf-4ca3-92ac-08090268fb54',
                                                phenotype_name: '40001#C159#C15.9 Oesophagus| unspecified',
                                                source: 'glr',
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
export const FinngenR8ResponseMock = {
    data: {
        dataset_catalogs: [
            {
                provider: EProvider.FinnGen,
                catalogs: [
                    {
                        dataset_id: '1074bb02-150a-492f-a4ac-30a1e5e062bb',
                        dataset_name: 'FinnGen R8',
                        version_id: DatasetVersionIds.finngenR8,
                        version_name: 'R8',
                        provider: EProvider.FinnGen,
                        metadata: [
                            'FinnGen R8 GWAS',
                            'FinnGen phenotypes analysed: 2,202',
                            'FinnGen Sample Count: 342,499',
                        ],
                        catalog: [
                            {
                                category_id: '1500b85e-6881-46c5-b993-f4205308c5e9',
                                category_name: 'I Certain infectious and parasitic diseases (AB1_)',
                                subcategories_and_phenotypes: [
                                    {
                                        subcategory: '',
                                        phenotypes: [
                                            {
                                                phenotype_id: '3865cf5e-eff2-447b-8943-85440290e843',
                                                phenotype_name: 'Other cestode infections',
                                                source: 'vlr',
                                            },
                                            {
                                                phenotype_id: '394cbeb0-4828-4001-bac6-499d7703a122',
                                                phenotype_name: 'Mumps',
                                                source: 'vlr',
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                category_id: '301dc363-390c-400f-a899-ae10405c989e',
                                category_name: 'II Neoplasms, from cancer register (ICD-O-3)',
                                subcategories_and_phenotypes: [
                                    {
                                        subcategory: '',
                                        phenotypes: [
                                            {
                                                phenotype_id: 'fcac60b6-3c7e-4995-8e7a-ef564c652f47',
                                                phenotype_name: 'Malignant neoplasm of breast, HER-positive (controls excluding all cancers)',
                                                source: 'vlr',
                                            },
                                            {
                                                phenotype_id: 'dbf662b5-661d-43cf-9739-78ae0433cf4c',
                                                phenotype_name: 'Malignant neoplasm of bone and articular cartilage (controls excluding all cancers, controls excluding all cancers, controls excluding all cancers)',
                                                source: 'vlr',
                                            },
                                            {
                                                phenotype_id: 'dbf662b5-661d-43cf-9739-78ae0433cf4d',
                                                phenotype_name: 'Malignant neoplasm of bone and articular cartilage (controls excluding all cancers)',
                                                source: 'vlr',
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
const baseUrl = createMockedURL(resource, {});
const mockedURL = {
    [`${baseUrl}?version_id=${DatasetVersionIds.ukb450}`]: {
        full: UKB450kResponseMock,
        empty: {},
    },
    [`${baseUrl}?version_id=${DatasetVersionIds.ukb300}`]: {
        full: UKB300kResponseMock,
        empty: {},
    },
    [`${baseUrl}?version_id=${DatasetVersionIds.finngenR8}`]: {
        full: FinngenR8ResponseMock,
        empty: {},
    },
};
export const fixtures = getMock(mockedURL);

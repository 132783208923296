import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Link } from 'react-router-dom';
import Logo from 'Shared/assets/images/cgrLogoWhite.svg';
import Title from 'Common/components/Title/Title';
import { PORTAL_NAME } from 'Common/consts';
import { SEARCH_PATH } from 'Router/index';
import SearchBox from 'Search/components/SearchGeneBox/SearchGeneBox';
import { RECENT_SEARCH_LS_KEY } from 'Search/consts';
import styles from './Header.css';
const Header = ({ withSearch = true, children, referrerState, }) => (_jsxs("section", Object.assign({ className: styles.layout, "data-testid": "header" }, { children: [_jsxs(Link, Object.assign({ className: styles.link, to: SEARCH_PATH }, { children: [_jsx(Logo, { className: styles.logo, alt: PORTAL_NAME }, void 0), _jsx(Title, { className: styles.title }, void 0)] }), void 0), children && (_jsx("div", Object.assign({ className: styles.children }, { children: children }), void 0)), withSearch && (_jsx(SearchBox, { recentSuggestionsKey: RECENT_SEARCH_LS_KEY, referrerState: referrerState }, void 0))] }), void 0));
export default memo(Header);

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HTTPNotFoundError } from 'API/errors';
import { PfamDomain } from 'API/resources';
import { logger } from 'Common/utils/logger/logger';
export const fetchDomains = (transcriptsData, signal, setLoadingMessage) => __awaiter(void 0, void 0, void 0, function* () {
    const uniquePfamDomainIdsSet = new Set(transcriptsData.pfamDomains.map(({ pfamDomainId }) => pfamDomainId));
    const uniquePfarmDomainIds = Array.from(uniquePfamDomainIdsSet);
    setLoadingMessage('Loading variants and Pfam domains...');
    const pfamDomainsData = yield Promise.all(uniquePfarmDomainIds.map((pfamDomainId) => (PfamDomain.resource
        .getData({ pfamDomainId }, undefined, undefined, signal)
        .catch((err) => {
        if (err instanceof HTTPNotFoundError) {
            logger.warn(`No data was found for pfam domain ${pfamDomainId}. Empty data would be used instead`);
            return ({
                pfamAccession: '',
                name: '',
            });
        }
        throw err;
    })))).then((result) => result.filter((pfamDomain) => pfamDomain.pfamAccession.length > 0));
    return pfamDomainsData;
});

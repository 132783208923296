import { DEFAULT_API_SERVICE_URI } from 'API/consts';
import { logger } from 'Common/utils/logger/logger';
import { RESTResource } from '../RESTResource';
const searchParams = {
    phenotypeId: 'phenotype_id',
    ancestries: 'ancestries',
};
/* eslint-disable @typescript-eslint/indent */
export default new RESTResource(({ datasetVersionId, collapsingModelId }) => {
    var _a;
    const urlRoot = (_a = process.env.GLR_SERVICE_URI) !== null && _a !== void 0 ? _a : DEFAULT_API_SERVICE_URI;
    return `${urlRoot}/v1/${datasetVersionId}/${collapsingModelId}/qq-data`;
}, 'get', searchParams, (error) => {
    logger.warn(`Error fetching QQ data from service: ${error.message}. Empty data will be used instead`);
    return {
        quantiles: [],
    };
});

import { useCallback } from 'react';
import { useNavigate, useParams, useLocation, useMatch, } from 'react-router';
import { PathFactory, GO_BACK } from 'Router/consts';
import { Route } from 'Router/models';
/**
 * Facade hook with encalsulates routing actions
 *
 * @returns Hook API
 *  - navigate callback
 *  - redirect callback
 *  - path getter
 *  - params getter
 *  - match callback
 *  - location callback
 *  - go back helper
 *  - route match hook
 *  - replace history
 *  - route getter
 */
export default function useRouter() {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const getRouteState = useCallback(() => {
        const { state } = location;
        const currentRouteHistory = (state === null || state === void 0 ? void 0 : state.routeHistory) || [];
        const routeHistory = [...currentRouteHistory];
        const addToRouteHistory = (data) => {
            routeHistory.push(data);
        };
        const deleteFromRouteHistory = () => {
            routeHistory.pop();
        };
        return {
            referrerData: (state === null || state === void 0 ? void 0 : state.referrerData) || {},
            routeHistory,
            addToRouteHistory,
            deleteFromRouteHistory,
        };
    }, [location]);
    const navigateTo = useCallback((route, routeParams, referrerURI, referrerData) => {
        const { routeHistory, addToRouteHistory } = getRouteState();
        if (referrerURI !== undefined) {
            addToRouteHistory({
                referrerURI,
                referrerData,
            });
        }
        const URL = (route instanceof Route)
            ? route.getPath(routeParams)
            : PathFactory[route](routeParams);
        navigate(URL, { state: { routeHistory, referrerData } });
    }, [navigate, getRouteState]);
    const redirectTo = useCallback((route, routeParams) => {
        const { state } = location;
        navigate((route instanceof Route)
            ? route.getPath(routeParams)
            : PathFactory[route](routeParams), { state, replace: true });
    }, [navigate, location]);
    const getPath = useCallback((route, routeParams) => (PathFactory[route](routeParams)), []);
    const getParams = useCallback(() => params, [params]);
    const getLocation = useCallback(() => location, [location]);
    const goBack = useCallback(() => navigate(GO_BACK), [navigate]);
    const replaceHistory = useCallback((replacedLocation) => navigate(replacedLocation, { replace: true }), [navigate]);
    const getRoute = useCallback((pathname, referrerData) => {
        const { pathname: referrerURI } = getLocation();
        return {
            pathname,
            state: {
                referrerURI,
                referrerData,
            },
        };
    }, [getLocation]);
    const getReferrerData = useCallback(() => {
        var _a;
        const { routeHistory } = getRouteState();
        const { state } = getLocation();
        if (routeHistory.length) {
            return ((_a = routeHistory[routeHistory.length - 1]) === null || _a === void 0 ? void 0 : _a.referrerData) || {};
        }
        return (state === null || state === void 0 ? void 0 : state.referrerData) || {};
    }, [getLocation, getRouteState]);
    return {
        navigateTo,
        redirectTo,
        getPath,
        getParams,
        getLocation,
        goBack,
        getRouteState,
        useMatch,
        replaceHistory,
        getRoute,
        getReferrerData,
    };
}

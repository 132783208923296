/**
 * getValidatedPlotData
 *
 * @param plotData Array of the gene associations(binary and continuos)
 * @returns Array without associations with falsy oddsRatio
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getValidatedPlotData = (plotData) => {
    const isBinary = (associations, oddsRatio) => !!associations.find((association) => (association === null || association === void 0 ? void 0 : association[oddsRatio]) !== undefined);
    if (isBinary(plotData, 'oddsRatio')) {
        return plotData.filter((association) => association.oddsRatio);
    }
    return plotData;
};

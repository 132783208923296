import { escapeRegExp } from 'Shared/utils';
import { ETraitsTypes } from 'Shared/types';
import { BinaryVariantsDataType, ContinuousVariantsDataType } from 'QVView/types';
import { QVariantService } from './QVariant/QVariant.service';
import { QBinaryVariantService } from './QBinaryVariant/QBinaryVariant.service';
import { QContinuousVariantService } from './QContinuousVariant/QContinuousVariant.service';
/**
 * Class service with static methods for work with QVariants
 */
export class QVariantsService {
    /**
     * Private method for creating variants for getPlotData method
     * @private
     * @static
     * @param storage - Plot variants storage
     * @param id - The id of the variant
     * @param codon - The codon of the variant
     * @param count - The count of the variant
     * @param isSplice - The IsSplice boolean value of the variant
     * @param impactLevel - The impactLevel value of the variant
     * @returns Updated plot variant storage
     */
    static addVariant(storage, id, codon, count, isSplice, impactLevel) {
        const updatedStorage = storage;
        if (!updatedStorage[id]) {
            updatedStorage[id] = {};
        }
        if (!updatedStorage[id][codon]) {
            updatedStorage[id][codon] = {
                id,
                count: 0,
                codon,
                isSplice,
                impactLevel,
            };
        }
        updatedStorage[id][codon].count += count;
        return updatedStorage;
    }
    /**
     * Gets an boolean value which identify does QVariant association have binary type
     * @static
     * @param association - associations data
     * @returns boolean value
     */
    static isBinary(association) {
        var _a, _b, _c, _d;
        const continuousVariantCandidate = association;
        const firstNonMissingStat = (_d = (_c = (_b = (_a = continuousVariantCandidate.homo_genotype_major) !== null && _a !== void 0 ? _a : continuousVariantCandidate.hetero_genotype) !== null && _b !== void 0 ? _b : continuousVariantCandidate.homo_genotype_minor) !== null && _c !== void 0 ? _c : continuousVariantCandidate.minor_allele) !== null && _d !== void 0 ? _d : null;
        return firstNonMissingStat === null;
    }
    /**
     * Gets an boolean value which identify does QVariant have continuous type
     * @static
     * @param association - associations data
     * @returns boolean value
     */
    static isContinuous(association) {
        return !QVariantsService.isBinary(association);
    }
    /**
     * Gets an array of filtered variant unique ids array
     * @static
     * @param variantsCollection - Sorted collection of QVariants
     * @param variantsFilter - Variants filter value
     * @param consequentTypeFilter -An array of consequent type filters
     * @returns Array of filtered Variant ids
     */
    static filterBy({ collection, order, }, variantsFilter, consequentTypeFilter) {
        let filteredByVariantsData = order;
        if (variantsFilter && variantsFilter.trim()) {
            const filteringRegexp = new RegExp(escapeRegExp(variantsFilter), 'i');
            filteredByVariantsData = order
                .filter((id) => filteringRegexp.test(collection[id].variant_name));
        }
        const consequentTypeSet = new Set(consequentTypeFilter);
        return filteredByVariantsData.filter((id) => consequentTypeSet.has(collection[id].consequence_type));
    }
    /**
     * Gets an sorted collection of QVariants
     * @static
     * @param data - an array of QVariants from response
     * @param sortFn - A function for sorting variants
     * @param traitsType - A traits type
     * @returns Sorted collection of QVariants
     */
    static createCollection(data, sortFn, traitsType) {
        if (data.length === 0) {
            return {
                collection: {},
                order: [],
            };
        }
        const isBinaryData = traitsType === undefined
            ? QVariantsService.isBinary(data[0])
            : traitsType === ETraitsTypes.Binary;
        const collection = data.reduce((acc, variantData) => {
            const association = isBinaryData
                ? QBinaryVariantService.create(variantData)
                : QContinuousVariantService.create(variantData);
            return Object.assign(Object.assign({}, acc), { [association.id]: association });
        }, {});
        const order = Object.values(collection).sort(sortFn).map(({ id }) => id);
        return {
            collection,
            order,
        };
    }
    /**
     * Gets an array of unique QV consequence types from collection
     * @static
     * @param variantsCollection - collection of QVariants
     * @returns Array of unique QV consequence types
     */
    static getUniqueConsequenceTypes(collection) {
        const variantsArray = Object.values(collection);
        return Array.from(new Set(variantsArray.map((variant) => variant.consequence_type)));
    }
    /**
     * Gets an array of QVariants for the table
     * @static
     * @param variantsCollection - collection of QVariants
     * @param filteredVariantsIds - an array of filtered ids
     * @returns Array of unique QV consequence types
     */
    static getTableData(collection, filteredVariantsIds) {
        return filteredVariantsIds.map((id) => collection[id]);
    }
    /**
     * Gets an collection of LollipopVariants for the plot
     * @static
     * @param data - Array of QVariants
     * @returns An collection of LollipopVariants for the plot
     */
    static getPlotData(data) {
        const storage = {
            [BinaryVariantsDataType.Case]: {},
            [BinaryVariantsDataType.Control]: {},
            [ContinuousVariantsDataType.Variants]: {},
        };
        const variantsStorage = data.reduce((plotStorage, variant) => {
            const updatedPlotStorage = plotStorage;
            const isSplice = QVariantService.isSplice(variant);
            const impactLevel = QVariantService.getImpactLevel(variant.consequence_type);
            if (QVariantsService.isBinary(variant)) {
                const caseCount = QBinaryVariantService.getNumberOfQualifyingCases(variant);
                const controlCount = QBinaryVariantService.getNumberOfQualifyingControls(variant);
                if (caseCount > 0) {
                    const variantStorage = updatedPlotStorage[BinaryVariantsDataType.Case];
                    const updatedVariantStorage = QVariantsService.addVariant(variantStorage, variant.id, QVariantService.getAminoAcidNumber(variant), caseCount, isSplice, impactLevel);
                    updatedPlotStorage[BinaryVariantsDataType.Case] = updatedVariantStorage;
                }
                if (controlCount > 0) {
                    const variantStorage = updatedPlotStorage[BinaryVariantsDataType.Control];
                    const updatedVariantStorage = QVariantsService.addVariant(variantStorage, variant.id, QVariantService.getAminoAcidNumber(variant), controlCount, isSplice, impactLevel);
                    updatedPlotStorage[BinaryVariantsDataType.Control] = updatedVariantStorage;
                }
            }
            else {
                const variantsCount = QContinuousVariantService.getNumberOfQualifyingParticipants(variant);
                const variantStorage = updatedPlotStorage[ContinuousVariantsDataType.Variants];
                const updatedVariantStorage = QVariantsService.addVariant(variantStorage, variant.id, QVariantService.getAminoAcidNumber(variant), variantsCount, isSplice, impactLevel);
                updatedPlotStorage[ContinuousVariantsDataType.Variants] = updatedVariantStorage;
            }
            return updatedPlotStorage;
        }, storage);
        return Object.entries(variantsStorage)
            .reduce((plotStorage, [id, variants]) => {
            const storageId = id;
            const updatedStorage = plotStorage;
            updatedStorage[storageId] = Object
                .values(variants)
                .map((codonVariants) => Object.values(codonVariants))
                .reduce((variantsArray, codonVariants) => [
                ...variantsArray,
                ...codonVariants,
            ], []);
            return updatedStorage;
        }, {});
    }
    /**
     * Gets an array of QV from collection
     * @static
     * @param collection - collection of QVariants
     * @returns Array of QVariants
     */
    static getVariantsArray(collection) {
        return Object.values(collection);
    }
    /**
     * Gets an array of QV ids from collection
     * @static
     * @param collection - collection of QVariants
     * @returns Array of QVariants ids
     */
    static getVariantsIds(collection) {
        return Object.keys(collection);
    }
}
